import React from 'react';
import { withRouter } from 'react-router'
import * as actions from '../../../../redux/actions'
import { connect } from "react-redux";
import className from "classnames"
import { makeStyles, createStyles,  } from '@material-ui/core/styles';
import editWrapper from './../../../default/wrappers/editWrapper'
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Box from '@material-ui/core/Box';
import CustomIcon from './../../../components/icons/CustomIcon'

const useStyle = makeStyles( theme => createStyles({
  button:{
    cursor: "pointer",
    "&:hover":{
      color: "white",
      textDecoration: "underline",
    }
  },

  activeLarge:{
    background: theme.palette.primary.main,
  },

  activeSmall:{
    textDecoration: "underline"
  }
 
}))

function ButtonDrawerElement(props) {

  const { elementStyle, type, avatar, history, goToPath, redirectLink, onClickFunction, label , icon, info, active } = props

  const classes = useStyle()

  let style={
    ...elementStyle,
    ...props.style
  }
  
  const onClick = () => {
    if(goToPath){
      history.push(goToPath)
    }else if(redirectLink){
      window.location.replace(redirectLink)
    }else if(onClickFunction){
      props[onClickFunction] && props[onClickFunction]()
    } 
  }

  if(type === "small") {
    return (
      <Box
       
        color="white"  
        p={1}
        pl={3}
        className={className(classes.button, {[classes.activeSmall]: active})}
        onClick={onClick}
      >
        <Box fontSize="clamp(0.7em, 2vw, 0.8em)"  display="flex" flexDirection="row" alignItems="center">
            <Box pr={1} fontSize="clamp(0.8em, 2vw, 0.9em)" display="flex" flexDirection="row" alignItems="center">
                <CustomIcon name={icon}/> 
            </Box>
            <Typography variant="body2">{label}</Typography>
            <Box pl="4px">
                <Typography variant="body2">({info})</Typography>
            </Box>
        </Box>
      </Box>
    )
  }else{
    return (
      <ButtonBase onClick={onClick}  className={className(classes.button, {[classes.activeLarge]: active})}>
        <Box p={2} display="flex" width="100%" color="white" fontSize="clamp(0.7em, 2vw, 0.8em)">
          <Typography variant="h2" fontSize="inherit">{label}</Typography>
        </Box>
      </ButtonBase> 
    )
  } 
  
}


ButtonDrawerElement.defaultProps = {

    
    buttonType: "button",
    elementStyle: {}, // style object of element from database
    style: {}, // style object is pushed to element if there is active animation present
    
    elementClasses: []
  };
  

const mapStateToProps = ({ auth }) => {
  return {
    auth
  }
}

export default withRouter(connect(mapStateToProps, actions)(editWrapper(ButtonDrawerElement)))
