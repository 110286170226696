import * as supportFunctions from './../../supportFunctions'
import * as firebase from './../../../../config/firebase'
import elementsConstructor from './../../../../webElements/elementsConstructor'
import logger from  './../../../../helpers/logger'
import {
  SET_SELECTED_PAGE,
  FETCH_ALL_PAGES,
  FETCH_PAGE,
  ADD_NEW_PAGE,
  FETCH_PAGE_ELEMENTS,
  SET_ACTIVE_MODAL,
  ADD_PAGE_INFO,
  DELETE_PAGE,
  FETCH_PAGES_INFO
} from './../../types'


export const fetchWebpagesInfo = () => async dispatch =>{
  try{
    logger('api_call', "fetchWebpagesInfo")
    let webpagesInfo = await firebase.pagesInfoRef.once("value")
  
    dispatch({
      type: FETCH_PAGES_INFO,
      payload: webpagesInfo.val()
    })
  }catch(err){
    logger('exception', "fetchWebpagesInfo", {err})
  }
 
}

export const fetchWebpages = () => async dispatch =>{
  try{
    logger('api_call', "fetchWebpages")
    let webpages = await firebase.pagesRef.once("value")
    webpages = webpages.val()

    let elements = {}

    Object.keys(webpages).forEach((webpageId)=>{
      elements = {...elements, ...webpages[webpageId].elements}
    })

    dispatch({
      type: FETCH_ALL_PAGES,
      payload: webpages
    })
  }catch(err){
    logger('exception', "fetchWebpages", {err})
  }

}

export const fetchWebpageById = (id) => async (dispatch, getState) =>{

  // check if page is already loaded
  if(getState().webpages[id]){

    //sets right elemenets to display on page view
    dispatch({
      type: FETCH_PAGE_ELEMENTS,
      payload: getState().webpages[id].elements
    })

    return true
  }

  try{
    logger('api_call', "fetchWebpageById")
    let webpage = await firebase.pagesRef.child(id).once("value")
    webpage = webpage.val()
    let elements = webpage && webpage.elements


    dispatch({
      type: FETCH_PAGE,
      payload: {[id]:webpage}
    })

    //sets right elemenets to display on page view
    dispatch({
      type: FETCH_PAGE_ELEMENTS,
      payload: elements
    })
  }catch(err){
    logger('exception', "fetchWebpageById", {err})
  }
  

  return true
}

export const refetchWebpageById = (id) => async (dispatch, getState) =>{
  // FORCING REFETCH, fetching page without checking if already exsist in store
  try{
    logger('api_call', "refetchWebpageById")
    let webpage = await firebase.pagesRef.child(id).once("value")
    webpage = webpage.val()
    let elements = webpage.elements


    dispatch({
      type: FETCH_PAGE,
      payload: {[id]:webpage}
    })

    //sets right elements to display on page view
    dispatch({
      type: FETCH_PAGE_ELEMENTS,
      payload: elements
    })
  }catch(err){
    logger('exception', "refetchWebpageById", {err})
  }
  

  return true
}


export const addNewPage = (values) => async (dispatch, getState) => {

  let pageId = values.pageUrlPath.replace(/\//g,"_")

  let rootElement = supportFunctions.createNewElement(values.rootElementType, "root element", "root")

  rootElement.style = {height: "100vh"}
  // check if element has initChildren
  let rootElementChildren = {}
  if(elementsConstructor[rootElement.type] && elementsConstructor[rootElement.type].initChildren){
    let elementChildrenIds = []
    let initElementChildren = {}

    // generate init children
    elementsConstructor[rootElement.type].initChildren.forEach((obj)=>{
      let elementChild = supportFunctions.createNewElement(obj.type, `${obj.placement} element`, rootElement.id)
      

      initElementChildren = {
        ...initElementChildren,
        [obj.placement]: elementChild.id
      }

      elementChildrenIds = [
        ...elementChildrenIds, 
        elementChild.id
      ]

      rootElementChildren = {
        ...rootElementChildren, 
        [elementChild.id]: elementChild
      }

    })
    rootElement.initElementChildren = initElementChildren
    rootElement.elementChildren = elementChildrenIds

  }

  let pageData = {
    name: values.pageName,
    path: values.pageUrlPath,
    requiresAuth: values.requiresAuth,

    elements:{
      root:{
        id: "root",
        name: "root",
        elementChildren: [...values.globalElements, rootElement.id]
      },
      [rootElement.id]: rootElement,

      ...rootElementChildren
    }
  }

  let webpageInfoData = { 
    name: values.pageName, 
    requiresAuth: values.requiresAuth
  } 
 
  
  firebase.databaseRef.update({
    [`/pages/${pageId}`]:pageData,
    [`pagesInfo/${pageId}`]: webpageInfoData,
  }).then(()=>{
    dispatch({
      type: ADD_NEW_PAGE,
      payload: {
        [pageId]: pageData
      }
    })

    dispatch({
      type: ADD_PAGE_INFO,
      payload: {
        [pageId]: webpageInfoData
      }
    })

  }).catch((err)=>{
    console.log(err)
  })

}

export const copyPage = (values) => async (dispatch, getState) =>{

  let pageId = values.copiedPageId

  let webpage = getState().webpages[pageId]
  let elements = getState().elements
  let element = webpage.elements["root"]

  let newPageId = values.pageUrlPath.replace(/\//g, "_")

  let newPageElements = supportFunctions.copyElementTree(elements ,element, null)

  firebase.databaseRef.update({
    [`/pages/${newPageId}/`]: {
       name: values.pageName,
       path: values.pageUrlPath,
       elements: newPageElements
    },
    [`/pagesInfo/${newPageId}/`]: {
      name: values.pageName,
    }
  }).then(()=>{
    dispatch({
      type: ADD_NEW_PAGE,
      payload: {[newPageId]: {
        name: values.pageName,
        path: values.pageUrlPath,
        elements: newPageElements
      }}
    })
    dispatch({
      type: FETCH_PAGE_ELEMENTS,
      payload: newPageElements
    })
    dispatch({
      type: SET_ACTIVE_MODAL,
      payload: ""
    })
    dispatch({
      type: SET_SELECTED_PAGE,
      payload: newPageId
    })
  }).catch((err)=>{
    console.log(err)
  })



}


export const deletePage = (pageId) => async dispatch => {
  firebase.databaseRef.update({
    [`/pages/${pageId}/`]: null,
    [`/pagesInfo/${pageId}/`]: null
  }).then(()=>{
    dispatch({
      type: DELETE_PAGE,
      pageId
    })
  }).catch((err)=>{
    console.log(err)
  })

}
