import {
  SET_SELECTED_CUSTOM_CONTAINER,
  ADD_CHANGE_PAGE_CHANGE_HISTORY,
  FETCH_PAGE_ELEMENTS
} from './../../types'

import _ from 'lodash'
import * as supportFunctions from './../../supportFunctions'

export const setSelectedCustomContainer = (containerId) => async dispatch =>{

  dispatch({
    type: SET_SELECTED_CUSTOM_CONTAINER,
    payload: containerId
  })

}



export const addCustomContainerToElements = (customContainer) => dispatch =>{

  dispatch({
    type: FETCH_PAGE_ELEMENTS,
    payload: customContainer.elements
  })
}

export const addSelectedCustomContainerToPageElement = (copyToElementId) => (dispatch, getState) =>{
  console.log("addSelectedCustomContainerToPageElement")

  const selectedCustomContainerId = getState().selectedCustomContainer
  const customContainers = getState().customContainers

  let selectedCustomContainer = customContainers[selectedCustomContainerId]

  let elements = _.cloneDeep(getState().elements)

  let copiedElement = _.cloneDeep(elements[selectedCustomContainer.rootElementId])

  copiedElement.parent = copyToElementId

  let copiedElementChildren = supportFunctions.copyElementTree(_.cloneDeep(elements), copiedElement, null)
  let rootElementId = supportFunctions.findRootElementId(copiedElementChildren)

  let newCopiedElements = {}

  Object.entries(copiedElementChildren).forEach(([elementId, element])=>{

    newCopiedElements = {...newCopiedElements, [/elements/+elementId]:element}
  })

  /// build new parent child tree, with new child id
  //// BUG: changing state directly ? ...
  let parent = getState().elements[copyToElementId]

  let parentChildren = parent.elementChildren ? [...parent.elementChildren , rootElementId] : [rootElementId]

  parent.elementChildren = parentChildren
  let updatedElements = {parent,...copiedElementChildren}

  

  dispatch({
    type: ADD_CHANGE_PAGE_CHANGE_HISTORY,
    payload: {
      [`/elements/${copyToElementId}/elementChildren`]: parentChildren,
      ...newCopiedElements
    }
  })

  dispatch({
    type: FETCH_PAGE_ELEMENTS,
    payload: updatedElements
  })

  return rootElementId

}
