import React from 'react';
import {withRouter, Prompt} from 'react-router'
import * as actions from '../../../../redux/actions'
import { connect } from "react-redux";
import { useCookies } from 'react-cookie';
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'

import Typography from '@material-ui/core/Typography'

import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import ButtonBase from '@material-ui/core/ButtonBase';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import CustomDialogLayout from './CustomDialogLayout'
import CustomInfoLayout from './CustomInfoLayout'
import CustomButton from './CustomButton'
import CustomIcon from './../../../components/icons/CustomIcon'
import editWrapper from '../../../default/wrappers/editWrapper'

import useMediaBreakPoint from '../../../../hooks/useMediaBreakPoint'
import useDictionary from '../../../../hooks/useDictionary';


const TITLE_HEIGHT = 60

const useStyles = makeStyles( theme => createStyles({
  cssLabel: {
    color: theme.palette.text.secondary+"!important",
    fontWeight: 200,
  },

  subTitle:{
    color: "black",
    background: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,

    padding: "4px",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),

    '& h2':{
      fontSize:"1em"
    }

  },

  cssOutlinedInput: {
      color: theme.palette.text.secondary+"!important",
      fontWeight: 200,
      '&$cssFocused $notchedOutline': {
          color: theme.palette.text.secondary+"!important",
          borderColor: `${theme.palette.text.secondary}!important`,
      }
  },

  cssFocused: {
    color: theme.palette.text.secondary+"!important"
  },

  notchedOutline: {
      color: theme.palette.text.secondary+"!important",
      borderWidth: '1px',
      borderColor: theme.palette.text.secondary+"!important",
      fontWeight: 200,  
  },

  notchedOutlineDisabled:{
    color: theme.palette.text.secondary+"!important",
    borderWidth: '1px',
    borderColor: "gray!important",
    fontWeight: 200,  
  },  

  inputDisabled:{
    color:"lightgray!important"
  },

  iconButton:{
   
    fontSize: "1.2em",
    borderRadius: 40,
    width: 40,
    height: 40,

  },
  tipsterDisplayNameLabel:{
    cursor: "pointer",
    "&:hover":{
      textDecoration: "underline",
    }
  }
}))

function UserSettingsElement({auth, userGeoLocation,...props}) {
    
  const theme = useTheme()
  const classes = useStyles()
  const dictionary = useDictionary({elementId: "user_settings_element", userGeoLocation})
  const breakPoint = useMediaBreakPoint()

  const fileInput = React.useRef()
  const [file, setFile] = React.useState(null)
  const [profile, setProfile] = React.useState(auth.userInfo)
  const [cookies, setCookie] = useCookies()
  const [userType, setUserType] = React.useState(auth.userInfo.type)
  const [userDisplayName, setUserDisplayName] = React.useState(auth.userInfo.displayName)
  const [userDescription, setUserDescription] = React.useState(auth.userInfo.description)
  const [userProfilePicture, setUserProfilePicture] = React.useState(auth.userInfo.icon)
  const [notificationPermission, setNotificationPermission] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [snackbar, setSnackbar] = React.useState({show: false})
  
  const [selectedUserType, setSelectedUserType] = React.useState(userType) 
  
  
  const [selectedSubscription, setSelectedSubscription] = React.useState({})
  const [selectedTipster, setSelectedTipster] = React.useState(null)
  

  React.useEffect(()=>{
    try{
      setNotificationPermission(Notification.permission)
    }catch(err){

    }
  },[])

  React.useEffect(()=>{
    setProfile(auth.userInfo)
    setLoading(false)
  },[auth.userInfo])

  const requestPermission = async () => {
    if(notificationPermission === "default"){
      try{
        let permission = await Notification.requestPermission()
        setNotificationPermission(permission)
      }catch(err){

      }
    }
  }

  const handleProfilePictureChange = (e)=>{
    let f = e.target.files[0]
    if(!f)return 

    const reader = new FileReader()
    reader.onloadend = () => {
      
      let img = new Image
      img.onload = function() {
        
        if(img.width <= 50 && img.height <= 50){
          setUserProfilePicture(reader.result) 
       
          setFile(f)
        }else{
         
          props.setActiveSnackbar("iconSizeError", "error")
         
        }
      }

      img.src = reader.result
    }
    reader.readAsDataURL(f)
  }

  const handleFileInputClick = ()=>{
    fileInput.current.click()
  }

  const handleDisplayNameChange = (e)=>{
    setUserDisplayName(e.target.value)
   
  }

  const handleUserDescriptionChange = (e)=>{
    setUserDescription(e.target.value)
   
  }

  const handleUserTypeChange = (userType) => () => {
    if(userType === 0) props.setActiveModal("changeUserTypeModal")
    else if(userType === 3){
      props.closeActiveModal()
      setUserType(selectedUserType)
     
    }else setSelectedUserType(userType)
  }

  const checkIfProfileHasChanged = () =>{
    if(userType !== profile.type || userDescription !== profile.description || userDisplayName !== profile.displayName || userProfilePicture !== profile.icon ){
      return true
    }else return false
    
  }

  const handleUserSettingsSave = async ()=>{
   
    let updatedValues = {}
    
    if(userType !== profile.type)updatedValues.type = userType
    if(userDescription !== profile.description)updatedValues.description = userDescription
    if(userDisplayName !== profile.displayName)updatedValues.displayName = userDisplayName
    if(file)updatedValues.file = file
    
    props.updateUserInfo({uid: auth.uid, values: updatedValues})

    setLoading(true)
  }

  const handleEditSubscriptionModal = (tipsterId) => () => {
    let selectedSubscription = auth.userInfo.metadata.subscriptions[tipsterId]
    selectedSubscription.tipsterId = tipsterId
    selectedSubscription.pushNotifications =  (cookies['notification_device_subscription'] && cookies['notification_device_subscription'].active[selectedSubscription.tipsterId]) || false
 
    setSelectedSubscription(selectedSubscription)

    props.setActiveModal("editSubscriptionModal")
  }

  const handleRemoveSubscriptionModal = (tipsterId) => ()=>{
    let selectedSubscription = auth.userInfo.metadata.subscriptions[tipsterId]
    selectedSubscription.tipsterId = tipsterId

    setSelectedSubscription(selectedSubscription)
    props.setActiveModal("removeSubscriptionModal")
  }
  
  const handleRemoveSubscription = (tipsterId) => {
    setLoading(true)
    props.removeSubscription({tipsterId: selectedSubscription.tipsterId, auth})
  }

  const handleSaveSubscription = () => {
 
    props.updateUserSubscriptions({userId: auth.uid, userEmail: auth.userInfo.email, tipsterId: selectedSubscription.tipsterId, subscription: selectedSubscription, cookies, setCookie})
  }

  const handleSwitchChange = (e) => {
    
    setSelectedSubscription(state => ({
      ...state,
      [e.target.name]: !state[e.target.name]
    }))

  }

  return (
    <>
      <Prompt when={checkIfProfileHasChanged()} message={dictionary.promptMessage}/>
      <Snackbar open={snackbar.show} onClose={()=>setSnackbar({show: false})}>
        <Alert elevation={6} variant="filled" onClose={() => setSnackbar({show: false})} severity="error">
          {snackbar.content} 
        </Alert>
      </Snackbar>
      
      <Grid container spacing={1}>
        <Grid item  xs={12} sm={12} md={12} lg={6} xl={6}>
          <Box width="100%"  display="flex" flexDirection="column" borderRadius={2}>
            <Box display="flex" justifyContent="space-between" alignItems="flex-end" mb={1} height={TITLE_HEIGHT} >
              <CustomButton 
                label={dictionary.title}
                height={TITLE_HEIGHT}
                type="title"
              />
            </Box>
  
            <Box p={2} display="flex" flexDirection="column"  alignItems="flex-start" bgcolor="background.transparent" color="white" borderRadius={4} borderBottom={"1px solid black"}>
              <Box display="flex" flexDirection="column" alignItems="flex-start" width="100%">
                <Box className={classes.subTitle}>
                    <Typography variant="h2" color="inherit">{dictionary.displayNameInputLabel}</Typography>
                </Box>
                <Box py={2}>
                  <Typography variant="h2">{profile.displayName}</Typography>
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" alignItems="flex-start" width="100%">
                <Box className={classes.subTitle}>
                    <Typography variant="h2" color="inherit">{dictionary.emailInputLabel}</Typography>
                </Box>
                <Box py={2}>
                  <Typography variant="h2">{profile.email}</Typography>
                </Box>
              </Box>
              <Box className={classes.subTitle}>
                  <Typography variant="h2" color="inherit">{dictionary.descriptionInputLabel}</Typography>
              </Box>

              <Box py={2} width="100%"> 
                <TextField 
                  label=""
                  fullWidth
                  multiline
                  rowsMax={10}
                  rows={5}
              
                  value={userDescription}
                  onChange={handleUserDescriptionChange}
                  InputLabelProps={{
                      classes:{
                      root: classes.cssLabel,
                      focused: classes.cssFocused,
                      disabled: classes.inputDisabled
                      },
                  }}
                  InputProps={{
                      className: classes.input,
                      classes:{
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                    
                      disabled: classes.inputDisabled
                      },
                  }}
                />
              </Box>
              <Box className={classes.subTitle}>
                  <Typography variant="h2" color="inherit">{dictionary.profileImageTitle}</Typography>
              </Box>
              <Box py={2}  display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" width="100%" flexWrap="wrap">
                <input accept="image/jpeg, image/png " onChange={handleProfilePictureChange} ref={fileInput} type="file" style={{display: "none"}}/>
                {
                  !userProfilePicture ? 
                  <CustomButton 
                    label={dictionary.profilePictureButtonLabel}
                    height={60}
                    width="100%"
                    type="button"
                    bgcolor="background.dark"
                    fontSize="1.3em"
                    onClick={handleFileInputClick}
                  />:
                  <Box height={60} width="100%" display="flex" alignItems="center" justifyContent="space-between" borderRadius={theme.shape.borderRadius} overflow="hidden">
                    <CustomButton 
                      label={dictionary.profilePictureButtonLabel}
                      height={60}
                     
                      type="button"
                      bgcolor="background.dark"
                      fontSize="1.3em"
                      onClick={handleFileInputClick}
                    />
                    <img height="50px" width="auto" alt="icon" src={userProfilePicture} />
                  </Box>
                }
               
              </Box>
              <Box className={classes.subTitle}>
                  <Typography variant="h2" color="inherit">{dictionary.accountTypeTitle}</Typography>
              </Box>
              <Box pt={2}  display="flex" width="100%" flexWrap="wrap" justifyContent="space-between" alignItems="center">
                <CustomButton 
                  label={dictionary.accountTypeButtonLabel}
                  onClick={handleUserTypeChange(0)}
                  bgcolor="background.dark"
                  height={60}
                  fontSize="1.3em"
                  type="button"
                />
                <Typography variant="h2"> {userType === 1 ? dictionary.user : dictionary.tipster} </Typography>
              </Box>
            </Box>
            
          </Box>
          <Box mt={1}>
          {
                checkIfProfileHasChanged() &&
                  <CustomButton 
                    label={dictionary.saveButtonLabel}
                    onClick={handleUserSettingsSave}
                    disabled={loading}
                    height={65}
                    bgcolor="primary.main"
                    color="black"
                  
                    type="button"
                  
                  />
          }
          </Box>
        </Grid>
        <Grid item  xs={12} sm={12} md={12} lg={6} xl={6}>
          <Box width="100%" display="flex" flexDirection="column" borderRadius={2}>
            <Box display="flex" justifyContent="space-between" alignItems="flex-end" mb={1} height={TITLE_HEIGHT}>
              <CustomButton 
                label={dictionary.userSubscriptionsTitle}
                height={TITLE_HEIGHT}
                type="title"
              />
            </Box>
          </Box>
          <Box flexDirection="column"  borderRadius={4} color="white">
            <Box py={1} mb={1} display="flex" bgcolor="background.transparent" justifyContent="space-between" alignItems="center" borderBottom="1px solid black" borderRadius={4} fontSize="1em">
              <Box width="40%" >
                <Typography variant="body2">{dictionary.tipster}</Typography>
              </Box>
              <Box width="1px" height="24px" bgcolor="primary.main" />
              <Box width="35%">
                <Typography variant="body2">{dictionary.subscribedFrom}</Typography>
              </Box>
              <Box width="1px" height="24px" bgcolor="primary.main" />
              <Box width="15%">
                <Typography variant="body2">{dictionary.notifications}</Typography>
              </Box>
              <Box width="1px" height="24px" bgcolor="primary.main" />
              <Box width="10%">
               
              </Box>
            </Box>
            {
              auth.userInfo.metadata && auth.userInfo.metadata.subscriptions ?  
                Object.entries(auth.userInfo.metadata.subscriptions).map(([tipsterId, values]) => (
                  <Box key={tipsterId} mb="4px" py={1} display="flex" justifyContent="space-between" bgcolor="background.transparent" borderBottom="1px solid black" borderRadius={4}>
                    <Box onClick={()=>props.history.push(`/user/profile?userId=${tipsterId}`)} className={classes.tipsterDisplayNameLabel} width="40%" display="flex" justifyContent="center" alignItems="center">
                      <Typography  variant="body2">{values.displayName}</Typography>
                    </Box>
                    <Box width="35%" display="flex" justifyContent="center" alignItems="center">
                      <Typography variant="body2">{(new Date(values.time)).toLocaleDateString()}</Typography>
                    </Box>
                   
                    <Box width="15%" display="flex" alignItems="center" justifyContent="center">
                      { values.emailNotifications && <CustomIcon name="email" />}
                      { cookies['notification_device_subscription'] && cookies['notification_device_subscription'].active[tipsterId] && <CustomIcon  name="notifications" />}
                    </Box>
               
                    <Box px={1} display="flex" justifyContent="flex-end" alignItems="center" width="10%">
                      <ButtonBase onClick={handleEditSubscriptionModal(tipsterId)} className={classes.iconButton}>
                        <CustomIcon name="edit" />
                      </ButtonBase> 
                      <ButtonBase disabled={loading} onClick={handleRemoveSubscriptionModal(tipsterId)}  className={classes.iconButton} style={{color: theme.palette.error.main}}>
                        <CustomIcon name="delete" />
                      </ButtonBase>
                    </Box>
                  </Box>
                )):
                <Box mt={1} bgcolor="background.transparent" borderRadius={4} p={3}>
                  <Typography variant="h2">{dictionary.noSubscriptions}</Typography>
                </Box>
            }
          </Box>
        </Grid>
        
      </Grid>
      <CustomDialogLayout name="editSubscriptionModal" title={dictionary.editSubscriptionModalNotifyViaEmailTitle && dictionary.editSubscriptionModalNotifyViaEmailTitle(selectedSubscription.displayName)} actions={[{label: dictionary.confirmButton, onClick: handleSaveSubscription}]}>
        <>
          <Box>
            <CustomInfoLayout 
              type="info"
              content={dictionary.editSubscriptionModalNotifyViaEmailInfo} 
            />
            <Box mt={1} display="flex" justifyContent="space-between" width="100%" color={"white"}  borderBottom={"1px solid "+theme.palette.primary.main}>
              <Typography  style={{fontSize: "1.1em", lineHeight:"42px"}}  variant="body1" color="inherit">
                {dictionary.editSubscriptionModalNotifyViaEmailLabel}
              </Typography>
              <Box>
                <Switch
                  checked={ "emailNotifications" in selectedSubscription  ? selectedSubscription.emailNotifications : false}
                  onChange={handleSwitchChange}
                  color="primary"
                  name="emailNotifications"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </Box>
            </Box>
          </Box>
          <Box>
            <Box py={2}>
              <CustomInfoLayout 
                type="info"
                content={dictionary.editSubscriptionModalPushNotificationsInfo}
              />
            </Box>
            
             <CustomInfoLayout 
              type="warning"
              content={dictionary.editSubscriptionModalPushNotificationsWarning}
            />
            <Box pt={1} display="flex" justifyContent="space-between" width="100%" color={"white"} borderBottom={"1px solid "+theme.palette.primary.main}>
              <Typography style={{fontSize: "1.1em", lineHeight:"42px"}}  variant="body1" color="inherit">
                {dictionary.editSubscriptionModalPushNotificationsLabel}
              </Typography>
            
              {
                notificationPermission === "granted" ? (
                  <Switch
                    checked={"pushNotifications" in selectedSubscription  ? selectedSubscription.pushNotifications : false}
                    onChange={handleSwitchChange}
                    color="primary"
                    name="pushNotifications"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                ):(
                  <Box >
                    <Button variant="text" color="primary" onClick={requestPermission}>Dovoli</Button>
                  </Box>
                )
              }
            </Box>
          </Box>

        </>
      </CustomDialogLayout>
      <CustomDialogLayout name="removeSubscriptionModal" title={dictionary.removeSubscriptionModalTitle} actions={[{label: dictionary.confirmButton, onClick: handleRemoveSubscription}]}>
        <Box>
          <Typography variant="body1" align="justify">
            {dictionary.removeSubscriptionModalRemoveSubscriptionInfo}  
          </Typography>
        </Box>
      </CustomDialogLayout>
      <CustomDialogLayout name="changeUserTypeModal" title={dictionary.changeUserTypeModalTitle} actions={[{label: dictionary.confirmButton, onClick: handleUserTypeChange(3)}]}>
        <>
          <Box display="flex" width="100%" justifyContent="space-between">
            <CustomButton 
              label={dictionary.user}
              height={100}
              type="button"
              color="white"
              bgcolor={selectedUserType === 1 ? theme.palette.primary.main : theme.palette.background.dark}
              onClick={handleUserTypeChange(1)}
              width="calc(50% - 4px)"
              fontSize="2em"
            />
            <CustomButton 
              label={dictionary.tipster}
              height={100}

              type="button"
              color="white"
              bgcolor={selectedUserType === 2 ? theme.palette.primary.main : theme.palette.background.dark}
              onClick={handleUserTypeChange(2)}
              width="calc(50% - 4px)"
              fontSize="2em"
            />
          </Box>
          <Box py={2}>
              <CustomInfoLayout 
                type="info"
                content= {selectedUserType === 1 ? dictionary.changeUserTypeModalUserInfo :dictionary.changeUserTypeModalTipsterInfo}      
              />
          </Box>
          <CustomInfoLayout 
              type="error"
              content={dictionary.changeUserTypeModalTipsterWarning}
          />
         
        </>
      </CustomDialogLayout>
      
    </>
  )
}


UserSettingsElement.defaultProps = {

  elementStyle: {}, // style object of element from database
  style: {}, // style object is pushed to element if there is active animation present
  elementClasses: [],
  
};
  

const mapStateToProps = ({auth, brdoparaData, userGeoLocation, deviceInfo}) => {
  return {
    auth,
    brdoparaData,
    userGeoLocation,
    deviceInfo,
  }
}

export default withRouter(connect(mapStateToProps, actions)(editWrapper(UserSettingsElement)))
