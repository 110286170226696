import React from 'react';
import { connect } from "react-redux";
import * as actions from './../../../../redux/actions'
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CustomButton from './CustomButton'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import CustomIcon from './../../../components/icons/CustomIcon'

const useStyle = makeStyles( theme => createStyles({
    paper:{
        background: "none",
      
        borderRadius: 2,
        display:"flex",
        justifyContent:"center",
        height: "100%",

        boxShadow: "none",

        [theme.breakpoints.down('sm')]:{
            background: theme.palette.background.secondary,
            overflow: "auto",
            padding: theme.spacing(1)
        }
    }
}))


function CustomDialogLayout({name, modalStatus, auth, title, body, actions, disableClose, disableBackButton, disabled, maxWidth="sm", fullWidth=false, bgcolor, disableActions, ...props}){
   
    const theme = useTheme();
    const classes = useStyle()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [loading, setLoading] = React.useState(false)
  
    const handleClose = () => {
        props.closeActiveModal()
    };

    React.useEffect(()=>{
        setLoading(false)
    },[modalStatus])
  
    return (
        <Dialog
            
            fullScreen={fullScreen}
            open={modalStatus.active === name}
            onClose={handleClose}
            aria-labelledby="dialog"
            disableBackdropClick={disableClose}
            disableEscapeKeyDown={disableClose}
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            classes={{
                paper: classes.paper
            }}

            BackdropProps={{
                style:{
                    background: "rgba(0,0,0,0.7)"
                }
            }}
        >
            <Box  display="column" alignItems="center"  flexDirection="column" justifyContent="center" overflow="auto">
                {
                    title && 
                        <Box width="fit-content">
                            <CustomButton 
                                label={title}
                                height={75}
                                type="title"
                            />
                        </Box>
                }
                
                <Box p={2} mt={1}  display="flex" flexDirection="column" borderRadius={2} bgcolor={bgcolor || "background.primary"} color="white" overflow="auto">
                  {props.children}
                  {
                     props.infos && props.infos.map(info => (
                        <Box display="flex" alignItems="center" mt={2} bgcolor={`${info.type}.main`} border={"2px solid "+theme.palette.error.dark} p={2} borderRadius={2}>
                            <Box  display="flex" alignItems="center" fontSize="clamp(1.3em, 3vw, 2.5em)">
                                <CustomIcon name={info.type} />
                            </Box>
                            <Box ml={1}>
                                <Typography variant="body2" align="justify" style={{fontSize: "clamp(0.6em, 3vw, 0.8em)", fontWeight: 400}}>
                                    {info.content}
                                </Typography>       
                            </Box>
                        </Box>
                    ))
                  }
                  
                </Box>
                {
                    !disableActions &&
                        <Box display="flex" justifyContent="space-between" mt={1}>
                            {
                                !disableBackButton && 
                                    <CustomButton
                                        label="Nazaj"
                                        height={75}
                                        type="button"
                                        color="white"
                                        width="calc(25% - 4px)"
                                        onClick={handleClose}
                                        disabled={loading}
                                    />
                            }

                            {
                                actions.map((action, i)=>(
                                    <CustomButton
                                        key={i}
                                        label={action.label}
                                        height={75}
                                        type="button"
                                        color="white"
                                        loading={loading}
                                    
                                        width={disableBackButton ? "100%":`calc(${75/actions.length}% - 4px)`}
                                        onClick={() => {
                                            action.onClick()
                                            setLoading(true)
                                        }}
                                    />
                                ))
                            }
                        
                        </Box>
                }
                
            </Box>  
        </Dialog>
    )
  }

  const mapStateToProps = ({auth, modalStatus}) => {
    return {
      auth,
      modalStatus
    }
  }

  export default connect(mapStateToProps, actions)(CustomDialogLayout)