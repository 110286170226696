import * as defaultCss from './defaultCss'
import brdoparaElementsConstructor from './custom/brdopara/brdoparaElementsConstructor'

/*
####################################################
###################################################
##################VARIABLES#######################

name(String) - container description
childrens(Array) - contains ids of possible elementChildren inputs
properties(Array) - container properties
  label - property description
  type[text, selection, elementStyle, checkbox] - input type
  id - input id
  value - input value
  if(type === selection) values - select input values
  default - default input value
  linkedTo - id of input that it is linked to
  linkedValue - value shown in selection
  valuesQuery - ?
  basic - basic inputs (for switching between advanced and normal mode)
  groupBy[content, look, function, image] - for easier grouping of inputs inside forms
*/


const GRID_VALUES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
const DEFAULT_CSS_CLASSES = defaultCss.allClasses

const root = {

  properties:[
    {
      label:"Name", type:"text", id: "name", value: "", default: "", groupBy: "function"
    },
  ],
  containsElements:[
    "grid_container_element",
    "box_element",
    "box_with_drawer_element"
  ]
}

const grid_container_element = {

  name:"Grid container",
  properties:[
    {
      label:"Name", type:"text", id: "name", value: "", default: "", groupBy: "function"
    },
    {
      label:"Styles", type:"elementStyle", id: "elementStyle", values: [], default: [], groupBy: "style"
    },
    {
      label:"Aligment", type:"selection", id: "justify", values: [ "center", "flex-start", "flex-end", "space-between", "space-around", "space-evenly"], default: "center", groupBy: "look"
    },
    {
      label: "Direction", type:"selection", id: "direction", values:["row", "column"], default: "row", groupBy: "look"
    },
    {
      label: "Item aligment", type:"selection", id: "alignItems", values: [ "center", "flex-start", "flex-end", "space-between", "space-around", "space-evenly"], default: "center", groupBy: "look"
    },
    {
      label:"Spacing", type:"text", id: "spacing", value: "", default: "", groupBy: "look"
    },
    {
      label:"Global element", type:"checkbox", id: "global", value: false, default: false, groupBy: "global"
    },
    {
      label:"Global root element", type:"checkbox", id: "globalRoot", value: false, default: false, groupBy: "global"
    },
   

  ],
  containedInElements: [
    "box_element"
  ],
  containsElements:[
    "grid_item_element"
  ]
}

const grid_item_element = {

  name:"Grid item",
  properties:[
    {
      label:"Name", type:"text", id: "name", value: "", default: "", groupBy: "function"
    },
    {
      label:"Styles", type:"elementStyle", id: "elementStyle", values: [], default: [], groupBy: "style"
    },
    {
      label:"Select background image", type:"fileUpload", id: "fileUrl", value:  "", default: "", groupBy: "image", basic: true
    },
    {
      label:"Extra large devices", type:"selection", id: "xl", values: GRID_VALUES, default: 12, groupBy: "look"
    },
    {
      label:"Large devices", type:"selection", id: "lg", values: GRID_VALUES, default: 12, groupBy: "look"
    },
    {
      label:"Medium devices", type:"selection", id: "md", values: GRID_VALUES, default: 12, groupBy: "look"
    },
    {
      label:"Small devices", type:"selection", id: "sm", values: GRID_VALUES, default: 12, groupBy: "look"
    },
    {
      label:"Extra small devices", type:"selection", id: "xs", values: GRID_VALUES, default: 12, groupBy: "look"
    },
    {
      label:"Global element", type:"checkbox", id: "global", value: false, default: false, groupBy: "global"
    },
  ],

  containedInElements: [
    "grid_container_element"
  ],
  
  containsElements: [
    "grid_container_element",
    "carousel_element",
    "box_element",
    "button_element",
    "text_element",
    "image_element",
    "google_maps_element",
    "box_with_drawer_element",
    "form_element",
    "icon_element",

    //brdopara elements
    "brdopara_register_user_element",
    "brdopara_navbar_element",
    "brdopara_button_drawer_element",
    "brdopara_list_box_element",
    "brdopara_circle_box_element",
    "brdopara_add_bet_element",
    "brdopara_profile_element",
    "brdopara_account_creation_element",
    "brdopara_bet_info",
    "brdopara_user_settings_element",
    "brdopara_history_bet_info",
    "brdopara_active_bet_recommendation_element",
    "brdopara_bets_list_box_element",
    "brdopara_carousel_element",
    "brdopara_home_element"
  ]
}

const carousel_element = {

  name: "Carousel",
  properties: [
    {
      label:"Name", type:"text", id: "name", value: "", default: "", groupBy: "function"
    },
    {
      label:"Classes", type:"selection", id: "classNames", values: DEFAULT_CSS_CLASSES, default: [], groupBy: "look"
    },
    {
      label:"Styles", type:"elementStyle", id: "elementStyle", values: [], default: [], groupBy: "style"
    },
    {
      label:"Select background image", type:"fileUpload", id: "fileUrl", value:  "", default: "", groupBy: "image", basic: true

    },
    {
      label:"Disable buttons", type:"checkbox", id: "disableButtons", value: "", default: "", groupBy: "function"
    },
    {
      label:"Extra large devices", type:"selection", id: "xl", values: GRID_VALUES, default: 12, groupBy: "look"
    },
    {
      label:"Large devices", type:"selection", id: "lg", values: GRID_VALUES, default: 12, groupBy: "look"
    },
    {
      label:"Medium devices", type:"selection", id: "md", values: GRID_VALUES, default: 12, groupBy: "look"
    },
    {
      label:"Small devices", type:"selection", id: "sm", values: GRID_VALUES, default: 12, groupBy: "look"
    },
    {
      label:"Extra small devices", type:"selection", id: "xs", values: GRID_VALUES, default: 12, groupBy: "look"
    },
  ],

}

const box_element = {

  name: "Box element",
  properties: [
    {
      label:"Name", type:"text", id: "name", value: "", default: "", groupBy: "function"
    },
    {
      label:"Classes", type:"selection", id: "classNames", values: DEFAULT_CSS_CLASSES, default: [], groupBy: "look"
    },
    {
      label:"Element style", type:"elementStyle", id: "elementStyle", values: {}, default: {}, groupBy: "Element style"
    },
    {
      label:"Box style", type:"elementStyle", id: "boxStyle", values: {}, default: {}, groupBy: "Box style"
    },
    
    {
      label:"Select background image", type:"imageUpload", id: "bgImage", value:  "", default: "", groupBy: "image", basic: true
    },
    {
      label:"Image style", type:"elementStyle", id: "bgImageStyle", values: {}, default: {}, groupBy: "image"
    },
    {
      label:"Global element", type:"checkbox", id: "global", value: false, default: false, groupBy: "global"
    },
    {
      label:"Global page root element", type:"checkbox", id: "globalPageRoot", value: false, default: false, groupBy: "global"
    },
  ],

  containedInElements: [
    "box_element",
    "grid_item_element",
    "box_with_drawer_element"
  ],
  containsElements:[
    "grid_container_element",
    "carousel_element",
    "box_element",
    "button_element",
    "text_element",
    "image_element",
    "google_maps_element",
    "box_with_drawer_element",
    "form_element",
    "icon_element",

    //brdopara elements
    "brdopara_register_user_element",
    "brdopara_navbar_element",
    "brdopara_button_drawer_element",
    "brdopara_list_box_element",
    "brdopara_circle_box_element",
    "brdopara_add_bet_element",
    "brdopara_account_creation_element",
    "brdopara_bet_info",
    "brdopara_login_box_element",
    "brdopara_user_settings_element",
    "brdopara_history_bet_info",
    "brdopara_active_bet_recommendation_element",
    "brdopara_carousel_element",
    "brdopara_home_element"
  ]

}

const box_with_drawer_element = {

  name: "Box with drawer element",
  properties: [
    {
      label:"Name", type:"text", id: "name", value: "", default: "", groupBy: "function"
    },
    {
      label:"Classes", type:"selection", id: "classNames", values: DEFAULT_CSS_CLASSES, default: [], groupBy: "look"
    },
    {
      label:"Styles", type:"elementStyle", id: "elementStyle", values: [], default: [], groupBy: "style"
    },
    {
      label:"Select background image", type:"fileUpload", id: "bgImage", value:  "", default: "", groupBy: "image", basic: true
    },
    {
      label:"Global element", type:"checkbox", id: "global", value: false, default: false, groupBy: "global"
    },
    {
      label:"Select background image", type:"imageUpload", id: "bgImage", value:  "", default: "", groupBy: "image", basic: true
    },
    {
      label:"Image style", type:"elementStyle", id: "bgImageStyle", values: {}, default: {}, groupBy: "image"
    },
  ],

  initChildren: [
    {type: "box_element", placement: "navbar"},
    {type: "box_element", placement: "drawer"},
    {type: "grid_container_element", placement: "content"},
  ],

  containedInElements: [
    "box_element",
    "grid_item_element"
  ],

  containsElements: [
    "grid_container_element",
    "carousel_element",
    "box_element",
    "button_element",
    "text_element",
    "image_element",
    "google_maps_element",
    "brdopara_user_settings_element"
  ]

}

const button_element = {

  name:"Button element",
  properties:[
    {
      label:"Name", type:"text", id: "name", value: "", default: "", groupBy: "function"
    },
    {
      label:"Classes", type:"multipleSelection", id: "classNames", values: DEFAULT_CSS_CLASSES, default: [], groupBy: "look"
    },
    {
      label:"Element style", type:"elementStyle", id: "elementStyle", values: {}, default: {}, groupBy: "style"
    },
    {
      label:"Label", type:"text", id: "label", value: "", default: "", basic: true, groupBy: "content"
    },
    {
      label:"Icon name", type:"text", id: "icon", value: "", default: "", basic: true, groupBy: "content"
    },
    {
      label: "Variant", type:"selection", id: "variant", values:["contained", "outlined", "text"], default: "contained", groupBy: "look"
    },
    {
      label: "Text align", type:"selection", id: "align", values: [ "center", "left", "right"], default: "center", groupBy: "look"
    },
    {
      label: "Color", type:"selection", id: "color", values: [ "inherit", "primary", "secondary", "default"], default: "primary", groupBy: "look"
    },
    {
      label:"Redirect to", id: "redirectLink", type:"text",  value: "", default: "", basic: true, groupBy: "function"
    },
    {
      label:"Go to", type:"text", id:"goToPath", values: "", default: "", basic: true, groupBy: "function"
    },
    {
      label: "Type", type:"selection", id: "animationType", values:["fade","grow", "slide", "collapse"] , default: "", groupBy: "animations"
    },
   
    {
      label:"Global element", type:"checkbox", id: "global", value: false, default: false, groupBy: "global"
    },
  ],

}

const text_element = {

  name:"Text element",
  properties:[
    {
      label:"Name", type:"text", id: "name", value: "", default: "", groupBy: "function"
    },
    {
      label:"Content", type:"text", id: "content", value: "", default: "", basic: true, groupBy: "content"
    },
    {
      label:"Classes", type:"multipleSelection", id: "classNames", values: DEFAULT_CSS_CLASSES, default: [], groupBy: "look"
    },
    {
      label:"Styles", type:"elementStyle", id: "elementStyle", values: [], default: [], groupBy: "style"
    },
    {
      label:"Aligment", type:"selection", id: "align", values: [ "center", "left", "right", "justify"], default: "center", groupBy: "look"
    },
    {
      label: "Variant", type:"selection", id: "variant", values:["body1","h1", "h2", "h3", "h4", "h5", "h6",] , default: "body1", groupBy: "look"
    },
    {
      label: "Color", type:"selection", id: "color", values: [ "inherit", "primary", "secondary", "default"], default: "primary", groupBy: "look"
    },
    {
      label: "Type", type:"selection", id: "animationType", values:["fade","grow", "slide", "collapse"] , default: "", groupBy: "animations"
    },
    {
      label: "Delay", type:"text", id: "animationDelay", value: "", default: "", groupBy: "animations"
    },
    {
      label: "Direction", type:"selection", id: "animationDirection",  values:["up","down", "left", "right" ], default: "", groupBy: "animations"
    },
    {
      label:"Global element", type:"checkbox", id: "global", value: false, default: false, groupBy: "global"
    },
  ],

  containedInElements: [
    "box_element",
    "grid_item_element"
  ],


}

const image_element = {

  name:"Image element",
  properties:[
    {
      label:"Name", type:"text", id: "name", value: "", default: "", groupBy: "Element"
    },
    {
      label:"Select image", type:"imageUpload", id: "image", value:  "", default: "", basic: true, groupBy: "image"
    },
    {
      label:"Classes", type:"selection", id: "elementClasses", values: DEFAULT_CSS_CLASSES, default: [], groupBy: "look"
    },
    {
      label:"Element style", type:"elementStyle", id: "elementStyle", values: [], default: [], groupBy: "Element"
    },
    {
      label:"Image style", type:"elementStyle", id: "imageStyle", values: [], default: [], groupBy: "image"
    },
    {
      label:"Redirect to", id: "redirect", type:"text",  value: "", default: "", basic: true, groupBy: "function"
    },
    {
      label:"Position in document", id: "documentPosition", type:"selection", values:[0,1,2,3,4,5],  value: 0, default: 0, groupBy: "animation"
    },

  ],
  containedInElements: [
    "box_element",
    "grid_item_element",
  ],

}

const icon_element = {

  name:"Icon container",
  properties:[
    {
      label:"Name", type:"text", id: "name", value: "", default: "", groupBy: "function"
    },
    {
      label:"Animated", type:"checkbox", id: "animated", value: false, default: false, groupBy: "function"
    },
    {
      label:"Icon name", type:"text", id: "onSubmitAction", value: "", default: "", groupBy: "Submit"
    },
    {
      label:"Box style", type:"elementStyle", id: "boxStyle", values: {}, default: {}, groupBy: "Box style"
    },
  ],

  containedInElements: [
    "box_element",
    "grid_item_element"
  ],

}


const google_maps_element = {

  name:"Google maps element",
  properties:[
    {
      label:"Name", type:"text", id: "name", value: "", default: "", groupBy: "function"
    },
    {
      label:"Offset height", type:"text", id: "offset", value: "", default: "", groupBy: "look"
    },
    {
      label:"Minimum height", type:"text", id: "minHeight", value: "", default: "", groupBy: "look"
    },
    {
      label:"Latitude", type:"text", id: "latitude", value: "", default: "", groupBy: "function"
    },
    {
      label:"Longitude", type:"text", id: "longitude", value: "", default: "", groupBy: "function"
    },
    {
      label:"Zoom", type:"text", id: "zoom", value: "", default: "", groupBy: "function"
    },
    {
      label:"Google maps API key", type:"text", id: "key", value: "", default: "", groupBy: "function"
    },

  ],

}

const form_element = {

  name:"Form container",
  properties:[
    {
      label:"Name", type:"text", id: "name", value: "", default: "", groupBy: "function"
    },
    {
      label:"Submit button label", type:"text", id: "buttonLabel", value: "", default: "", groupBy: "Submit"
    },
    {
      label:"onSubmitAction", type:"text", id: "onSubmitAction", value: "", default: "", groupBy: "Submit"
    },
    {
      label:"Box style", type:"elementStyle", id: "boxStyle", values: {}, default: {}, groupBy: "Box style"
    },

  ],
  containedInElements: [
    "box_element",
    "grid_item_element"
  ],
  containsElements:[
    "form_field_element",
    "button_element"
  ]
}


const form_field_element = {

  name:"Form field",
  properties:[
    {
      label:"Element name", type:"text", id: "name", value: "", default: "", groupBy: "Element"
    },
    {
      label:"Field type", 
      id: "fieldType", 
      type:"selection", 
      values:["input","select","checkbox"],  
      value: "input", 
      default: "input", 
      groupBy: "Field"
    },
    {
      label:"Field id", type:"text", id: "fieldId", value: "", default: "", groupBy: "Field"
    },
    {
      label:"Field label", type:"text", id: "fieldLabel", value: "", default: "", groupBy: "Field"
    },

  ],
  containedInElements: [
    "form_element"
  ],
  
}


export default {
  grid_container_element,
  grid_item_element,
  button_element,
  text_element,
  image_element,
  google_maps_element,
  carousel_element,
  box_element,
  box_with_drawer_element,
  root,
  form_element,
  form_field_element,
  icon_element,
  ...brdoparaElementsConstructor,
 
  //...tehnofanElemmentsConstructor
}
