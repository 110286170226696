import React from 'react';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import classNames from 'classnames'

import editWrapper from '../wrappers/editWrapper'


import CustomIcon from '../../components/icons/CustomIcon'


const useStyles = makeStyles( theme => ({
   
  animation: {
      animation: `$myEffect 1000ms ${theme.transitions.easing.easeInOut}`,
  },

  "@keyframes myEffect": {
      "0%": {
          transform: "scale(1)",
          opacity: 1,
      },
      "25%": {
          transform: "scale(1.15)",
          opacity: 0.8,
      },
      "50%": {
          transform: "scale(1.1)",
          opacity: 1,
      },
      "75%": {
          transform: "scale(1.2)",
          opacity: 0.8,
      },
      "100%": {
          transform: "scale(1)",
          opacity: 1,
      }
  },

}));


function IconElement(props){

  const {
    classes,
    style,
    elementStyle,
    bgImageStyle,
    boxStyle,
    elementChildren,
    files,
    elementClasses,
    edit,
    elementId,
    name,
    animated,
    delay=0,
    repeatCycle=1000
  } = props

  let customClasses = useStyles()

  let [animationStart, setAnimationStart] = React.useState(false)


  React.useEffect(()=>{
    let timeoutId = setTimeout(()=>setAnimationStart(true), delay)

    
    let intervalId = setInterval(() => {
      timeoutId = setTimeout(()=>setAnimationStart(state => !state), delay)
    }, repeatCycle)
    

    return ()=> {
      clearInterval(intervalId)
      clearTimeout(timeoutId)
    }
  }, [])
 
  return (
    <Box
      className={classNames(elementClasses.reduce((acc, obj)=> {return {...acc, [classes[obj]]: true}},{[customClasses.animation]: animated && animationStart}))}
      style={{...style, ...elementStyle}}
      {...boxStyle}
    >
        <CustomIcon name={name} />
    </Box>
  )
}

IconElement.defaultProps = {

  elementClasses: [],
  elementStyle: {}, // style object of element from database
  style: {}, // style object is pushed to element if there is active animation present
  elementChildren: [] // element elementChildren stored in database

};


export default editWrapper(IconElement)
