import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';
 
import {analytics} from './../../../config/firebase'

const CustomButton = withStyles(theme=>({
  root:{
    fontSize: "clamp(1.4rem, 3vw, 1.6rem)",
    borderRadius: 4
  }
}))(Button)

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      hasError: false,
      error: null
    };
  
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    if(process.env.NODE_ENV !== 'development'){
      let pathname = window.location && window.location.pathname ? window.location.pathname : ""

      analytics.logEvent("exception", {name: "reactBoundaryError", exDescription: `${pathname} | reactBoundaryError: ${error.message}`, exFatal: true})  
      
    }else{
   
      this.setState({error})
    }
    
  }

  handleReload(){
    window.location.reload();
  }
  
  render(){
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Box height="100%" display="flex" alignItems="center" justifyContent="center" color="white" borderRadius={4}>
          <Box mb="100px" p={3}>
            <Typography variant="h1">UPS! <br/>There was an error!</Typography>

            <Box py={2} borderRadius={4} overflow="hidden">
              <img height={250} width="auto" alt="props to meho" src="https://firebasestorage.googleapis.com/v0/b/webpagebuilder-cf99c.appspot.com/o/relaxing-guinea-pig_01-min.png?alt=media&token=3c2487ae-4e7a-4771-a9a0-bc86492551de" />
            </Box>
            
            <CustomButton onClick={this.handleReload} variant="outlined" color="primary">Try again</CustomButton>
            <Box mt={2} fontSize="0.7em" textAlign="left">
              {this.state.error && this.state.error.stack}
            </Box>
          </Box>          
        </Box>
      )
    }

    return this.props.children; 
  }
}

export default ErrorBoundary