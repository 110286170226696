import {
    FETCH_BETS_HISTORY,
    FETCH_BET_HISTORY
} from "../../../actions/types";
  
  
export default (state = "loading", action) => {
    if(!action.payload)return state
    //initialize todays matches object on first load
    let historyBets = state !== "loading" ? {...state} : {}

    switch (action.type) {
     
        case FETCH_BETS_HISTORY:
            historyBets = {...historyBets, ...action.payload} 
           
            return historyBets;
        case FETCH_BET_HISTORY:
            historyBets = {...historyBets, [action.betId]: action.payload} 
            return historyBets;

        default:
            return state
    }
};