import {
    ADD_USER_FAVORITE_BET,
    REMOVE_USER_FAVORITE_BET,
    FETCH_USER_FAVORITE_BETS
} from "../../../actions/types";
  
  
export default (state = "loading", action) => {
    
    //initialize todays matches object on first load
    let favoriteBets = state !== "loading" ? {...state} : {}
    switch (action.type) {
        case FETCH_USER_FAVORITE_BETS:   
            favoriteBets = action.payload
            return favoriteBets;
        case ADD_USER_FAVORITE_BET:   
            favoriteBets[action.betId] = action.payload
            return favoriteBets;
        case REMOVE_USER_FAVORITE_BET:   
            favoriteBets[action.betId] = null
            return favoriteBets;
        default:
            return state
    }
};