
import {
  FETCH_PAGE,
  FETCH_ALL_PAGES,
  ADD_NEW_PAGE,
  DELETE_PAGE,
  UPDATE_PAGE,

} from "./../../../actions/types";

import localPages from './../../../../static/pages/pages.json'

export default (state = localPages, action) => {
  switch (action.type) {
    case FETCH_PAGE:
      return  { ...state, ...action.payload}
    case FETCH_ALL_PAGES:
      return  { ...state, ...action.payload}
    case ADD_NEW_PAGE:
      return {...state, ...action.payload}
    case UPDATE_PAGE:
      state[action.pageId].elements = {...state[action.pageId].elements, ...action.payload}
      return {...state}
    case DELETE_PAGE:
      if(state[action.pageId]) delete state[action.pageId]
      return {...state}

    default:
      return state;
  }
};
