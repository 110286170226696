import * as firebase from '../../../../config/firebase'
import logger from  './../../../../helpers/logger'

import {
    FETCH_WEEKLY_USERS_STATISTIC,
    FETCH_WEEKLY_USER_STATISTIC,
    FETCH_MONTHLY_USERS_STATISTIC,
    FETCH_MONTHLY_USER_STATISTIC,
    FETCH_OVERALL_USERS_STATISTIC,
    FETCH_OVERALL_USER_STATISTIC
} from "../../types";


export const fetchWeeklyStatisticData = ({ orderBy="profit", limit=20, startAt, searchedBy})=> async dispatch =>{
    
    try{
        logger('api_call', "fetchWeeklyStatisticData")

        let results = null
        if(searchedBy){
            results = await firebase.usersStatisticRef.child('2').child('overall').orderByChild(orderBy).startAt(searchedBy).endAt(searchedBy+"\uf8ff").once('value')
        }else if(startAt){
            results = await firebase.usersStatisticRef.child('2').child('overall').orderByChild(orderBy).endAt(startAt).once('value')
        }else results = await firebase.usersStatisticRef.child('2').child('weekly').orderByChild(orderBy).limitToLast(limit).once('value')
        
        dispatch({
            type: FETCH_WEEKLY_USERS_STATISTIC,
            orderBy,
            payload: results.val()
        })

        return true
    }catch(err){
        
        logger('exception', "fetchWeeklyStatisticData", {err})
    }
}

export const fetchMonthlyStatisticData = ({orderBy, limit=20, startAt, searchedBy})=> async dispatch =>{
    
    try{
        logger('api_call', "fetchMonthlyStatisticData")

        let results = null
        if(searchedBy){
            results = await firebase.usersStatisticRef.child('2').child('overall').orderByChild(orderBy).startAt(searchedBy).endAt(searchedBy+"\uf8ff").once('value')
        }else if(startAt){
            results = await firebase.usersStatisticRef.child('2').child('overall').orderByChild(orderBy).endAt(startAt).once('value')
        }else results = await firebase.usersStatisticRef.child('2').child('monthly').orderByChild(orderBy).limitToLast(limit).once('value')
        
        dispatch({
            type: FETCH_MONTHLY_USERS_STATISTIC,
            orderBy,
            payload: results.val()
        })

        return true
    }catch(err){
        
        logger('exception', "fetchMonthlyStatisticData", {err})
    }
   
}

export const fetchOverallStatisticData = ({orderBy, limit=20, startAt, searchedBy})=> async dispatch =>{
    
    
    try{
        logger('api_call', "fetchOverallStatisticData")
        let results = null
        
        if(searchedBy){
            results = await firebase.usersStatisticRef.child('2').child('overall').orderByChild(orderBy).startAt(searchedBy).endAt(searchedBy+"\uf8ff").once('value')
        }else if(startAt){
            results = await firebase.usersStatisticRef.child('2').child('overall').orderByChild(orderBy).endAt(startAt).once('value')
        }else results = await firebase.usersStatisticRef.child('2').child('overall').orderByChild(orderBy).limitToLast(limit).once('value')
        
        dispatch({
            type: FETCH_OVERALL_USERS_STATISTIC,
            orderBy,
            payload: results.val()
        })

        return true

    }catch(err){
        logger('exception', "fetchOverallStatisticData", {err})
    }
}

export const fetchOverallLeaderBoard = (auth, userId, orderBy)=> dispatch =>{
    return
}

export const fetchUserStatistic = ({userId, type, statisticSpan})=> async dispatch =>{
    
    
    try{
        logger('api_call', `fetchUserStatistic${statisticSpan}`)
        
        const result = await firebase.usersStatisticRef.child(type).child(statisticSpan).child(userId).once('value')
        let data = result.val()
       
        
        if(statisticSpan === "weekly"){
            dispatch({
                type: FETCH_WEEKLY_USER_STATISTIC,
                payload: result.val(),
                statisticSpan,
                userId
            })
        }else if(statisticSpan === "monthly"){
            dispatch({
                type: FETCH_MONTHLY_USER_STATISTIC,
                payload: result.val(),
                statisticSpan,
                userId
            })
        }else if(statisticSpan === "overall"){
            dispatch({
                type: FETCH_OVERALL_USER_STATISTIC,
                payload: result.val(),
                statisticSpan,
                userId
            })
        }
        
       
        return true
    }catch(err){
        
        logger('exception', "fetchUserStatistic", {err})
    }
}