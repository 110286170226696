import {
  SET_COPY_SELECTED_ELEMENT,
  SET_SELECTED_ELEMENT,
  SET_DISABLE_ELEMENT_EVENTS,
  SET_SHOW_ALL_ELEMENTS,
  SET_EDIT_WRAPPER__ADD_SELECTED_CUSTOM_CONTAINER
} from './../../types'

export const setSelectedElement  = (elementId) => async dispatch =>{

    dispatch({
      type: SET_SELECTED_ELEMENT,
      payload: elementId
    })

}

export const setCopySelectedElement  = (status) => async dispatch =>{

    dispatch({
      type: SET_COPY_SELECTED_ELEMENT,
      payload: status
    })

}


export const setDisableElementEvents  = (status) => async dispatch =>{

    dispatch({
      type: SET_DISABLE_ELEMENT_EVENTS,
      payload: status
    })

}

export const setShowAllElements  = (status) => async dispatch =>{

    dispatch({
      type: SET_SHOW_ALL_ELEMENTS,
      payload: status
    })

}

export const setAddSelectedCustomContainer  = (status) => async dispatch =>{

    dispatch({
      type: SET_EDIT_WRAPPER__ADD_SELECTED_CUSTOM_CONTAINER,
      payload: status
    })

}
