import React from 'react';
import {withRouter} from 'react-router'
import * as actions from '../../../../redux/actions'
import { connect } from "react-redux";
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Tooltip from '@material-ui/core/Tooltip';

import { ResponsivePie } from '@nivo/pie'
import { ResponsiveLine } from '@nivo/line'
import { FacebookShareButton, FacebookIcon } from 'react-share'
import Helmet from 'react-helmet'

import CustomDialogLayout from './CustomDialogLayout'
import CustomButton from './CustomButton'
import editWrapper from '../../../default/wrappers/editWrapper'
import useMediaBreakPoint from './../../../../hooks/useMediaBreakPoint'
import displayDataConstructor from './../displayedDataConstructor'

import useDictionary from '../../../../hooks/useDictionary';
import SubscribeTipsterModal from './SubscribeTipsterModal'
import DeleteActiveBetModal from './DeleteActiveBetModal'
import { useCookies } from 'react-cookie';

const TITLE_HEIGHT = 60
const BUTTON_HEIGHT = 45
const BREAKPOINT_WIDTH = 955

const useStyles = makeStyles( theme => createStyles({
   
    title:{

    },
    selectedPage:{
        height: TITLE_HEIGHT,
      
        width: TITLE_HEIGHT,
        background: theme.palette.primary.main
    },

    subscribeButton:{
      borderRadius: 4,
      background: theme.palette.error.main,
      borderBottom: "1px solid "+ theme.palette.error.dark,
      color:"white",
      '&:hover':{
        background: theme.palette.error.dark,
      }
    },

    unsubscribeButton:{
      borderRadius: 4,
      background: theme.palette.success.main,
      borderBottom: "1px solid "+ theme.palette.success.dark,
      color:"white",
      '&:hover':{
        background: theme.palette.success.dark,
      }
    },
    
    subscribedUsers:{
      fontSize: "1em"
    },
    subscribedUsersCounter:{
      fontSize: "1em",
    }
}))

function PieGraph({data:{titleData, graphData}}){
  
  const theme = useTheme()
  const breakpoint = useMediaBreakPoint()
  const color = {
    "1": theme.palette.background.dark,
    "2": theme.palette.primary.main,
  }

  const getColors = bar=> color[bar.id]

  return(
    <Box flexGrow={1} position="relative" height={breakpoint === "xs" ? 180 : 225} width={breakpoint === "xs" ? 160 : 200} p={3} >
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" position="absolute" top={0} left={0} height="100%" width="100%" color="text.secondary">
        <Typography variant={"h2"} >
          {titleData.label}
        </Typography>
        <Typography variant={"body1"} >
           <b>{titleData.value}</b> 
        </Typography>
      </Box>
      <ResponsivePie
        data={graphData}
        margin={{ top: 0, right: 4, bottom: 0, left: 4 }}
        innerRadius={0.65}
        padAngle={3}
        cornerRadius={8}
        colors={getColors}
        borderWidth={2}
        borderColor={{ from: 'color', modifiers: [ [ 'darker', '0' ] ] }}
        enableRadialLabels={false}
        radialLabelsSkipAngle={10}
        radialLabelsTextXOffset={6}
        radialLabelsTextColor="#ffffff"
        radialLabelsLinkOffset={0}
        radialLabelsLinkDiagonalLength={16}
        radialLabelsLinkHorizontalLength={24}
        radialLabelsLinkStrokeWidth={1}
        radialLabelsLinkColor={{ from: 'color' }}
        slicesLabelsSkipAngle={10}
        slicesLabelsTextColor="#ffffff"
        animate={false}
        motionConfig="default"
     
      />
    </Box>
    
  )
}

function ProfileBoxElement(props) {
    const { title, auth, brdoparaData, fetchUserStatistic, location, users, fetchUserById, userGeoLocation, trophies} = props
    
    const theme = useTheme()
    const classes = useStyles()
    const dictionary = useDictionary({elementId: "profile_box_element", userGeoLocation})
    
    const displayedDataDictionary = useDictionary({elementId: "displayed_data_constructor", userGeoLocation})
    const dateDictionary = useDictionary({elementId: "date", userGeoLocation})
    const headerDictionary = useDictionary({elementId: "header", userGeoLocation})
    const breakPoint = useMediaBreakPoint()
    const [cookies, setCookie] = useCookies()
    const [popperAnchorEl, setPopperAnchorEl] = React.useState(null);
    const [popperOpen, setPopperOpen] = React.useState(false);

    const [statisticSpan, setStatisticSpan] = React.useState("monthly")
    const [profile, setProfile] = React.useState({})
    const [statisticData, setStatisticData] = React.useState("loading")
    const [loadingData, setLoadingData] = React.useState(false)

    const [pushNotifications, setPushNotifications] = React.useState(false)
    const [emailNotifications, setEmailNotifications] = React.useState(false)
    const [notificationPermission, setNotificationPermission] = React.useState(null)
    const [hidden, setHidden] = React.useState(false)
   
    const graphTheme = {

      "textColor": theme.palette.text.secondary,
      "fontSize": 11,
      "axis": {
          "domain": {
              "line": {
                  "stroke": "#777777",
                  "strokeWidth": 1
              }
          },
          "ticks": {
              "line": {
                  "stroke": "#777777",
                  "strokeWidth": 1
              }
          }
      },
      "grid": {
          "line": {
              "stroke": "#dddddd",
              "strokeWidth": 1
          }
      }
    
    }

    React.useEffect(()=>{

      //reset scroll position
      window.scrollTo(0,0)

      let urlParams = new URLSearchParams(location.search)
      let userId = urlParams.get('userId') ? urlParams.get('userId') : auth.uid

      try{
        setNotificationPermission(Notification.permission)
      }catch(err){

      }

      if(userId !== auth.uid && !users[userId]){
        fetchUserById(userId)
      }
    }, [])
   
    React.useEffect(()=>{
      let urlParams = new URLSearchParams(location.search)
      
      //MOBILE ONLY -> set hidden 
      if(window.innerWidth < BREAKPOINT_WIDTH){
        if(urlParams.get('dataType')){
          setHidden(true)
        }else if(hidden){
          setHidden(false)
        }
      }

      if(profile.id) return

      let userId = urlParams.get('userId') ? urlParams.get('userId') : auth.uid
     
      //set selected profile
      let user = null
      if((userId === auth.uid)){
        user = auth.userInfo
        user["id"] = userId
        setProfile(user)      
      }else if(users !== "loading" &&  users[userId]){
        user = users[userId]
        user["id"] = userId
        
        setProfile(user)
      } 
    },[users, location])
   
    React.useEffect(()=>{
      
      if(profile.id){
        //when profile is set fetch user statistic
        let userStatisticData = null
        let data = brdoparaData[displayDataConstructor["myProfile_statistic"](displayedDataDictionary).reducer[statisticSpan]]
        if(data !== "loading"){
          
          userStatisticData = data ? data[profile.id] : null

          //check if data is already stored
          if(data && profile.id in data){

            let statisticData = userStatisticData ? displayDataConstructor["myProfile_statistic"](displayedDataDictionary).displayData({data: userStatisticData, auth, type: statisticSpan}) : null
            setLoadingData(false)
            setStatisticData(statisticData)
          }else{
            setLoadingData(true)
            fetchUserStatistic({userId: profile.id, type: profile.type, statisticSpan})
          }
        }else{
          
          setLoadingData(true)
          fetchUserStatistic({userId: profile.id, type: profile.type, statisticSpan})
        } 
      }
    },[brdoparaData.usersStatisticWeekly, brdoparaData.usersStatisticMonthly, brdoparaData.usersStatisticOverall , statisticSpan, profile])

    const handleStatisticSpanChange = (value) => () => {
      let newStatisticSpan = value

      setPopperOpen(false)
      setStatisticSpan(newStatisticSpan)
    }

    const handleAddSubscription = ()=>{
      props.addSubscription({auth, tipsterId: profile.id, pushNotifications, emailNotifications, cookies, setCookie})
    }

    const handleSubscribeModal = ()=>{
      props.setActiveModal("tipsterSubscribeModal")
    }

    const handleUnSubscribeModal = ()=>{
      props.setActiveModal("removeSubscriptionModal")
    }

    const handleRemoveSubscription = () => {
      setLoadingData(true)
      props.removeSubscription({tipsterId: profile.id, auth})
    }

    const handlePopperToggle = (event) =>{
      setPopperAnchorEl(event.currentTarget)
      setPopperOpen((prev) => !prev);
    }


    const requestNotificationPermission = async () => {
      if(notificationPermission === "default"){
        let permission = await Notification.requestPermission()
        
        setNotificationPermission(permission)
      }
    }
    
    return (
      <Box height="100%" position="relative"  borderRadius={2} display={hidden ? 'none': 'block'}> 

        <Fade in={statisticData !== "loading"}>
          <Box width="100%"  display="flex" flexDirection="column" borderRadius={2}>
            <Box display="flex"  justifyContent="space-between" alignItems="flex-end" mb={1} height={TITLE_HEIGHT} >
              <CustomButton 
                label={dictionary.title}
                height={TITLE_HEIGHT}
                type="title"
              />
              <Box display="flex" alignItems="center">
                {
                  profile.id === auth.uid && 
                    <FacebookShareButton
                      url={`https://brdopara.com/facebookShare?type=profile&id=${profile.id}`}
                      appId="118291070242161"
                    >
                      <Box display="flex" alignItems="center" justifyContent="center" bgcolor="#3b5998" height={BUTTON_HEIGHT} width={BUTTON_HEIGHT} borderRadius={4} overflow="hidden">
                        <FacebookIcon size={40} />
                      </Box>
                    </FacebookShareButton>
                }
               
                {
                  profile.id === auth.uid && auth.userInfo && auth.userInfo.activeSubscribers &&  
                    <Box height={BUTTON_HEIGHT} display="flex" alignItems="center" bgcolor="background.transparent" borderRadius={4} ml={1} color="white" overflow="hidden" >
                      <Box  py={1} px={2} display="flex"  justifyContent="center" alignItems="center">
                        <Typography variant="h2" className={classes.subscribedUsers}>{dictionary.subscribedUsers}</Typography>
                      </Box>
                      <Box height={BUTTON_HEIGHT}  py={1} px={2} bgcolor="primary.main" display="flex" alignItems="center">
                        <Typography variant="h2" className={classes.subscribedUsersCounter}>
                          {auth.userInfo.activeSubscribers}
                        </Typography>
                      </Box>
                    </Box>
                }
                {
                  auth.userInfo.metadata && auth.userInfo.metadata.subscriptions[profile.id] ? (
                    <Box ml={1}>
                      <Button
                        variant="contained"
                        color="inherit"
                        height={BUTTON_HEIGHT}
                        className={classes.unsubscribeButton}
                        onClick={handleUnSubscribeModal}
                      >
                        {dictionary.subscribedButtonLabel || ""}
                      </Button>
                    </Box>
                  ): auth.uid !== profile.id && (
                    <Box ml={1}>
                      <Button
                        variant="contained"
                        color="inherit"
                        height={BUTTON_HEIGHT}
                        className={classes.subscribeButton}
                        onClick={handleSubscribeModal}
                      >
                        {dictionary.subscribeButtonLabel || ""}
                      </Button>
                    </Box>
                  )
                }
              </Box>
            </Box>
            <Box flexGrow={1} display="flex" flexDirection="column" p={2} bgcolor="background.transparent" borderRadius={4} color="text.secondary" borderBottom="1px solid black">  
              <Box  display="flex"  justifyContent="flex-start"  alignItems="center" >
                <Box display="flex" alignItems="center" >
                  <Box mr={2} width={40} height={40} borderRadius={4} display="flex" alignItems="center" overflow="hidden">
                    {
                      profile.icon ? <img width={40} height={40} src={profile.icon} alt="profile icon" /> : <Box height={40} width={40} bgcolor="background.main" borderRadius={4}/>
                    }
                  </Box>
                  <Box display="flex" flexWrap="wrap" alignItems="center">
                    <Typography variant="body1" align="left" style={{fontSize: "1.4em", fontWeight: 400}}>{profile.displayName}</Typography>
                    <Box height="100%" display="flex" alignItems="center">
                      {profile.achievements && Object.keys(profile.achievements).filter(key => trophies[key]).sort((a, b) => trophies[a].pos - trophies[b].pos ).map(key =>  (
                            <Box key={key} ml={1} display="flex" alignItems="center">
                              <Tooltip  placement="top" title={trophies[key].label}>
                                <img alt="trophy icon" height="25px" width="auto" src={trophies[key].icon} />
                              </Tooltip>
                            </Box>
                      ))}
                    </Box>
                  </Box>
                 
                </Box>
                
              </Box>
              {
                profile.description &&
                  <Box pt={1} mt={1} borderTop={"1px solid "+theme.palette.background.dark}> 
                    <Typography variant="body2" align="left">{profile.description}</Typography>
                  </Box>
              }
              
            </Box>
          </Box> 
        </Fade>
        
        <Fade in={statisticData !== "loading"} timeout={500}>
          <Box display="flex"  justifyContent="space-between" alignItems="flex-end" my={1}  height={TITLE_HEIGHT}>
              
              <CustomButton 
                label={dictionary.title2}
                height={TITLE_HEIGHT}
                type="title"
              />
              <Popper open={popperOpen} anchorEl={popperAnchorEl} placement="bottom-end" transition>
                {({ TransitionProps }) => (
                
                    <Box display="flex" flexDirection="column"  bgcolor="background.primary" mt={1}  borderRadius={2} boxShadow={3}>
                        
                      {statisticSpan !== "weekly" && <CustomButton type="large" label={dictionary.weeklyStatisticButtonLabel} onClick={handleStatisticSpanChange("weekly")} size="small" variant="text" fullWidth />}
                      {statisticSpan !== "monthly" && <CustomButton type="large" label={dictionary.monthlyStatisticButtonLabel} onClick={handleStatisticSpanChange("monthly")} size="small" variant="text" fullWidth />}
                      {statisticSpan !== "overall" && <CustomButton type="large" label={dictionary.overallStatisticButtonLabel} onClick={handleStatisticSpanChange("overall")} size="small" variant="text" fullWidth />}
                      
                    </Box>
                
                )}
              </Popper>
              {
                breakPoint === "xs" ? (
                    <CustomButton 
                      onClick={handlePopperToggle} 
                      type="titleIconButton"
                      icon="moreVert"

                      label={
                        (statisticSpan === "weekly" && dictionary.weeklyStatisticButtonLabel) || 
                        (statisticSpan === "monthly" && dictionary.monthlyStatisticButtonLabel) || 
                        (statisticSpan === "overall" && dictionary.overallStatisticButtonLabel) 
                      }
                    />
    
                ):(
                  <Box display="flex"  alignItems="flex-end" >
                    <Box ml={1}>
                       <CustomButton type="titleButton" label={dictionary.weeklyStatisticButtonLabel} onClick={handleStatisticSpanChange("weekly")} active={statisticSpan === "weekly"} />
                    </Box>
                    <Box ml={1}>
                      <CustomButton type="titleButton" label={dictionary.monthlyStatisticButtonLabel} onClick={handleStatisticSpanChange("monthly")} active={statisticSpan === "monthly"} /> 
                    </Box>
                    <Box ml={1}>
                      <CustomButton type="titleButton" label={dictionary.overallStatisticButtonLabel} onClick={handleStatisticSpanChange("overall")} active={statisticSpan === "overall"} />
                    </Box>
                  </Box>
                )
              }
              
          </Box>
        </Fade>
        <Box position="relative" minHeight={300}>
        {
          statisticData && statisticData !== "loading" && 
            <Fade in={true} timeout={500} mountOnEnter unmountOnExit>
              <Box bgcolor="background.transparent" borderRadius={4} display="flex" flexWrap="wrap" overflow="hidden" borderBottom="1px solid black">
                <PieGraph  
                  data={{
                    graphData: [
                      {
                        "id": "1",
                        "label":  statisticData.stakeSum.label,
                        "value":  statisticData.stakeSum.value,
                      
                      },
                      {
                        "id": "2",
                        "label": statisticData.returns.label,
                        "value": statisticData.returns.value,
                  
                      }
                    ],
                    titleData:{
                      label: displayedDataDictionary.profit,
                      value: statisticData.profit.value
                    }
                  }}
                />
                <PieGraph  
                  data={{
                    graphData: [
                        {
                        "id": "1",
                          "label": statisticData.lostNum.label,
                          "value": statisticData.lostNum.value,
                        },
                        {
                          "id": "2",
                          "label": statisticData.winNum.label,
                          "value": statisticData.winNum.value,
                        }
                      ],
                      titleData:{
                        label: displayedDataDictionary.avrgWins,
                        value: statisticData.avrgWins.value+"%"
                      }
                    }}
                  />
                  <PieGraph  
                  data={{
                    graphData: [
                      {
                        "id": "1",
                          "label": statisticData.lostNum.label,
                          "value": statisticData.lostNum.value,
                        },
                        {
                          "id": "2",
                          "label": statisticData.winNum.label,
                          "value": statisticData.winNum.value,
                        }
                      ],
                      titleData:{
                        label: displayedDataDictionary.roi,
                        value: statisticData.roi.value+"%"
                      }
                    }}
                  />
              </Box>
            </Fade>
        }
        {
          statisticData && statisticData !== "loading" && 
            <Fade in={true} timeout={1000} mountOnEnter unmountOnExit>
              <Box mt={1}   width="100%">
                <Box display="flex"  justifyContent="space-between" my={1}  height={TITLE_HEIGHT}>
                    <CustomButton 
                      label={dictionary.title3}
                      height={TITLE_HEIGHT}
                      type="title"
                    />
                </Box>
                
                <Box bgcolor="background.transparent" borderRadius={4} borderBottom="1px solid black" mb={1} p={1} height="225px" width="100%">
                  {
                    statisticData && statisticData !== "loading" && (
                      <ResponsiveLine
                        data={[{id: dictionary.profitLabel, data: statisticData.graphData.value}]}
                        theme={graphTheme}
                        colors={d => theme.palette.primary.main}
                        margin={{ top: 32, right: 16, bottom: 32, left: 16 }}
                        xScale={{ type: 'point' }}
                        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            orient: 'bottom',
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            format: x => {
                              if(statisticSpan === "weekly") return dateDictionary.daysShort[x]
                              if(statisticSpan === "monthly"){
                                if(x % 2 === 0)return `${x+1}`
                                else return null
 
                              }
                              if(statisticSpan === "overall") return dateDictionary.monthsShort[x]
                              
                            },
                            legendOffset: 36,
                            legendPosition: 'middle',
                            
                        }}
                        isInteractive={false}
                        axisLeft={null}
                        enableArea={true}
                        enableGridX={false}
                        enableGridY={false}
                        pointSize={3}
                        pointColor={{ theme: 'background' }}
                        pointBorderWidth={2}
                        pointBorderColor={{ from: 'serieColor' }}
                        pointLabel={(d)=> {
                            if(statisticSpan === "monthly") {
                              if(d.x % 2 === 0)return Math.round(d.y)
                              else return null
                            }else return d.y
                        }}
                        enablePointLabel={true}
                        pointLabelYOffset={-15}
                        enableCrosshair={false}
                        useMesh={true}
                        legends={[]}
                      />
                    )
                  }    
                </Box>
              </Box>
            </Fade>
        }

        {!statisticData && (
          <Fade in={true} timeout={500}>
            <Box position="absolute" top={0} height={300} width="100%" bgcolor="background.transparent" borderRadius={2} display="flex" alignItems="center" justifyContent="center" color="text.secondary">
              <Typography variant="h2">
                {dictionary.noStatisticLabel}
              </Typography>
            </Box>
          </Fade>
        )}
      </Box> 

      <SubscribeTipsterModal tipsterId={profile.id} tipsterName={profile.displayName}  />
      <DeleteActiveBetModal />
      <CustomDialogLayout name="removeSubscriptionModal" title={dictionary.removeSubscriptionModalTitle} actions={[{label: dictionary.confirmButton, onClick: handleRemoveSubscription}]}>
        <Box>
          <Typography variant="body1" align="justify">
            {dictionary.removeSubscriptionModalRemoveSubscriptionInfo}  
          </Typography>
        </Box>
      </CustomDialogLayout>  
    </Box>
  )
}


ProfileBoxElement.defaultProps = {
    
    variant: "text",
    color: "main",
  
    elementStyle: {}, // style object of element from database
    style: {}, // style object is pushed to element if there is active animation present
    title: "Zadnje stave",
    dataType: "leaderBoardWeekly",

    elementClasses: [],

    
  };
  

const mapStateToProps = ({auth, brdoparaData, users, userGeoLocation, trophies}) => {
  return {
    auth,
    brdoparaData,
    users,
    userGeoLocation,
    trophies
  }
}

export default withRouter(connect(mapStateToProps, actions)(editWrapper(ProfileBoxElement)))
