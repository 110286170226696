import {
    FETCH_SUBSCRIBED_ACTIVE_BETS,
    FETCH_SUBSCRIBED_ACTIVE_BET,
} from "../../../actions/types";
  
export default (state = "loading", action) => {
    if(!action.payload)return state
    //initialize todays matches object on first load
    let subscribedBets = state !== "loading" ? {...state} : {}
   
    switch (action.type) {
        case FETCH_SUBSCRIBED_ACTIVE_BETS:   
            subscribedBets ={...subscribedBets, ...action.payload} 
            return subscribedBets;
        case FETCH_SUBSCRIBED_ACTIVE_BET:   
            subscribedBets[action.betId] = action.payload
            return subscribedBets;
        default:
            return state
    }
};