import React from 'react';
import {withRouter} from 'react-router'

import * as actions from '../../../../redux/actions'
import { connect } from "react-redux";
import classNames from 'classnames'
import { makeStyles, createStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Popper from '@material-ui/core/Popper';
import SearchIcon from '@material-ui/icons/Search';
import MoreIcon from '@material-ui/icons/MoreVert';

import { ResponsiveLine } from '@nivo/line'
import CustomButton from './CustomButton'

import CustomIcon from './../../../components/icons/CustomIcon'
import editWrapper from '../../../default/wrappers/editWrapper'
import Preloader from './../../../components/preloaders/Preloader'
import displayedDataConstructor from './../displayedDataConstructor'
import useMediaBreakPoint from './../../../../hooks/useMediaBreakPoint'
import useDictionary from '../../../../hooks/useDictionary';
import timestampFormatter from '../../../../helpers/timestampFormatter';
import AdvertisementElement from './AdvertisementElement';

import debounce from 'lodash/debounce'
import { setActiveModal } from '../../../../redux/actions';

const WINDOW_HEIGHT = window.innerHeight

const TABLE_HEADER_HEIGHT = 60

const BUTTON_HEIGHT = 45

const useStyles = makeStyles( theme => createStyles({
    
    header:{
        fontFamily: 'Racing Sans One',
        minHeight: 30,

        [theme.breakpoints.down('xs')]:{
            minHeight: 40,
            fontSize: "1.2em"
        }
    },

   
    footer:{
        fontFamily: 'Racing Sans One',
    },
   
    actionButton:{
      height: 60,
      borderRadius: 60,
      width: `calc(50% - ${theme.spacing(1)}px)`,
      
    },
    actionButtonLabel:{
        fontSize: 24,
        fontWeight: 300
    },

    button:{
        borderRadius: 8,
        color: "white", 
        fontSize: 16,
        fontWeight: 200,
        minHeight: 40,
     
    },
    
    searchButton:{
        borderRadius: 8,
        fontSize: 32,
        background: theme.palette.primary.main,
        paddingTop: theme.spacing(1),
     
    },
    showMoreRow:{
        cursor:" pointer",
        borderBottom: "1px solid black",
        "&:hover":{
            background: theme.palette.background.transparent,
            borderBottom: "none"
        }
    },

    mobileFontWeight:{
        [theme.breakpoints.down('sm')]:{
            fontWeight: 400
        }
    }
    
}))

const useStyleRow =  makeStyles( theme => createStyles({
    row:{
        color: theme.palette.text.primary,
        "&:hover":{
            background: theme.palette.background.dark,
            color: theme.palette.text.secondary,

        },

        transition: "all 0.2s ease-in-out",
        cursor: "pointer",

        [theme.breakpoints.down('xs')]:{
            minHeight: 40,
            fontSize: "1.2em"
        }
    },
    
    profileCell:{
        
        "&:hover":{
            textDecoration: "underline", 
        },
    },
    cellTypography:{
        fontWeight: 300
    },
    cellMatchTypography:{
        
    },
    cellProfileTypography:{
        
    }
}))

const CustomInput = withStyles(theme =>({
    root:{
       paddingRight: theme.spacing(1),
       paddingLeft: theme.spacing(1)
    },
    input:{
        color: "white",
        fontSize: 16,
        fontWeight: 300,
        maxWidth: 100,
    }
}))(InputBase)

const CustomIconButton = withStyles(theme =>({
    root:{
       padding: 0,
       color: "white"
    },
   
}))(IconButton)

function CustomSearchInput({handleSearchInput, orderBy, placeholder }){
    let theme = useTheme()
    let inputRef = React.useRef()
    let [showInput, setShowInput] = React.useState(false)

    const handleClick = () => {  
        setShowInput(state => {

            if(!state) inputRef.current.click()
            return !state
        })
    }

    const constHandleInputChange = (e)=>{
        handleSearchInput(e.target.value)
    }
   
    return(
        <Box 
            px={1} 
            
            borderRadius={4} 
            fontSize={32} 
            bgcolor={"primary.main"} 
            color="white" 
            height={45}
            minWidth={45}
            display="flex" 
            flexDirection="row" 
            alignItems="center"
            justifyContent="center"
            
        > 
            <Box display="flex" flexDirection="row" alignItems="center" style={{transition: "width 0.25s", width: showInput ? 100 : 0}}>
                <CustomInput ref={inputRef}  onChange={constHandleInputChange} id="input-with-icon-textfield" label="" placeholder={placeholder} />
            </Box>
            
            <Box display="flex" alignItems="center" onClick={handleClick} color="black"  style={{cursor: "pointer"}} position="relative">
                <SearchIcon fontSize="small" />
            </Box>
          
        </Box>
)}

function ListHeader({dataType, handleOrderChange, orderBy, orderDirection, userGeoLocation, enableSort}){
    const theme = useTheme()
    const classes = useStyles()
    const breakPoint = useMediaBreakPoint()
    const displayedDataDictionary = useDictionary({elementId: "displayed_data_constructor", userGeoLocation})
    const dataTypeDisplayedDataConstructor = displayedDataConstructor[dataType](displayedDataDictionary)

    const handleToggleOrderBy = (key) => ()=> {
        handleOrderChange({orderBy: key, orderDirection : orderDirection === 1 ? -1 : 1})
    }

    return dataTypeDisplayedDataConstructor.dataMap.map((o, i) => {
        let hiddenCells = o.hideOn && o.hideOn.includes(breakPoint)
        let cellWidth = o.breakpointWidth && o.breakpointWidth[breakPoint] ? o.breakpointWidth[breakPoint] : o.width
        
        if(!hiddenCells){
            return (
                <Box 
                    position="relative"
                    key={o.id}
                    display="flex" 
                    justifyContent="center" 
                    alignItems="center"
                    minWidth={o.minWidth}
                    width={cellWidth}
                    height="100%"
                    color="white" 

                    onClick={o.sort && enableSort ? handleToggleOrderBy(o.id):null}
                    style={o.sort && {cursor:"pointer"}}
                >
                    {enableSort && orderBy === o.id  &&  orderDirection === 1 &&
                        <Box 
                            top={-2}
                            position="absolute"
                            fontSize="24px"
                            color={theme.palette.primary.main}
                        >
                            <CustomIcon name={"up"}/>
                        </Box>
                    }
                    {
                        enableSort && orderBy === o.id  &&  orderDirection === -1 &&
                            <Box 
                                bottom={-10}
                                position="absolute"
                                fontSize="24px"
                                color={theme.palette.primary.main}
                            >
                                <CustomIcon name={"down"}/>
                            </Box>
                    }
                    <Box fontSize="0.85em" width="100%" px="4px">
                        <Typography className={classes.mobileFontWeight} variant="body1" noWrap>
                            {o.label} 
                        </Typography>
                    </Box>
                    {
                        dataTypeDisplayedDataConstructor.dataMap.length - 1 !== i &&
                            <Box position="absolute" right="0" bgcolor={theme.palette.primary.main} height="calc(100% - 24px)" width="1px" style={{opacity: 0.3}} />
                    }
                
                </Box>
            )
        }else return null
    })
}

function RowContainer({data, dataMap, rowPosition, lastRow, rowHeight, onRowClick, userGeoLocation}){
    const theme = useTheme()
    const classes = useStyleRow()
    const breakPoint = useMediaBreakPoint()
    const dateDictionary = useDictionary({elementId: "date", userGeoLocation})
    //console.log(data, rowPosition, lastRow, dataMap)

    const handleClick = (type) => (e) =>{
     
        onRowClick(type, data)
    }

    
    return(
       
        <Box display="flex" flexDirection="row" width="100%" height={rowHeight} className={classes.row} borderBottom={lastRow !== rowPosition && "1px solid"+ theme.palette.background.dark} >
        {
            dataMap.map((o, i ) => {
                let hiddenCells = o.hideOn && o.hideOn.includes(breakPoint)
                let cellWidth = o.breakpointWidth && o.breakpointWidth[breakPoint] ? o.breakpointWidth[breakPoint] : o.width
                if(!data[o.id]) return null
                if(!hiddenCells){   
                    if(o.type === "number"){
                        return (
                            <Box key={o.id}  onClick={handleClick(o.onClick)} width={cellWidth} minWidth={o.minWidth}  display="flex" alignItems="center" justifyContent="center" color="white"  p={1}>
                               
                                <Typography variant="body2" className={classes.cellTypography}><b>{data[o.id].value}</b></Typography>
                            </Box>
                        )
                    }if(o.type === "number_bold"){
                        return (
                            <Box key={o.id}  onClick={handleClick(o.onClick)} width={cellWidth} minWidth={o.minWidth}  display="flex" alignItems="center" justifyContent="center" color="white"  p={1}>
                                <Typography variant="body2" className={classes.cellTypography}><b>{data[o.id].value}</b></Typography>
                            </Box>
                        )
                    }if(o.type === "percentage"){
                        return (
                            <Box key={o.id}  onClick={handleClick(o.onClick)} width={cellWidth} minWidth={o.minWidth}  display="flex" alignItems="center" justifyContent="center" color="white"  p={1}>
                                <Typography variant="body2" className={classes.cellTypography}><b>{data[o.id].value}%</b></Typography>
                            </Box>
                        )
                    }if(o.type === "element_position"){
                        return (
                            <Box 
                                key={o.id}  
                                onClick={handleClick(o.onClick)} 
                                minWidth={o.minWidth}
                                width={cellWidth}
                                display="flex" 
                                alignItems="center" 
                                justifyContent="center" 
                                
                                color="white"  
                                p={1} 
                                fontSize="clamp(0.75em, 2vw, 0.8em)"
                            >
                                <Typography variant="h2" className={classes.cellPositionTypography} fontSize="inherit"> {rowPosition+1}.</Typography>
                            </Box>
                        )
                    }if(o.type === "profile"){
                        
                        return (
                            <Box key={o.id}  onClick={handleClick(o.onClick)} className={classes.profileCell} width={cellWidth} minWidth={o.minWidth} display="flex" alignItems="center" justifyContent="left" color="white" >
                                {
                                    data[o.id] && data[o.id].icon ?
                                        <Box height="auto" width={rowHeight} display="flex"  alignItems="center"  color="white" bgcolor="background.primary">
                                            <img width="100%" height="auto" src={data[o.id].icon} alt="profile icon"/>
                                        </Box>:
                                        <Box px={1} height="100%" width={rowHeight} display="flex" fontSize="clamp(1)" alignItems="center" justifyContent="center" color="white" bgcolor="background.primary">
                                            
                                        </Box>     
                                }
                                <Box pl={1} minWidth={`calc(${cellWidth})`}>
                                    <Typography variant="body1" className={classes.cellTypography} noWrap align="left">{data[o.id].value}</Typography>
                                </Box>
                                
                            </Box>
                        )
                    }if(o.type === "match"){
                        
                        if(data.combination) {
                            return (
                                <Box key={o.id}  onClick={handleClick(o.onClick)} width={cellWidth} minWidth={o.minWidth} display="flex" alignItems="center" justifyContent="center" color="white"  p={1}>
                                    <Typography variant="body2" className={classes.cellTypography} align="center" noWrap>{data[o.id].match}</Typography>
                                </Box>
                            )
                        }

                        let match =  data[o.id].match && data[o.id].match.split("-")

                        if(breakPoint === "xs"){
                            return (
                                <Box key={o.id}  position="relative" onClick={handleClick(o.onClick)} width={cellWidth} minWidth={o.minWidth} display="flex" flexDirection="column" alignItems="center" justifyContent="center"  overflow="hidden" color="white">
                                    <Typography variant="body2" className={classes.cellMatchTypography} noWrap>{match[0]}</Typography>
                                    <Box width="40px" borderBottom={"1px solid "+theme.palette.primary.main}></Box>
                                    <Typography variant="body2" className={classes.cellMatchTypography} noWrap>{match[1]}</Typography>
                                </Box>
                            )
                        }else{
                            return (
                                <Box  key={o.id} position="relative" onClick={handleClick(o.onClick)} width={cellWidth} minWidth={o.minWidth} display="flex" alignItems="center" justifyContent="flex-start"  overflow="hidden" > 
                                    <Box  minWidth="calc(50% - 26px)" p={1} borderRadius={32} color="white" m={1}>
                                        <Typography variant="body2" className={classes.cellMatchTypography} noWrap>{match[0]}</Typography>
                                    </Box>
                                    <Box width="20px" color="white">
                                        <Typography variant="body2">vs</Typography>
                                    </Box>
                                
                                    <Box  minWidth="calc(50% - 26px)"  p={1} borderRadius={32} color="white" m={1}>
                                        <Typography variant="body2" className={classes.cellMatchTypography}  noWrap>{match[1]}</Typography>
                                    </Box>
                                </Box>
                            )
                        }
                        
                    }if(o.type === "string"){
                        return (
                            <Box key={o.id}  onClick={handleClick(o.onClick)} width={cellWidth} minWidth={o.minWidth} display="flex" alignItems="center" justifyContent="center" color="white"  p={1}>
                                <Typography variant="body2" className={classes.cellTypography} align="center" noWrap>{data[o.id] && data[o.id].value}</Typography>
                            </Box>
                        )
                    }if(o.type === "date"){
                        return (
                            <Box key={o.id}  onClick={handleClick(o.onClick)} width={cellWidth} minWidth={o.minWidth} display="flex" alignItems="center" justifyContent="center" color="white"  p={1}>
                                <Typography variant="body1" className={classes.cellTypography} align="center" noWrap>{timestampFormatter(data[o.id].value, dateDictionary, {dateTimeShort: true})}</Typography>
                            </Box>
                        )
                    }if(o.type === "time"){
                        return (
                            <Box key={o.id}  onClick={handleClick(o.onClick)} width={cellWidth} minWidth={o.minWidth} display="flex" alignItems="center" justifyContent="center" color="white"  p={1}>
                                <Typography variant="body1" className={classes.cellTypography} align="center" noWrap>{data[o.id].value}</Typography>
                            </Box>
                        )
                    }if(o.type === "icon"){
                        return (
                            <Box key={o.id}  onClick={handleClick(o.onClick)} width={cellWidth} minWidth={o.minWidth} display="flex" alignItems="center" justifyContent="center"  p={1} color="white" fontSize="1.4em">
                                <CustomIcon name={data[o.id].value}/>
                            </Box>
                        )
                    }if(o.type === "comment"){
                        return (
                            <Box key={o.id}  onClick={handleClick(o.onClick)} width={cellWidth} minWidth={o.minWidth} display="flex" alignItems="center" justifyContent="center"  p={1} color="white" fontSize="1.4em">
                                <CustomIcon name={"comment"}/>
                            </Box>
                        )
                    }if(o.type === "remove"){
                        return (
                            <Box key={o.id}  onClick={handleClick(o.onClick)} width={cellWidth} minWidth={o.minWidth} display="flex" alignItems="center" justifyContent="center"  p={1} color="red" fontSize="1.4em">
                                <CustomIcon name="delete"/>
                            </Box>
                        )
                    }if(o.type === "graphData"){
                        
                        return (
                            <Box key={o.id} onClick={handleClick(o.onClick)} width={cellWidth} minWidth={o.minWidth}  height={rowHeight || "50px"} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                              
                                <ResponsiveLine
                                    data={[{id: data[o.id].label, data: data[o.id].value}]}
                                    colors={d => theme.palette.primary.main}
                                    margin={{ top: 16, right: 16, bottom: 4, left: 16 }}
                                    xScale={{ type: 'point' }}
                                    yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
                                    axisTop={null}
                                    axisRight={null}
                                    axisBottom={null}
                                    axisLeft={null}
                                    enableGridX={false}
                                    enableGridY={false}
                                    enableArea={true}
                                    enablePoints={false}
                                    pointSize={10}
                                    pointColor={{ theme: 'background' }}
                                    pointBorderWidth={2}
                                    pointBorderColor={{ from: 'serieColor' }}
                                    pointLabel="y"
                                    pointLabelYOffset={-12}
                                    isInteractive={false}
                                    legends={[]}
                                />
                            </Box>
                        )
                    }else{
                        return <Box key={o.id} ></Box>
                    }
                }else return false
            })       
        }
    </Box>
)}

function ListBoxElement({ 
    edit,
    title, 
    auth, 
    brdoparaData, 
    history, 
    location, 
    fetchAdditionalData=false,
    numOfDataOnFirstLoad=50,
    expandMoreData=true,
    maxRowsDisplayed=14,
    containerHeightMultiplier=1,
    enableSearch=false, 
    searchBy,
    enableSort=true,
    style,
    elementStyle,
    userGeoLocation,
    ...props
}){
   
    const theme = useTheme()
    const classes = useStyles()
    const dictionary = useDictionary({elementId: "list_box_element", userGeoLocation})
    const displayedDataDictionary = useDictionary({elementId: "displayed_data_constructor", userGeoLocation})
    const breakPoint = useMediaBreakPoint()
    const urlParams = new URLSearchParams(location.search)
    const listBodyRef = React.useRef()

    const [selectedPage, setSelectedPage] = React.useState(0)
    const [dataType, setDataType] = React.useState(urlParams.get("dataType") || props.dataType)
  
    const [orderBy, setOrderBy] = React.useState(urlParams.get("orderBy") || displayedDataConstructor[props.dataType]().orderBy)
    const [groupBy, setGroupBy] = React.useState(urlParams.get("groupBy") || null)
    const [orderDirection, setOrderDirection]  = React.useState(1);

    const [displayedData, setDisplayedData] = React.useState(null)
    const [displayedDataLength, setDisplayedDataLength] = React.useState({})
    const [popperAnchorEl, setPopperAnchorEl] = React.useState(null);
    const [popperOpen, setPopperOpen] = React.useState(false);
    const [loadingData, setLoadingData] = React.useState(false);
    const [showMoreData, setShowMoreData] = React.useState(false);
    const [scrollEnd, setScrollEnd] = React.useState(false);
    const [searchedBy, setSearchedBy] = React.useState("");

    
    const [numberOfDataShown, setNumberOfDataShown] = React.useState(maxRowsDisplayed)
   
    React.useEffect(()=>{
        let activeDataType = urlParams.get("dataType") 
        
        if(activeDataType && activeDataType !== dataType) setDataType(activeDataType)  

    }, [location])

 

    React.useEffect(()=>{
        if(edit) return

        //set userId to params object if url params do not exsist
        let userId = urlParams.get('userId') ? urlParams.get('userId') : auth.uid
        let betId = urlParams.get('betId') ? urlParams.get('betId') : ""
        
        let listDisplayDataConstructor = displayedDataConstructor[dataType](displayedDataDictionary)
 
        if(brdoparaData[listDisplayDataConstructor.reducer] === "loading"){    
            //fetch data if not exist
            
            props[listDisplayDataConstructor.fetchFunction]({auth, betId, userId, orderBy, groupBy})
            
            setLoadingData(true)
        }

        
        if(brdoparaData[listDisplayDataConstructor.reducer] !== "loading"){
            let displayData = listDisplayDataConstructor.displayData({auth, data: brdoparaData, orderBy, userId, orderDirection, groupBy, betId})
            
            if(searchedBy){
                let findBy = searchBy || groupBy
                displayData = displayData.filter(o => o[findBy].includes(searchedBy))
            }   

            let currDisplayDataLength = displayData.length

            setDisplayedData(displayData)
            setShowMoreData(currDisplayDataLength > numberOfDataShown)
            setDisplayedDataLength(state => ({...state, [orderBy]: currDisplayDataLength}))
           
            //console.log({currDisplayDataLength, numberOfDataShown, displayedDataLength: displayedDataLength[orderBy], orderBy})
            if(!loadingData && currDisplayDataLength <= numberOfDataShown && (!displayedDataLength[orderBy] || displayedDataLength[orderBy] < currDisplayDataLength) ){
                props[listDisplayDataConstructor.fetchFunction]({auth, betId, userId, orderBy, groupBy})
               
                setLoadingData(true)
                     
            }else{
                setLoadingData(false) 
            }

        }
        
    },[brdoparaData[displayedDataConstructor[dataType](displayedDataDictionary).reducer], dataType, orderBy, orderDirection])
    
    React.useEffect(()=>{
        //set userId to params object if url params do not exist
        let listDisplayDataConstructor = displayedDataConstructor[dataType](displayedDataDictionary)
        let userId = urlParams.get('userId') ? urlParams.get('userId') : auth.uid
        let betId = urlParams.get('betId') ? urlParams.get('betId') : ""
        let groupBy = urlParams.get("groupBy") || null
        let orderBy = urlParams.get("orderBy") || displayedDataConstructor[dataType]().orderBy

       
        if(!loadingData && displayedData && (displayedData.length <= numberOfDataShown-maxRowsDisplayed) && fetchAdditionalData){
            setLoadingData(true)
            
            let startAt =  displayedData[displayedData.length-1][orderBy].value ||  displayedData[displayedData.length-1][orderBy] 
            
            props[listDisplayDataConstructor.fetchFunction]({auth, betId, userId, orderBy, startAt})
        }
    },[numberOfDataShown])

    let numberOfRowDisplayed = maxRowsDisplayed 
    let containerHeight = window.innerHeight - 186
    
    containerHeight = containerHeight / containerHeightMultiplier
    containerHeight = containerHeight < 300 ? 300 : containerHeight

    let tableContainerHeight = containerHeight - 24

    let rowHeight = (tableContainerHeight)/(numberOfRowDisplayed + (expandMoreData ? 0.82 : 0.3))
    rowHeight = rowHeight%2 !== 0 ? rowHeight+1 : rowHeight

    let tableInfoRowHeight = rowHeight
    
    //////////////////////////////////////////////////////

    const handleActionClick = async (action)=>{

        setDisplayedData(null)
        urlParams.set("dataType", action.dataType)
       
        history.replace(`${location.pathname}?${urlParams}`)
        setDataType(action.dataType)  
        setSearchedBy("")
        setDisplayedDataLength({})
        setPopperOpen(false)
    }

    const handleSearchInput = debounce((value)=>{
        let findBy = searchBy || orderBy
        let userId = urlParams.get('userId') ? urlParams.get('userId') : auth.uid
        let betId = urlParams.get('betId') ? urlParams.get('betId') : ""
        let data = displayedDataConstructor[dataType](displayedDataDictionary).displayData({auth, data: brdoparaData, orderBy, userId, orderDirection, groupBy, betId})

        if(value.length > 2){
            let newData = data.filter(o => o[findBy].includes(value))

            setDisplayedData(newData)
            setShowMoreData(false)
            setSearchedBy(value)

            if(fetchAdditionalData){
                props[displayedDataConstructor[dataType](displayedDataDictionary).fetchFunction]({auth, betId, userId, orderBy: findBy, searchedBy: value})
                setLoadingData(true)
            }

        }else{

            setDisplayedData(data)
            setShowMoreData(true)
            setSearchedBy(value)

        }
        
        
       
    }, 500)

    const handleDisplayDataOrderChange = ({orderBy, orderDirection})=>{
        setOrderBy(orderBy)
        setOrderDirection(orderDirection)
    }

    const handleRowClick = (type, data)=>{
        //console.log(data)w
        if(type === "active_bet_info"){
            history.push(`/bets/active?betId=${data.key}&orderBy=sportId&groupBy=${data.sportId}&sportId=${data.sportId}`)
        }else if(type === "profile"){
            history.push(`/user/profile?userId=${data.userId}`)
        }else if(type === "history_bet_info"){
            history.push(`/bets/history?betId=${data.key}&userId=${data.userId}&sportId=${data.sportId}`)
        }else if(type === "remove_active_bet"){
            props.setActiveModalWithData("removeActiveBetModal", data)
        }
        
        
    }

    const handlePopperToggle = (event) =>{
        setPopperAnchorEl(event.currentTarget)
        setPopperOpen((prev) => !prev);
    }

    const handleShowMoreData = ()=>{
        setNumberOfDataShown(state => state + maxRowsDisplayed)
    }

    const listBody = []

    return (
        <Box mb={1} height="100%" position="relative" style={{...elementStyle, ...style}}>  
            {/* INFO */}
            <Box display="flex" justifyContent="space-between" mb={1}  height={TABLE_HEADER_HEIGHT}>
                <CustomButton 
                    label={displayedDataConstructor[dataType](displayedDataDictionary).title} 
                    height={TABLE_HEADER_HEIGHT}
                    type="title"
                />
                <Box display="flex" alignItems="flex-end">
                    <Popper open={popperOpen} anchorEl={popperAnchorEl} placement="bottom-end" transition>
                        {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={350}>
                                <Box display="flex" flexDirection="column" bgcolor="background.primary" mt={1}  borderRadius={2} boxShadow={2}>
                                    {     
                                        displayedDataConstructor[dataType](displayedDataDictionary).actions.map(action =>
                                            !action.showWhenUserType ?
                                                <CustomButton key={action.label} onClick={()=>handleActionClick(action)} type="large" label={action.label}/>:
                                            auth.userInfo.type === action.showWhenUserType &&
                                                <CustomButton key={action.label} onClick={()=>handleActionClick(action)} type="large" label={action.label} />
                                        )
                                    }
                                </Box>
                            </Fade>
                        )}
                    </Popper>
                    {
                        breakPoint === "xs" ? 
                            displayedDataConstructor[dataType](displayedDataDictionary).actions.length > 0 && 
                                <CustomButton onClick={handlePopperToggle} type="titleIconButton" icon="moreVert" />
                              
                            :displayedDataConstructor[dataType](displayedDataDictionary).actions.map((action, i) =>
                                !action.showWhenUserType ?
                                    <Box key={i} pl={1}>
                                        <CustomButton key={i} onClick={()=>handleActionClick(action)}  type="titleButton" label={action.label}/>
                                    </Box>:
                                    auth.userInfo.type === action.showWhenUserType &&
                                        <Box key={i} pl={1}>
                                            <CustomButton key={i} onClick={()=>handleActionClick(action)}  type="titleButton" label={action.label}/>
                                        </Box>
                                     
                            )
                    }
                    {
                        enableSearch && 
                            <Box  pl={1}>
                                <CustomSearchInput orderBy={searchBy || orderBy} placeholder={displayedDataDictionary[searchBy || orderBy]} handleSearchInput={handleSearchInput}/>
                            </Box>
                    }
                    
                </Box>
            </Box>
            {/* HEADER */}
            
            <Box 
                position="relative"
                display="flex" 
                alignItems="center" 
                width="100%"  
                bgcolor={theme.palette.background.main} 
                height={tableInfoRowHeight} 
                className={classes.header} 
                borderRadius={4} 
                borderBottom={"1px solid black"}
                marginBottom={1}
                overflow="hidden"
            >
                <ListHeader dataType={dataType} orderBy={orderBy} orderDirection={orderDirection} handleOrderChange={handleDisplayDataOrderChange} userGeoLocation={userGeoLocation} enableSort={enableSort} />
                {loadingData && <Preloader type="linear" rootStyle={{position: "absolute", bottom: 1, left: 0}}/>}
            </Box>
        
            {/* BODY */}         
            <Box position="relative" minHeight={tableContainerHeight} height="100%">
                
                <Box  ref={listBodyRef} overflow="auto" minHeight={tableContainerHeight} borderRadius={4}>
                    { displayedData && displayedData.length > 0 &&
                        displayedData.filter((o, i)=> i < numberOfDataShown).map((rowData, i) => {    
                            return (
                                <Fade key={rowData.key || i} in={displayedData && displayedData.length > 0} timeout={i < 10 ? i*200 : 2000} >
                                    <Box 
                                        color="white"
                                        fontSize={15} 
                                        className={classNames(classes.dark)}
                                        overflow="hidden"
                                        bgcolor="background.transparent"
                                        borderRadius={4}
                                       
                                        marginBottom="2px"
                                    >    
                                        <RowContainer 
                                            data={rowData} 
                                            dataMap={displayedDataConstructor[dataType](displayedDataDictionary).dataMap} 
                                            rowPosition={i} 
                                            lastRow={displayedData.length} 
                                            rowHeight={rowHeight}
                                            onRowClick={handleRowClick}
                                            userGeoLocation={userGeoLocation}
                                        /> 
                                    </Box> 
                                </Fade>
                                
                            )
                        })
                    }
                    {
                        displayedData && displayedData.length === 0 &&
                            <Box p={3} width="100%" height="100%"  bgcolor="background.transparent" display="flex" alignItems="center" justifyContent="center" color="white" borderRadius={4}>
                                <Typography variant="h2">{dictionary.noAvailableData}</Typography>
                            </Box>
                    }
                    {
                        
                        showMoreData && expandMoreData &&
                            <Box 
                                color="white"
                                fontSize={20} 
                                className={classNames(classes.showMoreRow)}
                                overflow="hidden"
                                bgcolor="background.primary"
                                height={rowHeight/1.5}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                onClick={!loadingData ? handleShowMoreData : null}
                                borderRadius={4}
                            >    
                                
                               { loadingData ? 
                                <Preloader size={20} rootStyle={{width: "100%", height: "100%"}}/>:
                                <CustomIcon name="arrow"/>}
                            </Box> 
                    }
                    {
                        props.advertisement &&
                            <Box mt={1} borderRadius={4} overflow="hidden">
                                <AdvertisementElement 
                                    advertiser="melbet"
                                    location="profile"
                                    height="170px"
                                    width="100%"
                                />
                            </Box>
                            
                    }
                </Box>
            </Box>
        </Box>
    )
}

ListBoxElement.defaultProps = {
    variant: "text",
    color: "main",
    elementStyle: {}, // style object of element from database
    style: {}, // style object is pushed to element if there is active animation present
    title: "Zadnje stave",
    dataType: "leaderBoardWeekly",
    elementClasses: [],
};
  
const mapStateToProps = ({auth, brdoparaData, userGeoLocation}) => {
  return {
    auth,
    brdoparaData,
    userGeoLocation
  }
}

export default withRouter(connect(mapStateToProps, actions)(editWrapper(ListBoxElement)))


  