import { analytics } from "../config/firebase"

const DEBUG = false

export default function logger(type, name, options){
    let pathname = window.location && window.location.pathname ? window.location.pathname : ""

    if(process.env.NODE_ENV === 'development'){
        if(type === "exception"){
            
            console.log(`${type}-${name} error: ${options.err.stack}`)
        }else{
            console.log(`${type}-${name}`)
        }
   
    }else{
        
        if(type === "exception"){
            if(DEBUG)console.log(`${type}-${name} error: ${options.err.stack}`)
            
            analytics.logEvent(type, {name, exDescription: `${pathname} | ${name}: ${options.err.message}` , exFatal: false});
        }else{
            if(DEBUG)console.log(`${type}-${name}`)
            analytics.logEvent(type, {name});
        }
    }
} 