import React from 'react';
import * as actions from './../../../../redux/actions'
import { connect } from "react-redux";
import {Formik, Field} from 'formik';
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import editWrapper from './../../../default/wrappers/editWrapper'
import CustomInputComponent from '../../../components/inputs/CustomInputComponent'
import CustomButtonBase from './../../../components/buttons/CustomButtonBase'
import useDictionary from '../../../../hooks/useDictionary';

const useStyles = makeStyles({
    root: {
        backgroundColor: 'red',
        color: props => props.color,
    },

    registerButton:{
        fontSize: 30,
        
        
    }
});


function RegisterUserBoxElement(props) {

    const { submitButtonLabel, edit, userGeoLocation } = props

    const classes = useStyles(props)
    const dictionary = useDictionary({elementId: "register_user_box_element", userGeoLocation})
    
    const signInWithProvider = (provider)=>{
        if(provider === "google") props.authenticateUserWithGoogle()
    }

    return (
        <Box 
            display="flex" 
            flexDirection="column" 
            justifyContent="center" 
            maxWidth={450} 
            bgcolor="background.secondary" 
            boxShadow={5} 
            borderRadius={24}
            overflow="hidden"
        >
            <Box width="100%" bgcolor="background.main" color="white" fontWeight={200} fontSize={50}>
                {dictionary.title}
               
            </Box>
            <Box p={3}>
                <Formik
                    initialValues={{email: "", password: ""}}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                        setSubmitting(true)
                        

                        resetForm()
                    }}
                    validationSchema={null}
                    >
                    {props => {
                        const {
                        isSubmitting,
                        handleSubmit,
                        } = props;
                        
                        return(
                            <Grid container spacing={3} >        
    
                                <Grid item xs={12}>
                                    <Field name="email" label={dictionary.emailInputLabel} component={CustomInputComponent}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Field name="password" label={dictionary.passwordInputLabel} component={CustomInputComponent}/>
                                </Grid>
                                
                                <Grid item xs={12}>
                                    <CustomButtonBase 
                                        onClick={handleSubmit} 
                                        color="secondary" 
                                        variant="contained" 
                                        label={dictionary.registerButtonLabel}
                                        fontWeight={400}
                                        fontSize={24}
                                        width="100%" 
                                        height="65px" 
                                        disableGutter
                                        icon="send"
                                    />
                                   
                                </Grid>                     
                            </Grid>
                        )   
                    }}
                </Formik>
                
                <Box py={3} display="flex" flexDirection="row">
                    <Button 
                        onClick={()=>signInWithProvider("google")} 
                        className={classNames(classes.buttonGoogle, classes.soicalMediaButton)} 
                        fullWidth
                    >
                        <Typography variant="body1" noWrap>
                            Google+
                        </Typography>
                        
                    </Button>

                    <Button 
                        onClick={()=>signInWithProvider("facebook")} 
                        className={classNames(classes.buttonFacebook, classes.soicalMediaButton)} 
                        fullWidth
                    >
                        <Typography variant="body1" noWrap>
                            Facebook
                        </Typography>
                    </Button>
                </Box>
                <Box>
                    <Typography style={{fontSize: 12, textAlign: "center"}}>
                        {dictionary.info}<a href="/" onClick={null}>{dictionary.infoLink}</a>
                    </Typography>
                </Box>      
            </Box>     
            
        </Box>
    )
}
const mapStateToProps = ({ userGeoLocation }) => {
  return {
    userGeoLocation
  }
}

export default connect(mapStateToProps, actions)(editWrapper(RegisterUserBoxElement))
