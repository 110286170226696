
import React from 'react';
import BrdoparaElement from './brdopara/BrdoparaElement'

const  CustomElement = (props)=> {
    const { elementId } = props
    
    if(elementId.split("_").includes("brdopara")){
      return <BrdoparaElement {...props} />
    }else return null

}


export default CustomElement
