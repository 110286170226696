import {
  SET_ACTIVE_SNACKBAR,
  CLOSE_ACTIVE_SNACKBAR,
} from '../../types'

export const setActiveSnackbar = (snackbarId, status, label, actions) => async dispatch =>{

  dispatch({
    type: SET_ACTIVE_SNACKBAR,
    name: snackbarId,
    status: status || "info",
    label: label || "",
    actions: actions || {}
  })
}

export const closeActiveSnackbar = () => async dispatch =>{

  dispatch({
    type: CLOSE_ACTIVE_SNACKBAR,
    payload: []
  })
}
