import React from 'react';
import {withRouter} from 'react-router'
import * as actions from '../../../../redux/actions'
import { connect } from "react-redux";
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';
import CustomInfoLayout from './CustomInfoLayout'

import { ResponsivePie } from '@nivo/pie'
import { ResponsiveLine } from '@nivo/line'
import { FacebookShareButton, FacebookIcon } from 'react-share'
import Helmet from 'react-helmet'
import CustomIcon from '../../../components/icons/CustomIcon'
import CustomButton from './CustomButton'
import editWrapper from '../../../default/wrappers/editWrapper'
import useMediaBreakPoint from '../../../../hooks/useMediaBreakPoint'
import displayDataConstructor from '../displayedDataConstructor'
import CustomDialogLayout from './CustomDialogLayout'
import useDictionary from '../../../../hooks/useDictionary';
import { useCookies } from 'react-cookie';

const useStyles = makeStyles( theme => createStyles({
   
    title:{

    },
    subTitle:{
      color: "white",
   
      borderBottom: "1px solid "+theme.palette.primary.main,
      marginBottom: theme.spacing(1),

      '& p':{
    
        fontSize:"0.95em",
        fontWeight: 400,
      }
  
    },
}))



function SubscribeTipsterModal({userGeoLocation, auth, tipsterId, tipsterName, deviceNotificationStatus, setActiveSnackbar,...props}) {
  
    
    const theme = useTheme()
    const classes = useStyles()
    const dictionary = useDictionary({elementId: "subscribe_tipster_modal", userGeoLocation})
    const [pushNotifications, setPushNotifications] = React.useState(false)
    const [emailNotifications, setEmailNotifications] = React.useState(false)
    const [notificationPermission, setNotificationPermission] = React.useState(deviceNotificationStatus)
    const [cookies, setCookie] = useCookies()


    const handleAddSubscription = ()=>{
      props.addSubscription({auth, tipsterId, pushNotifications, emailNotifications, cookies, setCookie})
    }

    const requestNotificationPermission = async () => {
     
      if(notificationPermission === "default"){
        let permission = await Notification.requestPermission()
        setNotificationPermission(permission)
      }else{
        
        setActiveSnackbar("blockedDeviceNotificationsSnackbar", "error")
        
      }
    }

    return (
      <CustomDialogLayout 
        name="tipsterSubscribeModal" 
        title={dictionary.tipsterSubscribeModalTitle && dictionary.tipsterSubscribeModalTitle(tipsterName)} 
        actions={[
          {label: dictionary.confirmSubscriptionButtonLabel, onClick: handleAddSubscription}
        ]}
      >
        <Box>
          <CustomInfoLayout 
            type="info"
            content={dictionary.tipsterSubscribeModalTitle && dictionary.tipsterSubscribeModalDescription(tipsterName)}  
          />
          
          <Box mt={2} display="flex" flexDirection="column" justifyContent="space-between" width="100%" color={"white"}>
            <Box className={classes.subTitle}>
              <Typography  variant="body2" color="inherit">
                {dictionary.editSubscriptionModalNotifyViaEmailLabel}
              </Typography>
            </Box>
            <Box>
              <Switch
                checked={emailNotifications}
                onChange={(e)=> setEmailNotifications(state => !state)}
                color="primary"
                name="emailNotifications"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Box>
          </Box>
          {
            notificationPermission !== "unsupported" ? 
            <Box mt={1} display="flex" flexDirection="column" justifyContent="space-between" width="100%" color={"white"}>
              <Box className={classes.subTitle}>
                <Typography variant="body2" color="inherit">
                  {dictionary.editSubscriptionModalPushNotificationsLabel}
                </Typography>
              </Box>
             
            
              {
                notificationPermission === "granted" ? (
                  <Switch
                    checked={pushNotifications}
                    onChange={(e)=> setPushNotifications(state => !state)}
                    color="primary"
                    name="pushNotifications"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                ):(
                 <Box mt={1}>
                    <CustomButton 
                      type="button" 
                      bgcolor="background.dark" 
                      height={50}
                      onClick={requestNotificationPermission} 
                      label={dictionary.allowNotificationButtonLabel} 
                    />
                  </Box>

                )
              }
            </Box>:
            <CustomInfoLayout 
              type="error"
              content={dictionary.tipsterSubscribeModalDescription}  
            />
          }
         
       
        </Box>
      </CustomDialogLayout>
  )
}


SubscribeTipsterModal.defaultProps = {
    
    variant: "text",
    color: "main",
  
    elementStyle: {}, // style object of element from database
    style: {}, // style object is pushed to element if there is active animation present
    title: "Zadnje stave",
    dataType: "leaderBoardWeekly",

    elementClasses: [],

    
  };
  

const mapStateToProps = ({auth, userGeoLocation, deviceNotificationStatus}) => {
  return {
    auth,
    userGeoLocation,
    deviceNotificationStatus
  }
}

export default withRouter(connect(mapStateToProps, actions)(editWrapper(SubscribeTipsterModal)))
