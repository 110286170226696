
import * as firebase from './../../../../config/firebase'
import logger from  './../../../../helpers/logger'

import {
  FETCH_ALL_SUBSCRIPTIONS
} from './../../types'

export const fetchAllSubscribersOnce = () => async dispatch => {
    try{
      logger('api_call', "fetchAllSubscribersOnce")
      let data = await firebase.subscriptions.orderByKey().limitToFirst(10).once("value")

      dispatch({
        type: FETCH_ALL_SUBSCRIPTIONS,
        payload: data.val()
      })
    }catch(err){
      logger('exception', "fetchAllSubscribersOnce", {err})
    }
    
}

export const addSubscribedUser = (email, type)=> async dispatch => {

  firebase.subscriptions.push().set({
    email,
    type,
    datetime: new Date().getTime()
  }).then(function() {
    logger('api_call', "addSubscribedUser")
  }).catch(function(err) {
    logger('exception', "addSubscribedUser", {err})
  });
}
