import React from 'react';
import * as actions from '../../../../redux/actions'
import classNames from 'classnames'
import { connect } from "react-redux";
import {withRouter} from 'react-router'
import { createStyles, useTheme, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Slide from '@material-ui/core/Slide';
import ButtonBase from '@material-ui/core/ButtonBase';
import TextField from '@material-ui/core/TextField';
import Zoom from '@material-ui/core/Zoom';
import Snackbar  from '@material-ui/core/Snackbar';
import editWrapper from '../../../default/wrappers/editWrapper'
import CustomButton from './CustomButton'
import { Typography } from '@material-ui/core';
import CustomIcon from '../../../components/icons/CustomIcon'
import useDictionary from '../../../../hooks/useDictionary';
import CustomInfoLayout from './CustomInfoLayout';
import { setIn } from 'formik';


const useStyles = makeStyles( theme => createStyles({
    root:{
        display: "flex",
        flexDirection: "column",
        width: "100%",

        maxWidth: 1000,
        height: "100%",
        minHeight: "100vh",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        position: "relative",

        paddingTop: 83,
        padding: theme.spacing(1)
    },

    container:{
        display:"flex", 
        flexDirection:"column",
        width:"100%",
        background: theme.palette.background.transparent,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(2),

        color: theme.palette.text.secondary
    },
   

    subTitle:{
        background: theme.palette.primary.main,
        borderRadius: theme.shape.borderRadius,

        width:"fit-content",

        padding: theme.spacing(1),
        paddingTop: "4px",
        paddingBottom: "4px",

        '& h2':{
            fontSize: "1.1em",
            color: theme.palette.text.primary
        }
    },

    policyContainer:{
        '& p':{
            textAlign: "left"
        },

        '& ol':{
            textAlign: "left"
        },

        '& li':{
            textAlign: "left"
        },
    },

    cookiesContainer:{
        '& p':{
            textAlign: "left"
        },

        '& ol':{
            textAlign: "left"
        },

        '& li':{
            textAlign: "left"
        },
    }

}))


function InfoElement({
    userGeoLocation,
    location
}){

    const theme = useTheme()
    const classes = useStyles()

    const dictionary = useDictionary({elementId: "info_element", userGeoLocation})
   
    const [type, setType] = React.useState(null)

    React.useEffect(()=>{
        let query = new URLSearchParams(location.search)
        if(query.get("type"))setType(query.get("type"))  
        else setType("about")

        window.scrollTo(0,0); 
    },[])


    if(type === "privacyPolicy"){
        return (
            <Slide direction="up" in="true" timeout={200}>
                <Box className={classes.root}>
                    <Box mb={1}>
                        <CustomButton 
                            type="title"
                            label={dictionary.privacyPolicyTitle}
                        />
                    </Box>

                    <Box className={classes.policyContainer} px={3} width="100%" bgcolor="background.transparent" borderRadius={4} color="white">
                        <p class="privacy-policy-p">
                            This Privacy Policy describes the policies of
                            
                            Wolf consulting d.o.o.,
                            Ul.Miše Stupara br. 4,
                            Banja Luka
                        
                            on the collection,
                            use and disclosure of your information that we collect
                            when you use our website ( https://brdopara.com ).
                            (the “Service”). By accessing or using
                            the
                            Service, you are consenting to the collection, use and
                            disclosure of your information in accordance with this
                            Privacy Policy. If you do not consent to the same,
                            please do not access or use the Service.
                        </p>

                        <p class="privacy-policy-p">
                            We may modify this Privacy Policy at any time without
                            any prior notice to you and will post the revised
                            Privacy Policy on the Service. The revised Policy will
                            be effective 180 days from when the
                            revised Policy is posted in the Service and your
                            continued access or use of the Service after such time
                            will constitute your acceptance of the revised Privacy
                            Policy. We therefore recommend that you periodically
                            review this page.
                        </p>

                        <ol class="privacy-policy-ol">
                            <li>
                                <h2 class="privacy-policy-h2">
                                    Information We Collect:
                                </h2>

                                <p class="privacy-policy-p">
                                    We will collect and process the following
                                    personal information about you:
                                </p>
                                <ol class="privacy-policy-ol">

                                    <li>Name</li>
                        
                                    <li>Email</li>
                                                        
                                </ol>
                            </li>

                            <li>
                                <h2 class="privacy-policy-h2">
                                    How We Collect Your Information:
                                </h2>
                                <p class="privacy-policy-p">
                                    We collect/receive information about you in the
                                    following manner:
                                </p>
                                <ol class="privacy-policy-ol">

                                    <li>When a user fills up the registration form or otherwise submits personal information</li>
                                    <li>Interacts with the website</li>
                                    
                                    <li>From public sources</li>
                                    
                                </ol>
                            </li>
                            <li>
                                <h2 class="privacy-policy-h2">
                                    How We Use Your Information:
                                </h2>
                                <p class="privacy-policy-p">
                                    We will use the information that we collect
                                    about you for the following purposes:
                                </p>
                                <ol class="privacy-policy-ol">

                                    <li>Creating user account</li>

                                </ol>
                                <p class="privacy-policy-p">
                                    If we want to use your information for any other
                                    purpose, we will ask you for consent and will
                                    use your information only on receiving your
                                    consent and then, only for the purpose(s) for
                                    which grant consent unless we are required to do
                                    otherwise by law.
                                </p>
                            </li>
                            
                            
                            <li>
                                <h2 class="privacy-policy-h2">
                                    How We Share Your Information:
                                </h2>


                                        <p class="privacy-policy-p">
                                    We will not transfer your personal information
                                    to any third party without seeking your consent,
                                    except in limited circumstances as described
                                    below:
                                </p>
                                <ol class="privacy-policy-ol">
                                                <li>Ad service</li>
                                    
                                    
                                    
                                    
                                                <li>Analytics</li>
                                    
                                    
                                            </ol>
                                
                                <p class="privacy-policy-p">
                                    We require such third party’s to use the
                                    personal information we transfer to them only
                                    for the purpose for which it was transferred and
                                    not to retain it for longer than is required for
                                    fulfilling the said purpose.
                                </p>
                                <p class="privacy-policy-p">
                                    We may also disclose your personal information
                                    for the following: (1) to comply with applicable
                                    law, regulation, court order or other legal
                                    process; (2) to enforce your agreements with us,
                                    including this Privacy Policy; or (3) to respond
                                    to claims that your use of the Service violates
                                    any third-party rights. If the Service or our
                                    company is merged or acquired with another
                                    company, your information will be one of the
                                    assets that is transferred to the new owner.
                                </p>
                            </li>
                            

                            <li>
                                <h2 class="privacy-policy-h2">
                                    Retention Of Your Information:
                                </h2>
                                <p class="privacy-policy-p">
                                    We will retain your personal information with us
                                    for
                                                90 days to 2 years after user accounts remain idle
                                                or for as
                                    long as we need it to fulfill the purposes for
                                    which it was collected as detailed in this
                                    Privacy Policy. We may need to retain certain
                                    information for longer periods such as
                                    record-keeping / reporting in accordance with
                                    applicable law or for other legitimate reasons
                                    like enforcement of legal rights, fraud
                                    prevention, etc. Residual anonymous information
                                    and aggregate information, neither of which
                                    identifies you (directly or indirectly), may be
                                    stored indefinitely.
                                </p>
                            </li>
                            
                            <li>
                                <h2 class="privacy-policy-h2">
                                    Your Rights:
                                </h2>
                                <p class="privacy-policy-p">
                                    Depending on the law that applies, you may have
                                    a right to access and rectify or erase your
                                    personal data or receive a copy of your personal
                                    data, restrict or object to the active
                                    processing of your data, ask us to share (port)
                                    your personal information to another entity,
                                    withdraw any consent you provided to us to
                                    process your data, a right to lodge a complaint
                                    with a statutory authority and such other rights
                                    as may be relevant under applicable laws. To
                                    exercise these rights, you can write to us at
                                    info@brdopara.com.
                                    We will respond to your
                                    request in accordance with applicable law.
                                </p>

                                
                                <p class="privacy-policy-p">
                                    Do note that if you do not allow us to collect
                                    or process the required personal information or
                                    withdraw the consent to process the same for the
                                    required purposes, you may not be able to access
                                    or use the services for which your information
                                    was sought.
                                </p>
                            </li>
                                <li>
                                <h2 class="privacy-policy-h2">
                                    Cookies Etc.
                                </h2>
                                <p class="privacy-policy-p">

                                    
                                    To learn more about how we use these
                                    and your choices in relation to these tracking
                                    technologies, please refer to our
                                    <a href="https://brdopara.com/info?type=cookies">Cookie Policy.</a>
                                </p>
                            </li>
                            
                            <li>
                                <h2 class="privacy-policy-h2">
                                    Security:
                                </h2>
                                <p class="privacy-policy-p">
                                    The security of your information is important to
                                    us and we will use reasonable security measures
                                    to prevent the loss, misuse or unauthorized
                                    alteration of your information under our
                                    control. However, given the inherent risks, we
                                    cannot guarantee absolute security and
                                    consequently, we cannot ensure or warrant the
                                    security of any information you transmit to us
                                    and you do so at your own risk.
                                </p>
                            </li>

                                <li>
                                <h2 class="privacy-policy-h2">
                                    Third Party Links & Use Of Your Information:
                                </h2>
                                <p class="privacy-policy-p">
                                    Our Service may contain links to other websites
                                    that are not operated by us. This Privacy Policy
                                    does not address the privacy policy and other
                                    practices of any third parties, including any
                                    third party operating any website or service
                                    that may be accessible via a link on the
                                    Service. We strongly advise you to review the
                                    privacy policy of every site you visit. We have
                                    no control over and assume no responsibility for
                                    the content, privacy policies or practices of
                                    any third party sites or services.
                                </p>
                            </li>
                            
                            <li>
                                <h2 class="privacy-policy-h2">
                                    Grievance / Data Protection Officer:
                                </h2>
                                <p class="privacy-policy-p">
                                    If you have any queries or concerns about the
                                    processing of your information that is available
                                    with us, you may email our Grievance Officer at
                                    Wolf consulting d.o.o.,
                                    Ul.Miše Stupara br. 4,
                                    email: info@brdopara.com.
                                    We will address your concerns in accordance with applicable law.
                                </p>
                            </li>
                        </ol>
                        <br/><span>Last Updated On 09-Jun-2021</span>
                        <br/><span>Effective Date 09-Jun-2021</span>
                    </Box>
                </Box>
            </Slide>
           
        )
       
    }else if(type === "about"){

        return(
            <Slide direction="up" in="true" timeout={200}>
                <Box className={classes.root}>
                
                    <Box mb={1}>
                        <CustomButton 
                            type="title"
                            label={dictionary.aboutTitle}
                        />
                    </Box>

                    <Box className={classes.container}>
                        <Box className={classes.subTitle}>
                            <Typography variant="h2">{dictionary.companyNameSubTitle}</Typography>
                        </Box>
                        <Box my={2} display="flex">
                            <Typography variant="body1">Wolf consulting d.o.o.</Typography>    
                        </Box>

                        <Box className={classes.subTitle}>
                            <Typography variant="h2">{dictionary.headquartersSubTitle}</Typography>
                        </Box>
                        <Box my={2} display="flex">
                            <Typography variant="body1" align="left">Ul.Miše Stupara br. 4, Banja Luka <br/> <b>Bosna in Hercegovina</b></Typography>    
                        </Box>
                        <Box className={classes.subTitle}>
                            <Typography variant="h2">{dictionary.registrationNumberSubTitle}</Typography>
                        </Box>
                        <Box my={2} display="flex">
                            <Typography variant="body1">11205208</Typography>    
                        </Box>
                    </Box>
                </Box>
            </Slide>
    )}else if(type === "cookies"){

        return(
            <Slide direction="up" in="true" timeout={200}>
                <Box className={classes.root}>
                
                    <Box mb={1}>
                        <CustomButton 
                            type="title"
                            label={dictionary.cookiesTitle}
                        />
                    </Box>

                    <Box px={2} className={classes.cookiesContainer} bgcolor="background.transparent" borderRadius={4} color="white" >
                        <h1>Cookies In Use on This Site</h1>

                        <h2>Cookies and how they Benefit You</h2>

                        <p>Our website uses cookies, as almost all websites do, to help provide you with the best experience we can.
                        Cookies are small text files that are placed on your computer or mobile phone when you browse websites</p>
                        <p>Our cookies help us:</p>

                        <ul>
                        <li>Make our website work as you'd expect</li>
                        <li>Save you having to login every time you visit the site</li><li>Remember your settings during and between visits</li><li>Improve the speed/security of the site</li>
                        <li>Personalise our site to you to help you get what you need faster</li><li>Continuously improve our website for you</li><li>Make our marketing more efficient (ultimately helping us to offer the service we do at the price we do)</li></ul>

                        <p>We do not use cookies to:</p>

                        <ul>
                        <li>Collect any personally identifiable information (without your express permission)</li><li>Collect any sensitive information (without your express permission)</li><li>Pass personally identifiable data to third parties</li><li>Pay sales commissions</li></ul>

                        <p>You can learn more about all the cookies we use below</p>

                        <h2>Granting us permission to use cookies</h2>

                        <p>If the settings on your software that you are using to view this website (your browser) are adjusted to accept cookies we take this, and your continued use of our website, to mean that you are fine with this.  Should you wish to remove or not use cookies from our site you can learn how to do this below, however doing so will likely mean that our site will not work as you would expect.</p>

                        <h2>More about our Cookies</h2>

                        <h3>Website Function Cookies</h3>

                        <h3>Our own cookies</h3>

                        <p>We use cookies to make our website work including:</p>

                        <ul>
                        <li>Determining if you are logged in or not</li>
                        <li>Remembering if you have accepted our terms and conditions</li>
                        <li>Tailoring content to your needs</li>
                        <li>Remembering if we have already asked you certain questions (e.g. you declined to use our app or take our survey)</li>
                        </ul>

                        <p>There is no way to prevent these cookies being set other than to not use our site.</p>




                        <h3>Anonymous Visitor Statistics Cookies</h3>

                        <p>We use cookies to compile visitor statistics such as how many people have visited our website, what type of technology they are using (e.g. Mac or Windows which helps to identify when our site isn't working as it should for particular technologies), how long they spend on the site, what page they look at etc.  This helps us to continuously improve our website. These so called “analytics” programs also tell us if , on an anonymous basis, how people reached this site (e.g. from a search engine) and whether they have been here before helping us to put more money into developing our services for you instead of marketing spend. </p>

                        <p>We use:</p>

                        <ul>
                        </ul>

                        <h3>Advertising Cookies</h3>

                        <p>Cookies are widely used in online advertising. Neither us, advertisers or our advertising partners can gain personally identifiable information from these cookies. </p>
                        <p>You can learn more about online advertising at http://www.youronlinechoices.com .  You can opt-out of almost all advertising cookies at http://www.youronlinechoices.com/uk/your-ad-choices although we would prefer that you didn’t as ultimately adverts help keep much of the internet free. It is also worth noting that opting out of advertising cookies will not mean you won't see adverts, just simply that they won't be tailored to you any longer.</p>

                        <p>We use:</p>
                        <ul>
                        </ul>


                        <h3>Remarketing Cookies</h3>

                        <p>You may notice that sometimes after visiting a site you see increased numbers of adverts from the site you visited.  This is because advertisers, including ourselves pay for these adverts.  The technology to do this is made possible by cookies and as such we may place a so called “remarketing cookie” during your visit.  We use these adverts to offer special offers etc to encourage you to come back to our site.  Don't worry we are unable to proactively reach out to you as the whole process is entirely anonymised.  You can opt out of these cookies at anytime as explained above.</p>

                        <ul>
                        </ul>


                        <h3>Turning Cookies Off</h3>

                        <p>You can usually switch cookies off by adjusting your browser settings to stop it from accepting cookies (Learn how <a href=" http://www.attacat.co.uk/resources/cookies/how-to-ban">here</a>).  Doing so however will likely limit the functionality of our's and a large proportion of the world's websites as cookies are a standard part of most modern websites</p>
                        <p>It may be that you concerns around cookies relate to so called "spyware".  Rather than switching off cookies in your browser you may find that anti-spyware software achieves the same objective by automatically deleting cookies considered to be invasive.  Learn more about <a href="http://www.attacat.co.uk/resources/cookies/how-to-control-your-online-privacy">managing cookies with antispyware software</a>.</p>


                    </Box>
                </Box>
            </Slide>
    )}else if(type === "userTerms"){

        return(
            <Slide direction="up" in="true" timeout={200}>
                <Box className={classes.root}>
                
                    <Box mb={1}>
                        <CustomButton 
                            type="title"
                            label={dictionary.userTermsTitle}
                        />
                    </Box>

                    <Box px={2} py={2} className={classes.cookiesContainer} bgcolor="background.transparent" borderRadius={4} color="white" >
                        <ol>
                            <li>Ovi uvjeti utvrđuju prava i obaveze Wolf consulting d.o.o. (u daljnjem tekstu: davatelj) i njegovi kupci (u daljnjem tekstu: korisnici) na web stranici www.brdopara.com.&nbsp;</li>
                            <br/>
                            <li>Kori&scaron;ćenjem ili pristupanjem web portalu brdopara.com prihvatate kori&scaron;ćenje istog.</li>
                            <br/>
                            <li>Kori&scaron;tenje ili čitanje sadržaja koji je davatelj objavio na web mjestu ili na njegovim podstranicama dozvoljeno je samo poslovno sposobnim fizičkim osobama i koja su punoljetna.</li>
                            <br/>
                            <li>Davatelj poziva korisnike da pročitaju i prouče uslove. Pristupanjem bilo kojoj web lokaciji dobavljača, korisnici potvrđuju i slažu se da su upoznati s ovim uvjetima kori&scaron;tenja i da su njima vezani.</li>
                            <br/>
                            <li>Davatelj će se truditi da web stranica radi bez zastoja, ali nije odgovoran za bilo kakve nedostatke, prekinuća itd. i nije ih dužan obja&scaron;njavati korisnicima.</li>
                            <br/>
                            <li>Davatelj nije odgovoran za &scaron;tetu ili na bilo koji drugi način odgovoran za nedostatke na web mjestu ili njegovim podstranicama.</li>
                            <br/>
                            <li>Davatelj ne garantira i nije odgovoran za uspjeh, predviđanja i slićno, koja su dobavljać ili korisnici objavili na web lokaciji.</li>
                            <br/>
                            <li>Davatelj može u bilo koje vrijeme zaustavi objavljivanje analiza i prognoza.</li>
                            <br/>
                            <li>Korisnik može imati samo 1 korisnički račun na web lokaciji.</li>
                            <br/>
                            <li>Davatelj pruža svojim korisnicima uvid u analizu i predviđanje rezultata sa sportskih takmičenja, koja takođe mogu biti plaćena (u daljem tekstu: plaćeni sadržaj). Pristup plaćenom sadržaju moguć je tek nakon &scaron;to davatelj korisniku potvrdi korisničko ime (UI) i lozinku (G) za pristup plaćenom sadržaju. Davatelj potvrđuje UI i G korisniku nakon plaćanja iznosa objavljenog na web mjestu davatelja. Davatelj ne potvrđuje UI i G fizičkim licima koja nisu punoljetna odnosno imaju manje od 18 godina i koja nisu poslovno sposobna, odnosno za koju davatelj utvrdi da se joj ne može potvrditi pristup.</li>
                            <br/>
                            <li>Da bi korisnik dobio UI i G dužan je navesti istinite i tačne podatke, koje davatelj zahtijeva prilikom pribavljanja UI i G. Polja označena sa &quot;*&quot; su obavezna. Davatelj će rukovati podacima koji se odnose na korisnike u skladu s propisima koji su na snazi ili su primjenjivi u Republici Bosni i Hercegovini.</li>
                            <br/>
                            <li>Korisnik mora čuvati UI i G na sigurnom i ni pod kojim okolnostima ga ne smije prosljeđivati trećoj strani. Davatelj upozorava korisnike da se uz potvrđeni UI i G plaćenom sadržaju davatelja može pristupiti sa IP adrese računara koja je kori&scaron;tena prilikom potvrde UI i G. U slučaju pristupa s druge IP adrese računara, davatelj može uskratiti korisniku pristup plaćenom sadržaju. U slučaju da je korisniku uskraćen pristup plaćenim sadržajima, davatelj nije dužan vratiti plaćeni iznos niti bilu kakvu od&scaron;tetu.</li>
                            <br/>
                            <li>Davatelj upozorava korisnike da plaćeni sadržaj ne znači bilo kakvu obaveznost za davatelja naprimjer za rezultat utakmice ili slićno.&nbsp;</li>
                            <br/>
                            <li>Davatelj upućuje korisnike da niti predviđenja niti rezultate sa sportskih takmičenja ne garantuje.</li>
                            <br/>
                            <li>Davatelj će statistički obavje&scaron;tavati korisnike u određenim intervalima o uspjehu svojih predviđanja rezultata sa sportskih takmičenja.</li>
                            <br/>
                            <li>U slučaju sportskog događaja koji je započeo i ne odigra se u roku od 72 sata, predviđanje se otkazuje. To je posebno tačno na teniskim priredbama gdje jedan od duela predaje duel. Bez obzira koji igrač napreduje, ovaj dvoboj se u svakom slučaju otkazuje. Isto vrijedi i za ko&scaron;arka&scaron;ka i fudbalska predviđanja kada se dogodi nered i prekine dvoboj.</li>
                            <br/>
                            <li>Davatelj ima pravo ispraviti podatke koje korisnici web mjesta daju u svojim publikacijama sportskih događaja.</li>
                            <br/>
                            <li>Davatelju upućuje korisnike da su analize i prognoze za&scaron;tićene autorskim pravima. U slučaju bilo kakve zloupotrebe, kao &scaron;to je kopiranjem, objavljivanjem u bilo kojem obliku, distribucijom trećim stranama, davatelj će poduzeti odgovarajuće mjere protiv prekr&scaron;itelja.&nbsp;</li>
                            <br/>
                            <li>Web stranica davatelja može sadržavati linkove do stranica u vlasni&scaron;tvu trećih strana. Davatelj nema kontrolu nad tima web lokacijama i nema utjecaja. Davatelj savjetuje korisnicima da pročitaju uvjete kori&scaron;tenja na svakoj od tih stranica.</li>
                            <br/>
                            <li>U svim slučajevima kr&scaron;enja uslova ili nano&scaron;enja &scaron;tete davatelju ili trećemu, davatelj će pokrenuti odgovarajući pravni postupak. U svakom slučaju, sud Bosne i Hercegovine, koji primjenjuje bosanskohercegovačko pravo, nadležan je za rje&scaron;avanje sporova.</li>
                            <br/>
                            <li>Ovi se uvjeti primjenjuju i upotrebljavaju od 8.6.2021.god do promjene. U slučaju promjena ili dopuna, davatelj će zahtijevati od korisnika da potvrde svoje slaganje s uvjetima.</li>  
                        </ol>
                    </Box>
                </Box>
            </Slide>
    )}else return null
}


const mapStateToProps = ({userGeoLocation}) => {
    return {
        userGeoLocation,
    }
}

export default withRouter(connect(mapStateToProps, actions)(InfoElement))


/*
<Box>
    
    */