import { combineReducers } from "redux";
import pagesReducer from './api/webpage/pagesReducer';
import modalStatusReducer from './component/modalStatusReducer';
import snackbarStatusReducer from './component/snackbarStatusReducer';
import drawerStatusReducer from './component/drawerStatusReducer';

import selectedPageReducer from './session/selectedPageReducer'
import elementsReducer from './api/webpage/elementsReducer'
import authReducer from './session/authReducer'
import pagesInfoReducer from './api/webpage/pagesInfoReducer'

import usersReducer from './api/webpage/usersReducer'
import activeLanguageReducer from './session/activeLanguageReducer'
import dictionaryReducer from './api/webpage/dictionaryReducer'
import activeDocumentPageReducer from './session/activeDocumentPageReducer'
import globalElementsReducer from './api/webpage/globalElementsReducer'

import globalElementsLoadedReducer from './session/globalElementsLoadedReducer'
import deviceInfoReducer from "./session/webpage/deviceInfoReducer";

import userGeoLocationReducer from "./session/webpage/userGeoLocationReducer";

import userVerifiedReducer from "./session/webpage/userVerifiedReducer";
import deviceNotificationReducer from "./api/webpage/deviceNotificationReducer";

//// BRDOPARA REDUCERS 
import todaysMatchesReducer from './api/brdopara/todaysMatchesReducer'
import leaderBoardsReducer from './api/brdopara/leaderBoardsReducer'
import activeBetsReducer from './api/brdopara/activeBetsReducer'
import userActiveBetsReducer from './api/brdopara/userActiveBetsReducer'
import activeBetsStatisticReducer from './api/brdopara/activeBetsStatisticReducer'
import subscribedBetsReducer from './api/brdopara/subscribedBetsReducer'
import usersStatisticWeeklyReducer from './api/brdopara/usersStatisticWeeklyReducer'
import usersStatisticMonthlyReducer from './api/brdopara/usersStatisticMonthlyReducer'
import usersStatisticOverallReducer from './api/brdopara/usersStatisticOverallReducer'
import userHistoryBetsReducer from './api/brdopara/userHistoryBetsReducer'
import userFavoriteBetsReducer from './api/brdopara/userFavoriteBetsReducer'
import historyBetsReducer from './api/brdopara/historyBetsReducer'

import trophiesReducer from "./api/brdopara/trophiesReducer";
import trophiesInfoReducer from "./api/brdopara/trophiesInfoReducer";
import userInactiveBetsReducer from './api/brdopara/userInactiveBetsReducer'


const brdoparaDataReducer = combineReducers({
    leaderBoards: leaderBoardsReducer,
    usersStatisticWeekly: usersStatisticWeeklyReducer,
    usersStatisticMonthly: usersStatisticMonthlyReducer,
    usersStatisticOverall: usersStatisticOverallReducer,
    activeBets: activeBetsReducer,
    subscribedBets: subscribedBetsReducer,
    userHistoryBets: userHistoryBetsReducer,
    historyBets: historyBetsReducer,
    activeBetsStatistic: activeBetsStatisticReducer,
    userActiveBets: userActiveBetsReducer,
    userFavoriteBets: userFavoriteBetsReducer,
    userInactiveBets: userInactiveBetsReducer
})

export default combineReducers({
  webpages: pagesReducer,
  webpagesInfo: pagesInfoReducer,
  modalStatus: modalStatusReducer,
  snackbarStatus: snackbarStatusReducer,
  selectedPage: selectedPageReducer,
 
  drawerStatus: drawerStatusReducer,
  auth: authReducer,
  userVerified: userVerifiedReducer,
  elements: elementsReducer,
  
  users: usersReducer,
  activeLanguage: activeLanguageReducer,
  dictionary: dictionaryReducer,
  activeDocumentPage: activeDocumentPageReducer,
  globalElements: globalElementsReducer,
  
  globalElementsLoaded: globalElementsLoadedReducer,
 
  todaysMatches: todaysMatchesReducer,

  userGeoLocation: userGeoLocationReducer,
  brdoparaData: brdoparaDataReducer,

  trophies: trophiesReducer,
  trophiesInfo: trophiesInfoReducer,
  deviceInfo: deviceInfoReducer,
  deviceNotificationStatus: deviceNotificationReducer
});
