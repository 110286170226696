import {

  ADD_DICTIONARY_LANGUAGE,
  FETCH_DICTIONARY,
  UPDATE_ELEMENT_LANGUAGE,
  DELETE_DICTIONARY_LANGUAGE,
  FETCH_DICTIONARY_LANGUAGES,
  FETCH_DICTIONARY_LANGUGAE_DATA
} from './../../types'

import logger from  './../../../../helpers/logger'

import * as firebase from './../../../../config/firebase'

const saveIcon = async (file) =>{

  let uploadTask = firebase.imageStorage.child("icons").put(file)

  let promise = new Promise((resolve, reject) => {
    uploadTask.on('state_changed', // or
    function(snapshot) {
     // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
     var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
     console.log('Upload is ' + progress + '% done');
     switch (snapshot.state) {
       case 'paused': // or 'paused'
         console.log('Upload is paused');
         break;
       case 'running': // or 'running'
         console.log('Upload is running');
         break;
     }
    }, function(error) {
        reject(error);
    },function(){
      let downloadURL = uploadTask.snapshot.ref.getDownloadURL()
      console.log(downloadURL)
      resolve(downloadURL);
    })
  })

  let downloadURL = await promise

  return downloadURL
}


export const exportDictionaryLanguageData = (language) => async (dispatch, getState) => {
  let dictionaryData = getState().dictionary.data[language]

  //combine global elements with page elements
  let allPageElements = {}
  Object.entries(dictionaryData.pages).forEach(([key, obj])=>{
    allPageElements = {...allPageElements, ...dictionaryData.pages[key].elements}
  })

  let elements = {...dictionaryData.global.elements, ...allPageElements}

  return elements
}

export const fetchDictionary = (uid) => async dispatch => {
 
  try{
    logger('api_call', "fetchDictionary")
    let result = await firebase.dictionaryRef.once('value')

    dispatch({
      type: FETCH_DICTIONARY,
      payload: result.val()
    })
  }catch(err){
    logger('exception', "fetchDictionary", {err})
  }
}


export const fetchDictionaryLanguages = () => async dispatch => {
 
  try{
    logger('api_call', "fetchDictionaryLanguages")
    let result = await firebase.dictionaryRef.child("languages").once('value')

    dispatch({
      type: FETCH_DICTIONARY_LANGUAGES,
      payload: result.val()
    })
  }catch(err){
    logger('exception', "fetchDictionaryLanguages", {err})
  }
}

export const fetchDictionaryLanguageData = (languageId) => async dispatch => {
  
  try{
    logger('api_call', "fetchDictionaryLanguageData")
    let result = await firebase.dictionaryRef.child("data").child(languageId).once('value')

    dispatch({
      type: FETCH_DICTIONARY_LANGUGAE_DATA,
      languageId,
      payload: result.val()
    })
  }catch(err){
    logger('exception', "fetchDictionaryLanguageData", {err})
  }
}

export const addDictionaryLanguage = (id, label, icon) => async dispatch => {


  try{
    logger('api_call', "addDictionaryLanguage")
    let iconUrl = await saveIcon(icon)

    await firebase.dictionaryRef.child("languages").update({
      [`${id}/label/`]: label,
      [`${id}/icon/`]: iconUrl,
    })

    dispatch({
      type: ADD_DICTIONARY_LANGUAGE,
      id,
      label,
    })
  }catch(err){
    logger('exception', "addDictionaryLanguage", {err})
  }


}

export const deleteDictionaryLanguage = (id) => async dispatch => {
  
  try{
    logger('api_call', "deleteDictionaryLanguage")
    await firebase.dictionaryRef.child("languages").update({
      [id]: null
    })

    dispatch({
      type: DELETE_DICTIONARY_LANGUAGE,
      id,

    })
  }catch(err){
    logger('exception', "deleteDictionaryLanguage", {err})
  }


}


export const updateElementLanguage = (values, language, elementId, pageId, globalElement) => async dispatch => {
  
  try{
    logger('api_call', "updateElementLanguage")
    if(globalElement){
      await firebase.dictionaryRef.child("data").update({
        [`${language}/global/elements/${elementId}`]: values
      })
    }else{
      await firebase.dictionaryRef.child("data").update({
        [`${language}/pages/${pageId}/elements/${elementId}`]: values
      })
    }


    dispatch({
      type: UPDATE_ELEMENT_LANGUAGE,
      language,
      elementId,
      pageId,
      payload: values
    })
  }catch(err){
    logger('exception', "updateElementLanguage", {err})
  }

}
