import React from 'react';

import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import Grow from '@material-ui/core/Grow';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import SettingsIcon from '@material-ui/icons/Settings';
import ConfirmIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Clear';
import CopyIcon from '@material-ui/icons/FileCopy';
import MoveIcon from '@material-ui/icons/ZoomOutMap';
import EditIcon from '@material-ui/icons/Edit';
import LeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import RightIcon from '@material-ui/icons/KeyboardArrowRight';

import LayersIcon from '@material-ui/icons/Layers';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import Tooltip from '@material-ui/core/Tooltip';

function EditWrapperMenu(props){

  const {id, selectedElement, elements, auth, selectedPage, copySelectedElement, canCopyElementToThisElement, addSelectedCustomContainer, canAddCustomContainerToThisElement} = props

  const [menuActive, setMenuActive] = React.useState(false)
  const [menuMove, setMenuMove] = React.useState(false)
  const [menuDeleteConfirm, setMenuDeleteConfirm] = React.useState(false)

  const elementParent = elements[elements[selectedElement] && elements[selectedElement].parent]
  const basicMode = auth && auth.userInfo && auth.userInfo.privileges && auth.userInfo.privileges.basic

  const handleParentSelect = (e)=>{

    e.stopPropagation()

    props.setShowAllElements(true)
    setMenuActive(false)
    props.setSelectedElement("")
  }

  const handleMenu = (e)=>{
    e.stopPropagation()

    if(basicMode) props.setActiveModal("elementModal")
    else setMenuActive(true)

  }

  const handleEdit = (e)=>{
    e.stopPropagation()
    setMenuActive(false)
    props.setActiveModal("elementModal")
  }

  const handleDelete = (e)=>{
    e.stopPropagation()
    if(menuDeleteConfirm){
      setMenuActive(false)
      setMenuDeleteConfirm(false)
      props.deleteSelectedElement(selectedElement)
    }else{
      setMenuDeleteConfirm(true)
    }
  }

  const handleCancelDelete = (e)=>{
    e.stopPropagation()
    setMenuDeleteConfirm(false)
  }

  const handleAddElement = (e)=>{
    e.stopPropagation()
    setMenuActive(false)
    props.setActiveModal("elementAddModal")
  }

  const handleSetActiveCopySelectedElement = (e)=>{
    e.stopPropagation()
    setMenuActive(false)
    props.setDisableElementEvents(true)
    props.setCopySelectedElement(true)
    //props.disableElementsMouseActions()
  }

  const handleCopySelectedElement = (e) => {
    e.stopPropagation()
    props.setDisableElementEvents(false)
    props.setCopySelectedElement(false)
    props.copyElement(selectedElement, selectedPage, selectedPage, selectedPage, id)
  }

  const handleMoveSelectedElement = async (e) => {
    e.stopPropagation()
    props.setDisableElementEvents(false)
    props.setCopySelectedElement(false)

    let newElementId = await props.copyElement(selectedElement, selectedPage, selectedPage, selectedPage, id)
    await props.deleteSelectedElement(selectedElement)
    props.setSelectedElement(newElementId)
  }

  const handleCancelCopySelectedElement = (e)=>{
    e.stopPropagation()
    props.setDisableElementEvents(false)
    props.setCopySelectedElement(false)
  }

  const handleToggleMenuMove = (e)=>{
    e.stopPropagation()
    //if element parent has more than one child, toggle menu move
    if(elementParent.elementChildren && elementParent.elementChildren.length > 1)setMenuMove(!menuMove)

  }

  const handleSwitchElementPositionLeft = (e)=>{
    e.stopPropagation()
    props.switchElementPositionInsideParent(selectedPage, selectedElement,-1)
  }

  const handleSwitchElementPositionRight = (e)=>{
    e.stopPropagation()
    props.switchElementPositionInsideParent(selectedPage, selectedElement, 1)
  }

  const handleCancelAddCustomContainerToThisElement = ()=>{
    props.setAddSelectedCustomContainer(false)
    props.setDisableElementEvents(false)
    props.setActiveModal("selectCustomContainerModal") // goes back to select custom container modal

  }

  const handleAddCustomContainerToThisElement = ()=>{
    let containerRootElementId = props.addSelectedCustomContainerToPageElement(id)
    props.setAddSelectedCustomContainer(false)
    props.setDisableElementEvents(false)
    props.setSelectedElement(containerRootElementId)
  }

  const fabPositions = [
    {top: -46, left: 0},
    {top: -22, left: -40},
    {top: 24, left: -40},
    {top: 46, left: 0},
    {top: 22, left: 40},
    {top: -22, left: 40},
  ]

  const deleteConformationMenu = (
    <React.Fragment>
      <Grow in={menuDeleteConfirm} timeout={1000}>

          <Fab
            size="small"
            color="secondary"
            style={{background: green[300], position:"absolute",  ...fabPositions[1]}}
            onClick={handleDelete}>
            <ConfirmIcon fontSize="inherit"/>
          </Fab>

      </Grow>
      <Grow in={menuDeleteConfirm} timeout={2000}>

          <Fab
            size="small"
            color="secondary"
            style={{background: red[300], position:"absolute",  ...fabPositions[2]}}
            onClick={handleCancelDelete}>
            <CancelIcon fontSize="inherit"/>
          </Fab>

      </Grow>

        <Fab
          color="primary"
          size="small"
          onClick={handleCancelDelete}
        >
          <DeleteIcon  fontSize="inherit"/>
        </Fab>

    </React.Fragment>
  )

  const moveMenu = (
    <React.Fragment>
      <Grow in={menuMove} timeout={1000} mountOnEnter>
        <Tooltip title="Move left" aria-label="left" placement="bottom">
          <Fab
            size="small"
            color="secondary"
            style={{position:"absolute",  ...fabPositions[1]}}
            disabled={elementParent && elementParent.elementChildren[0] === id }
            onClick={handleSwitchElementPositionLeft}
          >
            <LeftIcon fontSize="inherit" />
          </Fab>
        </Tooltip>
      </Grow>
      <Grow in={menuMove} timeout={500} mountOnEnter>
        <Tooltip title="Move right" aria-label="right" placement="bottom">
          <Fab
            size="small"
            color="secondary"
            style={{position:"absolute",  ...fabPositions[2]}}
            onClick={handleSwitchElementPositionRight}
            disabled={elementParent && elementParent.elementChildren[elementParent.elementChildren.length - 1] === id }
          >
            <RightIcon fontSize="inherit"/>
          </Fab>
        </Tooltip>
      </Grow>
      <Tooltip title="Close" aria-label="close" placement="bottom">
        <Fab
          size="small"
          color="primary"
          onClick={handleToggleMenuMove}
        >
          <CancelIcon fontSize="inherit"/>
        </Fab>
      </Tooltip>
    </React.Fragment>
  )

  const mainMenu = (
    <React.Fragment>
      <Grow in={true} timeout={2500}>
        <Tooltip title="Delete" aria-label="delete" placement="bottom">
          <Fab
            size="small"
            color="secondary"
            style={{background: red[300], position:"absolute",  ...fabPositions[0]}}
            onClick={handleDelete}>
            <DeleteIcon fontSize="inherit"/>
          </Fab>
        </Tooltip>
      </Grow>
      <Grow in={true} timeout={2000}>
        <Tooltip title="Add" aria-label="add" placement="bottom">
          <Fab
            size="small"
            color="secondary"
            style={{position:"absolute",  ...fabPositions[1]}}
            onClick={handleAddElement}>
            <AddIcon fontSize="inherit"/>
          </Fab>
        </Tooltip>
      </Grow>
      <Grow in={true} timeout={500}>
        <Tooltip title="Copy & Move" aria-label="copy" placement="bottom">
          <Fab
            size="small"
            color="secondary"
            style={{position:"absolute",  ...fabPositions[2]}}
            onClick={handleSetActiveCopySelectedElement}
          >
            <CopyIcon fontSize="inherit"/>
          </Fab>
        </Tooltip>
      </Grow>
      <Grow in={true} timeout={1000}>
        <Tooltip title="Move inside parent" aria-label="move" placement="bottom">
          <div>
            <Fab
              size="small"
              color="secondary"
              style={{position:"absolute",  ...fabPositions[3]}}
              onClick={handleToggleMenuMove}
              disabled={elementParent && elementParent.elementChildren.length <= 1}
            >
              <MoveIcon fontSize="inherit"/>
            </Fab>
          </div>
        </Tooltip>
      </Grow>
      <Grow in={true} timeout={1500}>
        <Tooltip title="Show layers" aria-label="layers" placement="bottom">
          <Fab
            size="small"
            color="secondary"
            style={{ position:"absolute",  ...fabPositions[4]}}
            onClick={handleParentSelect}
          >
            <LayersIcon fontSize="inherit"/>
          </Fab>
        </Tooltip>
      </Grow>
      <Tooltip title="Edit" aria-label="edit" placement="bottom">
        <Fab size="small" color="primary"  onClick={handleEdit}>
          <EditIcon fontSize="inherit"/>
        </Fab>
      </Tooltip>
    </React.Fragment>
  )

  //// RETURNS /////
  if(copySelectedElement || addSelectedCustomContainer){
    return (
      <Box position="absolute" top={-14} right={-14}  style={{zIndex: 1001}}>

          {
            copySelectedElement ? canCopyElementToThisElement ? (
                <Box position="relative"  display="flex" flexDirection="column" >
                  <Tooltip title="Paste here" aria-label="paste" placement="bottom" >
                    <Fab size="small" color="primary" onClick={handleCopySelectedElement}>
                      <CopyIcon fontSize="inherit"/>
                    </Fab>
                  </Tooltip>
                  <Tooltip title="Move here" aria-label="move" placement="bottom">
                    <Fab size="small" color="secondary" style={{marginTop: 8}} onClick={handleMoveSelectedElement}>
                      <MoveIcon fontSize="inherit"/>
                    </Fab>
                  </Tooltip>
                </Box>
              ) : (
                <Fab size="small" color="primary" onClick={handleCancelCopySelectedElement}>
                  <CancelIcon fontSize="inherit"/>
                </Fab>
              ) : null
          }

          {
            canAddCustomContainerToThisElement ? (
                <Box position="relative"  display="flex" flexDirection="column" >
                  <Tooltip title="Add here" aria-label="paste" placement="bottom" >
                    <Fab size="small" color="primary" onClick={handleAddCustomContainerToThisElement}>
                      <AddIcon fontSize="inherit"/>
                    </Fab>
                  </Tooltip>
                  <Tooltip title="Cancel" aria-label="paste" placement="bottom" >
                    <Fab  size="small" color="secondary" style={{background: red[300], marginTop: 8}} onClick={handleCancelAddCustomContainerToThisElement}>
                      <CancelIcon fontSize="inherit"/>
                    </Fab>
                  </Tooltip>
                </Box>
              ) : null
          }

      </Box>
    )
  }else{
    return (
      <Box position="absolute" top={-14} right={-14}  style={{zIndex: 1001}}>
        {
         menuActive ?
            menuDeleteConfirm ?
              deleteConformationMenu :
              menuMove ?
                moveMenu :
                mainMenu :
                <Fab size="small" color="primary" onClick={handleMenu}><SettingsIcon fontSize="inherit"/></Fab>
        }
      </Box>
    )
  }
}

export default EditWrapperMenu
