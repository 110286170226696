import * as firebase from '../../../../config/firebase'
import logger from  '../../../../helpers/logger'

import {
    FETCH_TROPHIES,
    FETCH_TROPHIES_INFO
} from "../../types";


export const fetchTrophies =  () => async dispatch => {
    
    try{
        logger('api_call', "fetchTrophies")
        const results = await firebase.trophiesRef.once('value')

        dispatch({
            type: FETCH_TROPHIES,
            payload: results.val()
        })

        return true
    }catch(err){
        
        logger('exception', "fetchTrophies", {err})
    }
}

export const fetchTrophiesInfo =  ({language}) => async (dispatch) => {
    
    try{
        logger('api_call', "fetchTrophiesInfo")
        const results = await firebase.trophiesInfoRef.child(language).once('value')
        
        dispatch({
            type: FETCH_TROPHIES_INFO,
            payload: results.val()
        })

        return true
    }catch(err){
        
        logger('exception', "fetchTrophiesInfo", {err})
    }
}
