import React from 'react';
import {withRouter} from 'react-router'
import * as actions from '../../../../redux/actions'
import { connect } from "react-redux";
import classNames from 'classnames'
import { makeStyles, createStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/ButtonBase';
import Fade from '@material-ui/core/Fade';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Popper from '@material-ui/core/Popper';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import CustomIcon from '../../../components/icons/CustomIcon'
import CustomButton from './CustomButton'
import editWrapper from '../../../default/wrappers/editWrapper'
import displayedDataConstructor from '../displayedDataConstructor'
import useMediaBreakPoint from '../../../../hooks/useMediaBreakPoint'
import sportsSettings from './../../../../helpers/sportsSettings'
import { Collapse } from '@material-ui/core';
import useDictionary from '../../../../hooks/useDictionary';
import timestampFormatter from '../../../../helpers/timestampFormatter';
import AdvertisementElement from './AdvertisementElement';

const WINDOW_HEIGHT = window.innerHeight

const TABLE_HEADER_HEIGHT = 60

const MENU_HEIGHT = 56

const useStyles = makeStyles( theme => createStyles({
    
    header:{
        fontFamily: 'Racing Sans One',
    },

   
    footer:{
        fontFamily: 'Racing Sans One',
    },
   
    actionButton:{
      height: 60,
      borderRadius: 60,
      width: `calc(50% - ${theme.spacing(1)}px)`,
      
    },
    actionButtonLabel:{
        fontSize: 24,
        fontWeight: 300
    },

    button:{
        borderRadius: 8,
        color: "white", 
        fontSize: 16,
        fontWeight: 200,
        minHeight: 40,
     
    },
    
    searchButton:{
        borderRadius: 8,
        fontSize: 32,
        background: theme.palette.primary.main,
        paddingTop: theme.spacing(1),
     
    },
    showMoreRow:{
        cursor:" pointer",
        background: theme.palette.background.light,
        borderRadius: theme.shape.borderRadius,
        borderBottom: "1px solid black",

        transition: "transform .1s ease-in-out",
        "&:hover":{
            transform: "scale(0.99)"
        }
    },


    
}))

const useMenuStyles =  makeStyles( theme => createStyles({
    competitionTitle:{
        cursor:"pointer",
        "&:hover":{
            textDecoration: "underline"
        }
    },
    scrollButtons: {
        color: "white",
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
      
        background: theme.palette.background.transparent,
        borderRadius: 4, 
        '&:first-child':{
            marginLeft: 0,
        }
    },

    moreVertButton:{
        display: "flex",
        alignItems:"center",
        height: 48,
        borderBottom: "1px solid "+ theme.palette.background.dark,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),

        color: "white", 

        borderRadius: theme.shape.borderRadius,
        fontSize: "0.6em",
        cursor: "pointer",

        background: theme.palette.background.primary,

        '&:hover':{
            borderBottom: "none",
            background: theme.palette.background.transparent,
        }
    },

    moreVertButtonLabel:{
        display: "flex",
        alignItems:"center",
        height: 48,
        borderBottom: "1px solid "+ theme.palette.background.dark,
       
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),

        color: "black",

        borderRadius: theme.shape.borderRadius,
        fontSize: "0.7em",

        background: theme.palette.primary.main,
        cursor: "pointer",
    },

 

}))

const useStylesBetContainer =  makeStyles( theme => createStyles({
    betContainer:{
        cursor:"pointer",
        "&:hover":{
           transform: "scale(1.005)"
        }
    },
    userLabel:{
        "&:hover":{
            textDecoration: "underline"
        }
    },
    mobileFontWeight:{
        [theme.breakpoints.down('sm')]:{
            fontWeight: 400
        }
    }
}))

const StyledTabs = withStyles(theme=>({
    indicator: {
      display: 'flex',
      justifyContent: 'center',
      background: "none",
      
      '& > span': {
        borderRadius: 4, 
        width: '100%',
        backgroundColor: theme.palette.primary.main,
      },
    },
  }))((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);
  
  const StyledTab = withStyles((theme) => ({
    root: {
      textTransform: 'none',
      
      color: '#fff',
      borderRadius: 4, 
      background: theme.palette.background.transparent,
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(1),
      '&:focus': {
        opacity: 1,
      },
    },
  }))((props) => <Tab disableRipple {...props} />);

function Menu({dataType, handleOrderChange, orderBy, orderDirection, betsStatistic, selectedCompetition, selectedSport, handleSportChange, handleCompetitionChange, handleDataTypeChange, userGeoLocation, ...props}){
    const theme = useTheme()
    const classes = useMenuStyles()
    const breakPoint = useMediaBreakPoint()
    const dictionary = useDictionary({elementId: "bets_list_box_element", userGeoLocation})
    const displayedDataDictionary = useDictionary({elementId: "displayed_data_constructor", userGeoLocation})
    const sportsDictionary = useDictionary({elementId: "sports", userGeoLocation})

    const [popperAnchorEl, setPopperAnchorEl] = React.useState(null)
    const [popperOpen, setPopperOpen] = React.useState(false)

    const [popperSportsAnchorEl, setPopperSportsAnchorEl] = React.useState(null)
    const [popperSportsOpen, setPopperSportsOpen] = React.useState(false)

    const [selectedTab, setSelectedTab] = React.useState(0)
    const [displayedSports, setDisplayedSports] = React.useState(null)
    const [displayedCompetitions, setDisplayedCompetitions] = React.useState(null)
    const [loadingData, setLoadingData] = React.useState(false)

    const handlePopperToggle = (event) => {
       
        setPopperAnchorEl(event.currentTarget)
        setPopperOpen((prev) => !prev);
    }

    const handlePopperSportsToggle = (event) => {
       
        setPopperSportsAnchorEl(event.currentTarget)
        setPopperSportsOpen((prev) => !prev);
    }

    const handleButtonClick = (dataType)=> (e)=>{
     
        handlePopperToggle(e)
       
        handleDataTypeChange(dataType)
    } 
    
    const handleTabChange = (event, newValue)=>{
        setSelectedTab(newValue)

        handleSportChange(newValue)
    }

    const handlePopperSportChange = (sport)=> ()=>{

        handleSportChange(sport)
        setPopperSportsOpen(false)
    }

    React.useEffect(() => {
        if(betsStatistic.sportsCounters){
            let sports =  Object.entries(betsStatistic.sportsCounters).sort((a, b)=> b[1]-a[1]).filter((o, i) => i < 6 ).map(o => o[0]) || []
            let competitions = {}
        
            for(let sport of sports){
               
                competitions[sport] = betsStatistic.competitionsCounters && betsStatistic.competitionsCounters[sport] && Object.entries(betsStatistic.competitionsCounters[sport]).sort((a, b)=> b[1]-a[1]).filter((o, i)=> i < 4).map(o => o[0]) || null
            }
        
            setSelectedTab(selectedSport || "")
            setDisplayedSports(sports) 
            setDisplayedCompetitions(competitions)
        }
       
    }, [betsStatistic])
    
   
    if(!displayedSports)return <Box height={MENU_HEIGHT}></Box>
   
    return (
        <Box  display="flex" flexDirection="column" width="100%"  overflow="hidden">
            <Box width="100%" pb={1}  display="flex" justifyContent="space-between" alignItems="flex-end" > 
                <Fade in={dataType === "home_allActiveBetsInfo"} >
                    <Box  width="calc(100% - 114px)" overflow="auto">
                        
                        <StyledTabs 
                            value={selectedSport} 
                            onChange={handleTabChange} 
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="tabs"
                            classes={{
                                scrollButtons: classes.scrollButtons
                            }}
                        >
                            { breakPoint === "xs" && (

                                <StyledTab 
                                    onClick={handlePopperSportsToggle}
                                    value={selectedSport} 
                                    key={0} 
                                    label={
                                        <Fade in={true}>
                                            <Box fontSize="0.8em"  display="flex" flexDirection="row" alignItems="center">
                                                <Box pr={1} fontSize="clamp(1em, 2vw, 1.2em)" display="flex" flexDirection="row" alignItems="center">
                                                    <CustomIcon name={selectedSport}/> 
                                                </Box>
                                                {
                                                    selectedSport ? (
                                                        <>
                                                            <Typography variant="h2" noWrap>{sportsDictionary[selectedSport]}</Typography>
                                                            <Box pl="4px">
                                                                <Typography variant="body2" noWrap>({betsStatistic.sportsCounters[selectedSport]})</Typography>
                                                            </Box>
                                                        </>
                                                    ):(
                                                        <>
                                                            <Typography variant="h2" noWrap>{dictionary.allSportsTabLabel}</Typography>
                                                            <Box pl="4px">
                                                                <Typography variant="body2" noWrap>({Object.keys(betsStatistic.sportsCounters).reduce((reducer, sportId)=> reducer + betsStatistic.sportsCounters[sportId], 0)})</Typography>
                                                            </Box>
                                                        </>
                                                    )
                                                }
                                                    <CustomIcon name="moreVert" fontSize="inherit" color="white" />
                                            </Box>
                                        </Fade>
                                    } 
                                />   
                               
                            )}
                            
                            {breakPoint !== "xs" && (
                                
                                    <StyledTab  
                                        value={""}
                                        label={(
                                            <Fade in={true}>
                                                <Box  fontSize="clamp(0.6em, 2vw, 0.7em)"  display="flex" flexDirection="row" alignItems="center" >
                                                    <Typography variant="h2">{dictionary.allSportsTabLabel}</Typography>
                                                    <Box pl="4px">
                                                        <Typography variant="body2">({Object.keys(betsStatistic.sportsCounters).reduce((reducer, sportId)=> reducer + betsStatistic.sportsCounters[sportId], 0)})</Typography>
                                                    </Box>
                                                </Box>
                                            </Fade>
                                        )}
                                    /> 
                            
                            )} 
                            
                            {breakPoint !== "xs" &&  displayedSports.map((sport, i)=> (
                            
                                    <StyledTab 
                                        value={sport}
                                        key={sport} 
                                        label={
                                            <Fade   in={true} timeout={(1+i)*500}>
                                                <Box fontSize="clamp(0.6em, 2vw, 0.7em)"  display="flex" flexDirection="row" alignItems="center">
                                                    <Box pr={1} fontSize="clamp(1em, 2vw, 1.2em)" display="flex" flexDirection="row" alignItems="center">
                                                        <CustomIcon name={sport}/> 
                                                    </Box>
                                                    <Typography variant="h2" noWrap>{sportsDictionary[sport]}</Typography>
                                                    <Box pl="4px">
                                                        <Typography variant="body2" noWrap>({betsStatistic.sportsCounters[sport]})</Typography>
                                                    </Box>
                                                </Box>
                                            </Fade>
                                        } 
                                    />
                                
                            ))}
                            
                        </StyledTabs>
                        <Popper open={popperSportsOpen} anchorEl={popperSportsAnchorEl} placement="bottom-end" transition>
                            {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={350}>
                                <Box mt={1} position="relative" display="flex" flexDirection="column"  bgcolor="background.primary" borderRadius={4} boxShadow={5} color="white" zIndex={11}>
                                    {
                                        selectedSport && 
                                            <CustomButton 
                                                onClick={handlePopperSportChange("")}
                                                type="large" 
                                                label={(
                                                    <Box fontSize="0.8em" display="flex" flexDirection="row" alignItems="center" >
                                                        <Typography variant="h2">{dictionary.allSportsTabLabel}</Typography>
                                                        <Box pl="4px">
                                                            <Typography variant="body2">({Object.keys(betsStatistic.sportsCounters).reduce((reducer, sportId)=> reducer + betsStatistic.sportsCounters[sportId], 0)})</Typography>
                                                        </Box>
                                                    </Box>)}
                                            />
                                    }
                                   
                            
                                    {
                                        displayedSports.map((sport, i)=> (
                                            
                                            selectedSport !== sport ? 
                                                <CustomButton 
                                                    onClick={handlePopperSportChange(sport)}
                                                    key={sport} 
                                                    type="large"
                                                    label={
                                                        <Box fontSize="0.8em" display="flex" flexDirection="row" alignItems="center">
                                                            <Box pr={1} fontSize="clamp(1em, 2vw, 1.2em)" display="flex" flexDirection="row" alignItems="center">
                                                                <CustomIcon name={sport}/> 
                                                            </Box>
                                                            <Typography variant="h2" noWrap>{sportsDictionary[sport]}</Typography>
                                                            <Box pl="4px">
                                                                <Typography variant="body2" noWrap>({betsStatistic.sportsCounters[sport]})</Typography>
                                                            </Box>
                                                        </Box>
                                                    } 
                                                />: null
                                        ))
                                    }
                                    
                                </Box>
                            </Fade>
                            )}
                        </Popper>                
                    </Box>
                </Fade>

                <Box onClick={handlePopperToggle}  className={classes.moreVertButtonLabel} color="white">
                    <Typography variant="h2"  noWrap>
                        {displayedDataConstructor[dataType](displayedDataDictionary).title || displayedDataDictionary.allBets}  
                    </Typography>
                    
                </Box>
               
                <Popper open={popperOpen} anchorEl={popperAnchorEl} placement="bottom-end" transition>
                    {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Box mt={1} position="relative" display="flex" flexDirection="column"  bgcolor="background.primary" borderRadius={4} boxShadow={5} color="white" zIndex={11}>
                            
                          
                            {dataType !== "home_allActiveBetsInfo" && <CustomButton onClick={handleButtonClick("home_allActiveBetsInfo")} type="large" label={displayedDataDictionary.home_allActiveBetsInfoButtonLabel} />}
                            {dataType !== "home_userSubscribedBets" && <CustomButton onClick={handleButtonClick("home_userSubscribedBets")} type="large" label={displayedDataDictionary.home_userSubscribedBetsButtonLabel} />}
                            {props.auth.userInfo.type === 1 && dataType !== "home_userFavoriteBets" && <CustomButton onClick={handleButtonClick("home_userFavoriteBets")} type="large" label={displayedDataDictionary.home_userFavoriteBetsButtonLabel} />}
                            {props.auth.userInfo.type === 2 && dataType !== "home_userBets" && <CustomButton onClick={handleButtonClick("home_userBets")} type="large" label={displayedDataDictionary.home_userBetsButtonLabel} />}
                           
                        </Box>
                    </Fade>
                    )}
                </Popper>
            </Box>
            
            <Collapse in={Boolean(displayedCompetitions[selectedSport])} timeout={250} mountOnEnter unmountOnExit>
                <Box  color="white"  display="flex" flexDirection="column" justifyContent="space-around" overflow="hidden" width="100%" bgcolor="background.light" borderBottom="1px solid black"  borderRadius={4} mb={1}>
                    <Box p={2} display="flex" flexDirection="row" justifyContent="space-around" flexWrap="wrap">
                        {
                            displayedCompetitions[selectedSport] && displayedCompetitions[selectedSport].map((competitionId)=>
                                <Box 
                                    bgcolor={selectedCompetition === competitionId ? "primary.main":"inherit"} 
                                    borderRadius={4}  
                                    key={competitionId} 
                                    width={200} 
                                    color="white" 
                                    p={1}
                                    className={classes.competitionTitle}
                                    onClick={handleCompetitionChange(competitionId)}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Box  overflow="hidden">
                                        <Typography variant="body2" noWrap>
                                            ({betsStatistic.competitionsCounters[selectedSport][competitionId]})
                                            {" "+betsStatistic.competitions[competitionId]}
                                        </Typography>
                                    </Box>
                                    {
                                        selectedCompetition === competitionId &&
                                            <Box pl="4px" fontSize="24px" display="flex" alignItems="center" color="black">
                                                <CloseIcon fontSize="inherit" size="small"/>
                                            </Box>    
                                    }
                                </Box>
                            )
                        }
                    </Box>
                </Box>
            </Collapse>
        </Box>
    )
 
}

function BetContainer({data, onRowClick, userGeoLocation, trophies}){
    const theme = useTheme()
    const displayDataDictionary = useDictionary({elementId: "displayed_data_constructor", userGeoLocation})
    const betOptionsDictionary = useDictionary({elementId: "bet_options", userGeoLocation})
    const sportsDictionary = useDictionary({elementId: "sports", userGeoLocation})
    const dateDictionary = useDictionary({elementId: "date", userGeoLocation})
    const classes = useStylesBetContainer()
    const breakPoint = useMediaBreakPoint()
   
    const handleUserClick = (e) =>{
        e.stopPropagation()
        onRowClick("user", data.userId)
    }

    const handleBetClick = (e) =>{
        e.stopPropagation()
        onRowClick("bet", data.key, data.sportId)
    }
    
    return(  
        <Box 
            display="flex" 
            flexDirection="column" 
            width="100%" 
           
            maxWidth={1000}
            justifyContent="space-between"
            bgcolor="background.light"
            mb={1}
            borderRadius={4}
            borderBottom="1px solid black"
            className={classes.betContainer}
            onClick={handleBetClick}
        >   
            <Box display="flex" justifyContent="space-between" color="white" py={1} px={2}  alignItems="center"  width="100%" >
                <Box width="calc(100% - 116px)"  display="flex" alignItems="center" onClick={handleUserClick} className={classes.userLabel}>
                    <Box width={30} height={30} bgcolor="background.primary" mr={1} borderRadius={4} overflow="hidden">
                        {
                            data.user.icon && 
                                <img height="30px" width="auto" src={data.user.icon} alt="profile icon" />
                        }
                    </Box>
                    <Box width="calc(100% - 30px)" display="flex" flexWrap="wrap">
                        <Typography className={classes.mobileFontWeight} variant="body1" align="left" fontSize="0.9em">
                            {data.user.value}
                        </Typography>
                        <Box display="flex">
                        {
                            data.user.achievements && Object.keys(data.user.achievements).filter(key => trophies[key]).sort((a, b) => trophies[a].pos - trophies[b].pos ).map( key => 
                                <Box key={key} ml={1} display="flex" alignItems="center">
                                    <Tooltip placement="top" title={trophies[key].label}>
                                        <img height="15px" width="auto" alt="trophy" src={trophies[key].icon} /> 
                                    </Tooltip> 
                                </Box>
                                
                            )
                        }    
                        </Box>   
                    </Box>
                   
                </Box>
                <Box width="100px" fontSize="0.7em" display="flex">
                    <Typography variant="body1" noWrap> 
                       <b>{timestampFormatter(data.timestamp, dateDictionary)}</b>
                    </Typography> 
                </Box>
                
            </Box>
            <Box height="1px" width="calc(100% - 24px)" margin="auto" bgcolor="background.transparent"/>
            <Box display="flex" color="white"  px={2} alignItems="center" height="100%" overflow="hidden">
                <Box flexGrow="1"display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
 
                    <Box p={1} flexGrow={2} flexBasis={breakPoint === "xs" ? "60%" : 1}  display="flex" justifyContent="flex-start" flexDirection="row" alignItems="center">
                         <Box display="flex" alignItems="center" pr={2} fontSize="1.8em">
                            <CustomIcon name={data.match.sport}/>
                        </Box>
                         <Box>
                            <Typography className={classes.mobileFontWeight} variant="body2" align="left">
                                {timestampFormatter(data.time.value, dateDictionary)}
                            </Typography> 
                            <Typography variant="body1" align="left">
                                <b>{data.match.match}</b>
                            </Typography>  
                             
                         </Box>
                    </Box>    

                    {
                        data.type.value &&
                            <Box p={1} flexGrow={2} flexBasis={breakPoint === "xs" ? "100%" : 1}  display="flex" justifyContent="center" flexDirection="column" alignItems="center">
                                <Typography className={classes.mobileFontWeight} variant="body2" align="left">
                                    {displayDataDictionary.betType}
                                </Typography>   
                                <Typography variant="body1" align="left" noWrap>
                                    <b>{data.type.value}</b>
                                </Typography>   
                            </Box>    
                    }
                    {
                        data.bet.value &&
                            <Box p={1} flexGrow={1} flexBasis={1} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
                                <Typography className={classes.mobileFontWeight} variant="body2" align="left" >
                                    {displayDataDictionary.bet}
                                </Typography>   
                                <Typography variant="body1" align="left" >
                                    <b>{data.bet.value}</b>
                                </Typography>   
                            </Box>  
                    }
                    {
                        data.odds.value &&
                            <Box p={1} flexGrow={1} flexBasis={1} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
                                <Typography className={classes.mobileFontWeight} variant="body2" align="left" >
                                    {displayDataDictionary.odds}
                                </Typography>   
                                <Typography variant="body1" align="left" >
                                    <b>{data.odds.value}</b>
                                </Typography>   
                            </Box>  
                    }
                    {
                        data.stake.value &&
                            <Box p={1} flexGrow={1} flexBasis={1} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
                                <Typography className={classes.mobileFontWeight} variant="body2" align="left" >
                                    {displayDataDictionary.stake}
                                </Typography>   
                                <Typography variant="body1" align="left" >
                                    <b>{data.stake.value}</b>
                                </Typography>   
                            </Box>  
                    }

                </Box>


            </Box>
            <Box width="100%" display="flex" color="white" p={1} px={2} justifyContent="space-between" alignItems="center" fontSize="0.8em">
                <Box display="flex">
                    {
                        data.combination ? 
                            <Box py="2px" px="8px" borderRadius={24} maxWidth={100} bgcolor="background.transparent">
                                <Typography className={classes.mobileFontWeight} variant="body2" noWrap> 
                                    {sportsDictionary["combination"]}
                                </Typography> 
                            </Box>:
                            <>
                                <Box py="2px" px="8px" borderRadius={24} maxWidth={100} bgcolor="background.transparent">
                                    <Typography className={classes.mobileFontWeight} variant="body2" noWrap> 
                                        {sportsDictionary[data.match.sport]}
                                    </Typography> 
                                </Box>
                                <Box px={1}>
                                    <Typography variant="body2" noWrap> {">"} </Typography> 
                                </Box>
                                <Box py="2px" px="8px" borderRadius={24} maxWidth={100} bgcolor="background.transparent">
                                    <Typography className={classes.mobileFontWeight} variant="body2" noWrap> 
                                        {data.match.competition}
                                    </Typography> 
                                </Box>
                            </>
                    }
                    
                </Box>
                {
                    data.comment.value && 
                        <Box display="flex" py="2px" px="8px" alignItems="center"  ml={1}  borderRadius={24} maxWidth={100} bgcolor="primary.main" color="black"> 
                            <CustomIcon name="comment" />
                            <Box ml={1}>
                                <Typography variant="body2">{displayDataDictionary.withComment}</Typography>
                            </Box>
                        </Box>        
                }  
            </Box>
        
        </Box>
    )
}

function BetsListBoxElement({ 
    edit,
    title, 
    auth, 
    history, 
    location, 
    fetchAdditionalData=false,
    numOfDataOnFirstLoad=50,
    expandMoreData=true,
    maxRowsDisplayed=14,
    containerHeightMultiplier=1,
    enableSearch=true, 
    enableSort=true,
    brdoparaData,
    userGeoLocation,
    trophies,
    ...props
}){
   
    const theme = useTheme()
    const classes = useStyles()
    const breakPoint = useMediaBreakPoint()
    const dictionary = useDictionary({elementId: "bets_list_box_element", userGeoLocation})
    const displayedDataDictionary = useDictionary({elementId: "displayed_data_constructor", userGeoLocation})

    const listBodyRef = React.useRef()

    const [selectedSport, setSelectedSport] = React.useState("");
    const [selectedCompetition, setSelectedCompetition] = React.useState("");

    const [dataType, setDataType] = React.useState(props.dataType || 1)
    
    const [orderBy, setOrderBy] = React.useState(displayedDataConstructor[props.dataType]().orderBy)
    const [orderDirection, setOrderDirection]  = React.useState(1);

    const [displayedData, setDisplayedData] = React.useState(null)
    const [displayedDataLength, setDisplayedDataLength] = React.useState({})
    const [popperAnchorEl, setPopperAnchorEl] = React.useState(null);
    const [popperOpen, setPopperOpen] = React.useState(false);
    const [loadingData, setLoadingData] = React.useState(false);
    const [showMoreData, setShowMoreData] = React.useState(false);
    const [scrollEnd, setScrollEnd] = React.useState(false);
    
    const [numberOfDataShown, setNumberOfDataShown] = React.useState(maxRowsDisplayed)

    React.useEffect(()=>{
        //reset scroll position
        window.scrollTo(0,0)
    },[])

    React.useEffect(()=>{
       

        let urlParams = new URLSearchParams(location.search)
       
        let sportId = urlParams.get("sportId")
        let competitionId = urlParams.get("competitionId")

        let currOrderBy = competitionId ? "competitionId" : "sportId"

        sportId = sportId ? sportId : ""
        competitionId = competitionId ? competitionId : ""

        let currGroupBy  = competitionId ? competitionId : sportId

        setSelectedSport(sportId)
        
        setSelectedCompetition(competitionId)

        let listDisplayDataConstructor = displayedDataConstructor[dataType](displayedDataDictionary, auth.userInfo && auth.userInfo.type ? auth.userInfo.type:1)

        
        if(brdoparaData[listDisplayDataConstructor.reducer] !== "loading"){
         
            /// set displayed data when fetch data is finished
            let displayData = listDisplayDataConstructor.displayData({auth, data: brdoparaData, orderBy: currOrderBy, orderDirection: 1, groupBy: currGroupBy})
            displayData = displayData.sort((a,b)=> b.timestamp - a.timestamp)
          
            //set flag for displayedDataLength
            setDisplayedDataLength(state => ({...state, [sportId]: Object.keys(displayData).length}))

            //set display data
            let betsWithComments = []
            let betsWithoutComments = []

            for(let bet of displayData){
                if(bet.comment && bet.comment.value)betsWithComments.push(bet)
                else betsWithoutComments.push(bet)
            }

            setDisplayedData([...betsWithComments, ...betsWithoutComments])

            //if fetched data length is grater than numberOfDataShown set flag showMoreData true
            // showMoreData flag enables button to display more data
            setShowMoreData(displayData.length > numberOfDataShown)

            //fetching data has finished, set flag loadingData to false
            setLoadingData(false)

        }
        
        if(brdoparaData[listDisplayDataConstructor.reducer] === "loading"){    
            //fetch data on init
            props[listDisplayDataConstructor.fetchFunction]({auth, orderBy: currOrderBy, groupBy: currGroupBy})
            setLoadingData(true)
        }else{  
           
            //GET NUMBER OF ACTIVE BETS STORED LOCALLY
            let storeData = brdoparaData[listDisplayDataConstructor.reducer]
            storeData = sportId !== "" ? Object.keys(storeData).filter(key => storeData[key][competitionId ? "competitionId":"sportId"] === currGroupBy) : Object.keys(storeData)
            
            
            //fetch more data when loadMore variable is se to true and
            //when storedData length is lower than numberOfDataShown variable and
            //if flag displayedDataLength is not set 
            //TBD: fetch more data if data fetched is partial data
     

            if(!loadingData && listDisplayDataConstructor.loadMore && storeData.length <= numberOfDataShown && !displayedDataLength[sportId]){
                props[listDisplayDataConstructor.fetchFunction]({orderBy: currOrderBy, groupBy: currGroupBy})
                
                setLoadingData(true)
                setDisplayedDataLength(state => ({...state, [selectedSport]: storeData.length}))
            }

           
        }

       
    },[brdoparaData[displayedDataConstructor[dataType]().reducer], location, dataType])
    
    React.useEffect(()=>{
        
        if(!loadingData && displayedData && (displayedData.length <= numberOfDataShown-maxRowsDisplayed) && fetchAdditionalData){
            setLoadingData(true)
        
            props[displayedDataConstructor[dataType](displayedDataDictionary,  auth.userInfo && auth.userInfo.type ? auth.userInfo.type:1 ).fetchFunction]({auth, orderBy, startAt: displayedData[displayedData.length-1].key})
        }
    },[numberOfDataShown])



    let numberOfRowDisplayed = maxRowsDisplayed 
    let containerHeight = window.innerHeight - 186
    
    containerHeight = containerHeight / containerHeightMultiplier
    containerHeight = containerHeight < 300 ? 300 : containerHeight

    let tableContainerHeight = containerHeight - 24

    let rowHeight = (tableContainerHeight)/(numberOfRowDisplayed + (expandMoreData ? 0.82 : 0.3))
    rowHeight = rowHeight%2 !== 0 ? rowHeight+1 : rowHeight

    let tableInfoRowHeight = rowHeight
    //////////////////////////////////////////////////////

    const handleActionClick = async (action)=>{
        //props[displayedDataConstructor[action.dataType].fetchFunction](auth, urlParams, orderBy)
        setDisplayedData(null)
       
        setDataType(action.dataType)  
    }

    const handleSearchInput = (value)=>{
        console.log(value)
    }

    const handleDisplayDataOrderChange = ({orderBy, orderDirection})=>{
        setOrderBy(orderBy)
        setOrderDirection(orderDirection)
    }

    const handleRowClick = (type, id, sportId)=>{
        //console.log(data)
        if(type === "bet"){
            history.push(`/bets/active?betId=${id}&orderBy=sportId&groupBy=${sportId}`)
        }else if(type === "user"){
            history.push(`/user/profile?userId=${id}`)
        }
        
    }

    const handlePopperToggle = (event) =>{
        setPopperAnchorEl(event.currentTarget)
        setPopperOpen((prev) => !prev);
    }

    const handleShowMoreData = ()=>{
        setNumberOfDataShown(state => state + maxRowsDisplayed)
    }

    const handleSportChange = (sport) => {
        setOrderBy("sport")
       
        setSelectedSport(sport)
        if(sport)history.replace({search: `?sportId=${sport}`})
        else history.replace({search: ``})
    }

    const handleCompetitionChange = (competitionId) => () => {
        if(competitionId === selectedCompetition){
            setOrderBy("sportId")
            history.replace({search: `?sportId=${selectedSport}`})
        }else{
            setOrderBy("competitionId")
            history.replace({search: `?sportId=${selectedSport}&competitionId=${competitionId}`})
        }

        setSelectedCompetition(competitionId)
    }

    const handleDataTypeChange = (dataType) => {
        setLoadingData(true)
        setDataType(dataType)
        setDisplayedData(null)
        history.replace({search: ""})
    }

    const listBody = []
    
    return (
        <Box height="100%" position="relative" width="100%">  
            <Box ref={listBodyRef}  minHeight={tableContainerHeight} display="flex" alignItems="center" flexDirection="column" width="100%">

                <Box mb={1} width="100%" maxWidth={1000} position="relative" minHeight={tableContainerHeight}>

                    <Menu 
                        dataType={dataType}
                        betsStatistic={brdoparaData.activeBetsStatistic} 
                        selectedCompetition={selectedCompetition} 
                        selectedSport={selectedSport} 
                        handleSportChange={handleSportChange} 
                        handleCompetitionChange={handleCompetitionChange}
                        handleDataTypeChange={handleDataTypeChange}
                        userGeoLocation={userGeoLocation}
                        auth={auth}
                    />

                    { displayedData && displayedData.length > 0 &&
                        displayedData.filter((o, i)=> i < numberOfDataShown).map((rowData, i) => {    
                            
                            return (
                                <Fade key={rowData.key} in={true} timeout={i < 5 ? (i+1)*500: 2000} >
                                    <Box>
                                        <BetContainer 
                                            
                                            data={rowData} 
                                            dataMap={displayedDataConstructor[dataType].dataMap} 
                                            rowPosition={i} 
                                            lastRow={displayedData.length} 
                                            rowHeight={rowHeight}
                                            onRowClick={handleRowClick}
                                            userGeoLocation={userGeoLocation}
                                            trophies={trophies}
                                        />  
                                            
                                            {
                                                i === 2 &&
                                                    <Box mb={1} borderRadius={4} overflow="hidden"> 
                                                        <AdvertisementElement 
                                                            location="bets"
                                                            advertiser="melbet"
                                                            height={window.innerWidth > 999 ? 156 : 250}
                                                            width="100%"
                                                        />
                                                    </Box>
                                            }  
                                    </Box> 
                                  
                                </Fade>
                            
                            )
                        })
                    }
                    {
                        showMoreData && expandMoreData &&
                            <Box 
                                color="white"
                                fontSize={12} 
                                className={classNames(classes.showMoreRow)}
                                overflow="hidden"
                                bgcolor="background.primary"
                                height={rowHeight/2}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                onClick={handleShowMoreData}
                            >    
                                <CustomIcon name="more"/>
                            </Box> 
                    }
                </Box>
            </Box>
            
        </Box>
    )
}

BetsListBoxElement.defaultProps = {
    variant: "text",
    color: "main",
    elementStyle: {}, // style object of element from database
    style: {}, // style object is pushed to element if there is active animation present
    title: "Zadnje stave",
    dataType: "leaderBoardWeekly",
    elementClasses: [],
};
  
const mapStateToProps = ({auth, brdoparaData, userGeoLocation, trophies}) => {
  return {
    auth,
    brdoparaData,
    userGeoLocation,
    trophies
  }
}

export default withRouter(connect(mapStateToProps, actions)(editWrapper(BetsListBoxElement)))

