import React from 'react';
import * as actions from '../../../../redux/actions'
import { connect } from "react-redux";

import {withRouter} from 'react-router'

import { withStyles,  } from '@material-ui/core/styles';
import editWrapper from '../../../default/wrappers/editWrapper'

const styles = theme => ({
    root:{
        
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
    },
    

});


function AccountCreationElement({
    classes,
    style,
    elementStyle,
    bgImageStyle,
    boxStyle,
    elementChildren,
    files,
    elementClasses,
    edit,
    elementId,
    auth,
    updateUserInfo,
    history,
    ...props
}){

  
  
  return null

}

AccountCreationElement.defaultProps = {

  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",

  elementClasses: [],
  elementStyle: {}, // style object of element from database
  style: {}, // style object is pushed to element if there is active animation present
  
};

const mapStateToProps = ({auth}) => {
    return {auth}
  }

export default withRouter(connect(mapStateToProps, actions)(editWrapper(withStyles(styles)(AccountCreationElement))))
