import {
  SET_DEVICE_NOTIFICATION_STATUS
} from "../../../actions/types";

export default (state = "false", action) => {
  switch (action.type) {
    case SET_DEVICE_NOTIFICATION_STATUS:
      return action.payload
    default:
      return state
  }
}
