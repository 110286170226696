import React from 'react';

import classNames from 'classnames'

import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';


const useStyles = makeStyles( theme => createStyles({
    buttonBase:{
        width:"100%",
        background: theme.palette.background.main,
        borderRadius: 2,
        overflow: "hidden"
    },

    root: {
        
        display:"flex",
        flexDirection:"row",
        flexWrap: "nowrap",
        alignItems:"center",
        overflow: "hidden",
        width: "100%",
        fontSize: 18,
        color: "white",
        background: theme.palette.background.main,
    },

    label:{
        display: "block",
        fontSize:"inherit",
        color:"inherit",
        fontWeight: 300,
        textAlign: "left",
        flexGrow: 1,
        paddingLeft: theme.spacing(2),
        fontFamily: 'Racing Sans One',
    },

    icon:{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: theme.palette.text.secondary, 
        textAlign:"center",  
        fontSize: 25,        
        
        transition: theme.transitions.create(['width']),
    },
    avatar:{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color:"inherit", 
        textAlign:"center",  
        fontSize: 25,        
        
        transition: theme.transitions.create(['width']),
    }
}))

function CustomButtonBase(props) {
   
    const classes = useStyles(props)
    const theme = useTheme()
    const [active, setActive] = React.useState(false)

    const { style, label, icon, height, width, disableGutter, color, fontSize, fontWeight, onClick, children } = props

    let buttonBaseStyle = {
        ...style,
        background: theme.palette.background.main  
    }

    let iconStyle = {
        
        minWidth: height,
        height: height,
        width: height,
    }

    let labelStyle = {
        fontSize,
        fontWeight,
        width: active ? 0 : "100%",
        paddingLeft: active ? 0 : theme.spacing(2),
        transition: theme.transitions.create(['all']),
    }


    if(height){
        buttonBaseStyle = {
            ...buttonBaseStyle,
            height: height, 
            
            
        }

        iconStyle = {
            ...iconStyle,
            height: height,
            width: height,
        }
    } 
    
    

    if(active){
        iconStyle = {
            ...iconStyle,
            width: "100%",
            transition: theme.transitions.create(['width'])
        }
    }

    return (
        <Box p={disableGutter ? 0 : 2 } pb={0} width={width ? width : "100%"}>
            <ButtonBase 
                classes={{root: classes.buttonBase}} 
                style={buttonBaseStyle}
                onMouseEnter={()=>setActive(true)}
                onMouseLeave={()=>setActive(false)}
                onClick={onClick}
            >   
                <span className={classNames(classes.root)}>
                
                    {icon &&
                        <span 
                            className={classes.icon}
                            style={iconStyle}
                        >
                            <Tooltip title={label} placement="right">
                                <Icon color="inherit" fontSize="inherit">{icon}</Icon>
                            </Tooltip>  
                        </span>
                    }   
                    {label &&
                        <Typography className={classes.label} style={labelStyle} noWrap>
                            {label}
                        </Typography>    
                    }

                    {children && children}
                    
                </span>       
            </ButtonBase>
        </Box>
        
    )
}


CustomButtonBase.defaultProps = {
    
    variant: "text",
    color: "primary",
    height: 40,
    width: "",
    fontSize: 16,
   
  };
  
export default CustomButtonBase
