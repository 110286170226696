


export default (timestamp, dictionary, options={})=>{
    let {withoutTime, withYear, dateTimeShort} = options
    if(!dictionary)return ""

    let date = new Date(timestamp*1000)
    let year = date.getFullYear()
    let day = date.getDay()
    let month = date.getMonth()
    let dayInMonth = date.getDate()
    let hours = date.getHours()
    hours = String(hours).length === 1 ? '0' + hours : hours
    
    let minutes = date.getMinutes()
    minutes = String(minutes).length === 1 ? '0' + minutes : minutes

    if(withoutTime){
        return `${dictionary.daysShort && dictionary.daysShort[day]}, ${dayInMonth}.${month+1}`
    }if(dateTimeShort){
        return `${dayInMonth}.${month+1} ${hours}:${minutes}`
    }else if(withYear){
        return `${dictionary.daysShort && dictionary.daysShort[day]}, ${dayInMonth}.${month+1}.${year}`
    }else{
        return `${dictionary.daysShort && dictionary.daysShort[day]}, ${dayInMonth}.${month+1}  ${hours}:${minutes}`
    }
   
}