import {

  FETCH_PAGE_ELEMENTS,
  FETCH_GLOBAL_ELEMENTS,
  SET_ACTIVE_MODAL,
  GLOBAL_ELEMENTS_LOADED,
  CLOSE_ACTIVE_MODAL,
  CLEAR_CHANGES_PAGE_CHANGE_HISTORY,
  SET_MODAL_DATA
} from './../../types'

import _ from 'lodash'

import * as firebase from './../../../../config/firebase'

import {saveFileToFireStore} from "./../../supportFunctions"


export const fetchGlobalElements = ()=> async dispatch => {
  console.log("fetchGlobalElements")
  let elements = await firebase.globalElementsRef.once("value")
  elements = elements.val()


  dispatch({
    type: FETCH_PAGE_ELEMENTS,
    payload: elements
  })

  dispatch({
    type: FETCH_GLOBAL_ELEMENTS,
    payload: elements
  })

  dispatch({
    type: GLOBAL_ELEMENTS_LOADED,
  })
}

function setValue(fields, val, obj) {
  console.log(fields)
  var result = obj;
  for (var i = 0, n = fields.length; i < n && result !== undefined; i++) {
    var field = fields[i];
    if (i === n - 1) {
      result[field] = val;
    } else {
      if (typeof result[field] === 'undefined' || !_.isObject(result[field])) {
        result[field] = {};
      }
      result = result[field];
    }
  }
}

export const saveElementsChanges = () => async (dispatch, getState) => {

  dispatch({
    type: SET_ACTIVE_MODAL,
    payload: "savingPageModal"
  })

  
  
  const elements = getState().elements
  const selectedPage = getState().selectedPage
  const globalElements = getState().globalElements

  let pageChangeHistory = _.cloneDeep(getState().pageChangeHistory)

  pageChangeHistory = pageChangeHistory.changes.reduce((acc, change)=>{

    Object.keys(acc).forEach( pathA =>{
      Object.keys(change).forEach( pathC =>{

        if(pathC.includes(pathA) && pathC.length > pathA.length){
          //console.log({pathC, pathA, "pathCPathA": pathC.replace("/elements/","").split("/"), value:change[pathC],acc })
          setValue(pathC.replace(pathA+"/","").split("/"), _.clone(change[pathC]), acc[pathA])
          delete change[pathC]
        }else if(pathA.includes(pathC) && pathC.length < pathA.length) delete acc[pathA]
      })
    })

    return {...acc, ...change}
  }, {})


  try{

    let elementsChanges = {}

    for await (let key of Object.keys(pageChangeHistory)){
      /// element id is always in 3th place in array
      let elementId = key.split("/")[2]

      //check if there are some files to upload
      if(pageChangeHistory[key] && pageChangeHistory[key].files){
        for await (let [id, file] of Object.entries(pageChangeHistory[key].files)){
          if(file.obj){

            // uploads file to server
            let fileUrl = await saveFileToFireStore(`${id}__${elementId}`, file.obj, file.type, dispatch,)

            // save file url to newElement
            pageChangeHistory[key].files[id].src = fileUrl

            // add file to files list in
            let pushImageRef = firebase.imagesRef.push()
            let fileId = pushImageRef.getKey()
            pushImageRef.set({src: fileUrl, name: file.obj.name})

            pageChangeHistory[key].files[id].fileListId = fileId

            // removes file obj from element
            delete file.obj

          }
        }

      }

      //check if element is global element
      if(elements[elementId] && elements[elementId].global){
          elementsChanges = {
            ...elementsChanges, 
            [`/globalElements${key.replace("/elements", "")}`]: pageChangeHistory[key],
            [`/pages/${selectedPage}${key}`]: null
          }
      }else{
        //remove element if elementId exist in globalElements and its not global element
        if(globalElements[elementId]) elementsChanges[`/globalElements/${elementId}`] = null
 
        elementsChanges[`/pages/${selectedPage}${key}`] = pageChangeHistory[key]
         
      }
    }

    await firebase.databaseRef.update(elementsChanges)
    dispatch({
      type: CLEAR_CHANGES_PAGE_CHANGE_HISTORY
    })

  }catch(err){
    console.log(err)
  }

  dispatch({
    type: CLOSE_ACTIVE_MODAL,
  })

}
