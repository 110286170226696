import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';

import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';

import LeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import RightIcon from '@material-ui/icons/KeyboardArrowRight';

import editWrapper from '../../../default/wrappers/editWrapper'
import AdvertisementElement from './AdvertisementElement'


const useStyles = makeStyles( theme => createStyles({
  root: {

    position: 'relative',

    width: "100%",
    overflow: "hidden"
  },
  absolute:{
    position:"absolute",
    top: 0,
    width: "100%",
    height: "100%"
  },
  column:{
    position: "relative",
    height:350,

    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
  },
  shadow:{
    position: "relative",
    zIndex: 20,
    boxShadow: theme.shadows[3]
  },
  sliderContainer:{
    height: "100%",
    position:"absolute",
    left: 0,
    top: 0,
    display:"flex",
    flexDirection:"row",

  },
  elementWrapper:{
    width:"100%",
    height:"100%",
    position:"relative",
  },
  element:{
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
  },

  firstPageElement:{
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,

  },
  buttonLeftWrapper:{
    position: "absolute",
    top: 0,
    bottom: 0,
    left:0,

    display: "flex",
    flexDirection:"column",
    justifyContent:"center"
  },
  buttonLeft:{
    borderRadius: 4,
    padding: 0,
    background: theme.palette.background.transparent,
    zIndex:502,
  },
  buttonRightWrapper:{
    position: "absolute",
    top: 0,
    bottom: 0,
    right:0,

    display: "flex",
    flexDirection:"column",
    justifyContent:"center"
  },
  buttonRight:{
    borderRadius: 4,
    padding: 0,
    background: theme.palette.background.transparent,
    zIndex:502,
  },
  icon:{
    fontSize: 40,
    color: theme.palette.text.secondary
  },
  overlay:{
    position: "absolute",
    top: 0,
    left: 0,

    width: "100%",
    height: "100%",
   
    background: "linear-gradient(75deg, rgba(28,28,28,0.6) 0%, rgba(28,28,28,.98) 100%)",
    zIndex: 10,
  },


  galleryItemInfo:{
    position:"absolute",
    bottom: 0,
    right: 0,
    width: "100%",
    background: theme.palette.background.transparent,
    height: "20%",
    boxShadow: theme.shadows[5],

    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    transition: "all .35s ease-in-out"
  },
  galleryItemInfoLarge:{
    height: "100%",
    transition: "all .35s ease-in-out",

    background: theme.palette.background.transparent,
  },
  imageTitle: {

    fontSize: 20,
    fontWeight: 600,
    textTransform: "uppercase",
    borderBottom: "2px solid rgba(0,0,0,0)",


  },
  imageTitleActive:{
    fontSize: 32,
    fontWeight: 600,
    textTransform: "uppercase",
    borderBottom: "2px solid "+ theme.palette.secondary.main,

    [theme.breakpoints.down("lg")]:{
      fontSize: 20,
    }
  },
  imageElementButtonWrapper:{
    position: "absolute",
    left: 0,
    right: 0,
    bottom: theme.spacing.unit * 4,

  }


}))

function Carousel({
  
  disableButtons, 
  backgroundImage, 
  childrenId,
  edit
}){

  const classes = useStyles()
  const theme = useTheme()
  const slidesNum = 1
  const [open, setOpen] = React.useState(false)
  const [activeElement,setActiveElement] = React.useState(0)
  const [transition,setTransition] = React.useState(null)
  const [slideDirection,setSlideDirection] = React.useState(null)
  const [intervalId, setIntervalId] = React.useState(null)

  const [width, setWidth] = React.useState(0)
  const [height, setHeight] = React.useState(0)

  const [showButton, setShowButton] = React.useState(false)

  const containerRef = React.useRef()

  const setWindowSize = () =>{

    setWidth(containerRef.current.offsetWidth)
    setHeight(window.innerHeight/3 )
  }

  React.useEffect(()=>{

    window.addEventListener('resize', setWindowSize)
    setWindowSize()

    return ()=>{
      window.removeEventListener('resize', setWindowSize)
      
    }

  }, [])

  React.useEffect(()=>{

    if(!intervalId) startSlideShow()
 
    return ()=>{
      clearInterval(intervalId)
    }

  }, [intervalId])

  const startSlideShow = ()=>{

    let intervalId  = setInterval(()=>{

      setActiveElement(state => (state + 1) % slidesNum)
    }, 10000);
    
    setIntervalId(intervalId)
  }

  const handleClose = () => {
    setOpen(false)
  };

  const handleClick = () => {
  
    setOpen(true)
  };

  const handleLeftClick = ()=>{
    setActiveElement(state => state - 1)
    setSlideDirection("left")
    resetInterval()
  }

  const handleRightClick = ()=>{
    setActiveElement(state => state + 1)
    setSlideDirection("right")
    resetInterval()
  }

  const resetInterval = ()=>{
    
    clearInterval(intervalId)
    setIntervalId(null)
  }


  const handleMouseEnter = ()=>{

    setShowButton(true)
  }

  const handleMouseLeave = ()=>{

    setShowButton(false)
  }

  let numberOfElements = slidesNum ? slidesNum : 0
  
  let slides = [

    <AdvertisementElement 
      location="dashboard"
      advertiser="melbet"
    />

  ]
  
  return (

    <Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className={classes.root} ref={containerRef} height={height} minHeight={150} mb={1} borderRadius={4} boxShadow={1}>
        {!disableButtons && activeElement !== 0 &&
          <Fade in={showButton}>
            <Box className={classes.buttonLeftWrapper} ml={1}>
              <IconButton disabled={activeElement === 0} className={classes.buttonLeft} onClick={handleLeftClick}>
                  <LeftIcon className={classes.icon} />
              </IconButton>
            </Box>
          </Fade>
          
        }
        <Box className={classes.sliderContainer} width={`calc(${ width * numberOfElements}px)`} style={{left: -activeElement*width, overflow: edit ? "auto": "hidden"}}>
          { slides.map((slide, i)=>{
            
            return(
              <Fade key={i} in={i === activeElement} timeout={300}>
                <Box position="relative" key={childrenId} height="100%" width={width} bgcolor="background.primary">
                  {slide}
                </Box>
              </Fade>
              
            )

          })}
        </Box>
        {!disableButtons && activeElement !== numberOfElements-1 &&
          <Box className={classes.buttonRightWrapper} mr={1}>
            <Fade in={showButton}>
              <IconButton disabled={activeElement === numberOfElements-1} className={classes.buttonRight} onClick={handleRightClick}>
                  <RightIcon className={classes.icon} />
              </IconButton>
            </Fade>
          </Box>
        }
        <Box position="absolute" bottom={0} width="calc(100%)" left={0} right={0} margin="auto" height={3} display="flex" justifyContent="space-around">
          {
            slidesNum > 1 && slides.map((child, i)=>(
              <Box key={i} width={`calc((100% / ${numberOfElements}))`} height="100%" bgcolor={activeElement === i ? theme.palette.primary.main: theme.palette.background.transparent} style={{opacity: 0.5}} />
            ))
          }
        </Box>
    </Box>
  )
  
}

Carousel.propTypes = {


};

export default editWrapper(Carousel)
