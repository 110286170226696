import * as firebase from './../../../../config/firebase'
import logger from  './../../../../helpers/logger'

import {
    FETCH_SERVER_INFO,
    FETCH_SCRAPER_INFO,
    UPDATE_SCRAPER_SETTINGS,
    SET_ACTIVE_SNACKBAR,
    FETCH_ODDSPORTALIDS,
    UPDATE_ODDSPORTALIDS

  } from './../../types'

export const startServerTask =  task => async dispatch => {
  
    try{
        logger('api_call', `startServerTask/${task}`)
        dispatch({
            type: SET_ACTIVE_SNACKBAR,
            name: "startTaskSnackbar",
            status: "info",
            label: `Task ${task} has started.`,
        })

        const fetchServerStatus = firebase.cloudFunction.httpsCallable('startServerTask')
        await fetchServerStatus({task})

        dispatch({
            type: SET_ACTIVE_SNACKBAR,
            name: "endTaskSnackbar",
            status: "info",
            label: `Task ${task} has been finished.`,
        })
     
    }catch(err){
        logger('exception', "startServerTask", {err})
        dispatch({
            type: SET_ACTIVE_SNACKBAR,
            name: "endTaskSnackbar",
            status: "error",
            label: `Task ${task} return error. ${err.message}`,
        })
    }
}

export const fetchServerInfo = () => async dispatch => {
    
    try{
        logger('api_call', "fetchServerInfo")
        const fetchServerStatus = firebase.cloudFunction.httpsCallable('fetchServerStatus')
        const result = await fetchServerStatus()
        
        dispatch({
            type: FETCH_SERVER_INFO,
            payload: result.data
        })

    }catch(err){
        logger('exception', "fetchServerInfo", {err})
    }
}


export const fetchScraperInfo = () => async dispatch => {
    
    try{
        logger('api_call', "fetchScraperInfo")
        let scraperInfo = await firebase.databaseRef.child('scraper').once('value')
        scraperInfo = scraperInfo.val()
        
        dispatch({
            type: FETCH_SCRAPER_INFO,
            payload: scraperInfo
        })

    }catch(err){
        
        logger('exception', "fetchScraperInfo", {err})
        
    }
     
}

export const updateScraperSettings = (scraperSetting, values)=> async dispatch => {
 
    try{    
        logger('api_call', "updateScraperSettings")
        await firebase.databaseRef.child('scraper').child("settings").child(scraperSetting).update(values)
        
        dispatch({
            type: UPDATE_SCRAPER_SETTINGS,
            scraperSetting,
            payload: values
        })

        dispatch({
            type: SET_ACTIVE_SNACKBAR,
            name: "updateScraperInfoSnackbar",
            status: "info",
            label: `Scraper info has been updated successfully.`,
        })

    }catch(err){

        dispatch({
            type: SET_ACTIVE_SNACKBAR,
            name: "updateScraperInfoSnackbar",
            status: "error",
            label: "Error: " + err.message,
        })
        
        logger('exception', "updateScraperSettings", {err})
        
    }
}

export const addSportScrapperSettings = ({sport, status})=> async dispatch => {
 
    await firebase.databaseRef.child('scraper').child("settings").update({
        [`sports/${sport}`]: status, 
       
    })
}

export const addRegionScrapperSettings = ({sport, region, regionLabel, flagIconFile, regionStatus})=> async dispatch => {
    let iconUrl = null
    if(flagIconFile){
        let uploadTask = await firebase.storageRef.child('countriesIcon').child(flagIconFile.name).put(flagIconFile, {cacheControl: 'public, max-age=31536000',})
        iconUrl = await uploadTask.ref.getDownloadURL()
    }
    

    await firebase.databaseRef.child('scraper').child("settings").update({
        [`regions/${sport}/${region}`]: regionStatus, 
        [`regionsList/${region}`]: {
            icon: iconUrl,
            label: regionLabel
        }
    })
}

export const addCompetitionScrapperSettings = ({sport, region, competition, competitionLabel, competitionStatus})=> async dispatch => {
     
    await firebase.databaseRef.child('scraper').child("settings").update({
        [`competitions/${sport}/${region}/${competition}`]: competitionStatus, 
        [`competitionsList/${competition}/`]: competitionLabel || competition, 
    })

}

export const fetchOddsportalIds = ()=> async dispatch => {
    
    try{    
        logger('api_call', "fetchOddsportalIds")
        let res = await firebase.databaseRef.child('scraper').child("oddsportalIds").once('value')
        
        dispatch({
            type: FETCH_ODDSPORTALIDS,
            payload: res.val()
        })
       

    }catch(err){

        logger('exception', "fetchOddsportalIds", {err})
        
    }
}

export const updateOddsportalIds = (sport) => async dispatch => {
    
    try{
        logger('api_call', "updateOddsportalIds")
        const oddsportalIds = firebase.cloudFunction.httpsCallable('startServerTask')
        const result = await oddsportalIds({task: "scrapeOddsportalIds", sport})
        
        dispatch({
            type: UPDATE_ODDSPORTALIDS,
            sport,
            payload: result
        })
    }catch(err){
        dispatch({
            type: SET_ACTIVE_SNACKBAR,
            name: "updateScraperInfoSnackbar",
            status: "error",
            label: "Error: " + err.message,
        })
        logger('exception', "updateOddsportalIds", {err})
    }
}

