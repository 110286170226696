import React from 'react';

import classNames from 'classnames'

import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import SvgIcon from '@material-ui/core/SvgIcon';
import CancelIcon from '@material-ui/icons/Cancel';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EventIcon from '@material-ui/icons/Event';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SaveIcon from '@material-ui/icons/Save';
import CommentIcon from '@material-ui/icons/Comment';
import StarIcon from '@material-ui/icons/Star';
import EditIcon from '@material-ui/icons/Edit';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/ErrorOutline';
import DeleteIcon from '@material-ui/icons/Remove';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import EmailIcon from '@material-ui/icons/Email';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MenuIcon from '@material-ui/icons/Menu';
import AddIcon from '@material-ui/icons/Add';
import DownIcon from '@material-ui/icons/ArrowDropDown';
import UpIcon from '@material-ui/icons/ArrowDropUp';


import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarHalfIcon from '@material-ui/icons/StarHalf';


import { ReactComponent as FootballIcon } from './soccer.svg';
import { ReactComponent as TennisIcon } from './tennis.svg';
import { ReactComponent as BasketballIcon } from './basketball.svg';

import { ReactComponent as UpArrowIcon } from './upArrow.svg'
import { ReactComponent as DownArrowIcon } from './arrows.svg'
import { ReactComponent as ArrowIcon } from './arrow.svg'
import { ReactComponent as ArrowsRightIcon } from './arrowsRight.svg'
import { ReactComponent as FbIcon } from './fb-icon.svg'
import { ReactComponent as GoogleIcon } from './google-icon.svg'
import { ReactComponent as ProfileIcon } from './profile.svg'
import { ReactComponent as InfoIcon } from './Information.svg'
import { ReactComponent as EsportsIcon } from './headphones.svg'
import { ReactComponent as CombinationIcon } from './combination2.svg'
import { ReactComponent as VolleyballIcon } from './volleyball.svg'
import { ReactComponent as HandballIcon } from './handball.svg'
import { ReactComponent as HockeyIcon } from './hockey.svg'
import { ReactComponent as AmericanFootballIcon } from './football.svg'

import CombinationImage from './combination.png'

const customIcons = {
   soccer: {component: FootballIcon, viewBox: "0 0 75 75", type: "svg"},
   americanFootball: {component: AmericanFootballIcon, viewBox: "0 0 512 512", type: "svg"},
   esports: {component: EsportsIcon, viewBox: "0 0 500 500", type: "svg", color: "white"},
   tennis: {component: TennisIcon, viewBox: "0 0 125 125", type: "svg"},
   basketball: {component: BasketballIcon, viewBox: "0 0 78 78", type: "svg"},
   combination: {component: CombinationIcon, viewBox: "0 0 64 64", type: "svg"},
   volleyball: {component: VolleyballIcon, viewBox: "0 0 123 123", type: "svg"},
   handball: {component: HandballIcon, viewBox: "0 0 24 24", type: "svg"},
   hockey: {component: HockeyIcon, viewBox: "0 0 32 32", type: "svg"},
   won: {component: <CheckCircleIcon style={{color: "#5cb85c"}}/>, type: "materialIcon"},
   lost: {component: <CancelIcon style={{color: "#ce3052"}}/>, type: "materialIcon"},
   canceled:  {component: <BlockIcon style={{color: "gray"}}/>, type: "materialIcon"},
   more:  {component: <ExpandMoreIcon style={{color: "white"}}/>, type: "materialIcon"},
   moreVert:  {component: <MoreVertIcon style={{color: "inherit"}}/>, type: "materialIcon"},
   upArrow: {component: UpArrowIcon, viewBox: "80 80 300 300", type : "svg"},
   downArrow: {component: DownArrowIcon, viewBox: "0 0 65 100", type : "svg"},
   arrow: {component: ArrowIcon, viewBox: "0 0 65 50", type : "svg"},
   arrowsRight: {component: ArrowsRightIcon, viewBox: "0 0 108 66", type : "svg"},
   fb: {component: FbIcon, viewBox: "30 30 500 500", type : "svg"},
   google: {component: GoogleIcon, viewBox: "0 0 140 140", type: "svg"},
   profile: {component: ProfileIcon, viewBox: "0 0 500 500", type: "svg", color: "white"},
   info: {component: InfoIcon, viewBox: "0 0 35 35", type : "svg"},
   date: {component: <EventIcon style={{color: "white"}}/>, type: "materialIcon"},
   save: {component: <SaveIcon fontSize="inherit" style={{color: "white"}}/>, type: "materialIcon"},
   comment: {component: <CommentIcon fontSize="inherit" color="inherit"/>, type: "materialIcon"},
   star: {component: <StarIcon fontSize="inherit" color="inherit"/>, type: "materialIcon"},
   edit: {component: <EditIcon fontSize="inherit" color="inherit"/>, type: "materialIcon"},
   error: {component: <ErrorIcon fontSize="inherit" color="inherit"/>, type: "materialIcon"},
   warning: {component: <WarningIcon fontSize="inherit" color="inherit"/>, type: "materialIcon"},
   success: {component: <CheckCircleOutlineIcon fontSize="inherit" color="inherit"/>, type: "materialIcon"},
   delete: {component: <DeleteIcon fontSize="inherit" color="inherit"/>, type: "materialIcon"},
   email: {component: <EmailIcon fontSize="inherit" color="inherit"/>, type: "materialIcon"},
   notifications: {component: <NotificationsIcon fontSize="inherit" color="inherit"/>, type: "materialIcon"},
   add: {component: <AddIcon fontSize="inherit" color="inherit" />, type: "materialIcon"},
   down: {component: <DownIcon fontSize="inherit" color="inherit"/>, type: "materialIcon"},
   up: {component: <UpIcon fontSize="inherit" color="inherit"/>, type: "materialIcon"},
   starEmpty: {component: <StarBorderIcon fontSize="inherit" color="inherit"/>, type: "materialIcon"},
   starHalfEmpty: {component: <StarHalfIcon fontSize="inherit" color="inherit"/>, type: "materialIcon"},
    
}

const useStyles = makeStyles( theme => ({
   
 
}));

const useStyleArrowsAnimated = makeStyles( theme => ({
   
 
}));


function ArrowsAnimated(){

    const classes = useStyleArrowsAnimated()

    return(
        <svg width="65" height="100" viewBox="0 0 65 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Arrows">
                <g id="arrow3" className="arrow3">
                    <rect id="Rectangle 10" width="10.7993" height="52.9548" rx="5.39965" transform="matrix(0.842651 -0.53846 0.578226 0.815877 0 56.7954)" fill="#D9B01C"/>
                    <rect id="Rectangle 11" width="10.7924" height="52.9899" rx="5.39621" transform="matrix(0.835462 0.549549 -0.589367 0.807865 55.9832 51.1515)" fill="#D9B01C"/>
                </g>
                <g id="arrow2" className="arrow2">
                    <rect id="Rectangle 10_2" width="10.7993" height="52.9548" rx="5.39965" transform="matrix(0.842651 -0.53846 0.578226 0.815877 0 31.3053)" fill="#D9B01C"/>
                    <rect id="Rectangle 11_2" width="10.7924" height="52.9899" rx="5.39621" transform="matrix(0.835462 0.549549 -0.589367 0.807865 55.9832 25.6614)" fill="#D9B01C"/>
                </g>
                <g id="arrow1" className="arrow1">
                    <rect id="Rectangle 10_3" width="10.7993" height="52.9548" rx="5.39965" transform="matrix(0.842651 -0.53846 0.578226 0.815877 0 5.81506)" fill="#D9B01C"/>
                    <rect id="Rectangle 11_3" width="10.7924" height="52.9899" rx="5.39621" transform="matrix(0.835462 0.549549 -0.589367 0.807865 55.9832 0.171143)" fill="#D9B01C"/>
                </g>
            </g>
        </svg>
    )
}


function CustomIcon({width, color, name, fontSize}){

    const classes = useStyles()
    const icon = customIcons[name]

    if(!icon) return null

    let iconElement = null

    if(icon.type === "svg"){
        iconElement = <SvgIcon color={"inherit"} component={icon.component} fontSize="inherit" viewBox={icon.viewBox} />
    }else if(icon.type === "materialIcon"){
        iconElement = icon.component
    }else if(icon.type === "img"){
        iconElement = <img height="auto" width="15px" alt="icon" src={icon.component}/>
    }
 
    return iconElement
}



export default CustomIcon
