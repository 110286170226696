
import {
  FETCH_PAGE_ELEMENTS,

} from "./../../../actions/types";

import globalElements from './../../../../static/pages/globalElements.json'

export default (state = globalElements, action) => {

  switch (action.type) {
    case FETCH_PAGE_ELEMENTS:
      return {...state, ...action.payload}
    default:
      return state;
  }
};
