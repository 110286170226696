
import { SET_USER_GEO_LOCATION } from "./../../../actions/types";

/// its set value of current scroll position
// if(scrollPositon < 100vh) state = 0
// variable needed to dynamically load content on pages larger than 100vh
export default (state = "loading", action) => {
  switch (action.type) {
    case SET_USER_GEO_LOCATION:
      return action.payload;
    default:
      return state;
  }
};
