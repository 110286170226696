import React from 'react';
import { withRouter } from 'react-router'
import { useCookies } from 'react-cookie';

import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import withRoot from './../withRoot';
import * as actions from './../redux/actions'
import { connect } from "react-redux";
import Preloader from './components/preloaders/Preloader'
import Element from './Element'
import {Redirect} from 'react-router-dom'
import ErrorBoundary from './default/wrappers/ErrorBoundary'

import { Helmet } from 'react-helmet'


const useStyle = makeStyles( theme => createStyles({

  root: {
    position:"relative",
    background: theme.palette.background.secondary,
    minHeight: "100vh",
  },

  adminButtons:{

    bottom: "24px",
    right: "24px",

    [theme.breakpoints.down('sm')]: {
      bottom: "16px",
      left: "16px",
      right: "initial"
    },
  }

}))

function PageView({
  deviceInfo,
  fetchWebpageById,
  setSelectedPage,
  location,
  webpages,
  globalElementsLoaded,
  setUserGeoLocation,
  userGeoLocation,
  auth,
  deviceNotificationStatus,
  ...props
}){

  const classes = useStyle()
  const [pageId, setPageId] = React.useState("_home")

  let [cookies, setCookie] = useCookies()

  React.useEffect(()=>{
    let pageId = location.pathname.replace(/\//g, "_")
   
    pageId = pageId === "_" ? "_home" : pageId
  
    setPageId(pageId)
    setSelectedPage(pageId)
    fetchWebpageById(pageId)

  }, [])
  
  React.useEffect(()=>{
    let pageId = location.pathname.replace(/\//g, "_")
    pageId = pageId === "_" ? "_home" : pageId
   
    fetchWebpageById(pageId)
    setSelectedPage(pageId)
    setPageId(pageId)

  },[location])

  React.useEffect(()=>{

    if(auth && auth.userInfo && auth.userInfo.metadata && auth.userInfo.metadata.subscriptions){ 
      let pushNotifications = Object.values(auth.userInfo.metadata.subscriptions).reduce( (reducer, subscription)=> reducer ? true : subscription.pushNotifications , false)

      if(deviceNotificationStatus !== "unsupported" && pushNotifications){
        props.fcmInit({cookies, setCookie})
        props.invokeFcmMessageListener()
      }
      
    }

  }, [auth])
  
  if(webpages !== "loading" && userGeoLocation !== "loading" && webpages[pageId]){
    let displayContent = null
    let page = webpages[pageId]
  
    if(!page.requiresAuth){
      // if page do not need logged user, do not wait till it is loaded
      return page.elements["root"].elementChildren.map((elementId,i) => <Element edit={false}  selectedPage={pageId}  key={elementId} elementId={elementId} {...page.elements[elementId]}/>)
    }else if(auth !== "loading"){
      //if page need logged user,  wait till auth data finish loading
      //FTB: email verification is removed. 
      if(auth){  //&& auth.emailVerified
          if("userInfo" in auth){
            displayContent = page.elements["root"].elementChildren.map((elementId,i) => <Element edit={false}  selectedPage={pageId}  key={elementId} elementId={elementId} {...page.elements[elementId]}/>)
          }else  return <Preloader />
          
      }else{
        // if not authenticated return to /login page
        return <Redirect to='/login' />
      } 
    }else{
      // auth data still loading
      return <Preloader />
    }
   
    return (
      <div className={classes.root}>
        <Helmet>
          <title>{`${page.name} | Brdopara.com`}</title>
        </Helmet>
        <Fade in={true} timeout={1000}>
          <ErrorBoundary>
            <Box>
              {displayContent}
            </Box>
          </ErrorBoundary>
        </Fade>
      </div>
      
    );
  }else return <Preloader />

  
}

PageView.propTypes = {
  
};

const mapStateToProps = ({ webpages, auth, activeDocumentPage, globalElementsLoaded, userGeoLocation, deviceInfo, deviceNotificationStatus}) => {
  return {
    webpages,
    globalElementsLoaded,
    auth,
    activeDocumentPage,
    userGeoLocation,
    deviceInfo, 
    deviceNotificationStatus,
  }
}

export default withRouter(
                  withRoot(
                    connect(mapStateToProps, actions)(PageView)))

