import React from 'react';
import {throttle} from 'lodash'
import {withRouter} from 'react-router'
import * as actions from '../../../../redux/actions'
import { connect } from "react-redux";
import classNames from 'classnames'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Tooltip  from '@material-ui/core/Tooltip';
import useDictionary from '../../../../hooks/useDictionary';

const NAVBAR_HEIGHT = 75

const useStyles = makeStyles( theme => ({
    root: {
        backgroundColor: 'red',
        color: props => props.color,
    },
    
    navbar:{
        height: NAVBAR_HEIGHT,
        background: theme.palette.background.primary, 
        transition: "all 0.2s ease-in-out",
        borderBottom: "2px solid "+theme.palette.background.dark,
        boxShadow: theme.shadows[3]
    },
    navbarDocked: {
        height: NAVBAR_HEIGHT,
      
        transition: "all 0.2s ease-in-out"
    },
    navButton:{
        height: NAVBAR_HEIGHT,
        fontSize: "1em",
        color: "white",
        transition: "all 0.2s ease-in-out",
       
        "&:hover": {
            background: theme.palette.primary.main,
        }
    },
    navButtonDocked:{
        height: NAVBAR_HEIGHT,
        color: "white",
        fontSize: "1.2em",
        transition: "all 0.2s ease-in-out",

        "&:hover": {
            background: theme.palette.primary.main,
        }

    },
    logo:{
        opacity: 0,
        transform: "scale(1.1)",
        transition: "transform 0.2s ease-in-out",

    },
    logoDocked:{
        opacity: 1,
        
        transition: "transform 0.2s ease-in-out",

    },
    logoImage:{
        [theme.breakpoints.down("sm")]:{
            height:"35px"
        }
    },

    title:{
        padding: theme.spacing(1),
        fontSize: "clamp(2em, 3vw, 2.5em)"
    },

    countryIcon: {
        display:"flex",
        alignItems:"center",
        paddingRight: theme.spacing(1.5),
        cursor:"pointer",
    
        '&:hover':{
          transform: "scale(1.2)"
        }
      },
    
      countryIconActive:{
        transform: "scale(1.2)",

        "& img":{
            border: "2px solid "+theme.palette.primary.main,
            borderRadius: 25
        }
      }

}));


function NavbarElement({auth, edit, activeDocumentPage, history, homePage, userGeoLocation, ...props}) {

    const classes = useStyles(props)
    const theme = useTheme()
    const dictionary = useDictionary({elementId: "navbar_element", userGeoLocation})
    const navbarRef = React.useRef()

    const [loginPopper, setLoginPopper] = React.useState(false)
    const [navbarShow, setNavbarShow] = React.useState(true)
    const [navbarDocked, setNavbarDocked] = React.useState(homePage)
    

    React.useEffect(()=>{
        window.addEventListener('scroll', throttle(()=>listenToScroll(), 200))

        return window.removeEventListener('scroll',  ()=>listenToScroll())
    }, [navbarDocked])


    const listenToScroll =  () => {
        
        const scrollPosition = document.body.scrollTop || document.documentElement.scrollTop 
        
        if(homePage && scrollPosition >= 100 && navbarDocked)setNavbarDocked(false)
        else if(homePage && scrollPosition < 100 && !navbarDocked)setNavbarDocked(true)
    }

    
    
    const handleLogin = ()=>{
        history.push('/login')
    }

    const handleGoHome = ()=>{
        history.replace("/")
    }
    
    const handleDashboardRedirect = ()=>{
        history.replace("/dashboard")
    }

    const handleChangeLanguage = (value) => () => {
        props.setUserGeoLocation({countryCode: value})
      }

    return (
        <>
            <Box 
                position={edit ? "absolute" : "fixed"}
                top={0}
                display="flex" 
                flexDirection="row" 
                alignItems="center" 
                justifyContent="space-between" 
                className={classNames({[classes.navbarDocked]: navbarDocked, [classes.navbar]: !navbarDocked})}
                width="100%" 
                zIndex={10}
                ref={navbarRef}
            >
                <Box px={3} display="flex" alignItems="center" className={classNames({[classes.logoDocked]: !navbarDocked, [classes.logo]: navbarDocked})} color="black">
                    {
                        !navbarDocked && <img height="45px" width="auto" alt="brdopara logo" className={classes.logoImage} src="https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/images%2FBrdoPara2-min.png?alt=media&token=fb9d4a02-e43b-41d0-8a90-695e7800ace0"/>
                    }
                  
                </Box>
                <Box display="flex">
                    {
                        navbarDocked &&
                            <Box display="flex" justifyContent="flex-start" alignItems="center" > 
                                <Box onClick={handleChangeLanguage("SI")} className={classNames(classes.countryIcon, {[classes.countryIconActive]: userGeoLocation.countryCode === "SI"})}>
                                    <Tooltip title="Slovenian"> 
                                    <img alt="flag" width="20px" height="20px" src="https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/countriesIcon%2Fslovenia.svg?alt=media&token=aa0fec1c-a5c4-4cb9-a5f0-99b76dd0c29d"/>
                                    </Tooltip>
                                </Box>
                                <Box onClick={handleChangeLanguage("HR")} className={classNames(classes.countryIcon, {[classes.countryIconActive]: userGeoLocation.countryCode === "HR"})}>
                                    <Tooltip title="Croatian"> 
                                    <img alt="flag" width="20px" height="20px" src="https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/countriesIcon%2Fcroatia.svg?alt=media&token=ee2c7064-0fd3-4748-9808-5ff2461cb45e"/>
                                    </Tooltip>
                                </Box>

                                {
                                    <Box onClick={handleChangeLanguage("RS")} className={classNames(classes.countryIcon, {[classes.countryIconActive]: userGeoLocation.countryCode === "RS"})}>
                                        <Tooltip title="Serbian"> 
                                        <img alt="flag" width="20px" height="20px" src="https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/countriesIcon%2Fserbia.svg?alt=media&token=051c7531-a18b-4b1e-b414-d2e0df59277c"/>
                                        </Tooltip>
                                    </Box>
                                }
                                
                                <Box onClick={handleChangeLanguage("EN")} className={classNames(classes.countryIcon, {[classes.countryIconActive]: userGeoLocation.countryCode === "EN"})}>
                                    <Tooltip title="English"> 
                                    <img alt="flag" width="20px" height="20px" src="https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/countriesIcon%2Funited-kingdom.svg?alt=media&token=690fc563-a7c7-4737-8e34-20438ef90e43"/>
                                    </Tooltip>
                                </Box>
                                
                            </Box>
                    }
                    
                    { homePage &&  props.auth !== "loading" &&
                        <>
                            {
                                auth ? 
                                    <Button onClick={handleDashboardRedirect} className={classNames({[classes.navButtonDocked]: navbarDocked, [classes.navButton]: !navbarDocked})}  disableRipple>{dictionary.myProfileButton}</Button>:
                                    <Button onClick={handleLogin} className={classNames({[classes.navButtonDocked]: navbarDocked, [classes.navButton]: !navbarDocked})}  disableRipple>{dictionary.loginButton}</Button>
                            }
                        </>
                       
                    }
                    { !homePage && <Button onClick={handleGoHome} className={classNames({[classes.navButtonDocked]: navbarDocked, [classes.navButton]: !navbarDocked})}  disableRipple>{dictionary.homeButton}</Button>}
                </Box>
            </Box>

        </>
        
    )
}
const mapStateToProps = ({ auth, activeDocumentPage, userGeoLocation}) => {
  return {
    auth,
    activeDocumentPage,
    userGeoLocation,
    
  }
}

export default withRouter(connect(mapStateToProps, actions)(NavbarElement))

