import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';

import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles( theme => createStyles({
   
  
}))

const CustomInputComponent = ({
  label,
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  width,
  style,
  ...props
}) =>{ 

  const classes = useStyles()

  return (
    <Box width={width ? width : "100%"} {...style}>
      <FormControl error={(errors[field.name] && touched[field.name]) ? true : false} fullWidth={props.fullWidth ? props.fullWidth : true}>
        <InputLabel 
            htmlFor="component-error"
            classes={{root: classes.inputLabel, focused: classes.inputLabelFocused}}
        >
          {label}
        </InputLabel>
        <Input classes={{input: classes.underline, root: classes.input}} variant="contained" {...props} {...field}/>
        {errors[field.name] &&
          touched[field.name] && 
            <FormHelperText id="component-error-text">{errors[field.name]}</FormHelperText>
        }
      </FormControl>
    </Box>
  )
}

export default CustomInputComponent
