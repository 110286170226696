import {

  FETCH_PAGE_ELEMENTS,

  ADD_PAGE_ELEMENT,
  EDIT_PAGE_ELEMENT,
  EDIT_PAGE_ELEMENT_CONTENT,
  EDIT_PAGE_ELEMENT_CHILDREN,
  DELETE_PAGE_ELEMENT,

  SET_SELECTED_ELEMENT,

  ADD_CHANGE_PAGE_CHANGE_HISTORY,

} from './../../types'

import _ from 'lodash'
import uniqid from 'uniqid'

import * as supportFunctions from './../../supportFunctions'
import * as firebase from './../../../../config/firebase'
import elementsConstructor from './../../../../webElements/elementsConstructor'

const createNewElement = (type)=>{
  let element = {}
  element.id = `${type}_${uniqid.time()}`
  element.type = type

  elementsConstructor[type] && elementsConstructor[type].properties.forEach( property =>{
    element = {
      ...element,
      [property.id]: property.default

    }
  })

  return element
}

export const addNewElement = (pageId, parentId, parent, newElement, elementPosition) => async dispatch =>{
  /// BUILD NEW PARENT CHILDREN ARRAY
  let parentChildren = []
  let pageChanges = {}

  try{

    if(parent.elementChildren){
      parentChildren = elementPosition !== -1 ? [
        ...parent.elementChildren.slice(0, elementPosition),
        newElement.id,
        ...parent.elementChildren.slice(elementPosition),
      ] : [
        ...parent.elementChildren,
        newElement.id
      ]
    }else{
      parentChildren = [newElement.id]
    }

    // check if element has initChildren
    if(elementsConstructor[newElement.type] && elementsConstructor[newElement.type].initChildren){
      let elementChildrenIds = []
      let elementsChildren = {}
      let initElementChildren = {}
      // generate init childrens
      elementsConstructor[newElement.type].initChildren.forEach((obj)=>{
        let elementChild = createNewElement(obj.type)
        elementChild.name = `${obj.placement} element`
        elementChild.parent = newElement.id

        initElementChildren = {
          ...initElementChildren,
          [obj.placement]: elementChild.id
        }

        elementChildrenIds = [...elementChildrenIds, elementChild.id]
        elementsChildren = {...elementsChildren, elementChild}

        pageChanges = {
          ...pageChanges,
          [`/elements/${elementChild.id}`]: elementChild
        }
      })
      newElement.initElementChildren = initElementChildren
      newElement.elementChildren = elementChildrenIds

    }

    //check if element is global element or if its parent is
    if(newElement.global || parent.global){
      // if parent element is global, his child element must be global element too
      if(parent.global) newElement.global = true

      if(newElement.parent === "root"){
        pageChanges = {
          ...pageChanges,
          [`pages/${pageId}/elements/root/elementChildren`]: parentChildren,
          [`globalElements/${newElement.id}`]: newElement
        }
      }else{
        pageChanges = {
          ...pageChanges,
          [`/${parentId}/elementChildren`]: parentChildren,
          [`/${newElement.id}`]: newElement
        }
      }
    }else{
      pageChanges = {
        ...pageChanges,
        [`/elements/${parentId}/elementChildren`]: parentChildren,
        [`/elements/${newElement.id}`]: newElement
      }
    }
    console.log(pageChanges)
    dispatch({
      type: ADD_CHANGE_PAGE_CHANGE_HISTORY,
      payload: pageChanges
    })

    dispatch({
      type: ADD_PAGE_ELEMENT,
      pageId,
      parentId,
      parentChildren,
      newElement
    })

    return true
  }catch(error){

    console.log(error)
    return false
  }


}

export const editElement  =  (pageId, parentId, parent, editedElement, elementPosition) => async (dispatch) => {
  //console.log(editedElement)
  try {

    /// set element position ---> if elementPosition is -1, put element at the end
    let parentChildren = parent && parent.elementChildren ? parent.elementChildren : null
    let currElementPosition = parent && parent.elementChildren && parent.elementChildren.indexOf(editedElement.id)
    
    if(parent && parent.elementChildren && currElementPosition !== elementPosition){
      parentChildren =  parent.elementChildren.map((value, i)=> value === editedElement.id ? null : value  )
      parentChildren = [...parentChildren.slice(0, elementPosition), editedElement.id, ...parentChildren.slice(elementPosition)]
      parentChildren = parentChildren.filter((value, i)=> value !== null )
    }

    if(parentId){
      dispatch({
        type: ADD_CHANGE_PAGE_CHANGE_HISTORY,
        payload: {
          [`/elements/${editedElement.id}`]: editedElement,
          [`/elements/${parentId}/elementChildren`]: parentChildren,
        }
      })
    }else{
      dispatch({
        type: ADD_CHANGE_PAGE_CHANGE_HISTORY,
        payload: {
          [`/elements/${editedElement.id}`]: editedElement,
        }
      })
    }

    dispatch({
      type: EDIT_PAGE_ELEMENT,
      pageId,
      parentId,
      parentChildren,
      editedElement,

    })

    return true
  } catch(error) {
    console.log(error)
    return false
  }

}

export const editElementContent = (pageId, editedElement) => async dispatch => {
  try {
    /// set element position ---> if elementPosition is -1, put elemenet at the end

    /*
    await firebase.pagesRef.child(pageId).update({
        [`/elements/${editedElement.id}`]: editedElement,
      })
    */
    dispatch({
      type: ADD_CHANGE_PAGE_CHANGE_HISTORY,
      payload: {
        [`/elements/${editedElement.id}`]: editedElement,
      }
    })

    dispatch({
      type: EDIT_PAGE_ELEMENT_CONTENT,
      pageId,
      editedElement,
    })

    return true
  } catch(error) {
    console.log(error)
    return false
  }
}


export const copyElement = (selectedElement, selectedPage, copyFromPageId, copyToPageId, copyToElementId) => async (dispatch, getState) =>{
  console.log("copyElement")
  let elements = _.cloneDeep(getState().elements)
  if(copyFromPageId !== copyToPageId) {
    let copyToPageIdElements = _.cloneDeep(getState().webpages[copyFromPageId].elements)
    delete copyToPageIdElements.root
    elements = {...elements, ...copyToPageIdElements}
  }
  let copiedElement = _.cloneDeep(elements[selectedElement])
  //console.log({elements, stateElementes: getState().elements, copiedElement, selectedElement})
  /*
  let copiedElement = copyFromPageId === copyToPageId ?
                                    _.cloneDeep(getState().elements[selectedElement]):
                                    _.cloneDeep(getState().webpages[copyFromPageId].elements[selectedElement])
                                    */
  // if element parent  has changed, it sets new one equal to copyToElementId
  //console.log({copiedElement, selectedElement, selectedPage, copyFromPageId, copyToPageId, copyToElementId, elements: getState().webpages[selectedPage].elements})
  copiedElement.parent = copyToElementId

  let copiedElementChildren = supportFunctions.copyElementTree(_.cloneDeep(elements), copiedElement, null)
  let rootElementId = supportFunctions.findRootElementId(copiedElementChildren)

  let newCopiedElements = {}

  Object.entries(copiedElementChildren).forEach(([elementId, element])=>{

    newCopiedElements = {...newCopiedElements, ["/elements/"+elementId]:element}
  })
  console.log(newCopiedElements)
  /// build new parent child tree, with new child id
  let parent = getState().elements[copyToElementId]
  console.log({parent})
  let parentChildren = parent.elementChildren ? [...parent.elementChildren , rootElementId] : [rootElementId]

  // if copying to another page and selecting root element as parent, there is wrrong root element in elements store. So we need to fetch right one from webpages store.
  if(selectedPage !== copyToPageId && copyToElementId === "root"){
    parentChildren = [...getState().webpages[copyToPageId].elements[copyToElementId].elementChildren, rootElementId]
  }

  if(copiedElement.global){
    if(copyToElementId === "root")parentChildren = [...getState().webpages[copyToPageId].elements[copyToElementId].elementChildren, selectedElement]
    /*
    await firebase.pagesRef.child(copyToPageId).update({
      [`/elements/${copyToElementId}/elementChildren`]: parentChildren,
    })
    */

    dispatch({
      type: ADD_CHANGE_PAGE_CHANGE_HISTORY,
      payload: {
        [`/elements/${copyToElementId}/elementChildren`]: parentChildren,
      }
    })
  }else{
    /*
    firebase.pagesRef.child(copyToPageId).update({
      [`/elements/${copyToElementId}/elementChildren`]: parentChildren,
      ...newCopiedElements
    }).then(()=>{
      dispatch({
        type: EDIT_PAGE_ELEMENT,
        parentId: copyToElementId,
        parentChildren,
        editedElement: getState().elements[copyToElementId],

      })
      dispatch({
        type: FETCH_PAGE_ELEMENTS,
        payload: copiedElementChildren
      })

      dispatch({
        type: UPDATE_PAGE,
        pageId: selectedPage,
        payload: copiedElementChildren
      })
    })
    */
    parent.elementChildren = parentChildren
    let updatedElements = {
      parent,
      ...copiedElementChildren
    }
    dispatch({
      type: ADD_CHANGE_PAGE_CHANGE_HISTORY,
      payload: {
        [`/elements/${copyToElementId}/elementChildren`]: parentChildren,
        ...newCopiedElements
      }
    })

    dispatch({
      type: FETCH_PAGE_ELEMENTS,
      payload: updatedElements
    })



  }

  return rootElementId
}



export const deleteSelectedElement = (selectedElementId) => async (dispatch, getState) => {

  let state = getState()

  let selectedElement = state.elements[selectedElementId]
  let selectedPage = state.selectedPage

  let parentId = state.elements[selectedElementId].parent
  let parent = state.elements[parentId]

  /// REMOVE ELEMENT FROM PARENT
  let parentChildren = parent.elementChildren.filter((child)=> child !== selectedElementId)

  /// FIND ALL ELEMENT CHILDREN
  let selectedElementChildren = {}
  if(selectedElement.elementChildren){
    supportFunctions.getAllElementChildren(state.elements, selectedElement, []).forEach((childId)=>{
      selectedElementChildren = {...selectedElementChildren, [`/elements/${childId}`]: null}
    })
  }
  /*
  firebase.pagesRef.child(selectedPage).update({
      ...selectedElementChildren,
      [`/elements/${selectedElementId}`]: null,
      [`/elements/${parentId}/elementChildren`]: parentChildren,
  }).then(()=>{
    dispatch({
      type: SET_SELECTED_ELEMENT,
      payload: parentId
    })

    dispatch({
      type: DELETE_PAGE_ELEMENT,
      elementId:  selectedElement,
      pageId: selectedPage,
      parentId,
      parentChildren
    })

    dispatch({
      type: SET_ACTIVE_MODAL,
      payload: ""
    })
  }).catch((error)=>{
    console.log(error)
  })
  */
  dispatch({
    type: ADD_CHANGE_PAGE_CHANGE_HISTORY,
    payload: {
      ...selectedElementChildren,
      [`/elements/${selectedElementId}`]: null,
      [`/elements/${parentId}/elementChildren`]: parentChildren,
    }
  })

  dispatch({
    type: SET_SELECTED_ELEMENT,
    payload: parentId
  })

  dispatch({
    type: DELETE_PAGE_ELEMENT,
    elementId:  selectedElement,
    pageId: selectedPage,
    parentId,
    parentChildren
  })


}


// moveFor(int) ---> move element for X steps right if positive or left if negative
export const switchElementPositionInsideParent = (pageId, elementId, moveFor) => async (dispatch, getState) => {
  let element = getState().elements[elementId]
  let elementParent = getState().elements[element.parent]

  let parentChildren = [...elementParent.elementChildren]


  let elementCurrentPosition = parentChildren.indexOf(elementId)

  // remove element from current position
  parentChildren[elementCurrentPosition] = null

  let sliceArrayLeft,sliceArrayRight

  if(moveFor > 0){
    sliceArrayLeft = elementCurrentPosition+moveFor+1
    sliceArrayRight = elementCurrentPosition+moveFor+1
  }else{
    sliceArrayLeft = elementCurrentPosition+moveFor
    sliceArrayRight = elementCurrentPosition+moveFor
  }

  sliceArrayLeft = sliceArrayLeft < 0 ? 0 : sliceArrayLeft
  sliceArrayLeft =  sliceArrayLeft > parentChildren.length ? parentChildren.length : sliceArrayLeft


  sliceArrayRight = sliceArrayRight < 0 ? 0 : sliceArrayRight
  sliceArrayRight = sliceArrayRight > parentChildren.length ? parentChildren.length : sliceArrayRight


  //combine arrays and insert element on its new position
  parentChildren = [...parentChildren.slice(0, sliceArrayLeft),
                    elementId,
                    ...parentChildren.slice(sliceArrayRight, parentChildren.length)]


  //remove null values
  parentChildren = parentChildren.filter(o=>o)

  /*
  firebase.pagesRef.child(pageId).update({
      [`/elements/${elementParent.id}/elementChildren`]: parentChildren,
  }).then(()=>{
    dispatch({
      type: EDIT_PAGE_ELEMENT_CHILDREN,
      elementId: elementParent.id,
      elementChildren: parentChildren,
    })
  }).catch((err)=>{
    console.log(err)
  })
  */
  dispatch({
    type: ADD_CHANGE_PAGE_CHANGE_HISTORY,
    payload: {
      [`/elements/${elementParent.id}/elementChildren`]: parentChildren,
    }
  })

  dispatch({
    type: EDIT_PAGE_ELEMENT_CHILDREN,
    elementId: elementParent.id,
    elementChildren: parentChildren,
  })
}

export const linkElementToGlobalElement = (selectedElementId, globalElementId) => (dispatch, getState) => {
  const elements = getState().elements
  const selectedElement = elements[selectedElementId]
  const selectedElementParentId = elements[selectedElementId].parent 
  
  //console.log(selectedElementId, globalElementId)
  if(!selectedElementParentId){
    throw new Error("Selected element has no parent!")
  }

  const selectedElementParent = elements[selectedElementParentId]
  let selectedElementParentChildren = [...selectedElementParent.elementChildren]

  let index = selectedElementParentChildren.indexOf(selectedElementId)
  selectedElementParentChildren[index] = globalElementId

  let changes = {
    [`/elements/${selectedElementParentId}/elementChildren`]: selectedElementParentChildren,
    [`/elements/${selectedElementId}`]: null
  }

  //if element parent has init element children, need to change it accordingly
  if(selectedElementParent.initElementChildren){
    let initElementChildren = {...selectedElementParent.initElementChildren}
    let selectedElementLocation = _.findKey(initElementChildren, value => value === selectedElementId)

    initElementChildren[selectedElementLocation] = globalElementId
    changes[`/elements/${selectedElementParentId}/initElementChildren`] = initElementChildren

  }
  console.log()

  dispatch({
    type: ADD_CHANGE_PAGE_CHANGE_HISTORY,
    payload: changes
  })

  dispatch({
    type: SET_SELECTED_ELEMENT,
    payload: globalElementId
  })

  dispatch({
    type: EDIT_PAGE_ELEMENT_CHILDREN,
    elementId: selectedElementParentId,
    elementChildren: selectedElementParentChildren,
  })

  return true
}