import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router'

import * as defaultCss from './webElements/defaultCss'

import classNames from 'classnames'
import { useCookies } from 'react-cookie';

import { analytics } from './config/firebase'

const styles = theme => ({

  ...defaultCss.styles(theme),
  root: {
    position: "fixed",
    zIndex: 5000,
    bottom: 0,
    minHeight: 70,
    width: "100%",
    background: theme.palette.primary.main,
    color: "white",

    padding: theme.spacing(2)
  },
  row:{
    display:"flex",
    flexDirection:"row",
    justifyContent:"space-around",
    alignItems: "center",
    width: "100%",

    flexWrap: "wrap"
  },
  text:{
    color: "black",
    fontSize: 20,
    fontWeight: 300,
    padding: theme.spacing(1)
  },
  button:{
    borderRadius: 4,
    fontSize: 20,
    fontWeight: 200,
    textTransform: "initial",

    width: 150,
    
  }

});

function CookieBar(props) {
  const { classes, location } = props;
  const [cookies, setCookie] = useCookies(['user_allow_cookies_consent'])

  /// INITIALIZE GA ON PAGE LOAD
  React.useEffect(()=>{
    if(cookies['user_allow_cookies_consent']){
      analytics.setAnalyticsCollectionEnabled(true)
    }else{
      analytics.setAnalyticsCollectionEnabled(false)
    }
  },[])
  // SEND PAGE VIEW ON EVERY LOCATION CHANGE
  React.useEffect(()=>{
    if(cookies['user_allow_cookies_consent']){
      analytics.logEvent('pageview', {page: window.location.pathname + window.location.search})
    }
  },[location])

  const allowCookies = ()=>{
    setCookie('user_allow_cookies_consent', true, {maxAge: 315569260})
    window.location.reload()
  }

  const goTo = (path)=>{
    props.history.push(path)
  }

  if(!cookies['user_allow_cookies_consent']){
    return (
      <div  className={classNames(classes.root, classes.column)}>

        <div className={classNames(classes.row)}>
          <Typography className={classes.text} >Z obiskom in uporabo spletnega mesta soglašate z uporabo in beleženjem piškotkov. <a href="/info?type=cookies">Več o piškotkih</a></Typography>
          
          <Button onClick={allowCookies} className={classes.button} color="secondary" variant="contained">V redu</Button>
          
        </div>

      </div>
    );
  }else return null

}

CookieBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(CookieBar))
