
import {
   SET_ACTIVE_MODAL,
   SET_PREVIOUS_MODAL,
   CLOSE_ACTIVE_MODAL,
   SET_MODAL_DATA
 } from "./../../actions/types";

import {cloneDeep} from "lodash"

export default (state = {active: "", history:[], data: {}}, action) => {
  let currState = cloneDeep(state)

  switch (action.type) {
    case SET_ACTIVE_MODAL:

      //do nothing if modal is already active
      if(currState.active !== action.payload){
        currState.history.push(currState.active);
        currState.active = action.payload
      }

      return currState
    case SET_PREVIOUS_MODAL:

      //activate previously active modal and remove it from array history
      if(currState.active !== ""){
        let prevousActiveModal = currState.history.pop();
        currState.active = prevousActiveModal
        return currState
      }else return currState
    case CLOSE_ACTIVE_MODAL:
      //closes active modal and delete all history
      return {active: "", history:[], data: {}}

    case SET_MODAL_DATA:
      
      if(action.fileId){
        currState.data.files = {}
        currState.data.files[action.fileId] = action.progress
      }
      currState.data = {...currState.data, ...action.payload}
      return currState
    default:
      return currState;
  }
};
