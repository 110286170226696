import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';

import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles( theme => createStyles({
 
}))

const banners = {
    
    melbet:{
        profile: {
            laptop: "https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/homePage%2F900X300NBA130BONUSA.jpg?alt=media&token=bd0f639f-4fcc-4e14-88b4-b637b26d3be9",
            desktop: "https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/homePage%2F1000X150NBA.jpg?alt=media&token=c2829315-640d-4d08-ac23-6ae914b56d0c",
            mobile: "https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/homePage%2F360X270NBA.jpg?alt=media&token=5af5bbb8-9b1c-490d-8661-9afe1b68fcd3", 
        },

        dashboard: {
            laptop: "https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/homePage%2F900X300NBA130BONUSA.jpg?alt=media&token=bd0f639f-4fcc-4e14-88b4-b637b26d3be9",
            desktop: "https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/homePage%2F1100x320NBA.jpg?alt=media&token=0befb385-5f26-4f46-88c2-4abdf5226c2e",
            mobile: "https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/homePage%2F360X270NBA.jpg?alt=media&token=5af5bbb8-9b1c-490d-8661-9afe1b68fcd3", 
        },

        bets: {
            laptop: "https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/homePage%2F900X300NBA.jpg?alt=media&token=ea918277-5908-4823-bded-90d0f1823cb2",
            desktop: "https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/homePage%2F1000X150NBA.jpg?alt=media&token=c2829315-640d-4d08-ac23-6ae914b56d0c",
            mobile: "https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/homePage%2F360X270NBA.jpg?alt=media&token=5af5bbb8-9b1c-490d-8661-9afe1b68fcd3", 
        }
    }   
    
}

const advertisers = {
    melbet:{
        link: "https://refpakrtsb.top/L?tag=d_1028431m_18639c_brdopara1&site=1028431&ad=18639&r=user/registration.php"
    }   
}

function AdvertisementElement({
    advertiser,
    location,
    height,
    width
}){

  const classes = useStyles()
  const theme = useTheme()

  const [loading, setLoading] = React.useState(true)
  const [selectedAdvertiser, setSelectedAdvertiser] = React.useState(advertiser || "melbet")

  return (
    
    <Box onClick={()=> advertisers["melbet"] && window.open(advertisers["melbet"].link)} height={height || "100%"} width={width || "100%"} style={{cursor: "pointer"}}>
        {
           banners[advertiser][location] &&
           <Fade in={!loading}>
               <picture>
                <source media="(min-width: 1600px)" srcset={banners[advertiser][location].desktop}/>
                <source media="(min-width: 1000px)" srcset={banners[advertiser][location].laptop}/>
                <source media="(max-width: 999px)" srcset={banners[advertiser][location].mobile}/>
                <img 
                    onLoad={()=> setLoading(false)}
                    alt="background"  
                    src={banners[advertiser][location].laptop}
                    draggable="false"
                    height="100%"
                    width="100%"
                />
            </picture>
           </Fade>
           
        }
       
    </Box>
  )
  
}


export default AdvertisementElement
