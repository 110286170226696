import uniqid from 'uniqid'

import {

  SET_MODAL_DATA
} from './types'

import * as firebase from './../../config/firebase'
import elementsConstructor from './../../webElements/elementsConstructor'

export const copyElementTree = (elements, copiedElement, copiedElements)=>{
  if(!copiedElements){
    /// INIT //// ONLY ON FIRST ENTRY
    if(copiedElement.id !== "root"){
      let newElementId = copiedElement.id.split("_")
      newElementId[newElementId.length-1] = uniqid.time()
      newElementId = newElementId.join("_")

      copiedElement.id = newElementId
      copiedElements = {[newElementId]: copiedElement}
    }else{
      copiedElements = {"root": copiedElement}
    }

  }


  copiedElement.elementChildren && copiedElement.elementChildren.forEach((childId)=>{
    let child = {...elements[childId]}
    if(child.global)return

    let childParent = {...copiedElements[copiedElement.id]}
    child.parent = copiedElement.id

    let newChildId = childId.split("_")
    newChildId[newChildId.length-1] = uniqid.time()
    newChildId = newChildId.join("_")
    child.id = newChildId

    //console.log(copiedElements, copiedElement.id)

      let index = childParent.elementChildren.indexOf(childId)
      childParent.elementChildren[index] = newChildId



    copiedElements = {...copiedElements, [newChildId]: child}


    if(child && child.elementChildren){

       copiedElements = {...copiedElements, ...copyElementTree(elements, child, copiedElements)}
    }

  })

  return copiedElements
}

export const findRootElementId = (elementChildren)=>{

  let rootElementId = ""

  Object.entries(elementChildren).forEach(([elementId, element])=>{
    if(!elementChildren[element.parent]){
      rootElementId = elementId
      return true
    }
  })

  return rootElementId
}

export const getAllElementChildren = (elements, copiedElement, copiedElementChildren)=>{

  copiedElement.elementChildren.forEach((childId)=>{
    copiedElementChildren.push(childId)
    let child = elements[childId]

    if(child && child.elementChildren){
      getAllElementChildren(elements, child, copiedElementChildren)
    }

  })
  return copiedElementChildren
}


export const getAllElementChildrenObjects = (elements, copiedElement, copiedElementChildren)=>{

  copiedElement.elementChildren.forEach((childId)=>{
    let child = elements[childId]
    copiedElementChildren  = {...copiedElementChildren, [childId]: child}

    if(child.elementChildren){
      getAllElementChildrenObjects(elements, child, copiedElementChildren)
    }

  })
  return copiedElementChildren
}



export const saveFileToFireStore = async (fileId, file, type, dispatch, uid) =>{
  let metadata = {
    cacheControl: 'public,max-age=31536000',
  };

  let uploadTask = null

  if(type === "image"){
     uploadTask = firebase.storageRef.child('images').child(file.name).put(file, metadata)
  }else if(type === "file"){
     uploadTask = firebase.storageRef.child('files').child(file.name).put(file, metadata)
  }else if(type === "icon"){
     uploadTask = firebase.storageRef.child('icons').child(uid).put(file, metadata)
  }else{
     uploadTask = firebase.storageRef.child(file.name).put(file, metadata)
  }

  let promise = new Promise((resolve, reject) => {
    uploadTask.on('state_changed', // or
    function(snapshot) {
     // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
     var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

     dispatch({
       type: SET_MODAL_DATA,
       fileId,
       progress,

     })

    }, function(error) {
        console.log(error)
    },function(){
      let  downloadURL = uploadTask.snapshot.ref.getDownloadURL()

      resolve(downloadURL);
    })
  })

  return await promise
}

export const createNewElement = (type, name, parent)=>{
  let element = {}
  element.id = `${type}_${uniqid.time()}`
  element.type = type
  
  element.parent = parent

  elementsConstructor[type] && elementsConstructor[type].properties.forEach( property =>{
    element = {
      ...element,
      [property.id]: property.default

    }
  })

  element.name = name

  return element
}