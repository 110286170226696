import {
  FETCH_ALL_CUSTOM_CONTAINERS,
  FETCH_PAGE_ELEMENTS,
  SET_ACTIVE_MODAL,
  CLEAR_CHANGES_PAGE_CHANGE_HISTORY,
  CLOSE_ACTIVE_MODAL
} from './../../types'

import uniqid from 'uniqid'

import _ from 'lodash'

import * as firebase from './../../../../config/firebase'

import {saveFileToFireStore} from "./../../supportFunctions"

export const fetchAllCustomContainers = () => async dispatch =>{
  console.log("fetchAllCustomContainers")

  let customContainers = await firebase.customContainersRef.once("value")

  let elements = {}

  Object.entries(customContainers).forEach(([customContainerId, customContainer])=>{
    elements = {...elements, ...customContainer.elements}
  })

  dispatch({
    type: FETCH_PAGE_ELEMENTS,
    payload: elements
  })

  dispatch({
    type: FETCH_ALL_CUSTOM_CONTAINERS,
    payload: customContainers.val()
  })

}

export const addNweCustomContainer = (containerName, rootElement)=> async dispatch =>{
  console.log("addNweCustomContainer")

  let rootElementId = `${rootElement.type}_${uniqid.time()}`

  let newContainer = {
    name: containerName,
    rootElementId: rootElementId,
    elements: {
      [rootElementId]: rootElement
    }
  }

  try{
    await firebase.customContainersRef.push().set(newContainer)
  }catch(err){
    console.log(err)
  }

}

function setValue(fields, val, obj) {
  console.log(fields)
  var result = obj;
  for (var i = 0, n = fields.length; i < n && result !== undefined; i++) {
    var field = fields[i];
    if (i === n - 1) {
      result[field] = val;
    } else {
      if (typeof result[field] === 'undefined' || !_.isObject(result[field])) {
        result[field] = {};
      }
      result = result[field];
    }
  }
}

export const saveCustomContainer = () => async (dispatch, getState) =>{
  console.log("saveCustomContainer")


  dispatch({
    type: SET_ACTIVE_MODAL,
    payload: "savingPageModal"
  })

  const selectedCustomContainer = getState().selectedCustomContainer
  let pageChangeHistory = _.cloneDeep(getState().pageChangeHistory)

  pageChangeHistory = pageChangeHistory.changes.reduce((acc, change)=>{

    Object.keys(acc).forEach( pathA =>{
      Object.keys(change).forEach( pathC =>{

        if(pathC.includes(pathA) && pathC.length > pathA.length){
          //console.log({pathC, pathA, "pathCPathA": pathC.replace("/elements/","").split("/"), value:change[pathC],acc })
          setValue(pathC.replace(pathA+"/","").split("/"), _.clone(change[pathC]), acc[pathA])
          delete change[pathC]
        }else if(pathA.includes(pathC) && pathC.length < pathA.length) delete acc[pathA]
      })
    })

    return {...acc, ...change}
  }, {})


  try{

    let elementsChanges = {}

    for await (let key of Object.keys(pageChangeHistory)){
      /// element id is always in 3th place in array
      let elementId = key.split("/")[2]

      //check if there are some files to upload
      if(pageChangeHistory[key] && pageChangeHistory[key].files){
        for await (let [id, file] of Object.entries(pageChangeHistory[key].files)){
          if(file.obj){

            // uploads file to server
            let fileUrl = await saveFileToFireStore(`${id}__${elementId}`, file.obj, file.type, dispatch,)

            // save file url to newElement
            pageChangeHistory[key].files[id].src = fileUrl

            // add file to files list in
            let pushImageRef = firebase.imagesRef.push()
            let fileId = pushImageRef.getKey()
            pushImageRef.set({src: fileUrl, name: file.obj.name})

            pageChangeHistory[key].files[id].fileListId = fileId

            // removes file obj from element
            delete file.obj

          }
        }

      }

      //// TODO: updating entire object ... u could update just changed props
      elementsChanges = {...elementsChanges, [`/customContainers/${selectedCustomContainer}${key}`]: pageChangeHistory[key]}

    }

    await firebase.databaseRef.update(elementsChanges)

    dispatch({
      type: CLEAR_CHANGES_PAGE_CHANGE_HISTORY
    })

  }catch(err){
    console.log(err)
  }

  dispatch({
    type: CLOSE_ACTIVE_MODAL,
  })

}
