import React from 'react'
import * as actions from '../../../../redux/actions'
import { connect } from "react-redux"

import classNames from 'classnames'

import { makeStyles, createStyles, useTheme, withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Autocomplete from '@material-ui/lab/Autocomplete'

import MenuItem from  '@material-ui/core/MenuItem';
import InputBase from '@material-ui/core/InputBase'
import CloseIcon from '@material-ui/icons/Close';
import CommentIcon from '@material-ui/icons/Comment';
import EditIcon from '@material-ui/icons/Edit';

import CustomSnackbarLayout from './CustomSnackbarLayout'
import CustomIcon from '../../../components/icons/CustomIcon'
import editWrapper from '../../../default/wrappers/editWrapper'
import CustomButton from './CustomButton'
import useDictionary from '../../../../hooks/useDictionary';
import timestampFormatter from '../../../../helpers/timestampFormatter'
import useMediaBreakPoint from '../../../../hooks/useMediaBreakPoint'

const TITLE_HEIGHT = 60

const useStyles = makeStyles( theme => createStyles({
  container:{
    [theme.breakpoints.down('sm')]:{
  
    }
  },
  /// TEXT FILED SELECT 

  textFieldMenuPaper:{
    top: "0!important",
    left: "0!important",
  
    minHeight: "100vh!important",
    minWidth: "100vw!important",
    
    background: theme.palette.background.primary,
  
    color: "white",
  
  },
  
  menuList:{
    width: "100%",
    
  },

  menuItem:{
    borderBottom: "1px solid gray",
    borderColor: "black!important",
    width: "100%"
  },

  menuItemBackButton:{
    textAlign: "center",
    background: theme.palette.primary.main,
    color: "black",
    fontWeight: 400,
  },

  /// INPUT STYLES
  cssLabel: {
    color : `${theme.palette.text.secondary} !important`,
    fontWeight: 200,
    
  },

  cssOutlinedInput: {
    color: `${theme.palette.text.secondary} !important`,
    borderRadius: 4,
    
    fontWeight: 200,

    '&$cssFocused $notchedOutline': {
      color: `${theme.palette.text.secondary} !important`,
      borderColor: `${theme.palette.text.secondary} !important`,
    }
  },

  cssFocused: {
    color: `${theme.palette.text.secondary} !important`,
  },

  notchedOutline: {
    borderWidth: '1px',
    borderColor: `${theme.palette.text.secondary} !important`,
    fontWeight: 200,
    color: `${theme.palette.text.secondary} !important`,

  },

  clearIndicator:{
    color: theme.palette.error.main
  },

  popupIndicator:{
    color: theme.palette.text.secondary,
    marginRight: 4,
  },


  paper:{
   

    color: theme.palette.text.secondary,
    background: theme.palette.background.dark,
    fontWeight: 200,
    borderRadius: 4,
    padding: 0,

    
  },


  option:{
    borderBottom: "1px solid "+theme.palette.background.transparent,
    "&:hover":{
      background: "#0f0f0f",
      borderColor: theme.palette.primary.main
    }
  },

  //////////////////

 

  title:{
    padding: 0
  },

  buttonSend:{
    height: "100%",
    background: theme.palette.primary.main,
    borderRadius: 8,
    color: "white",
    fontSize: 30
  },
  ellipsis:{
    maxWidth: "200px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  winnerButtonActive:{
    background: theme.palette.primary.main,
    color: theme.palette.text.primary,
    
  },
  winnerButton:{
    cursor:"pointer",
    color: theme.palette.text.secondary,

    '&:hover':{
      background: theme.palette.primary.main,
      color: theme.palette.text.primary,
      transform: 'scale(0.98)',
      transitions: "all 0.5s ease-in-out"

    }
  
  },

  commentBoxContainer:{
    height: 0,
    opacity: 0,

    transition: "all 0.2s ease-in-out"
  },
  commentBoxContainerActive:{
    opacity: 1,
    height: 150,
  
  },

  inputPlaceholder:{
    '&::placeholder': {
      fontSize: "clamp(1em, 2vw, 1.2em)",
      color: "black"
    }
  },

  mobileFontWeight:{
    [theme.breakpoints.down('sm')]:{
        fontWeight: 400
    }
}
  
}))

const CustomInput = withStyles(theme =>({
  root:{
     
     
  },
  input:{
      paddingTop: "4px",
      fontSize: "clamp(0.8em, 3vw, 1em)",
      fontWeight: 400,
      
      textAlign: "center",
      padding: 0,
  },
}))(InputBase)

const CustomTextArea = withStyles(theme =>({
  root:{
    padding: theme.spacing(1),
    width: "100%",
    height: "100%",
  },
  input:{
      color: theme.palette.text.secondary,
      fontSize: 16,
      fontWeight: 400,
      width: "100%",
      height: "100%",
      textAlign: "left"
  }
}))(InputBase)


const ComboBox = (props)=>{
  
  const {name, label, options, onChange, disabled, selectedValue, loading, renderOption, inputRootClass, disableClearable} = props
  const classes = useStyles()
  const theme = useTheme()
  const breakpoint = useMediaBreakPoint()

  const [value, setValue] = React.useState(selectedValue ? selectedValue : "")
 
  React.useEffect(()=>{
    if(!selectedValue) setValue("")
    else setValue(options.find((o)=> o.value === selectedValue ))
  }, [options, selectedValue])

  const handleOnChange = (e, newValue)=>{  
    onChange(newValue)
  }

  const handleSelectChange = (e)=>{  
    if(e.target.value) onChange({value: e.target.value})
  }
  
  return (
    <Box width={"100%"}>
        {
          (breakpoint === "xs" || breakpoint === "sm") ? (
           
             <TextField
                select
                label={label}
                value={selectedValue || ""}
                variant="outlined"
                fullWidth
                onChange={handleSelectChange}
                disabled={loading === name}
                InputLabelProps={{
                  classes:{
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                  },
                }}
                InputProps={{
                  className: classes.input,
                  classes:{
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                  endAdornment: (
                    <React.Fragment>
                      {loading === name ? <CircularProgress size={20} /> : null}
                    </React.Fragment>
                  ),
                }}
                SelectProps={{
                  MenuProps:{
                    classes:{
                      paper: classes.textFieldMenuPaper,
                      list: classes.menuList
                    }
                  }
               
                }}
              >
                
                {
                  options && options.map(option => 
                    <MenuItem className={classes.menuItem} key={option.value} value={option.value}>
                      {(renderOption && renderOption(option, {})) || option.label}
                    </MenuItem> 
                  )
                }
               
                <MenuItem className={classes.menuItemBackButton} value={null}>
                  <Typography variant="h2">
                    Nazaj
                  </Typography>
                </MenuItem>
               
              </TextField>
           
          ):(
            <Autocomplete
              id={name}
              options={options}
              getOptionLabel={option => option.label ? option.label : ""}
              disableClearable={disableClearable}
              openOnFocus={true}
              renderOption={renderOption ? renderOption : 
                (option, { selected }) => (
                  <Box width="100%" height="100%" fontSize="1em" display="flex" justifyContent="center" flexDirection="column" >
                    <Typography variant="body2" noWrap>{option.label} </Typography> 
                  </Box>)
              }
              loading={loading === name}
              disabled={disabled}
              getOptionDisabled={props.getOptionDisabled}
              value={value}
              
              classes={{
                clearIndicator: classes.clearIndicator,
                popupIndicator: classes.popupIndicator,
                paper: classes.paper,
                option: classes.option,

              }}
              onChange={handleOnChange}
              renderInput={params => (
                <TextField
                  {...params}
                  label={label}
                  variant="outlined"
                  disabled={breakpoint === "xs" || breakpoint === "sm"}
                  InputLabelProps={{
                    classes:{
                      root: classes.cssLabel,
                      focused: classes.cssFocused,
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    className: classes.input,
                    classes:{
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline,
                    },
                    endAdornment: (
                      <React.Fragment>
                        {loading === name ? <CircularProgress size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          )
        }
        
    </Box>
  )
}

function AddBetElement(props) {

    const { 
      auth,
      todaysMatches,
      fetchTodaysMatchesCompetitionsList, 
      fetchTodaysMatchesSportList, 
      fetchTodaysMatchesCompetition, 
      addUserActiveBet,
      userGeoLocation,
      submitAction
    } = props

    const classes = useStyles()
    const theme = useTheme()
    const breakpoint = useMediaBreakPoint()
    const dictionary = useDictionary({elementId: "add_bet_element", userGeoLocation})
    const sportDictionary = useDictionary({elementId: "sports", userGeoLocation})
    const betOptionsDictionary = useDictionary({elementId: "bet_options", userGeoLocation})
    const dateDictionary = useDictionary({elementId: "date", userGeoLocation})
    //console.log(sportsSettings)

    const [loadingResources, setLoadingResources] = React.useState(false)
    const [selectedSportId, setSelectedSportId] = React.useState(null)
    const [selectedCompetitionId, setSelectedCompetitionId] = React.useState(null)
    const [selectedMatchId, setSelectedMatchId] = React.useState(null)
    const [selectedBetTypeId, setSelectedBetTypeId] = React.useState(null)
    const [selectedBetTypeOptionId, setSelectedBetTypeOptionId] = React.useState(null)
    const [selectedMatchResultOption, setSelectedMatchResultOption] = React.useState("0")

    const [selectedInputsObject, setSelectedInputsObject] =  React.useState({
      sport: null, 
      competition: null,
      match: null,
      betType: null,
      betTypeOption: null
    })
   
    const [selectedMatchWinner, setSelectedMatchWinner] = React.useState(null)

    const [betStake, setBetStake] = React.useState("")

    const [bets, setBets] = React.useState([])
    
    const [activeCommentBox, setActiveCommentBox] = React.useState(-1)
    //const [cloudFunctionRequestStatus, setCloudFunctionRequestStatus] = React.useState({status: "loading"})
    
   
    React.useEffect(()=>{
      if(todaysMatches === "loading")fetchTodaysMatchesSportList()

      //props.setActiveSnackbar("addBetErrorSnackbar","error", "Zaradni napake v omrežue, ne deluje osveževanje kvot. Napako smo odkrili in jo že odpravljamo. Hvala za razumevanje.")
    },[])

   
    React.useEffect(()=>{
      
      let sport = todaysMatches !== "loading" ? todaysMatches.sports[selectedSportId]:null
      let competition = sport ? sport.competitions[selectedCompetitionId]:null
      let match = competition ? competition.matches[selectedMatchId]:null
      let betType = match && match.odds ? match.odds[selectedBetTypeId]:null
      let betTypeOption = betType ? betType[selectedBetTypeOptionId]:null
      
      setSelectedInputsObject(state =>({
        ...state, 
        sport,
        competition,
        match,
        betType,
        betTypeOption
      }))
      
      
    },[selectedSportId, todaysMatches, selectedCompetitionId, selectedMatchId, selectedBetTypeId, selectedBetTypeOptionId])

    React.useEffect(()=>{
      setLoadingResources(false)
    },[todaysMatches])


    const fetchMatchOdds = (data)=>{
      //set loading status
      setLoadingResources(true)
      props.fetchMatchOdds(data)
      
    }
    
    const handleSportChange =  async selectedOption => {
      
      //clear inputs bellow
      setSelectedInputsObject(state =>({
        ...state, 
        sport: selectedOption ? state.sport : null,  
        competition: null, 
        match: null, 
        betType: null, 
        betTypeOption: null
      }))

      //if input value is deleted return
      if(!selectedOption)return setSelectedSportId(null)  
   
      //set selected element to display in input
      let sportId = selectedOption.value
      setSelectedSportId(sportId)
      setSelectedCompetitionId(null)
      setSelectedMatchId(null)
      setSelectedBetTypeId(null)
      setSelectedBetTypeOptionId(null)

      //fetch data if missing
      if(sportId !== selectedSportId){
        if(!todaysMatches.sports[sportId]){
          fetchTodaysMatchesCompetitionsList(sportId)
        }
      }
     
    }

    const handleCompetitionChange = async selectedOption =>{
      

      //clear inputs bellow
      setSelectedInputsObject(state =>({
        ...state, 
        competition: selectedOption ? state.competition : null,  
        match: null, 
        betTypes: null, 
        betTypeOptions: null
      }))

      //if input value is deleted return
      if(!selectedOption)return setSelectedCompetitionId(null)
      
      //set selected element to display in input
      let competitionId = selectedOption.value
      setSelectedCompetitionId(competitionId)
      setSelectedMatchId(null)
      setSelectedBetTypeId(null)
      setSelectedBetTypeOptionId(null)
      //fetch data if missing
      if(competitionId !== selectedCompetitionId){
        if(!todaysMatches.sports[selectedSportId].competitions[competitionId]){
          fetchTodaysMatchesCompetition(selectedSportId, competitionId)
        }
      }

    }

    const handleMatchChange = async selectedOption => {
      //clear inputs bellow
      setSelectedInputsObject(state =>({
        ...state, 
        match: selectedOption ? state.match : null,  
        betTypes: null, 
        betTypeOptions: null
      }))

      //if input value is deleted return
      if(!selectedOption) return setSelectedMatchId(null)
    
      //set selected element to display in input
      setSelectedMatchId(selectedOption.value)
      setSelectedBetTypeId(null)
      setSelectedBetTypeOptionId(null)

      //fetch data if missing
      let newlySelectedMatch = selectedInputsObject.competition.matches[selectedOption.value]
      if(!newlySelectedMatch.odds){ 
        fetchMatchOdds({
          sportId: selectedSportId, 
          competitionId: selectedCompetitionId, 
          matchId: selectedOption.value, 
          matchLink: newlySelectedMatch.matchLink, 
        })  
        
      }

    }

    const handleBetTypeChange = async selectedOption => {
       //clear inputs bellow
      setSelectedInputsObject(state =>({
        ...state, 
        betTypes: selectedOption ? state.betTypes : null,  
        betTypeOptions: null
      }))

      ///if input value is deleted return
      if(!selectedOption)return setSelectedBetTypeId(null)
  
      //set selected element to display in input
      let betType = selectedOption.value
      let selectedMatch = selectedInputsObject.match

      setSelectedBetTypeId(betType)
      setSelectedBetTypeOptionId(null)

      //fetch data if missing
      //  || selectedMatch.odds[betType].updated === 0 
      if(!selectedMatch.odds[betType] || selectedMatch.odds[betType].updated === 0 ){
        fetchMatchOdds({
          sportId: selectedSportId, 
          competitionId: selectedCompetitionId, 
          matchId: selectedMatchId, 
          matchLink: selectedMatch.matchLink, 
          betType: betType, 
        })
        
      } 
 
    }

    const handleBetTypeOptionChange = async selectedOption =>{
      //if null delete all selected and exit
      setSelectedInputsObject(state =>({
        ...state, 
        betTypeOptions: selectedOption ? state.betTypeOptions : null,  
      }))

      ///if input value is deleted return
      if(!selectedOption)return setSelectedBetTypeOptionId(null)
     
      //set selected element to display in input
      let betTypeOptionId = selectedOption.value
      setSelectedBetTypeOptionId(betTypeOptionId)

      let selectedMatch = selectedInputsObject.match
      let selectedMatchOddsByBetType = selectedMatch && selectedMatch.odds[selectedBetTypeId]
      
      //fetch data if missing
      // && (selectedMatchOddsByBetType[betTypeOptionId].updated + 900000) < Date.now()
  

      if(!selectedMatchOddsByBetType[betTypeOptionId] || 
        (selectedMatchOddsByBetType[betTypeOptionId] && selectedMatchOddsByBetType[betTypeOptionId].updated === 0) || 
        (selectedMatchOddsByBetType[betTypeOptionId] && selectedMatchOddsByBetType[betTypeOptionId].updated + 10800000 < Date.now())){
          
          fetchMatchOdds({
            sportId: selectedSportId, 
            competitionId: selectedCompetitionId, 
            matchId: selectedMatchId, 
            matchLink: selectedMatch.matchLink, 
            betType: selectedBetTypeId, 
            betTypeOption: betTypeOptionId
          })
       
      }
  
    }

    const handleMatchResultOptionChange = selectedOption => {
      setSelectedMatchResultOption(selectedOption.value)
    }

    const handleAddBet = matchWinner => ()=>{

      //check for duplicates
      for(let bet of bets){
      
        if(selectedMatchId === bet.matchId && selectedBetTypeId === bet.betTypeId ){
          props.setActiveSnackbar("betCombinationDuplicateError", "error")
          return 
        }
      }
      
      setSelectedInputsObject({
        sport: null, 
        competition: null,
        match: null,
        betType: null,
        betTypeOption: null
      })

      setSelectedSportId(null)
      setSelectedCompetitionId(null)
      setSelectedMatchId(null)
      setSelectedBetTypeId(null)
      setSelectedBetTypeOptionId(null)
      setSelectedMatchWinner(null)
      setActiveCommentBox(bets.length)

      let betOdds = null
      let matchResultOption = null
      if(selectedBetTypeId === "cs" || selectedBetTypeId === "ah"  ||  selectedBetTypeId === "o-u"  ||  selectedBetTypeId === "eh" ){

        betOdds = todaysMatches.sports[selectedSportId].competitions[selectedCompetitionId].matches[selectedMatchId].odds[selectedBetTypeId][selectedBetTypeOptionId][selectedMatchResultOption].odds[matchWinner]
        matchResultOption = todaysMatches.sports[selectedSportId].competitions[selectedCompetitionId].matches[selectedMatchId].odds[selectedBetTypeId][selectedBetTypeOptionId][selectedMatchResultOption].option 
       
        if(selectedBetTypeId === "ah" && matchWinner === "2"){
          let matchResultOptionNumber = Number(matchResultOption.split(' ')[0]) * -1
          if(matchResultOptionNumber > 0) matchResultOptionNumber = "+" + matchResultOptionNumber

          matchResultOption = matchResultOptionNumber + (matchResultOption.split(' ')[1] || "")
        }
         
      }else{
        betOdds = todaysMatches.sports[selectedSportId].competitions[selectedCompetitionId].matches[selectedMatchId].odds[selectedBetTypeId][selectedBetTypeOptionId][matchWinner] 
      }
      
      

      setBets(state =>(
        [
          ...state,
          {
            sportId: selectedSportId,
            competitionId: selectedCompetitionId,
            matchId: selectedMatchId,
            betTypeId: selectedBetTypeId,
            betTypeOptionId: selectedBetTypeOptionId,
            matchResultOption,
            matchWinner,
            betOdds,
            betStake
          }
        ]
      ))

      return
    }

    
    const handleBetStakeChange = (e) => {
      
      let value = Number(e.target.value)
      
      if(value > 10)value = 10
      if(value < 0)value = 0

      setBetStake(value === 0 ? "": value)
    }
   
    const toggleActiveCommentBoxContainer = (i) => ()=>{
      setActiveCommentBox(state => state === i ? -1:i)
    }

    const handleBetEdit = (betId)=>()=>{
      let selectedBet = bets[betId]

      setSelectedSportId(selectedBet.sportId)
      setSelectedCompetitionId(selectedBet.competitionId)
      setSelectedMatchId(selectedBet.matchId)
      setSelectedBetTypeId(selectedBet.betTypeId)
      setSelectedBetTypeOptionId(selectedBet.betTypeOptionId)
      setSelectedMatchWinner(selectedBet.matchWinner)

      setSelectedInputsObject({
        sport: todaysMatches.sports[selectedBet.sportId], 
        competition: todaysMatches.sports[selectedBet.sportId].competitions[selectedBet.competitionId],
        match: todaysMatches.sports[selectedBet.sportId].competitions[selectedBet.competitionId].matches[selectedBet.matchId],
        betType: todaysMatches.sports[selectedBet.sportId].competitions[selectedBet.competitionId].matches[selectedBet.matchId].odds[selectedBet.betTypeId],
        betTypeOption:  todaysMatches.sports[selectedBet.sportId].competitions[selectedBet.competitionId].matches[selectedBet.matchId].odds[selectedBet.betTypeId][selectedBet.betTypeOptionId],
      })

      setBets(state => {
        state[betId] = null
        state = state.filter(o=>o)
        return [...state]
      })
    }

    const handelBetCommentChange = (betId)=> (e)=>{
      let value = e.target.value
      if(value){
        setBets(state => {
          state[betId].comment = value
          return [...state]
        })
      }
    }

    const handleBetDelete = (betId)=>(e)=>{
      setBets(state => {
        state[betId] = null
        state = state.filter(o=>o)
        return [...state]
      })
    }

    const handleSendBet = ()=> {
      
      if(!betStake){
        
        props.setActiveSnackbar("addStakeError", "error")
        return 
      }
      
      addUserActiveBet(auth, bets, betStake)
      setSelectedInputsObject({
        sport: null, 
        competition: null,
        match: null,
        betType: null,
        betTypeOption: null
      })

      setSelectedSportId(null)
      setSelectedCompetitionId(null)
      setSelectedMatchId(null)
      setSelectedBetTypeId(null)
      setSelectedBetTypeOptionId(null)
      setSelectedMatchWinner(null)
      
      setBets([])
      props.closeActiveModal()
    }

    const handleModalClose = ()=>{
      if(bets.length !== 0){
        let answer = window.confirm(dictionary.modalWindowLabel)
        if(answer) props.closeActiveModal()
      }else{
        props.closeActiveModal()
      }

    }
    
    return (
      <Grid container spacing={1} className={classes.container}>
        <Grid item lg={6} md={6} sm={12} xs={12} >
          <Box width="fit-content" p={2} bgcolor="background.primary" borderRadius={4}>
            <Typography variant="h2">
              {dictionary.title}
            </Typography>
          </Box>
          
          <Box mt={1} p={2}  minHeight={(breakpoint === "sm" || breakpoint === "xs") ? "auto": 550} margin="auto" bgcolor="background.primary" borderRadius={4}>

            <Grid spacing={3} container justify="center">
              {
                todaysMatches !== "loading" &&
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ComboBox 
                      name="sport" 
                      label={dictionary.sportComboBoxLabel}
                      selectedValue={selectedSportId}
                      disabled={loadingResources}
                      loading={loadingResources && "sport"}
                      onChange={handleSportChange}
                      options={todaysMatches.sportsList
                          .map(sport => ({label: sportDictionary[sport], value: sport}))                 
                      }
                      renderOption={(option, { selected }) => (
                        <Box width="100%" display="flex" alignItems="center">
                          
                          <Box display="flex" alignItems="center" pr={1}>
                            <CustomIcon  name={option.value}/>
                          </Box>
                          
                          <Box>
                            <Typography variant="body2" align="right" noWrap>
                              {option.label}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    />
                  
                  </Grid>
              } 
              {
                selectedInputsObject.sport && 
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ComboBox 
                      name="competition" 
                      label={dictionary.competitionComboBoxLabel}
                      selectedValue={selectedCompetitionId}
                      disabled={loadingResources}
                      loading={loadingResources && "competition"}
                      onChange={handleCompetitionChange}
                      options={Object.entries(selectedInputsObject.sport.competitionsList)
                                      .map(([competitionId, competition]) => ({label: competition.title, value: competitionId, icon: competition.icon, country: competition.country}))
                                      .sort((a, b) => -b.country.localeCompare(a.country))
                      }
                      renderOption={(option, { selected }) => (
                        <Box width="100%" display="flex" alignItems="center">
                          {
                            option.icon && 
                              <Box display="flex" alignItems="center" pr={1}>
                                <img height="25px" width="25px" alt={option.label} src={option.icon} />
                              </Box>
                          }
                          <Box>
                            <Typography variant="body2" align="right" noWrap>
                              {option.label}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    />
                  </Grid>
              }
              {
                selectedInputsObject.competition && 
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ComboBox 
                      name="match" 
                      label={dictionary.matchComboBoxLabel}
                      selectedValue={selectedMatchId}
                      disabled={loadingResources}
                      loading={loadingResources && "match"}
                      onChange={handleMatchChange}
                      options={ 
                        Object.entries(selectedInputsObject.competition.matches)
                          .filter(a =>  Date.now()/1000 < a[1].time)
                          .sort((a, b)=> a[1].time - b[1].time)
                          .map(
                            ([matchId, match]) => ({
                                label: match.participants, 
                                value: matchId,
                                time: timestampFormatter(match.time, dateDictionary),  
                            })
                      )}
                      getOptionDisabled={option => Date.now()/1000 > (option.time - 900)
                      }
                      renderOption={(option, { selected }) => {
                        
                        let participants = option.label.split("-")
                        let isFinished = Date.now()/1000 > option.time
                        return(
                          <Box width="100%" display="flex" justifyContent="center" flexDirection="column">
                              {
                                isFinished ? 
                                  <Box width="150px" fontSize="0.7em" fontWeight={500} align="center" bgcolor="error.main" color="white" borderRadius={4}>{dictionary.matchComboBoxOptionStatus}</Box>:
                                  <Box width="110px" fontSize="0.7em" fontWeight={500} align="center" bgcolor="success.main" color="black" borderRadius={4}>{option.time}</Box>
                              }
                              <Box width="100%" display="flex" justifyContent="space-between" flexDirection="row" alignItems="flex-end" pt={1}>
                                <Box width="45%" fontSize="1em"><Typography variant="body2" align="left" noWrap>{participants[0]}</Typography></Box>
                                <Box px={1} fontSize="1em">vs</Box>
                                <Box width="45%" fontSize="1em"><Typography variant="body2" align="right" noWrap>{participants[1]}</Typography></Box>
                              </Box>
                        </Box>)
                      }}
                    />
                  </Grid>
              }
              {
                selectedInputsObject.match && selectedInputsObject.match.odds &&
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ComboBox 
                      name="betType" 
                      label={dictionary.betTypeComboBoxLabel}
                      selectedValue={selectedBetTypeId}
                      disabled={loadingResources}
                      loading={loadingResources && "betType"}
                      onChange={handleBetTypeChange}
                      options={Object.keys(selectedInputsObject.match.odds).map(betType => ({label: betOptionsDictionary[betType], value: betType}))}
                    />
                  </Grid>
              }
              {
                selectedInputsObject.betType && 
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ComboBox 
                      name="betTypeOption" 
                      label={dictionary.betTypeOptionComboBoxLabel}
                      selectedValue={selectedBetTypeOptionId}
                      disabled={loadingResources} 
                      loading={loadingResources && "betTypeOption"}
                      onChange={handleBetTypeOptionChange}
                      options={Object.keys(selectedInputsObject.betType)
                                .filter(o => o !== "updated")
                                .map(betTypeOption => ({label: betOptionsDictionary[betTypeOption], value: betTypeOption}))}
                    />
                  </Grid>
              }
            </Grid>
            {
              !loadingResources && selectedInputsObject.betTypeOption && selectedBetTypeId !== "cs" && selectedBetTypeId !== "ah"  && selectedBetTypeId !== "o-u"  && selectedBetTypeId !== "eh"   ?
                <Box mt={2} flexGrow={1} width="100%" display="flex" flexDirection={breakpoint === "xs" ? "column":"row"} alignItems="center" justifyContent="center" minHeight="100px" color="white">
                    {Object.entries(selectedInputsObject.betTypeOption).filter(([key, o])=> key !== "updated").map(([key, data], i)=> (
                      
                      <Box 
                        onClick={handleAddBet(key)} 
                        className={classNames({[classes.winnerButton]: true, [classes.winnerButtonActive]: key === selectedMatchWinner})} 
                        display="flex" 
                        flexDirection="column" 
                        justifyContent="center" 
                        flexGrow={1}
                        flexBasis={1}
                        width={breakpoint === "xs" ? "100%": "auto"}
                        bgcolor="background.dark" 
                        borderColor="primary.main"
                        border={1}
                        borderRadius={4} 
                        height={100}
                        p={2} 
                        ml={breakpoint === "xs" ? 0 : i !== 0 ? 2:0}
                        mb={breakpoint === "xs" ? 1 : 0}
                        key={key}
                      >
                        <Box width="100%">
                          <Typography  variant="h2" align="center">
                            {key}
                          </Typography>
                        </Box>
                        <Box pt={1} width="100%">
                          <Typography className={classes.mobileFontWeight} variant="body1" align="center">
                            {`${dictionary.betOdds} ${data}`}
                          </Typography>
                        </Box>
                        
                      </Box> 
                    ))} 
                </Box> : !loadingResources && selectedInputsObject.betTypeOption && 
                  <Box mt={2} flexGrow={1} width="100%" display="flex" flexDirection="row" alignItems="center" justifyContent="center"   color="white" flexWrap={breakpoint === "xs" || breakpoint === "sm" ? "wrap" : "nowrap"}>
                    <Box flexGrow={1} flexBasis={breakpoint === "xs" || breakpoint === "sm" ? "100%" : 1} minWidth={75}>
                      <ComboBox 
                          name="matchResultOption" 
                          disableClearable={true}
                          selectedValue={selectedMatchResultOption}
                          disabled={loadingResources} 
                          loading={loadingResources && "betTypeOption" }
                          onChange={handleMatchResultOptionChange}
                          options={Object.keys(selectedInputsObject.betTypeOption)
                                    .filter(o => o !== "updated")
                                    .map(matchResultOption => ({label: selectedInputsObject.betTypeOption[matchResultOption].option, value: matchResultOption}))}
                      />
                    </Box>
                    {selectedInputsObject.betTypeOption[selectedMatchResultOption] && Object.entries(selectedInputsObject.betTypeOption[selectedMatchResultOption].odds).map(([key, data], i)=> (
                      
                      <Box 
                        onClick={handleAddBet(key)} 
                        className={classNames({[classes.winnerButton]: true, [classes.winnerButtonActive]: key === selectedMatchWinner})} 
                        display="flex" 
                        flexDirection="column" 
                        justifyContent="center" 
                        flexGrow={1}
                        flexBasis={breakpoint === "xs" || breakpoint === "sm" ? "100%" : 1}
                        bgcolor="background.dark" 
                        borderColor="primary.main"
                        border={1}
                        borderRadius={4} 
                        height={100}
                        p={2} 

                        ml={breakpoint === "xs" || breakpoint === "sm" ? 0 : 2}
                        mt={breakpoint === "xs" || breakpoint === "sm" ? 2 : 0}

                        key={key}
                      >
                        <Box width="100%">
                          <Typography  variant="body1" align="center" noWrap>
                            {
                              
                              selectedBetTypeId === "ah" ?
                                <b>
                                  { 
                                  key === "1" ? 
                                    `${key} (${selectedInputsObject.betTypeOption[selectedMatchResultOption].option})` :
                                    `${key} (${Number(selectedInputsObject.betTypeOption[selectedMatchResultOption].option.split(' ')[0])*-1 > 0 ? 
                                                "+" + Number(selectedInputsObject.betTypeOption[selectedMatchResultOption].option.split(' ')[0])*-1:
                                                Number(selectedInputsObject.betTypeOption[selectedMatchResultOption].option.split(' ')[0])*-1} ${selectedInputsObject.betTypeOption[selectedMatchResultOption].option.split(' ')[1] || ""})`
                                  } 
                                </b>:
                                <b>{`${key} (${selectedInputsObject.betTypeOption[selectedMatchResultOption].option})`}</b>
                            }
                            
                          </Typography>
                        </Box>
                        <Box pt={1} width="100%">
                          <Typography  variant="body1" align="center">
                            {`${dictionary.betOdds} ${data}`}
                          </Typography>
                        </Box>
                      </Box> 
                    ))} 
                    
                  </Box>
              }    
          </Box>
        </Grid>
            
        <Grid item lg={6} md={6} sm={12} xs={12} >
          <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%"> 
            <Box width="fit-content" p={2} bgcolor="background.primary" borderRadius={4}>
              <Typography variant="h2">
                {dictionary.title2}
              </Typography>
            </Box>
            
            <Box mt={1} p={2} width="100%" height="100%"  display="flex" flexDirection="column" justifyContent="space-between" bgcolor="background.primary" borderRadius={4}>
              <Box width="100%" height="100%">
                {
                  bets.length > 0 && (
                    <Box mb={1} width="100%"  display="flex" flexDirection="column">
                      {bets.map( (obj, i) => (
                        <span key={i}>
                          <Box width="100%" key={i} display="flex" flexWrap={breakpoint === "sm" || breakpoint === "xs" ? "wrap" : "nowrap"}>
                            <Box display="flex" alignItems="center" justifyContent="center" width="50px" p={1} mr={1} mb={1} bgcolor="background.dark" height="50px" borderRadius={4} >
                              <Typography variant="h2" noWrap style={{fontSize: "1.1em"}}>{i+1}.</Typography>
                            </Box>
                            {
                              (breakpoint === "xs" || breakpoint === "sm" ) &&
                                <Box display="flex" justifyContent="space-around" alignItems="center" flexGrow={1} flexBasis={1} mb={1} height={50} bgcolor="background.dark"  borderRadius={4} >
                                  <IconButton onClick={toggleActiveCommentBoxContainer(i)}>
                                    <CommentIcon fontSize="small" style={{color: theme.palette.text.secondary}}/>
                                  </IconButton>
                                  <IconButton onClick={handleBetEdit(i)}>
                                    <EditIcon fontSize="small" style={{color: theme.palette.text.secondary}}/>
                                  </IconButton>
                                  <IconButton onClick={handleBetDelete(i)}>
                                    <CloseIcon fontSize="small" style={{color: theme.palette.error.main}}/>
                                  </IconButton>
                                </Box>
                            }
                           
                            <Box mb={1} width={breakpoint === "xs" || breakpoint === "sm" ? "100%": "auto"} flexGrow={3} flexBasis={200} display="flex" justifyContent="space-between" bgcolor="background.dark" height="50px" borderRadius={4} > 
                              <Box width="50px" display="flex" justifyContent="center" alignItems="center" pl={2} pr={1} color="white">
                                <CustomIcon name={obj.sportId} ></CustomIcon>
                              </Box>
                              <Box flexGrow={1} flexBasis={1} maxWidth={150} display="flex" justifyContent="center" alignItems="center" p={1} color="white">
                                  <Typography variant="body2" noWrap>{ todaysMatches.sports[obj.sportId].competitions[obj.competitionId].matches[obj.matchId].participants }</Typography>
                              </Box>
                              <Box flexGrow={1} flexBasis={1} maxWidth={100} display="flex" justifyContent="center" alignItems="center" px={1} color="white">
                                <Tooltip title={betOptionsDictionary[obj.betTypeId] || obj.betTypeId +" "+ betOptionsDictionary[obj.betTypeOptionId] || obj.betTypeOptionId}  aria-label="info">
                                 
                                    <Typography variant="body2" noWrap>
                                      {`${obj.matchWinner} ${obj.matchResultOption || ""}`} 
                                    </Typography>
                                 
                                </Tooltip>
                              </Box>
                            </Box>
                            <Box display="flex" justifyContent="center" alignItems="center" width="70px"  height={50}  mx={breakpoint === "xs" || breakpoint === "sm" ? 0 : 1} mb={1}  bgcolor="primary.main" borderRadius={4} >
                              <Typography variant="body1">
                                <b>
                                  {obj.betOdds}
                                </b>
                              </Typography>
                            </Box>
                            {
                              breakpoint !== "xs" && breakpoint !== "sm" &&
                                <Box display="flex" justifyContent="space-around" alignItems="center" flexGrow={1} flexBasis={1} mb={1} height={50} bgcolor="background.dark"  borderRadius={4} >
                                  <IconButton onClick={toggleActiveCommentBoxContainer(i)}>
                                    <CommentIcon fontSize="small" style={{color: theme.palette.text.secondary}}/>
                                  </IconButton>
                                  <IconButton onClick={handleBetEdit(i)}>
                                    <EditIcon fontSize="small" style={{color: theme.palette.text.secondary}}/>
                                  </IconButton>
                                  <IconButton onClick={handleBetDelete(i)}>
                                    <CloseIcon fontSize="small" style={{color: theme.palette.error.main}}/>
                                  </IconButton>
                                </Box>
                            }
                            
                          </Box>
                          <Box px={1} width="100%" className={classNames({[classes.commentBoxContainer]: true, [classes.commentBoxContainerActive]: activeCommentBox === i})} bgcolor="background.dark" borderRadius={4} >
                            <CustomTextArea onChange={handelBetCommentChange(i)} multiline={true} rows={5} rowsMax={10} type="text" label={dictionary.commentTextInputLabel} placeholder={dictionary.commentTextInputPlaceholder} />
                          </Box>
                        </span>
                      ))}
                    </Box>
                  )
                }
              </Box>
              <Box width="100%" display="flex" flexDirection="row" justifyContent="space-around" flexWrap="wrap" color="black" >
                <Box flexGrow={1} flexBasis={1} p={1} mr={2} height={100} display="flex"  flexDirection="column" alignItems="center" justifyContent="center"  border={1} bgcolor="primary.main" borderRadius={4} boxShadow={4}>
                    <Typography variant="h2" className={classes.title}>{dictionary.betOdds}</Typography>
                    <Typography variant="body1" className={classNames(classes.title, classes.mobileFontWeight)}>{ bets.length !== 0 ? bets.reduce((reducer, obj)=> obj.betOdds*reducer, 1).toFixed(2) : 0}</Typography>
                </Box>
                <Box flexGrow={1} flexBasis={1} p={1} mr={2} height={100}  display="flex" flexDirection="column" alignItems="center" justifyContent="center"   border={1} borderRadius={4} bgcolor="primary.main" boxShadow={4}>
                  <Typography variant="h2" className={classes.title}>{dictionary.betStake}</Typography>
                    <Box display="flex" justifyContent="center" alignItems="center">
                      <CustomInput  
                        onChange={handleBetStakeChange} 
                        value={betStake} 
                        
                        inputProps={{type:"number", step:"0.1", max:"10", classes:{input: classes.inputPlaceholder}}}  
                        placeholder={dictionary.stakeInputPlaceholder}
                      />
                    </Box>
                </Box>
                <Box flexGrow={1} flexBasis={1} p={1} height={100} display="flex"  flexDirection="column" alignItems="center" justifyContent="center"   border={1} bgcolor="primary.main" borderRadius={4} boxShadow={4} >
                  <Typography variant="h2" className={classes.title}>{dictionary.betReturns}</Typography>
                  <Typography variant="body1" className={classNames(classes.title, classes.mobileFontWeight)}>{(betStake * bets.reduce((reducer, obj)=> obj.betOdds * reducer,1)).toFixed(2)}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} >
         
          <Box display="flex" justifyContent="space-between">            
            <CustomButton
                label={dictionary.backButton}
                height={75}
                type="button"
                color="white"
                width="calc(35% - 4px)"
                onClick={handleModalClose}
               
            />

            <CustomButton
                label={dictionary.confirmBetButton}
                height={75}
                type="button"
                color="white"
                disabled={!Boolean(betStake) || bets.length === 0}
                width={`calc(65% - 4px)`}
                onClick={handleSendBet}
            />

          </Box>
          
        </Grid>
      </Grid>
     
    )
}


const mapStateToProps = ({todaysMatches, auth, userGeoLocation}) => {
  return {
    todaysMatches,
    auth,
    userGeoLocation,
  }
}



export default connect(mapStateToProps, actions)(editWrapper(AddBetElement))