import {
    FETCH_MONTHLY_LEADER_BOARD,
    FETCH_WEEKLY_LEADER_BOARD,
    FETCH_OVERALL_LEADER_BOARD,

} from "../../../actions/types";
  
  
export default (state = "loading", action) => {
    if(!action.payload)return state
    //initialize todays matches object on first load
    let leaderBoards = state !== "loading" ? {...state} : {weekly: {}, monthly:{}, overall:{}}

    switch (action.type) {
        case FETCH_MONTHLY_LEADER_BOARD:
            leaderBoards.monthly = action.payload
            return leaderBoards;
        case FETCH_WEEKLY_LEADER_BOARD:
            leaderBoards.weekly = action.payload
            return leaderBoards;
        case FETCH_OVERALL_LEADER_BOARD:
            leaderBoards.overall = action.payload
            return leaderBoards;
        default:
            return state
    }
};