import React, {Suspense} from 'react';

import * as actions from '../../../redux/actions'
import { connect } from "react-redux";

import RegisterUserBoxElement from './elements/RegisterUserBoxElement'
import NavbarElement from './elements/NavbarElement'
import ButtonDrawerElement from './elements/ButtonDrawerElement'
import ListBoxElement from './elements/ListBoxElement'
import CircleBoxElement from './elements/CircleBoxElement'
import AddBetElement from './elements/AddBetElement'
import ProfileBoxElement from './elements/ProfileBoxElement'
import AccountCreationElement from './elements/AccountCreationElement'
import BetInfoElement from './elements/BetInfoElement'
import HistoryBetInfoElement from './elements/HistoryBetInfoElement'
import LoginBoxElement from './elements/LoginBoxElement'
import UserSettingsElement from './elements/UserSettingsElement'
import BetsListBoxElement from './elements/BetsListBoxElement'
import InfoElement from './elements/InfoElement'

import Carousel from './elements/Carousel'
import Home from './elements/HomeElement'
/*
const RegisterUserBoxElement = React.lazy(()=> import('./elements/RegisterUserBoxElement'))
const NavbarElement = React.lazy(()=> import('./elements/NavbarElement'))
const ButtonDrawerElement = React.lazy(()=> import('./elements/ButtonDrawerElement'))
const ListBoxElement = React.lazy(()=> import('./elements/ListBoxElement'))
const CircleBoxElement = React.lazy(()=> import('./elements/CircleBoxElement'))
const AddBetElement = React.lazy(()=> import('./elements/AddBetElement'))
const ProfileBoxElement = React.lazy(()=> import('./elements/ProfileBoxElement'))
const AccountCreationElement = React.lazy(()=> import('./elements/AccountCreationElement'))
const BetInfoElement = React.lazy(()=> import('./elements/BetInfoElement'))
const HistoryBetInfoElement = React.lazy(()=> import('./elements/HistoryBetInfoElement'))
const LoginBoxElement = React.lazy(()=> import('./elements/LoginBoxElement'))
const UserSettingsElement = React.lazy(()=> import('./elements/UserSettingsElement'))
const BetsListBoxElement = React.lazy(()=> import('./elements/BetsListBoxElement'))
const SportActiveBetsRecommendationsElement = React.lazy(()=> import('./elements/SportActiveBetsRecommendationsElement'))
const Carousel = React.lazy(()=> import('./elements/Carousel'))
const Home = React.lazy(()=> import('./elements/HomeElement'))
*/
import Preloader from './../../components/preloaders/Preloader'
import TrophiesInfoElement from './elements/TrophiesInfoElement';
import BettingSitesInfoElement from './elements/BettingSitesInfoElement';

function BrdoparaElement(props){

  const {elementId, elements, style, edit} = props

  let element = elements[elementId]
  let type = element && element.type
  
  switch(type){
    case "brdopara_register_user_element":
      return( 
        <Suspense fallback={<Preloader rootStyle={{position: "absolute", top: 0, left: 0}} />}>
          <RegisterUserBoxElement style={style} elementId={elementId} edit={edit} {...element}/>
        </Suspense>
        
      )
    case "brdopara_circle_box_element":
      return (
        <Suspense fallback={<Preloader rootStyle={{position: "absolute", top: 0, left: 0}} />}>
          <CircleBoxElement style={style} elementId={elementId} edit={edit} {...element}/>
        </Suspense>
        
      )
    case "brdopara_list_box_element":
      return (
        <Suspense fallback={<Preloader rootStyle={{position: "absolute", top: 0, left: 0}} />}>
          <ListBoxElement style={style} elementId={elementId} edit={edit} {...element}/>
        </Suspense>
        
      )
    case "brdopara_button_drawer_element":
      return (
        <Suspense fallback={<Preloader rootStyle={{position: "absolute", top: 0, left: 0}} />}>
          <ButtonDrawerElement style={style} elementId={elementId} edit={edit} {...element}/>
        </Suspense> 
        
      )
    case "brdopara_navbar_element":
        return (
          <Suspense fallback={<Preloader rootStyle={{position: "absolute", top: 0, left: 0}} />}>
            <NavbarElement style={style} elementId={elementId} edit={edit} {...element}/>
          </Suspense>
          
        )
    case "brdopara_add_bet_element":
      return (
        <Suspense fallback={<Preloader rootStyle={{position: "absolute", top: 0, left: 0}} />}>
          <AddBetElement style={style} elementId={elementId} edit={edit} {...element}/>
        </Suspense>
        
      )
    case "brdopara_profile_element":
      return (
        <Suspense fallback={<Preloader rootStyle={{position: "absolute", top: 0, left: 0}} />}>
          <ProfileBoxElement style={style} elementId={elementId} edit={edit} {...element}/>
        </Suspense>
        
      )
    case "brdopara_bet_info":
      return (
        <Suspense fallback={<Preloader rootStyle={{position: "absolute", top: 0, left: 0}} />}>
          <BetInfoElement style={style} elementId={elementId} edit={edit} {...element}/>
        </Suspense>
        
      )
    case "brdopara_history_bet_info":
      return ( 
        <Suspense fallback={<Preloader rootStyle={{position: "absolute", top: 0, left: 0}} />}>
           <HistoryBetInfoElement style={style} elementId={elementId} edit={edit} {...element}/>
        </Suspense>
       
      )
    case "brdopara_account_creation_element":
      return (
        <Suspense fallback={<Preloader rootStyle={{position: "absolute", top: 0, left: 0}} />}>
          <AccountCreationElement style={style} elementId={elementId} edit={edit} {...element}/>
        </Suspense>
        
      )
    case "brdopara_login_box_element":
      return (
        <Suspense fallback={<Preloader rootStyle={{position: "absolute", top: 0, left: 0}} />}>
          <LoginBoxElement style={style} elementId={elementId} edit={edit} {...element}/>
        </Suspense>
        
      )
    case "brdopara_user_settings_element":
      return (
        <Suspense fallback={<Preloader rootStyle={{position: "absolute", top: 0, left: 0}} />}>
          <UserSettingsElement style={style} elementId={elementId} edit={edit} {...element}/>
        </Suspense>
        
      )
    case "brdopara_bets_list_box_element":
      return (
        <Suspense fallback={<Preloader rootStyle={{position: "absolute", top: 0, left: 0}} />}>
          <BetsListBoxElement style={style} elementId={elementId} edit={edit} {...element}/>
        </Suspense>
      )
    case "brdopara_carousel_element":
      return (
        <Suspense fallback={<Preloader rootStyle={{position: "absolute", top: 0, left: 0}} />}>
          <Carousel style={style} elementId={elementId} edit={edit} {...element}/>
        </Suspense>
      )
    case "brdopara_home_element":
      return (
        <Suspense fallback={<Preloader rootStyle={{position: "absolute", top: 0, left: 0}} />}>
          <Home style={style} elementId={elementId} edit={edit} {...element}/>
        </Suspense>
      )
    case "brdopara_trophies_info_element":
      return (
        <Suspense fallback={<Preloader rootStyle={{position: "absolute", top: 0, left: 0}} />}>
          <TrophiesInfoElement style={style} elementId={elementId} edit={edit} {...element}/>
        </Suspense>
      )
    case "brdopara_info_element":
      return (
        <Suspense fallback={<Preloader rootStyle={{position: "absolute", top: 0, left: 0}} />}>
          <InfoElement style={style} elementId={elementId} edit={edit} {...element}/>
        </Suspense>
      )
    case "brdopara_betting_sites_info_element":
      return (
        <Suspense fallback={<Preloader rootStyle={{position: "absolute", top: 0, left: 0}} />}>
          <BettingSitesInfoElement style={style} elementId={elementId} edit={edit} {...element}/>
        </Suspense>
      )
    default:
      return null
    }

  
}

const mapStateToProps = ({ webpages, selectedElement, elements }) => {
  return {
    webpages,
    selectedElement,
    elements
  }
}

export default connect(mapStateToProps, actions)(BrdoparaElement)
