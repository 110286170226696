import timestampFormatter from './../../../helpers/timestampFormatter'

const roundNumber = (number, decimalPlaces=2) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces) 

//////////////////// DATAMAPS /////////////////////

const tipstersDataMap = (dictionary)=> ([
    {id: "user", label: dictionary.tipster, type: "profile", width: "25%", minWidth: "100px", onClick: 'profile', breakpointWidth:{"xs": "40%"}, sort: true},
    {id: "graphData", label: dictionary.graphData, type: "graphData", width: "25%", minWidth: "100px", onClick: 'profile', hideOn: ["xs"], sort: true},
    {id: "betsNum", label: dictionary.betsNum, type: "number", width: "10%", onClick: 'profile', hideOn: ["xs"], sort: true},
    {id: "avrgWin", label: dictionary.avrgWin, type: "percentage", width: "10%", onClick: 'profile', breakpointWidth:{"xs": "20%"}, sort: true},
    {id: "avrgOdds", label: dictionary.avrgOdds, type: "number", width: "10%", onClick: 'profile', hideOn: ["xs"], sort: true},
    {id: "avrgStake", label: dictionary.avrgStake, type: "number", width: "10%", onClick: 'profile', hideOn: ["xs"], sort: true},
    {id: "roi", label: dictionary.roi, type: "percentage", width: "10%", onClick: 'profile', breakpointWidth:{"xs": "20%"}, sort: true},
    {id: "profit", label: dictionary.profit, type: "number_bold", width: "10%", onClick: 'profile', breakpointWidth:{"xs": "20%"}, sort: true},
])

const leaderBoardDataMap =  (dictionary)=> ([
    {id: "position", label: "", type: "element_position", width: "10%", minWidth: "30px", background:"primary.main"},
    {id: "user", label: dictionary.tipster, type: "profile",  width: "40%", minWidth: "100px", onClick: 'profile',  sort: true},
    {id: "roi", label: dictionary.roi, type: "percentage", width: "25%", onClick: 'profile', sort: true},
    {id: "profit", label: dictionary.profit, type: "number_bold", width: "25%", onClick: 'profile',  sort: true},
])

const activeBetsDataMap =  (dictionary)=> ([
    {id: "user", label: dictionary.tipster, type: "profile", width: "20%", minWidth: "100px", onClick: 'profile', breakpointWidth:{"xs": "30%"}},
    {id: "sport", label: dictionary.sport, type: "icon", width: "10%", icon: "sport", sort: true, onClick: 'active_bet_info', breakpointWidth:{"xs": "10%"}},
    {id: "match", label: dictionary.match, type: "match", width: "25%", onClick: 'active_bet_info', breakpointWidth:{"xs": "30%"}},
    {id: "odds", label: dictionary.odds, type: "number", width: "10%", onClick: 'active_bet_info', hideOn: ["xs"]},
    {id: "stake", label: dictionary.stake, type: "number", width: "10%", onClick: 'active_bet_info', hideOn: ["xs"]},
    {id: "time", label: dictionary.time, type: "date", width: "15%", onClick: 'active_bet_info', breakpointWidth:{"xs": "30%"}},
    {id: "comment", label: "", type: "comment", width: "10%",  onClick: 'active_bet_info', hideOn: ["xs"]},
 
])

const userActiveBetsDataMap =  (dictionary)=> ([
    {id: "sport", label: dictionary.sport, type: "icon", width: "7.5%", icon: "sport", sort: true, onClick: 'active_bet_info', breakpointWidth:{"xs": "15%"}},
    {id: "match", label: dictionary.match, type: "match", width: "20%", onClick: 'active_bet_info', breakpointWidth:{"xs": "35%"}},
    {id: "type", label: dictionary.betType, type: "string", width: "20%", onClick: 'active_bet_info', hideOn: ["xs"]},
    {id: "bet", label: dictionary.bet, type: "string", width: "17.5%", onClick: 'active_bet_info', breakpointWidth:{"xs": "15%"}},
    {id: "odds", label: dictionary.odds, type: "number", width: "10%", onClick: 'active_bet_info', hideOn: ["xs"]},
    {id: "stake", label: dictionary.stake, type: "number", width: "10%", onClick: 'active_bet_info', hideOn: ["xs"]},
    {id: "time", label: dictionary.time, type: "date", width: "15%", onClick: 'active_bet_info', breakpointWidth:{"xs": "35%"}},
    //{id: "key", label: "", type: "remove", onClick: 'remove_active_bet', width: "5%"},
])

const userInactiveBetsDataMap =  (dictionary)=> ([
    {id: "sport", label: dictionary.sport, type: "icon", width: "10%", icon: "sport", sort: true, onClick: '', breakpointWidth:{"xs": "15%"}},
    {id: "match", label: dictionary.match, type: "match", width: "30%", onClick: '', breakpointWidth:{"xs": "35%"}},
    {id: "type", label: dictionary.betType, type: "string", width: "25%", onClick: '', hideOn: ["xs"]},
    {id: "bet", label: dictionary.bet, type: "string", width: "15%", onClick: '', breakpointWidth:{"xs": "15%"}},
    {id: "odds", label: dictionary.odds, type: "number", width: "10%", onClick: '', hideOn: ["xs"]},
    {id: "stake", label: dictionary.stake, type: "number", width: "10%", onClick: '', hideOn: ["xs"]},
    {id: "time", label: dictionary.time, type: "date", width: "15%", onClick: '', breakpointWidth:{"xs": "35%"}},
])

const userActiveBetsInfoDataMap =  (dictionary)=> ([
    {id: "sport", label: dictionary.sport, type: "icon", width: "10%", icon: "sport", sort: true, onClick: 'active_bet_info', breakpointWidth:{"xs": "20%"}},
    {id: "competition", label:dictionary.competition, width: "30%", type: "string", sort: true, onClick: 'active_bet_info', hideOn: ["xs"]},
    {id: "match", label: dictionary.match, type: "match", width: "35%", sort: true, onClick: 'active_bet_info', breakpointWidth:{"xs": "40%"}},
    {id: "stake", label: dictionary.stake, type: "number", width: "10%", onClick: 'active_bet_info', hideOn: ["xs"]},
    {id: "time", label: dictionary.time, type: "date", width: "15%", onClick: 'active_bet_info', breakpointWidth:{"xs": "40%"}},
    {id: "comment", label: "", type: "comment", width: "5%",  onClick: 'active_bet_info', hideOn: ["xs"]},
])

const activeBetsInfoDataMap =  (dictionary)=> ([
    {id: "user", label:  dictionary.tipster, type: "profile", width: "20%", sort: true, minWidth: "100px", onClick: 'profile', breakpointWidth:{"xs": "35%"}},
    {id: "sport", label:  dictionary.sport, type: "icon", width: "10%", icon: "sport", sort: true, onClick: 'active_bet_info', hideOn: ["xs"]},
    {id: "competition", label:  dictionary.competition, width: "20%", type: "string", sort: true, onClick: 'active_bet_info', hideOn: ["xs"]},
    {id: "match", label:  dictionary.match, type: "match", width: "25%", sort: true, onClick: 'active_bet_info', breakpointWidth:{"xs": "35%"}},
    {id: "stake", label:  dictionary.stake, type: "number", width: "15%", onClick: 'active_bet_info', hideOn: ["xs"]},
    {id: "time", label:  dictionary.time, type: "date", width: "15%", onClick: 'active_bet_info', breakpointWidth:{"xs": "25%"}},
])

const userBetsHistoryDataMap =  (dictionary)=> ([
    {id: "date", label: dictionary.date, type: "date", width: "25%", onClick: 'history_bet_info', breakpointWidth:{"xs": "35%"}},
    {id: "sport", label: dictionary.sport, type: "icon", width: "15%", onClick: 'history_bet_info', icon: "sport", sort: true, breakpointWidth:{"xs": "15%"}},
    {id: "match", label: dictionary.match, type: "match", width: "35%", onClick: 'history_bet_info', breakpointWidth:{"xs": "35%"}},
    {id: "return", label: dictionary.returns, type: "number", width: "10%", onClick: 'history_bet_info', hideOn: ["xs"]},
    {id: "status", label: dictionary.status, type: "icon", width: "15%", onClick: 'history_bet_info', breakpointWidth:{"xs": "15%"}},
])

const betsHistoryDataMap =  (dictionary)=>([
    {id: "user", label: dictionary.tipster, type: "profile", width: "25%", minWidth: "100px", onClick: 'profile', breakpointWidth:{"xs": "25%"}},
    {id: "date", label: dictionary.date, type: "date", width: "15%", onClick: 'history_bet_info', breakpointWidth:{"xs": "25%"}},
    {id: "sport", label: dictionary.sport, type: "icon", width: "10%", onClick: 'history_bet_info', icon: "sport", sort: true, hideOn: ["xs"]},
    {id: "match", label: dictionary.match, type: "match", width: "30%", onClick: 'history_bet_info', breakpointWidth:{"xs": "35%"}},
    {id: "return", label: dictionary.returns, type: "number", width: "10%", onClick: 'history_bet_info', hideOn: ["xs"]},
    {id: "status", label: dictionary.status, type: "icon", width: "10%", onClick: 'history_bet_info', breakpointWidth:{"xs": "15%"}},
])

const correctGraphData = (graphData, type)=>{


    if(type === "overall"){
        let data = {}
        for(let month in graphData){
            data[Number(month)+1]=graphData[month]
        }

        let latestMonth = Object.keys(data).reduce((latestMonth, month)=> Number(month) > latestMonth ? month : latestMonth, 0)
    
        let lastValue = 0
        let newGraphData = []
    
        for(let month = 0; month < latestMonth; month++){
            if(data[month+1]){
                newGraphData[month] = {x: month, y: roundNumber(data[month+1])}
                lastValue = data[month+1]
            }else{
                newGraphData[month] = {x: month, y: roundNumber(lastValue)}
            }
        }
        
        return newGraphData
    }else{
        let latestDate = graphData ? Object.keys(graphData).reduce((latestDate, day)=> Number(day) > latestDate ? day : latestDate, 0) : null
    
        let lastValue = 0
        let newGraphData = []
    
        for(let dayInMonth = 0; dayInMonth < latestDate; dayInMonth++){
            if(graphData[dayInMonth+1]){
                newGraphData[dayInMonth] = {x: dayInMonth, y: roundNumber(graphData[dayInMonth+1])}
                lastValue = graphData[dayInMonth+1]
            }else{
                newGraphData[dayInMonth] = {x: dayInMonth, y: roundNumber(lastValue)}
            }
        }
        
        return newGraphData
    }
    
}


//////////////////////////////////////
const userStatisticDisplayData = ({data, userId, dictionary, type}) => {
  
    let {userName, avrgOdds, avrgStake, winNum, betsNum, returns, roi, stakeSum, position, graphData, icon, user} = data
  
    return {
        
        userId,
        
        user: {
            label: dictionary.user,
            value: userName,
            icon 
        },
        graphData:{
            label: dictionary.graphData,
            value: correctGraphData(graphData, type)
        },
        stakeSum:{
            label: dictionary.stakeSum,
            value: stakeSum
        },
        roi:{
            label: dictionary.roi,
            value: roundNumber(roi)
        },
        betsNum:{
            label: dictionary.betsNum,
            value: betsNum
        },

        returns:{
            label: dictionary.returns,
            value: roundNumber(returns)
        },
        
        profit:{
            label: dictionary.profit,
            value: roundNumber(returns - stakeSum)
        },

        lostNum:{
            label: dictionary.lostNum,
            value: betsNum - winNum
        },

        winNum:{
            label: dictionary.winNum,
            value: winNum
        },   

        avrgWins:{
            label: dictionary.avrgWin,
            value: roundNumber(100 * winNum / betsNum)
        },
        avrgStake:{
            label: dictionary.avrgStake,
            value: avrgStake
        },
        avrgOdds:{
            label: dictionary.avrgOdds,
            value: avrgOdds
        }
    }

}

const leaderBoardDisplayedData = ({data, orderBy, dictionary={}, orderDirection})=> {  
   
    let displayedData = []
   
    if(data){
        displayedData = Object.entries(data).map(([key, value])=>{
            
            if(!value) return null
            let {userName, avrgOdds, avrgStake, betsNum, returns, roi, stakeSum, position, graphData, userIcon, winNum} = value

            return {
                key,
                userId: key,
                userName,
                position:{
                    label: "",
                    value: position
                },
                user: {
                    label: dictionary.user,
                    value: userName,
                    icon: userIcon,
                },
                
                graphData:{
                    label: dictionary.graphData,
                    value: correctGraphData(graphData)
                },
                roi:{
                    label: dictionary.roi,
                    value: roundNumber(roi)
                },
                betsNum:{
                    label: dictionary.betsNum,
                    value: betsNum
                },
                profit:{
                    label: dictionary.profit,
                    value: roundNumber(returns - stakeSum)
                },
                avrgWin:{
                    label: dictionary.avrgWin,
                    value: roundNumber(100 * winNum / betsNum)
                },
                avrgStake:{
                    label: dictionary.avrgStake,
                    value: avrgStake
                },
                avrgOdds:{
                    label: dictionary.avrgOdds,
                    value: avrgOdds
                },
                stakeSum:{
                    label: dictionary.stakeSum,
                    value: stakeSum
                }
            }
        })
    }
    if(orderBy === "user"){
        if(orderDirection === 1) return displayedData.filter(o=>o).sort((a, b)=> b[orderBy].value.localeCompare(a[orderBy].value))
        else  return displayedData.filter(o=>o).sort((a, b)=> -b[orderBy].value.localeCompare(a[orderBy].value))
    }else{
        if(orderDirection === 1) return displayedData.filter(o=>o).sort((a, b)=> b[orderBy].value - a[orderBy].value)
        else return displayedData.filter(o=>o).sort((a, b)=> a[orderBy].value - b[orderBy].value)
    }
    

}

const betsDisplayedData = ({data, orderBy, orderDirection, groupBy, dictionary, history=false, showInactive=false}) => {  
 
    if(data){
     
        let displayedData =  Object.entries(data).filter(([key, value]) => value).sort((a, b) => {
         
            let dataA = String(a[1][orderBy])
            let dataB = String(b[1][orderBy])
            return dataA.localeCompare(dataB)
        }).map(([key, obj]) => {
            if(!obj)return {}
          
            let {
                userName, 
                userIcon,  
                sportId, 
                competition, 
                competitionId, 
                match, 
                matchId, 
                userAchievements,
                typeId, 
                typeOptionId,
                type, 
                typeOption, 
                timestamp,
                bet, 
                odds, 
                stake, 
                status, 
                user,
                matchTime,
                comment,
                combination,
                bets
            } = obj

            if(combination){
                return {
                    key,
                    userId: user,
                    matchId,
                    sportId,
                    competitionId,
                    typeId,
                    typeOptionId,
                    timestamp,
                    bets,
                    combination,
                    user: {
                        label: dictionary.user,
                        value: userName,
                        achievements: userAchievements,
                        icon: userIcon 
                    },
        
                    match:{
                        label: dictionary.match,
                        sport: sportId,
                        competition: dictionary.combination,
                        match: dictionary.combination,
                    },
    
                    odds:{
                        label: dictionary.odds,
                        value: odds
                    },
    
                    stake:{
                        label: dictionary.stake,
                        value: stake
                    },
    
                    bet:{
                        label: dictionary.betWinner,
                        value: ""
                    },
    
                    type:{
                        label: dictionary.betType,
                        value: ""
                    },
    
                    competition:{
                        label: dictionary.competition,
                        value: dictionary.combination
                    },
    
                    sport:{
                        label: dictionary.sport,
                        value: "combination"
                    },
    
                    status:{
                        label: dictionary.status,
                        value: status
                    },
    
                    date:{
                        label: dictionary.date,
                        value: timestamp
                    },
    
                    time:{
                        label: dictionary.betTime,
                      
                        value: matchTime
                    },
    
                    return:{
                        label: dictionary.return,
                        value: status === "won" ? roundNumber(odds*stake) : status === "lost" ? -stake : stake 
                    },
    
                    comment:{
                        label: dictionary.comment,
                        value: comment
                    }
    
                }
            }else return {
                key,
                userId: user,
                matchId,
                sportId,
                competitionId,
                typeId,
                typeOptionId,
                timestamp,

                user: {
                    label: dictionary.user,
                    value: userName,
                    achievements: userAchievements,
                    icon: userIcon 
                },
    
                match:{
                    label: dictionary.match,
                    sport: sportId,
                    competition,
                    match
                },

                odds:{
                    label: dictionary.odds,
                    value: odds
                },

                stake:{
                    label: dictionary.stake,
                    value: stake
                },

                bet:{
                    label: dictionary.betWinner,
                    value: bet
                },

                type:{
                    label: dictionary.betType,
                    value: type ? (dictionary[type] || type)  +" / "+ (dictionary[typeOption] || typeOption) : ""
                },

                competition:{
                    label: dictionary.competition,
                    value: competition
                },

                sport:{
                    label: dictionary.sport,
                    value: sportId
                },

                status:{
                    label: dictionary.status,
                    value: status
                },

                date:{
                    label: dictionary.date,
                    value: timestamp
                },

                time:{
                    label: dictionary.betTime,
                  
                    value: matchTime
                },

                return:{
                    label: dictionary.return,
                    value: status === "won" ? roundNumber(odds*stake) : status === "lost" ? -stake : stake 
                },

                comment:{
                    label: dictionary.comment,
                    value: comment
                }

            }
        })
        
        displayedData = orderDirection === 1 ? displayedData.reverse() : displayedData
        
        displayedData = groupBy ? displayedData.filter(data => data[orderBy] === groupBy) : displayedData
       
        displayedData = (showInactive || history) && displayedData.length !== 0 ? displayedData : displayedData.filter(data => data.time.value > Date.now()/1000)

     
     
        return displayedData

    }else return []
}

const leaderBoardWeekly = (dictionary = {}) => ({
    displayData: ({auth, data, orderBy="profit", groupBy, orderDirection}) => leaderBoardDisplayedData({data: data.usersStatisticWeekly, orderBy, dictionary, orderDirection}),
    fetchFunction: "fetchWeeklyStatisticData",
    dataMap: leaderBoardDataMap(dictionary),
    orderBy: "profit",
    reducer: "usersStatisticWeekly",
    title: dictionary.leaderBoardWeeklyTitle,
    actions: []
})

const leaderBoardMonthly = (dictionary = {}) => ({
    displayData: ({auth, data, orderBy="profit", groupBy, orderDirection}) => leaderBoardDisplayedData({data: data.usersStatisticMonthly, orderBy, dictionary, orderDirection}),
    fetchFunction: "fetchMonthlyStatisticData",
    dataMap: leaderBoardDataMap(dictionary),
    orderBy: "profit",
    reducer: "usersStatisticMonthly",
    title: dictionary.leaderBoardMonthlyTitle,
    actions: []
})

const leaderBoardOverall = (dictionary = {}) => ({
    displayData: ({auth, data, orderBy="profit", groupBy, orderDirection}) => leaderBoardDisplayedData({data: data.usersStatisticOverall, orderBy, dictionary, orderDirection}),
    fetchFunction: "fetchOverallStatisticData",
    reducer: "usersStatisticOverall",
    dataMap: leaderBoardDataMap(dictionary),
    orderBy: "profit",
    title: dictionary.leaderBoardOverallTitle,
    actions: []
})

/// 

// LANDING PAGE
const landingPage_statistic = (dictionary = {}) => ({
    displayData: ({auth, data, orderBy="profit", groupBy}) => leaderBoardDisplayedData({data, dictionary, orderBy}),
    fetchFunction: "fetchMonthlyStatisticData",
    reducer: "usersStatisticMonthly",
    dataMap: tipstersDataMap(dictionary),
    orderBy: "roi",
   
})

//BET INFO PAGE 
const betInfo_allActiveBetsInfoBySport = (dictionary = {}) => ({
    displayData: ({auth, data, orderBy="sport", orderDirection=1, groupBy}) => betsDisplayedData({data: data.activeBets, orderBy, orderDirection, groupBy, dictionary}),
    fetchFunction: "fetchActiveBetsInfo",
    reducer: "activeBets",
    title: dictionary.betInfo_allActiveBetsInfoBySportTitle,
    orderBy: "timestamp",
    dataMap: activeBetsInfoDataMap(dictionary),
    actions: []
})

const betInfo_allHistoryBets = (dictionary = {}) => ({
    displayData: ({auth, data, orderBy="timestamp", orderDirection=1, groupBy}) => betsDisplayedData({data: data.historyBets, orderBy, orderDirection, dictionary, history: true}),
    fetchFunction: "fetchBetsHistory",
    reducer: "historyBets",
    title: dictionary.betInfo_allHistoryBetsTitle,
    orderBy: "timestamp",
    dataMap:  betsHistoryDataMap(dictionary),
    actions: []
})

const bets_allActiveBetsInfo = (dictionary = {}) => ({
    displayData: ({auth, data, orderBy="timestamp", orderDirection=1, groupBy}) => betsDisplayedData({data: data.activeBets, orderBy, orderDirection, dictionary}),
    fetchFunction: "fetchActiveBetsInfo",
    reducer: "activeBets",
    title: dictionary.bets_allActiveBetsInfoTitle,
    orderBy: "timestamp",
    dataMap: activeBetsInfoDataMap(dictionary),
    actions: []
})

//BET HISTORY INFO PAGE
const historyBetInfo_userHistoryBets = (dictionary = {}) => ({
    displayData: ({auth, data, orderBy="user", orderDirection=1, groupBy, userId}) => betsDisplayedData({data: data.userHistoryBets[userId], orderBy, groupBy, orderDirection, dictionary, history: true}),
    fetchFunction: "fetchUserBetsHistory",
    reducer: "userHistoryBets",
    title: dictionary.betInfo_allHistoryBetsTitle,
    orderBy: "user",
    dataMap:  userBetsHistoryDataMap(dictionary),
    actions: []
})

//DASHBOARD PAGE
const home_allActiveBetsInfo = (dictionary = {}, userType) => ({
    displayData: ({auth, data, orderBy="timestamp", orderDirection=1, groupBy}) => betsDisplayedData({data: data.activeBets, orderBy, orderDirection, groupBy, dictionary}),
    fetchFunction: "fetchActiveBetsInfo",
    betsStatistic: "all",
    reducer: "activeBets",
    loadMore: true,
    orderBy: "timestamp",
    dataMap: activeBetsInfoDataMap(dictionary),
    actions: [
        {label: dictionary.home_userSubscribedBetsButtonLabel, dataType: "home_userSubscribedBets"},
        userType === 1 && {label: dictionary.home_userFavoriteBetsButtonLabel, dataType: "home_userFavoriteBets"},
    ]   
})

const home_userSubscribedBets = (dictionary = {}) => ({
    displayData: ({auth, data, orderBy="timestamp", orderDirection=1, groupBy}) => betsDisplayedData({data: data.subscribedBets, orderBy, orderDirection, groupBy, dictionary}),
    fetchFunction: "fetchSubscribedActiveBets",
    betsStatistic: "subscribed",
    reducer: "subscribedBets",
    loadMore: false,
    orderBy: "timestamp",
    dataMap: activeBetsDataMap(dictionary),
    title: dictionary.home_userSubscribedBetsTitle,
  
})

const home_userFavoriteBets = (dictionary = {}) => ({

    displayData: ({auth, data, orderBy="timestamp", orderDirection=1, groupBy}) => betsDisplayedData({data: data.userFavoriteBets, orderBy, orderDirection, groupBy, dictionary}),
    fetchFunction: "fetchSubscribedActiveBets",
    betsStatistic: "user",
    reducer: "userFavoriteBets",
    title: dictionary.home_userFavoriteBetsTitle,
    orderBy: "timestamp",
    dataMap: activeBetsDataMap(dictionary),
   
})

const home_userBets = (dictionary = {}) => ({
    
    displayData: ({auth, data, orderBy="timestamp", orderDirection=1, groupBy}) => betsDisplayedData({data: data.userActiveBets, orderBy, orderDirection, groupBy, dictionary}),
    fetchFunction: "fetchUserActiveBets",
    title: dictionary.home_userBetsTitle,
    reducer: "userActiveBets",
    orderBy: "timestamp",
    dataMap: userActiveBetsDataMap(dictionary),
   
})

//// MY PROFILE PAGE DATA
const myProfile_myActiveBets = (dictionary = {}) => ({
    displayData: ({auth, data, orderBy="timestamp", orderDirection=1, groupBy}) => betsDisplayedData({data: data.userActiveBets, orderBy, orderDirection, dictionary, groupBy, showInactive: true}),
    fetchFunction: "fetchUserActiveBets",
    reducer: "userActiveBets",
    title: dictionary.myProfile_myActiveBetsTitle,
    orderBy: "timestamp",
    dataMap: userActiveBetsDataMap(dictionary),
    actions: [
        {label: dictionary.myProfile_subscribedActiveBetsButtonLabel, dataType: "myProfile_subscribedActiveBets"},
        {label: dictionary.myProfile_myInactiveBetsButtonLabel, dataType: "myProfile_myInactiveBets", showWhenUserType: 2},
        {label: dictionary.myProfile_userHistoryBetsButtonLabel, dataType: "myProfile_userHistoryBets"},
    ]
})

const myProfile_myInactiveBets = (dictionary = {}) => ({
    displayData: ({auth, data, orderBy="timestamp", orderDirection=1, groupBy}) => betsDisplayedData({data: data.userInactiveBets, groupBy, orderDirection, orderBy, dictionary, showInactive: true}),
    fetchFunction: "fetchUserInactiveBets",
    reducer: "userInactiveBets",
    title: dictionary.myProfile_myInactiveBetsTitle,
    orderBy: "timestamp",
    dataMap: userInactiveBetsDataMap(dictionary),
    actions: [
        {label: dictionary.myProfile_subscribedActiveBetsButtonLabel, dataType: "myProfile_subscribedActiveBets"},
        {label: dictionary.myProfile_myActiveBetsButtonLabel, dataType: "myProfile_myActiveBets", showWhenUserType: 2},
        {label: dictionary.myProfile_userHistoryBetsButtonLabel, dataType: "myProfile_userHistoryBets"},
       
    ]
})

const myProfile_subscribedActiveBets = (dictionary = {}) => ({
    displayData: ({auth, data, orderBy="timestamp", orderDirection=1, groupBy}) => betsDisplayedData({data: data.subscribedBets, orderBy, orderDirection, dictionary}),
    fetchFunction: "fetchSubscribedActiveBets",
    reducer: "subscribedBets",
    title: dictionary.myProfile_subscribedActiveBetsTitle,
    orderBy: "timestamp",
    dataMap: activeBetsDataMap(dictionary),
    actions: [
        {label: dictionary.myProfile_favoriteActiveBetsButtonLabel, dataType: "myProfile_favoriteActiveBets", showWhenUserType: 1},
        {label: dictionary.myProfile_myActiveBetsButtonLabel, dataType: "myProfile_myActiveBets", showWhenUserType: 2},
        {label: dictionary.myProfile_myInactiveBetsButtonLabel, dataType: "myProfile_myInactiveBets", showWhenUserType: 2},
        {label: dictionary.myProfile_userHistoryBetsButtonLabel, dataType: "myProfile_userHistoryBets"},
    ]
})

const myProfile_favoriteActiveBets = (dictionary = {}) => ({
    displayData: ({auth, data, orderBy="timestamp", orderDirection=1, groupBy, favoriteBets}) => betsDisplayedData({data: data.userFavoriteBets, groupBy, orderBy, orderDirection, favoriteBets, dictionary}),
    fetchFunction: "fetchSubscribedActiveBets",
    reducer: "userFavoriteBets",
    
    dataMap: activeBetsDataMap(dictionary),
    title: dictionary.myProfile_favoriteActiveBetsTitle,
    orderBy: "timestamp",
    
    actions: [
        {label: dictionary.myProfile_subscribedActiveBetsButtonLabel, dataType: "myProfile_subscribedActiveBets"},
        {label: dictionary.myProfile_userHistoryBetsButtonLabel, dataType: "myProfile_userHistoryBets"},
    ]
})

const myProfile_userHistoryBets = (dictionary = {}) => ({
    displayData: ({auth, data, orderBy="timestamp", orderDirection=1, groupBy}) => betsDisplayedData({data: data.userHistoryBets[auth.uid], orderBy, groupBy, orderDirection, dictionary, history: true}),
    fetchFunction: "fetchUserBetsHistory",
    reducer: "userHistoryBets",
    dataMap: userBetsHistoryDataMap(dictionary),
    title: dictionary.myProfile_userHistoryBetsTitle,
    orderBy: "timestamp",
    actions: [
        {label: dictionary.myProfile_subscribedActiveBetsButtonLabel, dataType: "myProfile_subscribedActiveBets"},
        {label: dictionary.myProfile_myInactiveBetsButtonLabel, dataType: "myProfile_myInactiveBets", showWhenUserType: 2},
        {label: dictionary.myProfile_favoriteActiveBetsButtonLabel, dataType: "myProfile_favoriteActiveBets", showWhenUserType: 1},
        {label: dictionary.myProfile_myActiveBetsButtonLabel, dataType: "myProfile_myActiveBets", showWhenUserType: 2},

    ]
})

const myProfile_statistic = (dictionary = {}) => ({
    displayData: ({auth, data, type}) => userStatisticDisplayData({data, userId: auth.uid, dictionary, type}),
    fetchFunction: "fetchUserStatistic",
    orderBy: "profit",
    reducer: {weekly: "usersStatisticWeekly", monthly: "usersStatisticMonthly", overall: "usersStatisticOverall"},
    
})

/// USER PROFILE PAGE DATA MAP
const userProfile_userActiveBetsInfo = (dictionary = {}) => ({
    displayData: ({auth, data, orderBy="userId", userId, orderDirection=1}) => betsDisplayedData({data: data.activeBets, orderBy, orderDirection, groupBy: userId, dictionary}),
    fetchFunction: "fetchActiveBetsInfoByUserId",
    reducer: "activeBets",
    dataMap: userActiveBetsInfoDataMap(dictionary),
    orderBy: "userId",
    title: dictionary.userProfile_userActiveBetsInfoTitle,
    actions: [
        {label: dictionary.userProfile_userHistoryBetsButtonLabel, dataType: "userProfile_userHistoryBets"},
    ]
})

const userProfile_userHistoryBets = (dictionary = {}) => ({
    displayData: ({auth, data, orderBy="timestamp", userId, orderDirection=1}) => betsDisplayedData({data: data.userHistoryBets[userId], orderBy, orderDirection, dictionary, history: true}),
    fetchFunction: "fetchUserBetsHistory",
    reducer: "userHistoryBets",
    dataMap: userBetsHistoryDataMap(dictionary),
    title: dictionary.userProfile_userHistoryBetsTitle,
    orderBy: "timestamp",
    actions: [
        {label: dictionary.userProfile_userActiveBetsInfoButtonLabel, dataType: "userProfile_userActiveBetsInfo"},
    ]
})

/// TIPSTER PAGE DATA MAP
const tipsters_leaderBoardMonthly = (dictionary = {}) => ({
    displayData: ({auth, data, orderBy="profit", groupBy, orderDirection}) => leaderBoardDisplayedData({data: data.usersStatisticMonthly, dictionary, orderBy, orderDirection}),
    fetchFunction: "fetchMonthlyStatisticData",
    reducer: "usersStatisticMonthly",
    dataMap: tipstersDataMap(dictionary),
    orderBy: "profit",
    title: dictionary.tipsters_leaderBoardMonthlyTitle,
    actions: [
        {label: dictionary.tipsters_leaderBoarWeeklyButtonLabel, dataType: "tipsters_leaderBoarWeekly"},
        {label: dictionary.tipsters_leaderBoardOverallButtonLabel, dataType: "tipsters_leaderBoardOverall"},
    ]
})

const tipsters_leaderBoarWeekly = (dictionary = {}) => ({
    displayData: ({auth, data, orderBy="profit", groupBy, orderDirection}) => leaderBoardDisplayedData({data: data.usersStatisticWeekly, dictionary, orderBy, orderDirection}),
    fetchFunction: "fetchWeeklyStatisticData",
    reducer: "usersStatisticWeekly",
    dataMap: tipstersDataMap(dictionary),
    orderBy: "profit",
    title: dictionary.tipsters_leaderBoarWeeklyTitle,
    actions: [
        {label: dictionary.tipsters_leaderBoardOverallButtonLabel, dataType: "tipsters_leaderBoardOverall"},
        {label: dictionary.tipsters_leaderBoardMonthlyButtonLabel, dataType: "tipsters_leaderBoardMonthly"},
    ]
})


const tipsters_leaderBoardOverall = (dictionary = {}) => ({
    displayData: ({auth, data, orderBy="profit", groupBy, orderDirection}) => leaderBoardDisplayedData({data: data.usersStatisticOverall, dictionary, orderBy, orderDirection}),
    fetchFunction: "fetchOverallStatisticData",
    reducer: "usersStatisticOverall",
    dataMap: tipstersDataMap(dictionary),
    orderBy: "profit",
    title: dictionary.tipsters_leaderBoardOverallTitle,
    actions: [
        {label: dictionary.tipsters_leaderBoarWeeklyButtonLabel, dataType: "tipsters_leaderBoarWeekly"},
        {label: dictionary.tipsters_leaderBoardMonthlyButtonLabel, dataType: "tipsters_leaderBoardMonthly"},
    ]
})

export default {
    leaderBoardWeekly,
    leaderBoardMonthly,
    leaderBoardOverall,

    landingPage_statistic,

    home_userSubscribedBets,
    home_userFavoriteBets,
    home_allActiveBetsInfo,  
    home_userBets,
     
    myProfile_subscribedActiveBets,
    myProfile_userHistoryBets,
    myProfile_favoriteActiveBets,
    myProfile_myActiveBets,
    myProfile_statistic,
    myProfile_myInactiveBets,

    userProfile_userActiveBetsInfo,
    userProfile_userHistoryBets,

    bets_allActiveBetsInfo,
  
    betInfo_allActiveBetsInfoBySport,
    betInfo_allHistoryBets,

    historyBetInfo_userHistoryBets, 

    tipsters_leaderBoardOverall, 
    tipsters_leaderBoarWeekly,
    tipsters_leaderBoardMonthly
}