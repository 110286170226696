import React from 'react';
import classNames from 'classnames'
import { makeStyles, createStyles, useTheme, withStyles, darken } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Box from '@material-ui/core/Box';
import CustomIcon from './../../../components/icons/CustomIcon'
import Preloader from './../../../components/preloaders/Preloader'


const TitleButton = withStyles(theme => ({
    root:{
        borderRadius: theme.shape.borderRadius,
        borderBottom: "1px solid "+theme.palette.background.dark,
        fontSize: '0.7rem',
        color: "white",
        background: theme.palette.background.primary,
        height: 45,
        paddingLeft: 16,
        paddingRight: 16,

        '&:hover':{
            borderBottom: "none",
            background: theme.palette.background.transparent
        }

    }
}))(ButtonBase)

const useStyles = makeStyles( theme => createStyles({

    root: {
        display:"flex",
        flexDirection:"row",
        flexWrap: "nowrap",
        alignItems:"center",
        overflow: "hidden",
        width: "100%",
        fontSize: 18, 
        color: "white",
    },

    buttonBase:{
        //background: `url("${GoldImg}")`,
        color: "inherit",
        width: "100%", 
        height: "100%",
        cursor: "pointer",

        transition: "background 0.2s ease-in-out",
        borderRadius: 4,
        "&:hover":{
            background: theme.palette.primary.main
        }
    },

    buttonWrapper:{
        "&:hover":{
            boxShadow: "none"
        }
    },

    buttonWrapperDisabled:{
   
        borderBottom: "none",
        boxShadow: "none",
        color: "gray"
    },

    buttonLabel:{
        fontFamily: 'Racing Sans One',

        fontSize: "1.2em"
    },

    title:{
        //background: `url("${GoldImg}")`,
        
        borderRadius: 4,

        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        
    },

    titleTypography:{
        padding: 0,
        color: theme.palette.text.secondary,
        fontWeight: 200,
        fontFamily: 'Racing Sans One',
        textTransform: "uppercase",
        fontSize: "clamp(1em, 2vw, 1.2em)",

    },

    icon:{
        display:"flex",
        alignItems: "center",
        justifyContent: "center",

        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        height: "100%",

        transition: "width 0.1s ease-in-out"
    },

    iconHidden:{
        width: 0,
        visibility: "hidden"
    },

    iconActive:{
        width: "100%",
        visibility: "visible",
        transition: "width 0.2s ease-in-out"
    },

    label:{
        width:"100%",
        transition: "all 0.1s ease-in-out",
        opacity: 1,
    },

    labelActive:{
        width: 0,
        transition: "all 0.2s ease-in-out",
        opacity: 0,
    },

    button:{
        fontSize: "0.85em",
        cursor: "pointer",

        "&:hover":{
            color: "white",
            textDecoration: "underline",
        },

        [theme.breakpoints.down("md")]:{
            fontSize: "1em",
        },

        [theme.breakpoints.down("sm")]:{
            fontSize: "1.2em",
        }

    },

    buttonSmall:{
        fontSize: "1em",
        cursor: "pointer",

        "&:hover":{
            color: "white",
            textDecoration: "underline",
        },

        [theme.breakpoints.down("md")]:{
            fontSize: "0.9em",
        },

        [theme.breakpoints.down("sm")]:{
            fontSize: "0.8em",
            
        }

    },

    activeLarge:{
        background: theme.palette.primary.main,
    },

    activeSmall:{
        textDecoration: "underline"
    },

    mobileFontWeight:{
        [theme.breakpoints.down('sm')]:{
            fontWeight: 400
        }
    }

}))

function CustomButton({
    auth,
    height, 
    width, 
    color,
    disableGutter=false, 
    type="title", 
    ButtonBaseProps, 
    TypographyProps, 
    label, 
    info,
    bgcolor, 
    onClick, 
    icon, 
    buttonActive,
    hideIcon=false, 
    disabled=false, 
    loading=false,
    fontSize="clamp(1.3em, 2vw, 1.5em)",
    iconFontSize="clamp(1.5em, 3vw, 2em)",
    ...props}){
   
    const classes = useStyles(props)
    const theme = useTheme()

    const iconBox = React.useRef()

    const [active, setActive] = React.useState(false)

    React.useEffect(()=>{
       
        setActive(buttonActive ? buttonActive : false)
    }, [buttonActive])

    if(type === "button"){
        return (
            <Box 
                onClick={disabled ? null : onClick} 
                pb={0 } 
                width={width} 
                height={height} 
                borderRadius={4}
                boxShadow={theme.shadows[1]}
                className={classNames({[classes.buttonWrapper]: !disabled, [classes.buttonWrapperDisabled]: disabled})}
                borderBottom={"1px solid "+theme.palette.background.dark}
                bgcolor={bgcolor ? bgcolor : theme.palette.background.primary} 
                onMouseEnter={()=> !disabled && setActive(true)}
                onMouseLeave={()=> !disabled && setActive(false)}
                color={color ? color: theme.palette.text.secondary} 
            >
                
                <ButtonBase 
                    className={classes.buttonBase}  
                    disabled={disabled || loading} 
                    {...ButtonBaseProps} 
                >
                    <Box 
                        position="relative" 
                        px={2} 
                        borderRadius={4}
                        fontSize={fontSize} 
                        width="100%" 
                        height="100%" 
                        display="flex" 
                        alignItems="center" 
                        justifyContent="center" 
                        flexWrap="nowrap"   
                    >
                        {
                            icon ?
                                <>
                                    <Box className={classNames(classes.icon, {[classes.iconHidden]: hideIcon,[classes.iconActive]: active})} fontSize={iconFontSize} overflow="hidden">
                                        <CustomIcon name={icon} />
                                    </Box>
                                    <Box className={classNames(classes.label, {[classes.labelActive]: active})} visibility={loading ? "hidden":"visible"}>
                                        <Typography className={classes.buttonLabel} align="center" fontSize="inherit" noWrap={true}>{label}</Typography> 
                                    </Box> 
                                </>:
                                <Box width="100%" visibility={loading ? "hidden":"visible"}>
                                    <Typography className={classes.buttonLabel} align="center" fontSize="inherit" noWrap={true}>{label}</Typography> 
                                </Box> 
                        }
                        {loading && <Preloader rootStyle={{position: "absolute", top: 0, left: 0, width:"100%", height:"100%"}} size={height/2}/>}  
                    </Box>
                </ButtonBase>
            </Box>
        )
    }else if(type === "title"){
       
        return (
            <Box 
                className={classes.title} 
                pb={0}
                width={width || "auto"}
                height={height || 60} 
                bgcolor={bgcolor ? bgcolor : theme.palette.background.primary} 
                fontSize={fontSize} 
                color="white" 
                borderBottom={"1px solid "+theme.palette.background.dark}
            >
                <Typography height={height} width={width} variant={"h1"} className={classes.titleTypography} {...TypographyProps} noWrap>
                    {label}
                </Typography>
                {props.children}
            </Box>
        )
    }else if(type === "titleButton"){
       
        return (
            <TitleButton 
                onClick={onClick}
                style={props.active ? { background: theme.palette.primary.main} : {}}
            >
                {
                    label &&
                        <Typography variant={"h2"} fontSize="inherit" noWrap>
                            {label}
                        </Typography>
                }
                {props.children}
            </TitleButton>
        )
    }else if(type === "titleIconButton"){
       
        return (
            <Box display="flex">
                {
                    label &&
                        <Box display="flex" alignItems="center" px={2} mr={1} fontSize='clamp(0.8em, 2vw, 1em)' color="white" borderRadius={4} bgcolor={bgcolor || theme.palette.background.primary} >
                            <Typography variant={"h2"} fontSize="inherit" noWrap>
                                {label}
                            </Typography>
                        </Box>
                }
                <ButtonBase 
                    onClick={onClick}
                    style={{
                        borderRadius: theme.shape.borderRadius,
                        borderBottom: "1px solid "+theme.palette.background.dark,
                        fontSize: 'clamp(0.8em, 2vw, 1em)',
                        color: "white",
                        background: bgcolor || theme.palette.background.primary,
                        height: height || 45,
                        paddingLeft: 8,
                        paddingRight: 8
                    }}  
                >
                    <CustomIcon name={icon}/>      
                </ButtonBase>
            </Box>

        )
    }else if(type === "small"){
      
        return (
          <Box
           
            color="white"  
            p={1}
            pl={3}
            className={classNames(classes.buttonSmall, {[classes.activeSmall]: active})}
            onClick={onClick}
          >
            <Box className={classNames(classes.button, {[classes.activeSmall]: active})} display="flex" flexDirection="row" alignItems="center">
                <Box pr={1} fontSize="1em" display="flex" flexDirection="row" alignItems="center">
                    <CustomIcon name={icon}/> 
                </Box>
                <Typography className={classes.mobileFontWeight} variant="body2">{label}</Typography>
                {
                    (info || info === 0 ) &&
                        <Box pl="4px">
                            <Typography className={classes.mobileFontWeight} variant="body2">({info})</Typography>
                        </Box>
                }
               
            </Box>
          </Box>
        )
    }else if(type === "large"){
        return (
            <ButtonBase onClick={onClick}>
                <Box p={2} display="flex" width="100%" color="white" fontSize="inherit" className={classNames(classes.button, {[classes.activeLarge]: active})}>
                    <Typography variant="h2" fontSize="inherit">{label}</Typography>
                </Box>
            </ButtonBase> 
        )       
    }else return null

}

/*

*/


  
export default CustomButton
