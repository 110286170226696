
import {
   SET_ACTIVE_SNACKBAR,
   CLOSE_ACTIVE_SNACKBAR,
 } from "../../actions/types";

export default (state = {active: false, name: "", label: "", status: "", actions: []}, action) => {
  let currState = {...state}

  switch (action.type) {
    case SET_ACTIVE_SNACKBAR:

      
      currState.active = true
      currState.name = action.name
      currState.status = action.status
      currState.label = action.label
      currState.actions = action.actions
      
      return currState

    case CLOSE_ACTIVE_SNACKBAR:
      //closes active snackbar
      currState.active = false
      return currState

    default:
      return currState;
  }
};
