import {
    FETCH_TROPHIES_INFO,
} from "../../../actions/types";
  
  
export default (state = "loading", action) => {
    switch (action.type) {
        case FETCH_TROPHIES_INFO:
            return action.payload
        default:
            return state
    }
};