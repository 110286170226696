import React from 'react';
import {withRouter} from 'react-router'
import * as actions from '../../../../redux/actions'
import { connect } from "react-redux";
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import Typography from '@material-ui/core/Typography';


import Switch from '@material-ui/core/Switch';
import CustomInfoLayout from './CustomInfoLayout'

import CustomButton from './CustomButton'
import editWrapper from '../../../default/wrappers/editWrapper'

import CustomDialogLayout from './CustomDialogLayout'
import useDictionary from '../../../../hooks/useDictionary';
import { useCookies } from 'react-cookie';

const useStyles = makeStyles( theme => createStyles({
   
    title:{

    },
    subTitle:{
      color: "white",
   
      borderBottom: "1px solid "+theme.palette.primary.main,
      marginBottom: theme.spacing(1),

      '& p':{
    
        fontSize:"0.95em",
        fontWeight: 400,
      }
  
    },
}))



function DeleteActiveBetModal({userGeoLocation, auth,...props}) {
  
    
    const theme = useTheme()
    const classes = useStyles()
    const dictionary = useDictionary({elementId: "remove_active_bet_modal", userGeoLocation})

    const handleDeleteActiveBet = ()=>{
      let selectedBet = props.modalStatus.data 

      props.removeUserActiveBet(selectedBet.key)
    }

    return (
      <CustomDialogLayout 
        name="removeActiveBetModal" 
        title={dictionary.title} 
        actions={[
          {label: dictionary.buttonLabel, onClick: handleDeleteActiveBet}
        ]}
      >
        <Box>
          <CustomInfoLayout 
            type="error"
            content={dictionary.infoContent}  
          />
        </Box>
      </CustomDialogLayout>
  )
}



const mapStateToProps = ({auth, userGeoLocation, modalStatus}) => {
  return {
    auth,
    userGeoLocation,
    modalStatus
  }
}

export default withRouter(connect(mapStateToProps, actions)(DeleteActiveBetModal))
