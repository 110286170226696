import firebase from 'firebase/app';
import 'firebase/analytics'
import 'firebase/database'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/messaging'
import 'firebase/functions'


import { FirebaseConfig } from "../config/keys";

firebase.initializeApp(FirebaseConfig);

export const databaseRef = firebase.database().ref()

export const authRef = firebase.auth()
export const authProviderRef = firebase.auth.GoogleAuthProvider
export const authFacebookProviderRef = firebase.auth.FacebookAuthProvider

export const serverTimestamp = firebase.database.ServerValue.TIMESTAMP

export const userInfoRef = databaseRef.child("users")

export const pagesRef = databaseRef.child("pages")
export const pagesInfoRef = databaseRef.child("pagesInfo")

export const globalElementsRef = databaseRef.child("globalElements")

export const subscriptions = databaseRef.child("subscriptions")
export const usersRef = databaseRef.child("users")

export const dictionaryRef = databaseRef.child("dictionary")

export const imagesRef = databaseRef.child("images")

export const customContainersRef = databaseRef.child("customContainers")

/// BRDOPARA REF ///
export const todaysMatchesRef = databaseRef.child('todaysMatches')
export const betsRef = databaseRef.child('bets')
export const favActiveBetsRef = databaseRef.child('favActiveBets')
export const usersStatisticRef = databaseRef.child('usersStatistic')
export const trophiesRef = databaseRef.child("tipstersAchievementsOptions")
export const trophiesInfoRef = databaseRef.child("tipstersAchievementsOptionsInfo")


/// STORAGE REFS ////
export const storageRef = firebase.storage().ref()

export const imageStorage = storageRef.child("images")
export const pdfStorage = storageRef.child("pdfs")

/// CLOUD FUNCTIONS REF ////
export const cloudFunction = firebase.app().functions('us-central1')
export const cloudFunctionEu = firebase.app().functions('europe-west1')

// ANALYTICS REFS ////
export let analytics = null
if(process.env.NODE_ENV !== 'development'){
    analytics = firebase.analytics()
}

/// MESSAGING
export let messagingRef = null
export let getPushNotificationToken = null

if (firebase.messaging.isSupported()) {
    messagingRef = firebase.messaging()
    getPushNotificationToken = async () => await messagingRef.getToken({vapidKey: "BIQ21EMgdFX-0kKFF3i44tWQimyoqeNZO1zrD3nV9A5hZ9Wp4E92ZzWvURI24NCsbsIr5jy54enxCY5AZG_9vVo"})
}






