import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import CustomIcon from './../../../components/icons/CustomIcon'

const useStyle = makeStyles( theme => createStyles({
  
}))

function CustomInfoLayout(props){
   
    const theme = useTheme();
    const classes = useStyle()

    return (           
        <Box display="flex" alignItems="stretch" border={"2px solid "+theme.palette[props.type].dark} borderRadius={4}>
            <Box p={2} display="flex" alignItems="center" fontSize="clamp(1.8em, 3vw, 2.5em)" color="white" bgcolor={theme.palette[props.type].dark}>
                <CustomIcon name={props.type} />
            </Box>
            <Box p={2} display="flex" alignItems="center"  color="white">
                <Typography variant="body2" align="left" style={{fontSize: "clamp(0.8em, 3vw, 0.9em)", fontWeight: 400}} >
                    {props.content}
                </Typography>       
            </Box>
            
        </Box>         
    )
  }

  export default CustomInfoLayout