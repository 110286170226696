import React from 'react';

import {connect} from "react-redux";
import * as actions from './../../../redux/actions'

import { useTheme } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import EditWrapperMenu from './EditWrapperMenu'
import elementsConstructor from './../../elementsConstructor'

function editWrapper(WrappedComponent){

  function EditWrapper(props){

    const {id, edit, selectedElement, type, copySelectedElement, elements, elementEventsDisabled, showAllElements, addSelectedCustomContainer} = props

    const [hovered, setHovered] = React.useState(false)

    const theme = useTheme()

    const selectedElementObj = elements[selectedElement]
    const selectedElementConstructor = selectedElementObj && elementsConstructor[selectedElementObj.type]

    const handleMouseEnter = (e)=>{
      //console.log("enter "+id)
      e.stopPropagation()
      setHovered(true)
    }

    const handleMauseLeave = (e)=>{
      //console.log("leave "+id)
      e.stopPropagation()
      setHovered(false)
    }
    const handleElementSelect = (e) => {
      e.stopPropagation()
      showAllElements && props.setShowAllElements(false)
      props.setSelectedElement(id)
    }

    const selectedElementStyle = {
      position: "relative",
      border: "1px solid "+theme.palette.primary.main,

      boxShadow: "0px 0px 3px 0px "+theme.palette.primary.main,
      transition: "padding 0.1s ease-in"
    }

    const hoveredElementStyle = {
      position: "relative",

      border: "1px dashed "+theme.palette.primary.main,
      boxShadow: "0px 0px 3px 0px "+theme.palette.primary.main,
      transition: "padding 0.05s ease-in-out"
    }

    const copyToElementStyle = {
      position: "relative",
      padding: 24,
      border: "1px dashed "+theme.palette.primary.main,
      boxShadow: "0px 0px 3px 0px "+theme.palette.primary.main,
      transition: "padding 0.1s ease-in-out"
    }

    const showAllElementsStyle = {
      position: "relative",
      right: 24,

      top: 24,
      marginBottom: 24,

      boxShadow: "-1px 0px 5px 1px rgba(0,0,0,0.2)",
      transition: "right 0.1s ease-in-out"
    }

    const childStyle = {
      ...props.elementStyle,
      width: "100%",
      height: "100%",
      padding: 0,
      margin: 0,
      ...props.style
    }

    let wrapperStyle = {
      ...props.elementStyle,
      background: "none",
      transition: "all 0.1s ease-in",
      ...props.style
    }
   
    /// SELECTOR FOR ADDING NEW ELEMENT TO THIS ELEMENT
    let canCopyElementToThisElement = selectedElement !== id && copySelectedElement &&
                                        selectedElementConstructor.containedInElements &&
                                          selectedElementConstructor.containedInElements.includes(type)

    /// SELECTOR FOR ADDING CUSTOM CONTAINER TO ELEMENT
    let canAddCustomContainerToThisElement = addSelectedCustomContainer && (type === "grid_item_element" || type === "box_element")


    wrapperStyle = selectedElement === id ?
                      {...wrapperStyle, ...selectedElementStyle}:
                      canCopyElementToThisElement ?
                        {...wrapperStyle, ...copyToElementStyle}:
                        hovered ?
                          {...wrapperStyle, ...hoveredElementStyle}:
                          {...wrapperStyle}

    wrapperStyle = showAllElements ?
                      {...wrapperStyle, ...showAllElementsStyle} : wrapperStyle

    wrapperStyle = canAddCustomContainerToThisElement ?
                      {...wrapperStyle, ...copyToElementStyle} :
                      wrapperStyle



    const elementLabel = (
      <Box position="absolute" height={24} zIndex={1000} top={0} left={0} px={1} display="flex" flexDirection="row" alignItems="center" bgcolor="primary.main" color="white">
        <Typography color="inherit" style={{fontSize: 10}}>{props.name}</Typography>
      </Box>
    )

    if(edit){
      
        return (
          <Box
            onClick={!elementEventsDisabled ? handleElementSelect : null}
            onMouseOver={!elementEventsDisabled ? handleMouseEnter : null}
            onMouseOut={!elementEventsDisabled ? handleMauseLeave : null}
            style={wrapperStyle}
          >
            {
              (selectedElement === id || canCopyElementToThisElement || canAddCustomContainerToThisElement) &&
                <EditWrapperMenu canCopyElementToThisElement={canCopyElementToThisElement} canAddCustomContainerToThisElement={canAddCustomContainerToThisElement} {...props}/>
            }

            {(selectedElement === id || hovered || canCopyElementToThisElement || canAddCustomContainerToThisElement) && elementLabel}

            <WrappedComponent {...{...props, style: childStyle}}/>
          </Box>
        )
      


    }else return <WrappedComponent {...props}/>
  }

  const mapStateToProps = ({elements, selectedElement, auth, selectedPage, copySelectedElement, elementEventsDisabled, showAllElements, addSelectedCustomContainer}) => {
    return {
      elements,
      selectedElement,
      selectedPage,
      auth,
      copySelectedElement,
      elementEventsDisabled,
      showAllElements,
      addSelectedCustomContainer
    }
  }

  return connect(mapStateToProps, actions)(EditWrapper)
}

export default editWrapper
