import {
    FETCH_USER_ACTIVE_BETS,
    REMOVE_USER_ACTIVE_BET,
    ADD_USER_ACTIVE_BET,
} from "../../../actions/types";
  
  
export default (state = "loading", action) => {
    
    //initialize todays matches object on first load
    let userActiveBets = state !== "loading" ? {...state} : {}
    switch (action.type) {
        case FETCH_USER_ACTIVE_BETS:   
            userActiveBets = {...userActiveBets.all, ...action.payload} 
            return userActiveBets;

        case ADD_USER_ACTIVE_BET:
            userActiveBets ={...userActiveBets, [action.betId]: action.payload} 
            return userActiveBets

        case REMOVE_USER_ACTIVE_BET:
            if(userActiveBets[action.betKey]) delete userActiveBets[action.betKey]
            return userActiveBets
       
        default:
            return state
    }
};