const FirebaseConfig = {
  apiKey: "AIzaSyB33jsewvqUX1WThlB85GZtZYLSBCZMzNg",
  authDomain: "brdopara2.firebaseapp.com",
  databaseURL: "https://brdopara2-default-rtdb.firebaseio.com",
  projectId: "brdopara2",
  storageBucket: "brdopara2.appspot.com",
  messagingSenderId: "473536486717",
  appId: "1:473536486717:web:361b75c1086fb4ff2ef954",
  measurementId: "G-B5WBWLEDX6"
};

module.exports = {FirebaseConfig}
