import React from 'react';

import { connect } from "react-redux";
import * as actions from '../../../../redux/actions'

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';

import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';

import { Typography } from '@material-ui/core';

import useDictionary from '../../../../hooks/useDictionary';
import CustomInfoLayout from './CustomInfoLayout';
import CustomIcon from './../../../../webElements/components/icons/CustomIcon'
import AdvertisementElement from './AdvertisementElement';

const useStyles = makeStyles( theme => createStyles({
  root: {
    display:"flex",
    flexDirection: "column",
    width: "100%"
  },
  container:{
    
    display: "flex",
    flexDirection:"column",
    justifyContent: "space-between",
    background: theme.palette.background.primary,
    color:"white",

    marginTop: theme.spacing(1),
    padding: theme.spacing(2),

    borderRadius: theme.shape.borderRadius,
    borderBottom: "1px solid black"
  },

  title:{
    padding: theme.spacing(1),
   
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),

    background: theme.palette.background.primary,

    width: "fit-content",
  
    display: "flex", 
    alignItems: "center", 
    justifyContent: "center", 
    borderRadius: theme.shape.borderRadius,

    color: "white",

    borderBottom: "1px solid black"
  },

  label:{
    height: 40,
    
    width: "fit-content", 
    display: "flex", 
    padding: "4px",

    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),

    background: theme.palette.primary.main,
    color:"black", 
    borderRadius: theme.shape.borderRadius,

    fontSize: ".9em",

    "& h2":{
        lineHeight: "30px"
    }
  },

  subLabel:{
    width: "fit-content", 
    display: "flex", 

    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),

    background: theme.palette.background.dark,
    color:"white", 
    borderRadius: theme.shape.borderRadius,

    fontSize: ".7em"
  },

  paragraph:{
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),

    "&:first-child":{
        paddingTop: theme.spacing(0),
    },

    "&:last-child":{
        paddingBottom: theme.spacing(0),
    },

    "& > p":{
        textAlign: "left",
        fontSize: "0.9em",
        marginTop: 0,
        marginBottom: theme.spacing(1),
        fontWeight: 300,

    }
  },

  table:{
      display: "flex",
      flexDirection: "column",

      minWidth: 200,

      maxWidth: 400,

  },

  tableHeader:{
        background: theme.palette.primary.main,
        color: "black",
       
  },

  tableRow:{
    display: "flex",
    width: "100%",
    borderBottom: "1px solid "+theme.palette.background.dark,
    borderLeft: "1px solid "+theme.palette.background.dark,
    borderRight: "1px solid "+theme.palette.background.dark,

    "& > div":{
        width: "50%",
        padding: "4px"
    }
  },

  starsContainer:{
      display: "flex",
      alignItems: "center",

      height: 40,

      marginLeft: theme.spacing(1),
      
      padding: theme.spacing(1),

      background: theme.palette.background.dark,

      borderRadius: theme.shape.borderRadius,

  }
}))

const sportsMargins = [
    {sport: "Soccer", margin: 4.6},
    {sport: "Tennis", margin: 3.8},
    {sport: "Basketball", margin: 4.3},
    {sport: "Ice hockey", margin: 5.6},
    {sport: "Baseball", margin: 4.0},
    {sport: "Am. football", margin: 4.9},
]
function BettingSitesInfo({trophiesInfo, trophies, userGeoLocation, ...props}){

    const classes = useStyles()
    const theme = useTheme()

    const dictionary = useDictionary({elementId: "betting_sites_info_element", userGeoLocation})
    if(dictionary["melbet"]){
        return (
            <Box className={classes.root}>
                <Grid container justify="space-evenly" spacing={1}>
                    <Fade in={true} timeout={300}>
                        <Grid item xs={12} sm={12} md={12} lg={10}>
                            <Box className={classes.title}>
                                {
                                     /*
                                        <Box mr={1} display="flex" alignItems="center" height="40px">
                                            <img height="40px" width="auto" alt="icon" src="" />
                                        </Box>
                                    */
                                }
                               
                                <Typography variant="h2" align="left">MELBET</Typography>
                            </Box>
                        
                            <Box className={classes.container}>
                            
                                <Box className={classes.paragraph} display="flex" flexDirection="column">
                                    <Box display="flex">
                                        <Box className={classes.label}>
                                            <Typography variant="h2">{dictionary["melbet"].oddsTitleLabel}</Typography>
                                        </Box>
                                        <Box className={classes.starsContainer}>
                                            <Box mr={1}>
                                                <Typography variant="body2">Ocena</Typography>
                                            </Box>
                                            
                                            <CustomIcon name="star" />
                                            <CustomIcon name="star" />
                                            <CustomIcon name="star" />
                                            <CustomIcon name="star" />
                                            <CustomIcon name="star" />
                                        </Box>
                                    </Box>
                                    
                                   
                                    {dictionary["melbet"].oddsParagraphContent}
                                   
                                    <Box className={classes.table}>
                                        <Box className={classes.tableHeader}>
                                            <Box className={classes.tableRow}>
                                                <Box>
                                                    <b>Sport</b>
                                                </Box>
                                                <Box>
                                                    <b>Margin</b>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box className={classes.tableBody}>
                                            {
                                                sportsMargins.map(o =>(
                                                    <Box className={classes.tableRow}>
                                                        <Box>
                                                            {o.sport}
                                                        </Box>
                                                        <Box>   
                                                            {o.margin}%
                                                        </Box>
                                                    </Box>
                                                ))
                                            }
                                        </Box>
                                    </Box>
                                </Box>
        
                                <Box className={classes.paragraph} display="flex" flexDirection="column">
                                    <Box display="flex">
                                        <Box className={classes.label}>
                                            <Typography variant="h2">{dictionary["melbet"].offersTitleLabel}</Typography>
                                        </Box>
                                        <Box className={classes.starsContainer}>
                                            <Box mr={1}>
                                                <Typography variant="body2">Ocena</Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center">
                                                <CustomIcon name="star" />
                                                <CustomIcon name="star" />
                                                <CustomIcon name="star" />
                                                <CustomIcon name="star" />
                                                <CustomIcon name="star" />
                                            </Box>
                                        </Box>
                                    </Box>
                                    
                                    {dictionary["melbet"].offersParagraphContent}
                                    <CustomInfoLayout 
                                        type="warning"
                                        content={dictionary["melbet"].offersWarningContent}
                                    />
                                </Box>
                            </Box>
                            <Box mt={1} borderRadius={4} overflow="hidden">
                                <AdvertisementElement 
                                    advertiser="melbet"
                                    location="bets"
                                    height={170}
                                />
                            </Box>
                           
                            <Box className={classes.container}>

                                <Box className={classes.paragraph} display="flex" flexDirection="column">
                                    <Box display="flex">
                                        <Box className={classes.label}>
                                            <Typography variant="h2" >{dictionary["melbet"].bonusTitleLabel}</Typography>
                                            
                                        </Box>
                                        <Box className={classes.starsContainer}>
                                            <Box mr={1}>
                                                <Typography variant="body2">Ocena</Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center">
                                                <CustomIcon name="star" />
                                                <CustomIcon name="star" />
                                                <CustomIcon name="star" />
                                                <CustomIcon name="star" />
                                                <CustomIcon name="star" />
                                            </Box>
                                        </Box>
                                    </Box>
                                    {dictionary["melbet"].bonusParagraphContent}

                                    <Box className={classes.paragraph} pl={2} display="flex" flexDirection="column">
                                        <Box className={classes.subLabel}>
                                            <Typography variant="h2" >{dictionary["melbet"].bonusSubtitleLabel}</Typography>
                                        </Box>
                                    
                                        {dictionary["melbet"].bonusSubParagraphContent}
                                    </Box>
                                    
                                    
                                </Box>
        
                                <Box className={classes.paragraph} display="flex" flexDirection="column">
                                    <Box display="flex">
                                        <Box className={classes.label}>
                                            <Typography variant="h2" align="left">{dictionary["melbet"].depositTitleLabel}</Typography>
                                        </Box>
                                        <Box className={classes.starsContainer}>
                                            <Box mr={1}>
                                                <Typography variant="body2">Ocena</Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center">
                                                <CustomIcon name="star" />
                                                <CustomIcon name="star" />
                                                <CustomIcon name="star" />
                                                <CustomIcon name="star" />
                                                <CustomIcon name="star" />
                                            </Box>
                                        </Box>
                                    </Box>
                                    
                                    {dictionary["melbet"].depositParagraphContent}
                                    
                                </Box>

                                <Box className={classes.paragraph} display="flex" flexDirection="column">
                                    <Box display="flex">
                                        <Box className={classes.label}>
                                            <Typography variant="h2" align="left">{dictionary["melbet"].userExperienceTitleLabel}</Typography>
                                        </Box>
                                        <Box className={classes.starsContainer}>
                                            <Box mr={1}>
                                                <Typography variant="body2">Ocena</Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center">
                                                <CustomIcon name="star" />
                                                <CustomIcon name="star" />
                                                <CustomIcon name="starEmpty" />
                                                <CustomIcon name="starEmpty" />
                                                <CustomIcon name="starEmpty" />
                                            </Box>
                                        </Box>
                                    </Box>
                                    
                                    {dictionary["melbet"].userExperienceParagraphContent}
                                    
                                </Box>
                            </Box>
                            <Box mt={1} borderRadius={4} overflow="hidden">
                                <AdvertisementElement 
                                    advertiser="melbet"
                                    location="bets"
                                    height={170}
                                />
                            </Box>
                            <Box className={classes.container}>
                                <Box className={classes.paragraph} display="flex" flexDirection="column">
                    
                                    <Box display="flex">
                                        <Box className={classes.label}>
                                            <Typography variant="h2" align="left">{dictionary["melbet"].overallTitleLabel}</Typography>
                                        </Box>
                                        <Box className={classes.starsContainer}>
                                            <Box mr={1}>
                                                <Typography variant="body2">Ocena </Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center">
                                                <CustomIcon name="star" />
                                                <CustomIcon name="star" />
                                                <CustomIcon name="star" />
                                                <CustomIcon name="star" />
                                                <CustomIcon name="starHalfEmpty" />
                                            </Box>
                                           
                                        </Box>
                                    </Box>
                                    
                                    {dictionary["melbet"].overallParagraphContent}
                                    
                                </Box>
                            </Box>
                        </Grid>
                    </Fade>
                </Grid>
            </Box>
          )
    }else return null
 
}

const mapStateToProps = ({trophiesInfo, userGeoLocation, trophies}) => {
    return {
        trophiesInfo,
        trophies,
        userGeoLocation
    }
  }

export default connect(mapStateToProps, actions)(BettingSitesInfo)
