import React from 'react';
import * as actions from '../../../../redux/actions'
import { connect } from "react-redux";
import classNames from 'classnames'
import {Redirect, withRouter} from 'react-router'
import throttle from 'lodash/throttle'
import { makeStyles, useTheme, createStyles, withStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import Grow from '@material-ui/core/Grow';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { ListItem, List, ListItemText , ListItemIcon} from '@material-ui/core';

import LoginBox from './LoginBoxElement'
import Navbar from './NavbarElement'

import { ResponsiveLine } from '@nivo/line'

import editWrapper from '../../../default/wrappers/editWrapper'
import Preloader from './../../../components/preloaders/Preloader'
import IconElement from '../../../default/elements/IconElement';
import displayDataConstructor from './../displayedDataConstructor'
import useDictionary from './../../../../hooks/useDictionary'
import CustomIcon from '../../../components/icons/CustomIcon';

const CustomListItemIcon = withStyles(theme => ({
    root:{
        minWidth: 0,
        paddingRight: theme.spacing(1),
        color: "white"
    }
}))(ListItemIcon)

const CustomListItem = withStyles(theme => ({
    root:{
        padding: theme.spacing(1)
    }
}))(ListItem)

const CustomListItemText = withStyles(theme => ({
    root:{
        fontSize: "0.8em",

        '&:hover':{
            textDecoration: "underline",
            cursor: "pointer"
        }
    }
}))(ListItemText)

const OFFSET_REG_BACkGROUND = -160

const useStyles =  makeStyles( theme => createStyles({
    root:{
        
        width: "100%",
        height: "100%",
        display:"flex",
        flexDirection:"column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",

        
    },
    rootBackground:{
        position:"fixed",
        top: 0,
        left: 0,
        width: "100%",
        minWidth: 1000,
        height: "100%",
        minHeight: 1000,
    },

    /// OVERLAY

    loadingOverlay:{
        
        opacity: 0,
        background: theme.palette.background.secondary,
        transition: "opacity .5s ease-in-out"
    },

    loadingOverlayActive:{
        opacity: 1
    },

    //  PAGES STYLES

   
    page:{
        height: "100vh",
        width: "100%",
        
    },

     // FIRST PAGE
    homePage:{
        position: "relative",
        display:"flex",
        justifyContent:"center"
    },
    homeBackground:{
        position:"absolute",
        bottom: 0,
        left: 0,
        width: "100%",
        minWidth: 1000,
        height: "100%",
   
        minHeight: 400,

        [theme.breakpoints.down("md")]:{
            minWidth: "auto",
           
            top:0,
        }
    },
    homeTitleContainer:{
        marginTop: 80,
        height: "100%",
        [theme.breakpoints.down("sm")]:{
            marginTop: -40,
            padding: theme.spacing(3)
        }
    },
    homeTitle:{
        padding: 0,
        userSelect: "none",
        fontSize: "clamp(70px, 4vw, 120px)"
    },
    homeTitle1:{
        fontSize: "clamp(50px, 4vw, 50px)",
        paddingRight: theme.spacing(2)
    },
    homeTitle2:{
        fontSize: "clamp(50px, 4vw, 80px)",
        textShadow: "0px 0px 3px #000000"
    },
    homeTitle3:{
        color: theme.palette.primary.main,
        marginRight: -theme.spacing(8),
        fontSize: "clamp(50px, 4vw, 120px)",
        textShadow: "0px 0px 3px #000000",

        [theme.breakpoints.down("md")]:{
            marginRight: 0,
        }
    },  
    homeButton:{
        fontSize: "clamp(30px, 4vw, 30px)",
        
    },

    // SECOND PAGE 

    howPage:{
        position: "relative",
        display: "flex",
        alignItems:"center",
        justifyContent:"space-around",

        [theme.breakpoints.down("xs")]:{
            minHeight: 1400,
        }
    },
    howBackground:{
        position:"absolute",
        top: 0,
        left: 0,
        width: "100%",
        minWidth: 1000,
        height: "100%",
        
        minHeight: 1000,

        [theme.breakpoints.down("md")]:{
            minWidth: "auto",
        }
    },
    howPhoneTitle:{
        color: theme.palette.primary.main,
        textTransform: "uppercase", 
        textShadow: "0px 0px 3px #000000",
        padding: 0,
        userSelect: "none",
    },
    howPhoneSubTitle:{
        color: "white",
        userSelect: "none",
        padding: 0,
    },
    howPhoneImageContainer:{
        paddingTop: theme.spacing(1),
        width: 200,

        [theme.breakpoints.down("xs")]:{
            width: 150,
        }
    },

    howArrowIconContainer:{
        [theme.breakpoints.down("sm")]:{
            display: "none",
        }
    },

    //THIRD PAGE
    regPage:{
        position: "relative",
        display: "flex",
        alignItems:"center",
        justifyContent:"center",
        minHeight: 900
    },

    regBackground:{
        position:"absolute",
        top: -OFFSET_REG_BACkGROUND,
        left: 0,
        width: "100%",
        minWidth: 1000,
        height: "100%",
        
        minHeight: 1000,

        transition: "all 0.25s ease-in-out",

        [theme.breakpoints.down("md")]:{
            minWidth: "auto",
        }
    },

    regFormContainer:{
        width: "50%",
        display: "flex",
        alignItems:"center",
        justifyContent:"space-around",

        [theme.breakpoints.down("md")]:{
            width: "100%",
            padding: theme.spacing(3)
        }
    },

    regImageContainer:{
        position: "relative",
        width: "700px",
        display: "flex",
        alignItems:"center",
        justifyContent:"space-around",

        [theme.breakpoints.down("md")]:{
            display: "none",
        }
    },

    //FOURTH PAGE

    tipstersPage:{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems:"center",
        justifyContent:"flex-start",


        [theme.breakpoints.down("xs")]:{
            minHeight: 2200
        }
    },

    tipstersFirstBackground:{
        position:"absolute",
        top: 0,
        left: 0,
        width: "100%",
        minWidth: 1000,
        height: "100%",
        
        transition: "all 0.5s ease-in-out",
    },
    tipstersSecondBackground:{
        position:"absolute",
        top: 0,
        bottom: 0,
        left: 0,
        width: "100%",
        minWidth: 1000,
        height: "100%",
        zIndex: 10,
        transition: "all 0.5s ease-in-out",
    },
    tipstersSecondBackgroundActive:{
        top: "calc(80% - 100px)",
    },

    tipsterBoxTitle:{
        fontSize: "clamp(0.9em, 3vw, 1.1em)",
    
    },

    tipstersTitleContainer:{
       
        width: "100%", 
        display: "flex",  
        flexDirection:"column", 
        justifyContent: "center", 
        alignItems:"center",
        color: "white",
        transition: "all 0.5s ease-in-out",
        zIndex: 11
    },

    tipstersTitle:{
        color: theme.palette.primary.main,
        textShadow: "0px 0px 3px "+ theme.palette.text.primary,

        fontSize: "clamp(2.5em, 3vw, 3.5em)",

        paddingBottom: theme.spacing(5),
        marginTop: 200,

        [theme.breakpoints.down("xs")]:{
            marginTop: 400,
        },

        transition: "color 0.5s ease-in-out",
    },

    tipstersTitleActive:{
        color: theme.palette.text.primary,
    },

    //FOOTER
    footer:{
        position: "relative",
        minHeight: 200,
        width: "100%",
        background: theme.palette.background.primary,
        borderTop: "2px solid "+theme.palette.primary.main,
        display:"flex",
        justifyContent: "space-around",
        flexWrap: "wrap",

        padding: theme.spacing(2),
        paddingTop: theme.spacing(3)
    },

    footerBox:{
        display:"flex",
        flexDirection: "column",

        minWidth: 300,

    },

    footerBoxTitle:{
        width: "fit-content",
        background: theme.palette.primary.main,
        borderRadius: theme.shape.borderRadius,

    
        padding: theme.spacing(1),
        paddingTop: "4px",
        paddingBottom: "4px",
       
        color: theme.palette.text.primary,

        fontSize: "0.65em",
    }

}));

const getElementDocumentPosition = (element)=>{
    if(!element) return {top: 0, left: 0}
    let rect = element.getBoundingClientRect() || {top: 0, left: 0}
    let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

function HomeElement({
    brdoparaData,
    fetchMonthlyStatisticData,
    userGeoLocation,
    ...props
}){

    const classes = useStyles()
    const theme = useTheme()
    const dictionary = useDictionary({elementId: "home_element", userGeoLocation})
    const displayedDataDictionary = useDictionary({elementId: "displayed_data_constructor", userGeoLocation})

    const [loadingBackground, setLoadingBackground] = React.useState(true)
    const [loadingFirstPageImage, setLoadingFirstPageImage] = React.useState(true)
    const [topTipstersData, setTopTipstersData]  = React.useState(null)
    const [topTipsterDataLoaded, setTopTipsterDataLoaded]  = React.useState(false)
    const [scrollTopPosition, setScrollTopPosition] = React.useState(0)

    const howPageRef = React.useRef()
    const howPageOffsetY = getElementDocumentPosition(howPageRef.current).top * 1/2
    const registerFormRef = React.useRef()
    const registerFormOffSetY = getElementDocumentPosition(registerFormRef.current).top * 3/5
    const tipstersPageRef = React.useRef()
    const tipstersPageRefOffSetY = getElementDocumentPosition(tipstersPageRef.current).top * 3/4

    const loadingContent = loadingBackground || loadingFirstPageImage
    
    React.useEffect(()=>{
        window.addEventListener('scroll', throttle(()=>listenToScroll(), 200))
        
        return window.removeEventListener('scroll',  ()=>listenToScroll())
    }, [])
    
    React.useEffect(()=>{
       if(scrollTopPosition > tipstersPageRefOffSetY){ 
           
           if( brdoparaData.usersStatisticMonthly === "loading"){
                fetchMonthlyStatisticData({orderBy: "profit", limit: 6})
           }else if(!topTipstersData){
                setTimeout(()=>setTopTipsterDataLoaded(true), 500)
                
                setTopTipstersData(displayDataConstructor["landingPage_statistic"](displayedDataDictionary).displayData({data: brdoparaData.usersStatisticMonthly}))
           }    
           
        }
      
    }, [scrollTopPosition, brdoparaData.usersStatisticMonthly])

    const listenToScroll =  () => {
        
        const scrollTopPosition = document.body.scrollTop || document.documentElement.scrollTop 
        setScrollTopPosition(scrollTopPosition)
    }

    const handleJoinUsButtonClick = ()=>{
        window.scrollTo({
            top: getElementDocumentPosition(registerFormRef.current).top,
            behavior: 'smooth'
          })
    }

    const handleRedirectToDashboard = ()=>{
        
        props.history.push("/dashboard")
    }
  
    return (
        <Box className={classes.root}>
            
            <Box className={classNames(classes.loadingOverlay, {[classes.loadingOverlayActive]: loadingContent})}  height="100vh" width="100%" position="fixed" top={0} bgcolor="background.secondary"></Box>
            <picture>
                <source media="(min-width:1600px)" srcset="https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/homePage%2FgoldLarge.jpg?alt=media&token=8969d1c7-7ba4-43e0-a2bd-1dedaa9994b7" />
                <source media="(min-width: 1000px)" srcset="https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/homePage%2FgoldSmall.jpg?alt=media&token=91cd954a-f7bb-4b6f-8dde-ed07936d06d8"/>
                <source media="(max-width: 999px)" srcset="https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/homePage%2FgoldPhone-min.jpg?alt=media&token=47e8f7f6-3e50-4e63-a33d-4c15158cec5f"/>
                <img 
                    onLoad={()=> setLoadingBackground(false)} 
                    alt="gold background"  
                    src="https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/homePage%2FgoldSmall.jpg?alt=media&token=91cd954a-f7bb-4b6f-8dde-ed07936d06d8" 
                    className={classes.rootBackground}
                    draggable="false"
                 />
            </picture>
            
            <Navbar homePage/>
            <Box className={classNames(classes.page, classes.homePage)} overflow="hidden">
                <picture >
                    <source media="(min-width:1600px)" srcset="https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/homePage%2FlandingLarge2.png?alt=media&token=4b51636a-5be7-489d-a9b9-fc159bcc5028" />
                    <source media="(min-width: 1000px)" srcset="https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/homePage%2FlandingLarge2.png?alt=media&token=4b51636a-5be7-489d-a9b9-fc159bcc5028"/>
                    <source media="(max-width: 999px)" srcset="https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/homePage%2FbackgroundPhone2.jpg?alt=media&token=2e9b1bba-1ab9-4066-8769-a353430caf57"/>
                    <img 
                        onLoad={()=>setLoadingFirstPageImage(false)} 
                        alt="gold background" 
                        src="https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/homePage%2FlandingLarge2.png?alt=media&token=4b51636a-5be7-489d-a9b9-fc159bcc5028" 
                        className={classes.homeBackground} 
                        draggable="false"
                    />
                </picture>
                <Box  height="100%" className={classNames(classes.homeTitleContainer)} width="fit-content" position="relative" display="flex" flexDirection="column" alignItems="flex-end" justifyContent="center" >
                    <Slide in={!loadingContent} timeout={500} direction="right" mountOnEnter>
                        <Box display="flex" color="white" alignItems="center" flexWrap="wrap"> 
                            
                            <Typography className={classNames(classes.homeTitle, classes.homeTitle2)} variant="h1">{dictionary.homeTitle2}</Typography>
                        </Box>
                    </Slide>
                   <Slide in={!loadingContent} timeout={500} direction="left" mountOnEnter>
                        <Box pt={2}>
                            <Typography className={classNames(classes.homeTitle, classes.homeTitle3)}  variant="h1">{dictionary.homeTitle3}</Typography>
                        </Box>
                   </Slide>
                   <Fade in={!loadingContent && props.auth !== "loading"}  timeout={500} direction="up" >
                        <Box mt={4} width="100%" display="flex" justifyContent="center">
                            {
                                props.auth ? 
                                    <Button onClick={handleRedirectToDashboard} variant="contained" color="primary" className={classes.homeButton}>{dictionary.myProfileButton}</Button>:
                                    <Button onClick={handleJoinUsButtonClick} variant="contained" color="primary" className={classes.homeButton}>{dictionary.homeButton}</Button>
                            }
                        </Box>
                   </Fade>
                </Box>

                <Box position="absolute" bottom={24} left={0} right={0}  margin="auto" fontSize="50px" >
                    <IconElement name="arrow" animated/>
                </Box>
            </Box>
            <Box ref={howPageRef} className={classNames(classes.page, classes.howPage)}>
                <picture >
                    <source media="(min-width:1600px)" srcset="https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/homePage%2FhowPageBacgroundLarge.png?alt=media&token=28104cf3-46f8-4330-a587-f0c0cf8e12e3" />
                    <source media="(min-width: 1000px)" srcset="https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/homePage%2FhowPageBacgroundSmall.png?alt=media&token=066ab615-d2c0-4844-b740-1ad8ade56d8f"/>
                    <source media="(max-width: 999px)" srcset="https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/homePage%2FhowPageBacgroundPhone.png?alt=media&token=6108800a-ea57-423f-94e9-7421d18008f4"/>
                    <img 
                        alt="gold background" 
                        src="https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/homePage%2FhowPageBacgroundSmall.png?alt=media&token=066ab615-d2c0-4844-b740-1ad8ade56d8f" 
                        className={classes.howBackground} 
                        draggable="false"
                    />
                </picture>
                <Box width="100%" display="flex" justifyContent="space-around" flexWrap="wrap" overflow="hidden">
                    <Grow in={scrollTopPosition > howPageOffsetY}>
                        <Box position="relative" display="flex" flexDirection="column" justifyContent="space-around" alignItems="center" p={3}>
                            <Box>
                                <Typography variant="h1" className={classNames(classes.howPhoneSubTitle)}>{dictionary.howPhoneSubTitle1}</Typography>
                                <Typography variant="h1" className={classNames(classes.howPhoneTitle)}>{dictionary.howPhoneTitle1}</Typography>
                            </Box>
                            <Box className={classNames(classes.howPhoneImageContainer)}>
                                <img width="100%" height="auto" alt="phone step one" src="https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/homePage%2Fphone1.png?alt=media&token=553e2d3e-7d75-46e6-bf9b-b9f5e31cf3e4" draggable="false"/>
                            </Box>
                        </Box>
                    </Grow>
                    <Grow in={scrollTopPosition > howPageOffsetY + 100}>
                        <Box position="relative" display="flex" alignItems="center" justifyContent="center" fontSize="65px" className={classes.howArrowIconContainer}>
                            <IconElement name="arrowsRight" animated/>
                        </Box>
                    </Grow>
                    <Grow in={scrollTopPosition > howPageOffsetY + 200}>
                        <Box position="relative" display="flex" flexDirection="column" justifyContent="space-around" alignItems="center" p={3}>
                            <Box>
                                <Typography variant="h1" className={classNames(classes.howPhoneSubTitle)}>{dictionary.howPhoneSubTitle2}</Typography>
                                <Typography variant="h1" className={classNames(classes.howPhoneTitle)}>{dictionary.howPhoneTitle2}</Typography>
                            </Box>
                            <Box className={classNames(classes.howPhoneImageContainer)}>
                                <img width="100%" height="auto" alt="phone step two" src="https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/homePage%2Fphone2.png?alt=media&token=6c46f10e-7336-48fb-9529-d1f028a1c3b3" draggable="false"/>
                            </Box>
                        </Box>
                    </Grow>
                    <Grow in={scrollTopPosition > howPageOffsetY + 300}>
                        <Box position="relative" display="flex" alignItems="center" justifyContent="center" fontSize="65px" className={classes.howArrowIconContainer}>
                            <IconElement name="arrowsRight" animated delay={1000}/>
                        </Box>
                    </Grow>
                    <Grow in={scrollTopPosition > howPageOffsetY + 400}>
                        <Box position="relative" display="flex" flexDirection="column" justifyContent="space-around" alignItems="center" p={3}>
                            <Box>
                                <Typography variant="h1" className={classNames(classes.howPhoneSubTitle)}>{dictionary.howPhoneSubTitle3}</Typography>
                                <Typography variant="h1" className={classNames(classes.howPhoneTitle)}>{dictionary.howPhoneTitle3}</Typography>
                            </Box>
                            <Box className={classNames(classes.howPhoneImageContainer)}>
                                <img width="100%" height="auto" alt="phone step three" src="https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/homePage%2Fphone3.png?alt=media&token=12005659-8db0-417c-aed1-62d918243d9f"/>
                            </Box>
                        </Box>
                    </Grow>
                </Box>
                
            </Box>
            <Box ref={registerFormRef} className={classNames(classes.page, classes.regPage)}>
                <picture >
                    <source media="(min-width:1600px)" srcset="https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/homePage%2FregbackgroundLarge.png?alt=media&token=8746aebc-a486-4e7c-ad61-57b9e3653f74" />
                    <source media="(min-width: 1000px)" srcset="https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/homePage%2FregbackgroundSmall.png?alt=media&token=fa0a2e8e-c719-41ce-b0ba-94a0e8945c40"/>
                    <source media="(max-width: 999px)" srcset="https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/homePage%2FregbackgroundPhone.png?alt=media&token=36d787e7-1fd0-4c2d-862b-3ff598b5a16f"/>
                    <img 
                        alt="gold background" 
                        rc="https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/homePage%2FhowPageBacgroundSmall.png?alt=media&token=066ab615-d2c0-4844-b740-1ad8ade56d8f" 
                        className={classes.regBackground} 
                        style={{top: scrollTopPosition - registerFormOffSetY > 0 ? OFFSET_REG_BACkGROUND + scrollTopPosition - registerFormOffSetY < 0 ? OFFSET_REG_BACkGROUND + scrollTopPosition - registerFormOffSetY : 0 : OFFSET_REG_BACkGROUND}}
                        draggable="false"
                    />
                </picture>
                <Box height="100%" width="100%" display="flex" overflow="hidden" alignItems="center">
                    <Slide in={scrollTopPosition > registerFormOffSetY + 150} direction="right">
                        <Box className={classes.regFormContainer}>
                            <LoginBox formType="register"/>
                        </Box>
                    </Slide>
                    <Slide in={scrollTopPosition > registerFormOffSetY + 150} direction="left">
                        <Box className={classes.regImageContainer}>
                            <img width="100%" height="auto" alt="laptop" src="https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/homePage%2Flaptop2.png?alt=media&token=f726bbb5-81ab-46a5-aae8-ce2c9abec59c" draggable="false"/>
                        </Box>
                    </Slide>
                </Box>

            </Box>
            
            <Box position="relative" ref={tipstersPageRef} className={classNames(classes.page, classes.tipstersPage)} overflow="hidden">
                
                <picture >
                    <source media="(min-width:1600px)" srcset="https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/homePage%2FtipstersFirstBackgroundLarge.png?alt=media&token=233945c4-a948-4aa4-aa90-85a0b0fa0c18" />
                    <source media="(min-width: 1000px)" srcset="https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/homePage%2FtipstersFirstBackgroundSmall-min.png?alt=media&token=31755def-d5f7-4eb7-85fc-216497075a6b"/>
                    <source media="(max-width: 999px)" srcset="https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/homePage%2FtipstersFirstBackgroundPhone-min.png?alt=media&token=2bad6724-e169-4049-9feb-f2769c5361a3"/>
                    <img 
                        alt="gold background" 
                        src="https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/homePage%2FtipstersFirstBackgroundSmall-min.png?alt=media&token=31755def-d5f7-4eb7-85fc-216497075a6b" 
                        className={classes.tipstersFirstBackground} 
                        draggable="false"
                    />
                </picture>
                <picture >
                    <source media="(min-width:1600px)" srcset="https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/homePage%2FtipstersSecondBackgroundlarge.png?alt=media&token=e5742ba1-34f0-4eda-bd57-9f992c055771" />
                    <source media="(min-width: 1000px)" srcset="https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/homePage%2FregbackgroundSmall.png?alt=media&token=fa0a2e8e-c719-41ce-b0ba-94a0e8945c40"/>
                    <source media="(max-width: 999px)" srcset="https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/homePage%2FregbackgroundPhone.png?alt=media&token=36d787e7-1fd0-4c2d-862b-3ff598b5a16f"/>
                    <img 
                        alt="gold background" 
                        src="https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/homePage%2FhowPageBacgroundSmall.png?alt=media&token=066ab615-d2c0-4844-b740-1ad8ade56d8f" 
                        className={classNames(classes.tipstersSecondBackground, {[classes.tipstersSecondBackgroundActive]: topTipsterDataLoaded})} 
                        
                        draggable="false"
                    />
                            
                </picture>
                <Box className={classNames(classes.tipstersTitleContainer, {[classes.tipstersTitleContainerActive]: topTipsterDataLoaded})} >
                    <Typography variant="h1" className={classNames(classes.tipstersTitle, {[classes.tipstersTitleActive]: topTipsterDataLoaded})}>{dictionary.tipstersTitle}</Typography>
                   { !topTipsterDataLoaded && <Preloader rootStyle={{height:"auto", width:"auto", background:"none", marginTop: 100}}/>}
                </Box>
                <Box position="relative" display="flex" justifyContent="space-around" flexWrap="wrap" width="100%" p={1}>
                    <Grid container justify="space-around" spacing={1}>
                        {
                            topTipsterDataLoaded && topTipstersData.filter((o, i) => i < 6).map((tipster, i)=>(
                      
                                <Slide direction="right" key={i} in={topTipsterDataLoaded} timeout={200*i}>
                                    <Grid item xs={6} sm={6} md={4} lg={2} style={{display:"flex", justifyContent:"center"}}>
                                        <Box display="flex" flexDirection="column" color="white" width="250px" overflow="hidden">
                                            <Box width="100%" height="65px" display="flex">
                                                <Box display="flex" alignItems="center" justifyContent="center" width="65px" p={2} mr={1} bgcolor="primary.main" color="black"  borderRadius={4} boxShadow={2}>
                                                    <Typography variant="h2">{`${i+1}.`}</Typography>
                                                </Box>
                                                <Box display="flex" alignItems="center" justifyContent="center" width="calc(100% - 65px)" p={2} bgcolor="background.primary" borderRadius={4} boxShadow={2}>
                                                    <Typography variant="h2" noWrap>{tipster.user.value}</Typography>
                                                </Box>
                                            </Box>

                                            <Box display="flex" flexDirection="column" bgcolor="background.secondary" p={2} mt={1} borderRadius={4}  boxShadow={2}>
                                                <Box display="flex" flexWrap="wrap" justifyContent="space-between">
                                                    <Box p={1} mr={1} mb={1} flexGrow="1" flexBasis={0} bgcolor="background.primary" borderRadius={4}>
                                                        <Typography variant="body1">{tipster.profit.value}</Typography>
                                                        <Typography variant="h2" className={classes.tipsterBoxTitle}>{tipster.profit.label}</Typography>
                                                    </Box>
                                                    <Box p={1} mb={1}  flexGrow="1" flexBasis={0} bgcolor="background.primary" borderRadius={4}>
                                                        <Typography variant="body1">{tipster.roi.value}</Typography>
                                                        <Typography variant="h2" className={classes.tipsterBoxTitle}>{tipster.roi.label}</Typography>
                                                    </Box>
                                                </Box>
                                                <Box display="flex" flexWrap="wrap" justifyContent="space-between">
                                                    <Box p={1} mr={1} mb={1}  flexGrow="1" flexBasis={0} bgcolor="background.primary" borderRadius={4}>
                                                        <Typography variant="body1">{tipster.stakeSum.value}</Typography>
                                                        <Typography variant="h2" className={classes.tipsterBoxTitle}>{tipster.stakeSum.label}</Typography>
                                                    </Box>
                                                    <Box p={1} mb={1}  flexGrow="1" flexBasis={0} bgcolor="background.primary" borderRadius={4}>
                                                        <Typography variant="body1">{tipster.avrgWin.value}</Typography>
                                                        <Typography variant="h2" className={classes.tipsterBoxTitle}>{tipster.avrgWin.label}</Typography>
                                                    </Box>
                                                </Box>
                                                
                                                <Box height={100} bgcolor="background.primary" p={1} borderRadius={4}>
                                                    <Box>
                                                        <Typography variant="h2" className={classes.tipsterBoxTitle}>{tipster.graphData.label}</Typography>
                                                    </Box>
                                                    <ResponsiveLine
                                                        data={[{id: tipster.graphData.label, data: tipster.graphData.value}]}
                                                    
                                                        colors={d => theme.palette.primary.main}
                                                        margin={{ top: 4, right: 8, bottom: 24, left: 8 }}
                                                        xScale={{ type: 'point' }}
                                                        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
                                                        yFormat=" >-.2f"
                                                        axisTop={null}
                                                        axisRight={null}
                                                        axisBottom={null}
                                                        isInteractive={false}
                                                        axisLeft={null}
                                                        enableGridX={false}
                                                        enableGridY={false}
                                                        enableArea={true}
                                                        enablePoints={false}
                                                        pointSize={10}
                                                        pointColor={{ theme: 'background' }}
                                                        pointBorderWidth={2}
                                                        pointBorderColor={{ from: 'serieColor' }}
                                                        pointLabel="y"
                                                        enablePointLabel={false}
                                                        pointLabelYOffset={-15}
                                                        enableCrosshair={false}
                                                        useMesh={true}
                                                        legends={[]}
                                                    />
                                                </Box>
                                                
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Slide>
                            ))
                        }
                    </Grid>
                </Box>
            </Box>
            <Box className={classes.footer}>
                <Box className={classes.footerBox}>
                    <Box className={classes.footerBoxTitle}>
                        <Typography variant="h2" fontSize="inherit">Info</Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" color="white">
                        <List>
                            <CustomListItem>
                                <CustomListItemIcon>
                                    <CustomIcon name="arrowsRight" />
                                </CustomListItemIcon>
                                <CustomListItemText onClick={()=> props.history.push("/info?type=userTerms")}>
                                    Pogoji uporabe
                                </CustomListItemText>
                            </CustomListItem>
                            <CustomListItem>
                                <CustomListItemIcon>
                                    <CustomIcon name="arrowsRight" />
                                </CustomListItemIcon>
                                <CustomListItemText onClick={()=> props.history.push("/info?type=privacyPolicy")}>
                                    Varovanje podatkov
                                </CustomListItemText>
                            </CustomListItem>
                            <CustomListItem>
                                <CustomListItemIcon>
                                    <CustomIcon name="arrowsRight" />
                                </CustomListItemIcon>
                                <CustomListItemText onClick={()=> props.history.push("/info?type=cookies")}>
                                    Piškotki
                                </CustomListItemText>
                            </CustomListItem>
                        </List>
                    </Box>
                </Box>

                <Box className={classes.footerBox}>
                    <Box className={classes.footerBoxTitle}>
                        <Typography variant="h2" fontSize="inherit">O nas</Typography>
                    </Box>
                    <Box display="flex" flexDirection="column"  color="white">
                        <List>
                            <CustomListItem>
                                <CustomListItemIcon>
                                    <CustomIcon name="arrowsRight" />
                                </CustomListItemIcon>
                                <CustomListItemText onClick={()=> props.history.push("/info?type=about")}>
                                    O podjetju
                                </CustomListItemText>
                            </CustomListItem>
                            <CustomListItem>
                                <CustomListItemIcon>
                                    <CustomIcon name="email" />
                                </CustomListItemIcon>
                                <CustomListItemText>
                                    info@brdopara.com
                                </CustomListItemText>
                               
                            </CustomListItem>
                            
                        </List>
                    </Box>
                </Box>

                <Box className={classes.footerBox}>
                    <Box className={classes.footerBoxTitle}>
                        <Typography variant="h2" fontSize="inherit">Povezave</Typography>
                    </Box>
                    <Box display="flex" flexDirection="column">

                    </Box>
                </Box>
            </Box>
            <Box zIndex="10000" position="absolute" bottom="0" margin="auto" color="gray" fontSize=".5em" mb={1}>
                @2021 Brdopara, <a style={{color:"gray", cursor: "default", textDecoration: "none"}} href="https://www.youtube.com/watch?v=9DoXonmYppk">Nomo </a>. All rights reserved.
            </Box>
        </Box>
    )

}


const mapStateToProps = ({auth, brdoparaData, userGeoLocation}) => {
    return {
        auth,
        brdoparaData,
        userGeoLocation
    }
  }

export default withRouter(connect(mapStateToProps, actions)(editWrapper(HomeElement)))
