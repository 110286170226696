import React, {Suspense} from 'react';

import * as actions from './redux/actions'
import {connect} from "react-redux";

import {withRouter} from 'react-router'
import {Route, Switch, Redirect} from 'react-router-dom'

import {makeStyles, createStyles} from '@material-ui/core/styles';
import withRoot from './withRoot';

import CookieBar from './CookieBar'
import PageView from './webElements/PageView'
import useGeoLocation from './hooks/useGeoLocation';

import CustomSnackbarLayout from './webElements/custom/brdopara/elements/CustomSnackbarLayout'
import {analytics} from './config/firebase'

const useStyle = makeStyles( theme => createStyles({

  root: {
    minHeight: "100vh",
    position: "relative",
    textAlign: 'center'
  },

  backgroundImage: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%"
  }

}))

function App ({
  isAuthenticated,
  fetchGlobalElements,
  fetchDictionaryLanguages,
  setDeviceNotificationStatus,
  location,
  setUserGeoLocation,
  auth,
  userGeoLocation
}){

  const classes = useStyle()
  const [pageLoaded, setPageLoaded] = React.useState()
  const geoLocation = {countryCode: "SI"}//useGeoLocation()

  React.useEffect(()=>{
    isAuthenticated()
    //fetchGlobalElements()
    //fetchDictionaryLanguages()
    setPageLoaded(true)

    setDeviceNotificationStatus()
  }, [])
  
  React.useEffect(()=>{
    
    let urlSearchParams = new URLSearchParams(location.search)
    if(userGeoLocation === "loading"){

      if(urlSearchParams.get("lang")){
        setUserGeoLocation({countryCode: urlSearchParams.get("lang")})
        
      }else if(auth && auth.userInfo && auth.userInfo.lang){
        setUserGeoLocation({countryCode: auth.userInfo.lang})
       
      }else{
        setUserGeoLocation(geoLocation)
      }
      
    }
    

   
  }, [userGeoLocation, auth])

  return (
    <div className={classes.root}>
      <Route path="/" render={() => (
        <>
          <PageView/>
           {analytics && <CookieBar/>}
          
          <CustomSnackbarLayout />
        </>
      )}/>
    </div>
  ) 
}

App.propTypes = {

};

const mapStateToProps = ({auth, userGeoLocation}) => {
  return {
    auth,
    userGeoLocation
  }
}

export default withRouter(withRoot(connect(mapStateToProps, actions)(App)))

