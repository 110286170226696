import React from 'react';

export default {
	header:{
		
		title: "Stavi z znanjem najbolših",
		description: "Ustvari svoje stave ali sledi drugim",
		image: "https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/fbShareImage%2FhomeFb-min.jpg?alt=media&token=b576f9b1-f6c1-4968-9a15-70b94f7985bd",
		
		profileTitle: "Stavi z znanjem najbolših",
		profileDescription: "Ustvari svoje stave ali sledi drugim",
		profileImage: "https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/fbShareImage%2FstatistikaFB-min.jpg?alt=media&token=51bb7c82-1dca-48b8-8fc7-764ff7ffe6a8",
		
		betInfoTitle: "Stavi z znanjem najbolših",
		betInfoDescription: "Ustvari svoje stave ali sledi drugim",
		betInfoImage: "https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/fbShareImage%2FstavaFB-min.jpg?alt=media&token=1c89fe46-a835-481b-8b0c-0ef645aa6fe2"
	},

	date:{
		daysShort: [ "Ned", "Pon", "Tor", "Sre", "Čet", "Pet", "Sob"],
		monthsShort: ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Avg", "Sep", "Okt", "Nov", "Dec"],

	},

	sports:{
		soccer: "Nogomet",
		tennis: "Tenis",
		basketball: "Košarka",
		handball: "Rokomet",
		volleyball: "Odbojka",
		esports: "e-Športi",
		combination: "Kombinacije",
		hockey: "Hokej",
		americanFootball: "Ameriški nogomet"
	},
	bet_options:{

		// bet score options
		"1": "Zmaga 1.",
        "2": "Zmaga 2.",
		"X": "Remi",
        "1X2": "Remi",
		"over": "Over",
		"under": "Under",
				
		//bet types
		"1x2": "1X2",
        "home-away": "Home/Away", 
        "ah": "Handicap", 
		"bothteamstoscore": "Both Teams To Score",							
        "cs": "Correct score",
        "dnb": "Draw No Bet",
        "dc": "Double chance",
		"eh": "European  handicap",
		"o-e" : "Odd or Even",
		"o-u" : "Over/Under",

		// bet bet types options
		"ftincludingot": "Celotna tekma + podaljšek",
        "fulltime": "Celotna tekma",
        "1sthalf": "1. polčas",
        "2ndhalf":  "2. polčas",
        "1stset": "1. set",
        "2ndset": "2. set",
        "3rdset": " 3. set",
        "1q": "1. četrtina",
        "2q": "2. četrtina",
        "3q": "3. četrtina",
        "4q": "4. četrtina", 
		"1stperiod": "1. tretina",
		"2ndperiod": "2. tretina",
		"3rdperiod": "3. tretina",

	},
	displayed_data_constructor:{
		user: "Profil",
		tipster: "Tipster",
		graphData: "Graf",
		betsNum: "Št. stav",
		avrgWins: "Zmage",
		avrgWin: "Zmag",
		avrgOdds: "Povp. kvot",
		avrgStake: "Povp. vložek",
		roi: "ROI",
		profit: "Profit",
		sport: "Šport",
		match: "Tekma",
		odds: "Kvota",
		stake: "Vložek",
		time: "Pričetek",
		betTime: "Čas",
		betType: "Stavna opcija",
		bet: "Stava",
		competition: "Tekmovanje",
		date: "Datum",
		betStatus: "Status",
		returns: "Vračilo",
		stakeSum: "Vložek",
		winNum: "Dobljene stave",
		lostNum: "Izgubljene stave",
		betWinner: "Zmagovalec",
		comment: "Analiza",
		status: "Status",
		allBets: "Vse stave",
		withComment: "Z analizo",
		combination: "Kombinacija",
		userName: "Tipster",

		leaderBoardWeeklyTitle: "Top 10 tedna",
		leaderBoardMonthlyTitle: "Top 10 meseca",
		leaderBoardOverallTitle: "Top 10",

		betInfo_allActiveBetsInfoBySportTitle: "Podobne stave",
		betInfo_allHistoryBetsTitle: "Zgodovina stav",
		bets_allActiveBetsInfoTitle: "Aktivne stave",

		home_userSubscribedBetsButtonLabel: "Naročene stave",
		home_userFavoriteBetsButtonLabel: "Priljubljene stave",
		home_userSubscribedBetsTitle: "Naročene stave",
		home_userBetsTitle: "Moje stave",
		
		home_allActiveBetsInfoButtonLabel: "Vse stave",
		home_userFavoriteBetsTitle: "Priljubljene stave",
		home_userBetsButtonLabel: "Moje stave",

		myProfile_myActiveBetsTitle: "Moje stave",
		myProfile_subscribedActiveBetsButtonLabel: "Naročene",
		myProfile_userHistoryBetsButtonLabel: "Zgodovina",
		myProfile_subscribedActiveBetsTitle: "Naročene stave",
		myProfile_favoriteActiveBetsButtonLabel: "Priljubljene",
		myProfile_myActiveBetsButtonLabel: "Moje",
		myProfile_favoriteActiveBetsTitle: "Priljubljene stave",
		myProfile_userHistoryBetsTitle: "Zgodovina stav",
		myProfile_myInactiveBetsTitle: "Nepotrjene stave",
		myProfile_myInactiveBetsButtonLabel: "Nepotrjene",
		

		userProfile_userActiveBetsInfoTitle: "Aktivne stave",
		userProfile_userHistoryBetsTitle: "Zgodovina stav",
		userProfile_userActiveBetsInfoButtonLabel: "Aktivne",
		userProfile_userHistoryBetsButtonLabel: "Zgodovina",

		tipsters_leaderBoardMonthlyTitle: "TIPSTERJI",
		tipsters_leaderBoarWeeklyButtonLabel: "Tedenska statistika",
		tipsters_leaderBoardOverallButtonLabel: "Vsi tipsterji",
		tipsters_leaderBoarWeeklyTitle: "TIPSTERJI",
		tipsters_leaderBoardMonthlyButtonLabel: "Mesečna statistika",
		tipsters_leaderBoardOverallTitle: "TIPSTERJI",

		daysShort: [ "Ned", "Pon", "Tor", "Sre", "Čet", "Pet", "Sob"],
		monthsShort: ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Avg", "Sep", "Okt", "Nov", "Dec"],

		// bet score options
		"1": "Zmaga 1.",
        "2": "Zmaga 2.",
		"X": "Remi",
        "1X2": "Remi",
		"over": "Over",
		"under": "Under",
				
		//bet types
		"1x2": "1X2",
        "home-away": "Home/Away", 
        "ah": "Asian handicap", 							
        "cs": "Correct score",
        "dnb": "Draw No Bet",
        "dc": "Double chance",
		"eh": "European  handicap",
		"o-e" : "Odd or Even",
		"o-u" : "Over/Under",
		"bothteamstoscore": "Both Teams To Score",	

		// bet bet types options
		"ftincludingot": "Celotna tekma + podaljšek",
        "fulltime": "Celotna tekma",
        "1sthalf": "1. polčas",
        "2ndhalf":  "2. polčas",
        "1stset": "1. set",
        "2ndset": "2. set",
        "3rdset": " 3. set",
        "1q": "1. četrtina",
        "2q": "2. četrtina",
        "3q": "3. četrtina",
        "4q": "4. četrtina", 
		"1stperiod": "1. tretina",
		"2ndperiod": "2. tretina",
		"3rdperiod": "3. tretina",
	},

	home_element:{ 
		homeTitle2: "STAVI Z ZNANJEM.",	
		homeTitle3: "NAJBOLJŠIH.",
		homeButton:"Pridruži se",	
		myProfileButton: "Moj profil",

		howPhoneSubTitle1: "Ustvari",	
		howPhoneTitle1: "svoj profil",	
		
		howPhoneSubTitle2: "Objavi ali sledi",	
		howPhoneTitle2: "svojim stavam",
		
		howPhoneSubTitle3:"Sledi",	
		howPhoneTitle3:"svojim dosežkom",		
		
		tipstersTitle: "TOP5 TIPSTERJEV MESECA"
	},

	login_box_element:{
		backButton: "Nazaj",
		confirmButton: "Potrdi",
		
		forgotPasswordTitle: "Pozabil geslo",
		forgotPasswordEmailInputLabel: "E-naslov",
		forgotPasswordInfo: "Ob potrditvi bo na vnešeni e-naslov poslana zahteva za zamenjavo gesla. Za zamenjavo gesla sledite navodilom poslanim na vaš e-naslov.",
		
		resetPasswordTitle: "MENJAVA GESLA",
		resetPasswordPasswordInputLabel: "Geslo",
		resetPasswordRetryPasswordInputLabel: "Ponovno geslo",
		resetPasswordInfo: "Ob potrditvi bo na vnešeni e-naslov poslana zahteva za zamenjavo gesla. Za zamenjavo gesla sledite navodilom poslanim na vaš e-naslov.",
		
		verifyEmailTitle: "POTRDITEV E-NASLOVA",
		emailVerificationStatusSuccess: "E-naslov je bil uspešno potrjen!",
		emailVerificationStatusError: "Prišlo je do napake poskusi ponovno!",

		unverifiedAccountInfo: "Vaš e-naslovnaslov ni potrjen. Prosimo sledite navodilom, poslanim na vaš e-naslov. V primeru da niste prijeli potrditvene e-pošte, kliknite spodnji gumb.", 
		
		resendVerificationEmailButton: "Ponovno pošlji potrditveno e-pošto",

		registerTitle: "Registracija",
		loginTitle: "Prijava",
		loginInputEmailLabel: "E-naslov",
		loginInputPasswordLabel: "Geslo",
		loginInputRetryPasswordLabel: "Potrditev gesla",
		loginSeparator: "ali",

		loginButtonLabel: "Prijavi se",
		registerButtonLabel: "Registriraj se",
	},
	
	navbar_element:{
		loginButton: "Prijava",
		myProfileButton: "Moj profil",
		homeButton: "Domov"
	},
	
	add_bet_element:{
		
		modalWindowLabel: "Stava ni bila potrjena. Želiš vseeno zapustiti?",
		title: "Izberi stavo",
		
		sportComboBoxLabel: "Izberi šport",
		competitionComboBoxLabel: "Izberi turnir/tekmovanje",

		matchComboBoxLabel: "Izberi tekmo",
		matchComboBoxOptionStatus: "V teku ali zaključeno",
		betTypeComboBoxLabel: "Izberi vrsto stave",
		betTypeOptionComboBoxLabel: "Stavna opcija",

		title2: "Stavni listek",
		commentTextInputLabel: "Analiza",
		commentTextInputPlaceholder: "Analiza...",
		
		betOdds: "Kvota",
		betStake: "Vložek",
		stakeInputPlaceholder: "Vnesi vložek", 
		betReturns: "Vračilo",

		backButton: "Nazaj",
		confirmBetButton: "Objavi stavo",

	},

	box_with_drawer_element:{
		userSettingsButton: "Nastavitve",
		userLogout: "Odjava",

		newBetButton: "Nova stava",
		activeBetsButton: "Vse stave",
		userProfileButton: "Moj profil",
		subscribedBetsButton: "Naročene stave",
		favoriteBetsButton: "Priljubljene stave",
		userBetsButton: "Moje stave",
		userBetsHistoryButton: "Zgodovina stav",
		
		tipstersButton: "Tipsterji",
		trophiesButton: "Lovorike",
		bettingSitesButton: "Stavnice",
		userSupportButton: "Pomoč",

		userInitializationModalTitle: "KREIRAJ SVOJ PROFIL",
		userInitializationModalButton: "Potrdi",
		userInitializationModalDisplayNameInputLabel: "Prikazno ime",
		userInitializationModalUserIconInputLabel: "Naloži profilno sliko",
		userInitializationModalUserTypeButton: "Sledilec",
		userInitializationModalTipsterTypeButton: "Tipster",
		userInitializationModalEmailInputLabel: "E-naslov",
		userInitializationModalLanguageInputLabel: "Jezik strani",
		userInitializationModalUserInfo1: "Uporabniški račun \"SLEDILEC\" je namenjen vsem tistim, ki bi radi spremljali željene stave tipsterjev ter si iz naročenih stav izbrali svoje priljubljene. Izbranim priljubljenim stavam se beleži vaša osebna statistika.",
		userInitializationModalUserInfo2: " Tipster račun je namenjen vsem tistim, ki bi radi objavili svoje stave klasicnim uporabnikom ter z njimi delili svoje osebno znanje. Vsem objavljenim stavam se beleži statistika, ki jo lahko vidijo vsi obiskovalci na strani. Beležena statistika se rangira po uspehu.",
		userInitializationModalTipsterInfo: "Zaradi varnosti in pogojev uporabe, mora biti račun tipsterja odobren s strani administratorja.",

		userSettingsModalTitle: "MOJE NASTAVITVE",  
		userSettingsModalButton: "Potrdi",
		userSettingsModalDescription:  "S sledenjem stave, se vam bo stava shranila pod rubriko priljubljene stave.",

		userSettingsModalInfo: "Stavam, ki jim sledite se spremlja vaša statistika priljubljenih stav. Stavo lahko pobljubno odstranite, vendar le če se dogodek še ni začel.",

		verifiedEmailSnackbarDescription: "Vaš e-mail naslov ni potrjen. Funkcionalnosti na strani so omejene.",
		verifiedEmailSnackbarLinkLabel: "Pošlji e-pošto za aktivacijo",

		userTermsAckLabel: "Star sem več kot 18 let in se strinjam s pogoji uporabe te spletne strani.",
		userTermsAckLinkLabel: "Več o pogijih uporabe."
	},

	bet_info_element:{
		confirmButton: "Potrdi",
		title: "Tekma",
		title2: "Stava",
		title3: "Analiza",
		titleCombination: "Kombinacija", 
		matchFinished: "Zaključena",
		matchStarts: "Začetek",
		betCombinationNumLabel: "stava",

		unFollowBetButtonLabel: "Sledim",
		followBetButtonLabel: "Sledi stavi",

		combinationTotalOddsLabel: "Skupna kvota",
		betTypeLabel: "Stavna opcija",
		betOddsLabel: "Kvota",
		betLabel: "Stava",

		betMoreInfoLabel: "Za več podatkov o stavi se",
		betMoreInfoButton: "Naroči na",
		betStakeLabel: "Vložek",

		noCommentLabel: "Ni analize.",

		betAddToFavoriteModalTitle: "SLEDI STAVI",

		betAddToFavoriteModalDescription: "S sledenjem stave, se vam bo stava shranila pod rubriko priljubljene stave.",
		betAddToFavoriteModalInfo: "Stavam, ki jim sledite se beleži vaša statistika priljubljenih stav. Stavo lahko pobljubno odstranite, vendar le če je stava še vedno aktivna oz. se tekma še ni zacela.",

		betRemoveFromFavoriteModalTitle: "ODSTRANI STAVO",

		betRemoveFromFavoriteModalDescription: "Stava se bo odstranila iz rubrike priljubljene stave.",
		betRemoveFromFavoriteModalInfo: "Stavam, ki jim sledite se beleži vaša statistika priljubljenih stav. Stavo lahko poljubno odstranite ali dodajate, vendar le če se tekma še ni začela.",
	},

	
	bets_list_box_element:{
		allSportsTabLabel: "Vse",
	},

	history_bet_info_element: {
		title: "Tekma",
		title2: "Stava",
		title3: "Analiza",
		betLost: "Izgubljena",
		betWon: "Dobljena",
		betDeleted: "Izbrisana",
		betTypeLabel: "Stavna opcija",
		betOddsLabel: "Kvota",
		betLabel: "Stava",
		betReturnsLabel: "Vložek",
		betProfitLabel: "Profit",
		noCommentLabel: "Ni analize",
		titleCombination: "Kombinacija", 
		combinationTotalOddsLabel: "Skupna kvota",
		betStakeLabel: "Vložek",
		betCombinationNumLabel: "stava",
	
	},

	profile_box_element:{
		confirmButton: "Potrdi",
		title: "Profil",
		title2: "Statistika",
		title3: "Graf profita",
		profitLabel: "Profit",
		noStatisticLabel: "Ni zabeležene nobene statistike",
		subscribedButtonLabel: "Naročen",
		subscribeButtonLabel: "Naroči se",
		subscribedUsers: "NAROČNIKOV",
		weeklyStatisticButtonLabel: "Tedensko",
		monthlyStatisticButtonLabel: "Mesečno",
		overallStatisticButtonLabel: "Letno",

		removeSubscriptionModalRemoveSubscriptionInfo: "Ali želite odstraniti naročnino na izbranega tipsterja?",
		removeSubscriptionModalTitle: "Preklic naročnine",
	},

	subscribe_tipster_modal:{
		tipsterSubscribeModalTitle: profileName => `NAROČI SE NA ${profileName}`,
		tipsterSubscribeModalDescription: profileName => `Z naročilom na tipsterja ${profileName}, lahko spremlajte njegove objavlene stave ter jim sledite na svojem profilu.`,
		tipsterSubscribeModalInfo: "Na svojem profilu lahko vidite vse aktivne stave stavničarjev, na katere ste naročeni. Izberi željene stave, ter si ustvari svojo osebno statistiko izbranih stav.",

		editSubscriptionModalNotifyViaEmailLabel: "Prejmi bvestila o novi stavi preko e-pošte",
	
		editSubscriptionModalPushNotificationsLabel: "Prejmi bvestila o novi stavi preko potisnih obvestil",

		allowNotificationButtonLabel: "Dovoli obvestila",

		confirmSubscriptionButtonLabel: "Naroči se"
	},

	register_user_box_element:{
		title: "Pridruži se nam",
		emailInputLabel: "E-naslov",
		passwordInputLabel: "Geslo",
		registerButtonLabel: "Registracija",
		info: "Z registracijo se hkrati tudi strinjam s pogoji uporabe!",
		infoLink: "Več o pogojih tukaj!",

		
	},

	user_settings_element:{
		confirmButton: "Potrdi",
		user: "Sledilec", 
		tipster: "Tipster",
		title: "Nastavitve profila",
		saveButtonLabel: "Shrani",
		
		displayNameInputLabel: "Uporabniško ime", 
		emailInputLabel: "E-naslov", 
		descriptionInputLabel: "Kratek opis",
		profileImageTitle: "Profilna slika",
		profilePictureButtonLabel: "Naloži novo profilno sliko",
		accountTypeTitle: "Vrsta računa",
		accountTypeButtonLabel: "Zamenjaj vrsto računa",
		userSubscriptionsTitle: "Moje naročnine",
		subscribedFrom: "Naročen od",
		notifications: "Obvestila",
		noSubscriptions: "Nobenih naročnin",

		editSubscriptionModalNotifyViaEmailTitle: (displayName)=>`Naročnina ${displayName}`,
		editSubscriptionModalNotifyViaEmailLabel: "Obvestila preko e-pošte",
		editSubscriptionModalNotifyViaEmailInfo: " Za vsako novo objavljeno stavo izbranega tipsterja, vam bomo avtomatsko poslali obvestilo preko e-pošte.",
		editSubscriptionModalPushNotificationsLabel: "Obvestila preko potisnih obvestil (notificationov)",
		editSubscriptionModalPushNotificationsInfo: "Za vsako novo objavljeno stavo izbranega tipsterja, vam bomo avtomatsko poslali obvestilo preko potisnih obvestil.",
		editSubscriptionModalPushNotificationsWarning: "Potisna obvestila (notificationov) delujejo samo na brskalnikih Chrome in Mozila ali na novejših različicah brskalnika Edge. Ta vrsta obvestili ni podprta s strani Applea in ne deluje na nobenih Appleovih napravah!",

		removeSubscriptionModalTitle: "Preklic naročnine",
		removeSubscriptionModalRemoveSubscriptionInfo: "Ali želite odstraniti naročnino na izbranega tipsterja?",

		changeUserTypeModalTitle: "VRSTA RAČUNA",
		changeUserTypeModalUserInfo: "Uporabniški račun \"Uporabnik\" je namenjen vsem tistim, ki bi radi spremljali željene stave tipsterjev ter si iz naročenih stav izbrali svoje priljubljene. Izbranim priljubljenim stavam, se beleži vaša osebna statistika.",
		
		changeUserTypeModalTipsterInfo: "Tipster račun je namenjen vsem tistim, ki bi radi objavljali svoje stave vsem drugim uporabnikom portala"+ 
										"ter z njimi delili svoje osebno znanje. Vsem objavljenim stavam se beleži statistika, ki jo lahko vidijo vsi obiskovalci strani." +
										"Beležena statistika se rangira po uspehu.",

		changeUserTypeModalTipsterWarning: "Ob menjavi vrste uporabniškega računa, se vam bo zbrisala vsa zgodovina stav ter njihova statistika.",

		promptMessage: "Ovrži vse spremebe?",
	},

	list_box_element:{
		noAvailableData: "Ni podatkov."
	},

	info_element:{
		aboutTitle: "O podjetju",
		companyNameSubTitle: "Naziv podjetja",
		headquartersSubTitle: "Sedež podjetja",
		registrationNumberSubTitle: "Matična št.",



		cookiesTitle: "Piškotki",

		privacyPolicyTitle: "Privacy policy",

		userTermsTitle: "OPĆI UVJETI KORIŠĆENJA"
	},

	remove_active_bet_modal:{
		title: "Izbriši izbrano stavo",
		buttonLabel: "Potrdi",
		infoContent: "Izbrana stava bo izbrisana iz vaših aktivnih stav! "
	},

	user_support_element:{
		title1: "Kontakti",
		title2: "Prijava napake",
		backButtonLabel: "Nazaj",
		selectFieldLabel: "Vrsta težave",
		selectFieldOption1: "Stava je bila narobe zaključena.",
		selectFieldOption2: "Ne dobivam obvestil o novih stavah.",
		selectFieldOption3: "Obvestila o stavah niso podprta.",
		selectFieldOption4: "Ne morem se naročiti na izbranega tipsterja.",
		selectFieldOption5: "Ne morem slediti izbrani stavi.",
		selectFieldOption6: "Ostalo.",

		textFieldLabel: "Opis težave",

		infoContent: "V opisu podrobno opišite vaš problem, da bomo lažje napako odkrili in tudi kar se da hitro odpravili. Vsako težavo/napako bomo obravnavali v najkrajšem možnem času.",

		sendButtonLabel: "Pošlji"
	},	

	betting_sites_info_element:{
		melbet:{
			oddsTitleLabel: "Kvote",

			oddsParagraphContent: (
				<>
					<p>Melbet je ena od stavnic z najvišjimi kvotami na trgu. Njihova marža na nogometne tekme je v povprečju zgolj 4,6%. Na košarkarskih dvobojih imajo še nižjo maržo: 4,3%. Medtem, ko s kvotami na teniških obračunih sodijo v sam svetovni vrh, saj imajo maržo na teniških dvobojih zgolj 3,8%.</p> 
					<p>Iz vidika kvot je Melbet odlična stavnica. Če pogosto stavite na teniške dvoboje, potem Melbet enostavno mora biti med priljubljenimi zavihki.</p>		
				</>),
		

			offersTitleLabel: "Ponudba",
			offersParagraphContent: (
				<>
					<p>
						Največji adut v rokavu Melbeta je zagotovo ponudba. Ponudba je naravnost fantastična! Poleg tega, da pokrivajo praktično vse športe pa tudi druge igre, mislimo s tem ko rečem fantastična ponudba, 
						na število stavnih opcij na posameznih dogodkih. Torej če iščete stavnico, ki ima na enem dvoboju praktično vse možne handicape, pa naj to velja za nogomet, tenis ali košarko potem je Melbet stavnica za vas.
						Sami si lahko določate poljubne handicape/totale,… tako na ekipah, kot tudi recimo poljubno vsoto točk na košarkarskih igralcih ali poljubno visok handicap na teniških igralcih.
					</p>
					<p>
						Izjemno bogato ponudbo ponujajo tudi pri stavah v živo. Seveda pa je možno na Melbetu stave izplačati tudi predčasno (Cash out).

					</p>
					<p>
						Poleg športnih stav imajo tudi bogato ponudbo <b>CASINO IGER</b> in po novem ponujajo tudi <b>POKER</b>.
					</p>
				</>
			),

			offersWarningContent: "Morate se zavedati, da vam nobena stavnica ne bo pustila, da izkoriščate ranljiv market."+
								" Ranljiv market pa se pojavlja na nižjih in manj popularnih »eksotičnih« ligah, ter manj popularnih športih (E-SPORTI, konjske dirke, kriket, namizni tenis, pikado,…)."+
								" Ne mislite, da boste lahko bodisi z nameščeno tekmo ali z napačno postavljeno kvoto zaslužili."+
								" Pri Melbetu so glede tega zelo občutljivi in v primeru dobitkov na takšnih dogodkih se lahko zgodi, da ne bo prišlo do izplačila."+
								" Naš nasvet je, da se izogibate stavam na manj popularne lige, športe, tekmovanja itd. V kolikor boste igrali največje lige in največja tekmovanja ste lahko brezskrbni.",

			bonusTitleLabel: "Bonus",

			bonusParagraphContent: (
				<>
					<p>
						Melbet ima enega najvišjih first deposit bonusov. S <b>promo kodo: BRDOPARA</b> prejmete kar 100% bonus na prvi deposit do 130€.
					</p>
					<p>
						Poleg first deposit bonusa Melbet ponuja tudi cel kup drugih bonusov. Od vsakotedenskega Wednesday bonusa do povrnitve povprečnega vplačila, če v 1 mesecu sklenete vsaj 100 stav.
					</p>
					<p>
						Tako kot na vsaki stavnici, je tudi tukaj bonus seveda potrebno obrniti preden le ta postane real money. 
						Obrača se ga s kombinacijami najmanj 3 parov, kjer mora vsak par imeti kvoto 1.4 (3 dobre pare ob ponudbi, ki jo ponujajo na Melbetu res ni težko poiskati). 
						Ključno je, da zadenete prvo kombinacijo. Saj če jo zadanete, imate veliko manevrskega prostora, da boste bonus dobili izplačan.
					</p>
				</>
			),
		
			bonusSubtitleLabel: "Kako obrniti bonus?",
			bonusSubParagraphContent: (
				<>
					<p>
						Predpostavimo, da imate maksimalen bonus, 130€.
						Če stavite svoj celoten (to seveda ni potrebno) bonus na kvoto 3,  zadanete iz 130€… 390€. To pomeni, da ste 1x obrnili bonus, saj ste vplačali 130€.
						Ker ste 1x že vplačali in zadeli 390€, si lahko privoščite 2 poraza in v primeru 2 porazov imate še vedno 130€. Bonus pa ste obrnili že 3x. Z naslednjo dobljeno stavo si že zagotovite 130€ bonusa.</p>
					<p>
						Torej, če ste s prvim vplačilom bonusa uspešni, morate potem zadeti samo 1x v 3 poskusih kvoto 3, da dobite bonus izplačan.
					</p>
				</>
			),
			
			depositTitleLabel: "Deposit/izplačilo",

			depositParagraphContent:(
				<>
					<p>
						Stavnica podpira praktično vse večje payment sisteme na trgu. S katerim sistemom si lahko denar vplačate in izplačate je odvisno od države iz katere igrate.
						Seveda obvezno na svoj account vplačujte iz svojih plačilnih sistemov in ne iz kartic/accountov svojih bližnjih ali celo tretje osebe.

					</p>
					<p>
						Načeloma sta deposit in izplačilo možna z VISO/Mastercardom, Skrillom, Netellerjem, Paysafecardom in vse bolj priljubljenimi kriptovalutami. Na voljo imajo cel kup kriptovalut. Priporočamo deposit z Netellerjem.

					</p>
					<p>
						Najhitrejši deposit in izplačilo lahko naredite s Skrillom, Netellerjem ali kriptovalutami, kjer imate denar na in iz stavnice že v nekaj sekundah. Seveda v kolikor imate račun verificiran, kar pa je seveda obveznost.
					</p>
					<p>
						Tako kot na ostalih stavnicah morate vedeti, da je izplačilo potrebno narediti po enaki metodi kakršni je bil denar tudi vplačan. V kolikor želite izplačati po drugačni metodi, 
						je najprej potrebno narediti deposit z drugo metodo, vplačati stavo in ko bo stava končana lahko svoj denar izplačate po metodi kakršni je bil nazadnje denar vplačan na račun.
					</p>
				</>
			),
			userExperienceTitleLabel: "Uporabniša izkušnja",
			userExperienceParagraphContent:(
				<>
					<p>
						Uporabniška izkušnja je edina slabost Melbeta, a tudi na tem Melbet gradi in izboljšuje sistem. Zadnja posodobitev spletne strani je odlična, pa tudi aplikacija za mobilne telefone oz. dostop iz brskalnikov deluje dobro.
					</p>
					<p>
						Če ste malce potrpežljivi, se vse slej kot prej reši tako kot se mora. Še vedno se je. Seveda, če ravnate v skladu s splošnimi pogoji stavnice.
					</p>
					<p>
						Opozorili bi na slab customer support, kar pa se roko na srce dogaja tudi pri drugih stavnicah. 
						Pomoč customer supporta je premalo konkretna in razen splošnih zadev, se je z njimi res težko karkoli dogovoriti.
					</p>
					<p>
						A, če boste na Melbet prišli preko <b>bonus kode BRDOPARA</b> imate srečo, saj vam lahko pri marsikaterem problemu na Melbet pomagamo mi.
					</p>

					<p>
						Če boste imeli kakršnokoli težavo vam z veseljem pomagamo, če le lahko. <b>Lahko nas kontaktirate tudi pri pomoči ob registraciji.</b>
					</p>
				</>
			),

			overallTitleLabel: "Splošna ocena",

			overallParagraphContent:(
				<>
					<p>
						Melbet je ena od relativno novih stavnic. Če se ne motim je na trgu prisotna od leta 2012.
						Moderna stavnica z vizijo, katera lepo raste in se izboljšuje. Stacionirana je na Cipru in deluje pod strogo Curacao igralniško licenco.

					</p>
					<p>
						Na spletu lahko sicer naletite na nekaj negativnih komentarjev nezadovoljnih uporabnikov, kjer pa gre v večini primerov za stavničarje, ki niso spoštovali pravil.

					</p>
					<p>
						Najpogosteje se zgodi, da limitiran igralec odpre nov igralniški račun na tretjo osebo (kar je seveda strogo prepovedano) in potem ne dobi izplačila.
						OPOZARJAM VAS, nikakor ne uporabljajte več kot 1 stavnega računa, saj stavnice tega ne tolerirajo.
					</p>
					<p>
						Kot vse ostale stavnice tudi Melbet ne tolerira trade-erjev, ki so dandanes zelo lahko izsledljivi.
					</p>
					<p>
						Če ste stavničar, ki bo igral večje lige in velika tekmovanja ter ostale popularne dogodke vam MELBET toplo priporočamo.
					</p>
				</>
			),
		}
		
	
	},


	brdopara_snackbar_element:{
	
		///LOGIN
		loginSuccess:{
			label: "Prijava uspešna!"
		},
		loginError: {
			label: "Napačna prijava! Napačen e-naslov ali geslo!",
			actions:["Si pozabil geslo?"]
		},

		///REGISTRATION
		registrationSuccess:{
			label: "Registracija je bila uspešna! Na vnešeni e-naslov je bila poslana e-pošta za avtentikacijo vnešenega e-naslova."
		},
		registrationError:{
			label: "Prišlo je do napake pri registraciji. Poskusi ponovno!"
		},
		registrationErrorMailAlreadyExist:{
			label: "Uporabnik z vnesenim e-naslovom že obstaja!",
			actions: ["Vpiši se tukaj!"]
		},

		registrationFacebookErrorMailAlreadyExist:{
			label: "Enak e-naslov je povezan z Googlovim računom! Prosim uporabite Google gumb za prijavo ali pa se prijavite preko e-naslova in gesla.",
		},

		registrationErrorWrongEmailOrPassword:{
			label: "Napačen e-naslov ali geslo!",
		},

		registrationErrorPasswordsNotMatching:{
			label: "Vneseni gesli se ne ujemata! Poiskusi ponovno.",
		},

		///PASSWORD RECOVERY
		passwordResetRequestSuccess:{
			label: "Zahteva za zamenjavo gesla je bila uspešno poslana. Sledite navodilom poslanim na vnešen e-naslov!"
		},
		passwordResetSuccess:{
			label: "Geslo je bilo uspešno spremenjeno! Ponovno se vpišite s pravkar spremenjenim geslom."
		},
		passwordResetError:{
			label: "Prišlo je do napake, poskusi ponovno!"
		},
		passwordResetErrorEmailNotFound:{
			label: "Vneseni e-naslov ne obstaja, poskusi ponovno!"
		},
		passwordResetActionCodeError:{
			label: "Povezava za zamenjavo gesla je napačna! Ponovno pošlji zahtevo za zamenjavo gesla in sledi navodilom!",
			actions: ["Klikni tukaj!"]
		},


		//EMAIL VERIFICATION 
		emailVerificationRequestSuccess:{
			label: "Na vaš e-naslov je bila poslana povezava za potrditev vašega e-naslova!"
		},

		emailNotVerified: {
			label: "Vaš e-naslov ni potrjen. Preko povezave poslane na vaš e-naslov, potrdite pristnost vnešenga e-naslova. Če e-pošte niste dobili, šeenkrat",
			actions: ["pošlji potrditveno e-pošto."]
		},

		emailVerificationRequestError:{
			label: "Prišlo je do napake, poskusi ponovno!"
		},

		// ADDING BETS

		addBetSuccess:{
			label: "Stava je bila uspešno poslana.",
			actions: ["Klikni tukaj!"]
		},

		addBetError:{
			label: "Prišlo je do napake! Poskusi ponovno.",
		},

		addStakeError:{
			label: "Vnesi svoj vložek!"
		},
		
		duplicatedBetError:{
			label: "Podvojena stava! Željena stava je že objavljena."
		},

		betCombinationDuplicateError:{
			label: "Podvojena stava v kombinaciji! Izbrana stavna opcija že obstaja."
		},

		duplicatedCombinationBetError:{
			label: "Podvojena stava v kombinaciji! Stava vsebuje že objavleno stavo v kombinaciji."
		},

		// REMOVING BETS

		removeActiveBetErrorSnackbar:{
			label: "Stavo lahko izbrišeš le 15min po njeni objavi!"
		},

		removeActiveBetSuccessSnackbar:{
			label: "Uspešno ste izbrisali izbrano stavo."
		},

		// FOLLOWING BETS
		addFavoriteBetSnackbar:{
			label: "Stava je dodana med priljubljene. Vse priljublje stave lahko pogledaš v svojem profilu.",
			actions: ["Klikni tukaj!"]
		},

		removeFavoriteBetSnackbar:{
			label: "Stava je bila odstranjena. Vse priljublje stave lahko pogledaš v svojem profilu.",
			actions: ["Klikni tukaj!"]
		},
		
		favoriteBetErrorSnackbar:{
			label: "Prišlo je do napake! Poskusi ponovno.",
		  
		},
		
		// FETCHING MATCHES DATA

		fetchingMatchOddsInfo:{
			label: "Osvežujem stavne kvote! Poizvedba lahko traja nekaj sekund!",
		},

		
		fetchingMatchOddsError:{
			label: "Prišlo je do napake poskusi ponovno!",
		},

		// TIPSTER SUBSCRIPTION
		
		addSubscriptionSnackbar:{
			label: "Uspešno ste se naročili na tipsterja. Svoje naročnine lahko spremljaš v svojih nastavitvah!",
			actions: ["Moje nastavitve"]
		},

		removeSubscriptionSnackbar:{
			label: "Naročnina na izbranega tipsterja je bila uspešno preklicana!"
		},

		updateSubscriptionSnackbar:{
			label: "Posodobitev naročnine je bila uspešna."
		},

		subscriptionErrorSnackbar:{
			label: "Prislo je do napake, poskusi ponovno!",
		},

		pushNotificationTokenError:{
			label: "Prislo je do napake, poskusi ponovno!",
		},

		

		/// USER SETTINGS 
		updateUser:{
			label: "Nastavitve so bile uspešno posodobljene.",
		},
		updateUserError:{
			label: "Prišlo je do napake! Poskusi ponovno.",
		},

		iconUploadError:{
			label: "Prišlo je do napake! Poskusi ponovno.",
		},

		iconSizeError:{
			label: "Slika je prevelika! Višina in širina slike mora bit manjša od 50px.",
		},


		// PUSH NOTIFICATIONS 
		fcmDifferentDevice:{
			label: "Trenutna napravi ni registrirana za prejemanje obvestil. Preverite svoje nastavitve."
		},

		fcmDifferentFcmToken:{
			label: "Trenutna napravi ni registrirana za prejemanje obvestil. Preverite svoje nastavitve."

		},

		fcmNotSupported:{
			label: "Potisna obvestila na tej napravi ne delujejo!"
		},

		blockedDeviceNotificationsSnackbar:{
			label: "Potisna obvestila so za to stran blokirana. Če želite prejemati obvestila, morate spremeniti dovoljenja obvestil v vašem brskalniku!"
		},


		// SUPPORT TICKET

		supportTicketSendSuccess:{
			label: "Prijava napake je bila uspešno poslana. Odgovorili vam bomo v najkrajšem možnem času."
		},

		// EMAIL FERIFICATION

		emailRequired:{
			label: "Prosimo vnesite vaš e-poštni naslov!"
		}
	}
    
    
}