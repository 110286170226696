import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, lighten } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = theme => ({
  root: {
    width: "100vw",
    height: "100vh",
    background: theme.palette.background.main,
    display: "flex",
    flexDirection: "column",

    justifyContent: "center",
    alignContent: "center"
  },

  // spinner styles
  facebook: {
    margin: theme.spacing.unit * 2,
    position: 'relative',
  },
  facebook1: {
    color: theme.palette.primary.dark,
  },
  facebook2: {
    color: theme.palette.primary.main,
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },


  //linear styles
  linearBarRoot:{
    height: 1,
    background: theme.palette.background.primary
  },
  bar:{
   background: theme.palette.primary.main
  }

});

const SPINNER_SIZE = 80

function Preloader(props) {
  const { classes, type } = props;
  if(type === "linear"){
    return(
      <Box width="100%" style={props.rootStyle}>
        <LinearProgress 
          classes={{
            root: classes.linearBarRoot,
            bar: classes.bar
          }}
        />
      </Box>
    )
      
  }else  return (
    <div className={classes.root} style={props.rootStyle}>
        <div style={{position:"relative", margin: "auto", height:"100%", display:"flex", alignItems:"center"}}>
            <CircularProgress
            variant="determinate"
            value={100}
            className={classes.facebook1}
            size={props.size ? props.size:SPINNER_SIZE}
            thickness={4}
          />
          <CircularProgress
            variant="indeterminate"
            disableShrink
            className={classes.facebook2}
            size={props.size? props.size:SPINNER_SIZE}
            thickness={4}
          />
        </div>
    </div>
  );
}

Preloader.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Preloader);
