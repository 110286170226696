import * as firebase from '../../../../config/firebase'
import logger from  './../../../../helpers/logger'
import roundNumber from './../../../../helpers/roundNumber'

import {
    FETCH_SUBSCRIBED_ACTIVE_BETS,
    FETCH_SUBSCRIBED_ACTIVE_BET,
    FETCH_ACTIVE_BETS,
    FETCH_USER_ACTIVE_BETS,

    FETCH_USER_INACTIVE_BETS,

    FETCH_ACTIVE_BET_INFO_BY_ID,
    FETCH_ACTIVE_BETS_INFO,

    REMOVE_USER_ACTIVE_BET,
    REMOVE_ACTIVE_BET,
    
    FETCH_USER_BETS_HISTORY,
   
    FETCH_BETS_HISTORY,
    FETCH_BET_HISTORY,

    FETCH_ACTIVE_BETS_STATISTIC,
     
    FETCH_USER_FAVORITE_BETS,
    ADD_ACTIVE_BET,
    ADD_USER_ACTIVE_BET,
    ADD_USER_FAVORITE_BET,
    REMOVE_USER_FAVORITE_BET,
   
    SET_ACTIVE_SNACKBAR,

    ADD_BETS_STATISTIC_COUNTER,

    UPDATE_ACTIVE_BET,
    CLOSE_ACTIVE_MODAL,

} from "../../types";


export const fetchSubscribedActiveBetById = (betId) => async dispatch => {
    
    try{
        logger('api_call', "fetchSubscribedActiveBetById")

        let res = await firebase.betsRef.child('activeBets').child(betId).once('value')
        let result = res.val()

        dispatch({
            type: FETCH_SUBSCRIBED_ACTIVE_BET,
            betId,
            payload: result
        })
    }catch(err){
        logger('exception', "fetchSubscribedActiveBetById", {err})
        
    }
}

export const fetchActiveBets = ({startAt = null, limitToLast = 20}) => async dispatch => {

    try{
        logger('api_call', "fetchActiveBets")

        let res = null

        if(startAt) res = await firebase.betsRef.child(`activeBets`).orderByKey().startAt(startAt).limitToLast(limitToLast).once('value')
        else res = await firebase.betsRef.child(`activeBets`).orderByKey().limitToLast(limitToLast).once('value')

        let result = res.val() 
        dispatch({
            type: FETCH_ACTIVE_BETS,
            payload: result
        })
    }catch(err){
        logger('exception', "fetchActiveBets", {err})
    }
}


export const updateActiveBet = ({betKey, values}) => async dispatch => {
    
    try{
        logger('api_call', "updateActiveBet")
       
        await firebase.betsRef.child(`activeBets`).child(betKey).update(values)

        dispatch({
            type: UPDATE_ACTIVE_BET,
            betKey,
            payload: values
        })

        dispatch({
            type: CLOSE_ACTIVE_MODAL,
        })

    }catch(err){
        logger('exception', "updateActiveBet", {err})
    }
}

///ACTIVE BETS INFO ACTIONS

export const fetchActiveBetInfoById = (betId) => async dispatch => {
    
   
    try{
        logger('api_call', "fetchActiveBetInfoById")

        let res = await firebase.betsRef.child(`activeBetsInfo/${betId}`).once('value')
        let result = res.val()
       
        dispatch({
            type: FETCH_ACTIVE_BET_INFO_BY_ID,
            betId,
            payload: result
        })
    }catch(err){
        logger('exception', "fetchActiveBetInfoById", {err})
    }
}

export const fetchActiveBetsInfo = ({orderBy, startAt="", groupBy, limitToLast=20}) => async (dispatch, getState) => {
   
    try{
        logger('api_call', "fetchActiveBetsInfo")
     
        let res = null
        if(orderBy !== "timestamp"){
            if(groupBy){
               
                res = await firebase.betsRef.child(`activeBetsInfo`).orderByChild(orderBy).equalTo(groupBy).once('value') 
                
                
            }else{
                res = await firebase.betsRef.child(`activeBetsInfo`).orderByChild(orderBy).limitToLast(limitToLast).once('value')
            }
           
        }else{
            res = await firebase.betsRef.child(`activeBetsInfo`).orderByKey().endAt(startAt).limitToLast(limitToLast).once('value')
        }

       
        
        let result = res.val()
        
        ///fetch bets statistic
     
        dispatch({
            type: FETCH_ACTIVE_BETS_INFO,
            orderBy, 
            payload: result
        })

    }catch(err){
        logger('exception', "fetchActiveBetsInfo", {err})
    }
}

export const fetchActiveBetsInfoByUserId = ({auth, userId, orderBy, startAt=""}) => async dispatch => {
    
    try{
        logger('api_call', "fetchActiveBetsInfoByUserId")

        const results = await firebase.betsRef.child('activeBetsInfo').orderByChild('user').equalTo(userId).once('value')
     
        dispatch({
            type: FETCH_ACTIVE_BETS_INFO,
            userId,
            orderBy,
            payload: results.val()
        })

        return true
    }catch(err){
        logger('exception', "fetchActiveBetsInfoByUserId", {err})
    }
}

export const fetchActiveBetsInfoBySport = ({auth, userId, orderBy, groupBy="", startAt="", betId}) => async dispatch => {
    
    try{
        logger('api_call', "fetchActiveBetsInfoBySport")

        let res = await firebase.betsRef.child('activeBetsInfo').orderByChild(orderBy).equalTo(groupBy).once('value')
        let results = res.val()
        if(!results) return 
     
        dispatch({
            type: FETCH_ACTIVE_BETS_INFO,
            userId,
            orderBy,
            payload: results
        })

        return true
    }catch(err){
        logger('exception', "fetchActiveBetsInfoBySport", {err})
    }
}

///// ADD NEW ACTIVE BET
export const addUserActiveBet = (auth, bets, stake) => async (dispatch, getState) => {
    
    
    let todaysMatches =  getState().todaysMatches
    let timestamp = Math.floor(Date.now()/1000)

    let userActiveBets = getState().brdoparaData.userActiveBets 

    if(userActiveBets === "loading"){
        logger('api_call', "fetchUserActiveBets")
        let res = await firebase.betsRef.child('activeBets').orderByChild('user').equalTo(auth.uid).once('value')
        userActiveBets = res.val() || {}
        
        dispatch({
            type: FETCH_USER_ACTIVE_BETS,
            payload: userActiveBets
        })
    }

    
    try{
        logger('api_call', "addUserActiveBet")

        let activeBet = {
            timestamp,
            user: auth.uid,
            userName: auth.userInfo.displayName,

            userAchievements: auth.userInfo.achievements || null,
            userIcon: auth.userInfo.icon || null, 
            
            stake
        }

        let activeBetInfo = {...activeBet}

        if(bets.length === 1){

            let bet = bets[0]

            activeBet["sportId"] = bet.sportId
            activeBet["competitionId"] = bet.competitionId
            activeBet["matchId"] = bet.matchId
            activeBet["type"] = bet.betTypeId
            activeBet["typeOption"] = bet.betTypeOptionId
            activeBet["bet"] = bet.matchWinner
            activeBet["betOption"] =  bet.matchResultOption || null
            activeBet["odds"] = bet.betOdds
            activeBet["comment"] = bet.comment || null
            activeBet["competition"] = todaysMatches.sports[bet.sportId].competitionsList[bet.competitionId].title
            activeBet["match"] = todaysMatches.sports[bet.sportId].competitions[bet.competitionId].matches[bet.matchId].participants
            activeBet["matchLink"] = todaysMatches.sports[bet.sportId].competitions[bet.competitionId].matches[bet.matchId].matchLink
            activeBet["matchTime"] = todaysMatches.sports[bet.sportId].competitions[bet.competitionId].matches[bet.matchId].time
             
            activeBetInfo["sportId"] = bet.sportId
            activeBetInfo["competitionId"] = bet.competitionId
            activeBetInfo["matchId"] = bet.matchId
            activeBetInfo["competition"] = todaysMatches.sports[bet.sportId].competitionsList[bet.competitionId].title
            activeBetInfo["match"] = todaysMatches.sports[bet.sportId].competitions[bet.competitionId].matches[bet.matchId].participants
            activeBetInfo["matchTime"] = todaysMatches.sports[bet.sportId].competitions[bet.competitionId].matches[bet.matchId].time
            activeBetInfo["comment"] = !!bet.comment && bet.comment.length > 100 

        }else if(bets.length > 1 ){

            activeBet["sportId"] = "combination"
            activeBetInfo["sportId"] = "combination"

            activeBet["bets"] = []
            activeBetInfo["bets"] = []

            activeBet["combination"] = true
            activeBetInfo["combination"] = true

            activeBet["comment"] = false
            activeBetInfo["comment"] = false

            activeBet["matchTime"] = null
            activeBetInfo["matchTime"] = null

            activeBet["odds"] = 1

            for(let bet of bets){

                let combBet = {}
                let combBetInfo = {}

                combBet["sportId"] = bet.sportId
                combBet["competitionId"] = bet.competitionId
                combBet["matchId"] = bet.matchId
                combBet["type"] = bet.betTypeId
                combBet["typeOption"] = bet.betTypeOptionId
                combBet["bet"] = bet.matchWinner
                combBet["betOption"] =  bet.matchResultOption || null
                combBet["odds"] = bet.betOdds
                combBet["comment"] = bet.comment || null
                combBet["competition"] = todaysMatches.sports[bet.sportId].competitionsList[bet.competitionId].title
                combBet["match"] = todaysMatches.sports[bet.sportId].competitions[bet.competitionId].matches[bet.matchId].participants
                combBet["matchLink"] = todaysMatches.sports[bet.sportId].competitions[bet.competitionId].matches[bet.matchId].matchLink
                combBet["matchTime"] = todaysMatches.sports[bet.sportId].competitions[bet.competitionId].matches[bet.matchId].time
                
                combBetInfo["sportId"] = bet.sportId
                combBetInfo["competitionId"] = bet.competitionId
                combBetInfo["matchId"] = bet.matchId
                combBetInfo["competition"] = todaysMatches.sports[bet.sportId].competitionsList[bet.competitionId].title
                combBetInfo["match"] = todaysMatches.sports[bet.sportId].competitions[bet.competitionId].matches[bet.matchId].participants
                combBetInfo["matchTime"] = todaysMatches.sports[bet.sportId].competitions[bet.competitionId].matches[bet.matchId].time
                combBetInfo["comment"] = !!bet.comment && bet.comment.length > 100 

                activeBet.bets.push(combBet)
                activeBetInfo.bets.push(combBetInfo)

                //compute combined odds
                activeBet["odds"] =  roundNumber(activeBet["odds"] * combBet.odds)

                //set comment flag 
                if(combBet.comment){
                    activeBet["comment"] = true
                    activeBetInfo["comment"] = true
                }

                //set global matchTime, earliest matchTime
                if(!activeBet["matchTime"] || activeBet["matchTime"] > combBet.matchTime ){

                    activeBet["matchTime"] = combBet.matchTime
                    activeBetInfo["matchTime"] = combBet.matchTime

                }
            }

        }else return
            
        if(bets.length === 1){
            //single bet

             //check if there are similar bets
            for(let betKey in userActiveBets){
                
                let bet = userActiveBets[betKey]

                if(activeBet.matchId === bet.matchId &&  activeBet.type === bet.type && activeBet.typeOption === bet.typeOption){

                    dispatch({
                        type: SET_ACTIVE_SNACKBAR,
                        name: "duplicatedBetError",
                        status: "error"
                    })

                    return false
                }
            }
        }else{
            //combination bet

            for(let betKey in userActiveBets){
                let bet = userActiveBets[betKey]

                //if not combination continue
                if(!bet.bets)continue

                for(let combinationBet of bet.bets){
                    for(let newCombinationBet of activeBet.bets){

                        if(newCombinationBet.matchId === combinationBet.matchId && 
                                newCombinationBet.type === combinationBet.type &&
                                    newCombinationBet.typeOption === combinationBet.typeOption){

                            dispatch({
                                type: SET_ACTIVE_SNACKBAR,
                                name: "duplicatedCombinationBetError",
                                status: "error"
                            })
        
                            return false
                        }
                    }
                   
                }
            }
        }
       
        

        let newActiveBetKey = firebase.betsRef.child("activeBets").push().key

        firebase.betsRef.update({
            [`activeBets/${newActiveBetKey}`]: activeBet,
            [`activeBetsInfo/${newActiveBetKey}`]: activeBetInfo,
        })

        dispatch({
            type: ADD_USER_ACTIVE_BET,
            user: auth.uid,
            betId: newActiveBetKey,
            payload: activeBet
        })

        dispatch({
            type: ADD_ACTIVE_BET,
            user: auth.uid,
            betId: newActiveBetKey,
            payload: activeBet
        })

        dispatch({
            type: SET_ACTIVE_SNACKBAR,
            name: "addBetSuccess",
            status: "success"
        })

        dispatch({
            type: ADD_BETS_STATISTIC_COUNTER,
            bet: activeBet
        })
        
        return true
    }catch(err){

        dispatch({
            type: SET_ACTIVE_SNACKBAR,
            name: "addBetError",
            status: "error"
        })
        logger('exception', "addUserActiveBet", {err})
       
    }
    
}

/// INACTIVE BETS  ACTIONS

export const fetchUserInactiveBets = ({auth}) => async dispatch => {
    try{
        logger('api_call', "fetchUserInactiveBets")

        let res = await firebase.betsRef.child(`inactiveBets`).orderByChild('user').equalTo(auth.uid).once('value')

        let result = res.val() 

        

        dispatch({
            type: FETCH_USER_INACTIVE_BETS,
            payload: result
        })
    }catch(err){
        logger('exception', "fetchUserInactiveBets", {err})
    }
}

///// BETS STATISTIC ACTION
const asyncfetchActiveBetsStatistic = async () => {
    
    try{
        logger('api_call', "fetchActiveBetsStatistic")

        let res = await firebase.betsRef.child('betsStats').once('value')
        let result = res.val()
      
       return result
    }catch(err){
        logger('exception', "fetchActiveBetsStatistic", {err})
    }
}

export const fetchActiveBetsStatistic =  () => async dispatch => {
    
    try{
        logger('api_call', "fetchActiveBetsStatistic")

        let res = await firebase.betsRef.child('betsStats').once('value')
        let result = res.val()

        dispatch({
            type: FETCH_ACTIVE_BETS_STATISTIC,
            groupBy: "all",
            payload: result
        })

       return 
    }catch(err){
        logger('exception', "fetchActiveBetsStatistic", {err})
    }
}

///ACTIVE USER BETS ACTIONS
export const fetchSubscribedActiveBets = ({auth}) => async dispatch => {
    
    try{
        logger('api_call', "fetchSubscribedActiveBets")

        let subscriptions = auth.userInfo && auth.userInfo.metadata && auth.userInfo.metadata.subscriptions
       
        if(subscriptions){
            
            let subscribedBets = {}

            let promises = Object.keys(subscriptions).map(s => firebase.betsRef.child('activeBets').orderByChild('user').equalTo(s).once('value'))
            
            let resPromises = await Promise.all(promises)

            subscribedBets = resPromises.reduce((reducer, res)=> ({...reducer, ...res.val()}),{})
           
            let favActiveBetsId = await firebase.betsRef.child("usersFavorites").child(auth.uid).once('value')
            
            favActiveBetsId = favActiveBetsId.val()
            favActiveBetsId = favActiveBetsId ? favActiveBetsId:{}
            const userFavoriteBets = Object.keys(favActiveBetsId).reduce((reducer, betId) => (subscribedBets[betId] ? {...reducer, [betId]: subscribedBets[betId]} : reducer), {})
            
            dispatch({
                type: FETCH_SUBSCRIBED_ACTIVE_BETS,
                payload: subscribedBets
            })

            dispatch({
                type: FETCH_USER_FAVORITE_BETS,
                payload: userFavoriteBets
            })

         
        }
 
        return true
    }catch(err){
        
        logger('exception', "fetchSubscribedActiveBets", {err})
    }
}

export const fetchUserActiveBets = ({auth}) => async (dispatch, getState) => {
    
    try{
        logger('api_call', "fetchUserActiveBets")

        if(auth.userInfo.type === 1){

            const res = await firebase.favActiveBetsRef.child(auth.uid).once('value')
            const favActiveBetsId = res.val()

            if(favActiveBetsId){
                console.log("No favorite bets selected")
                return 
            }

            let subscribedActiveBets = getState().brdoparaData.activeBets.subscribed

            if(!subscribedActiveBets){
                
                let subscriptions = auth.userInfo && auth.userInfo.metadata && auth.userInfo.metadata.subscriptions
                subscribedActiveBets = {}

                if(subscriptions){
                    for(let s of subscriptions){
                        let res = await firebase.betsRef.child('activeBets').orderByChild('user').equalTo(s).once('value')
                        subscribedActiveBets = {...subscribedActiveBets, ...res.val()}
                    }
                }else{
                    console.log("No subscribed bets")
                    return
                }
            }

            dispatch({
                type: FETCH_USER_ACTIVE_BETS,
                orderBy: "time",
                payload: favActiveBetsId.map(betId => subscribedActiveBets[betId])
            })
        }else if(auth.userInfo.type === 2){

            let res = await firebase.betsRef.child('activeBets').orderByChild('user').equalTo(auth.uid).once('value')
            let result = res.val()
            if(!result)result = {}

            dispatch({
                type: FETCH_USER_ACTIVE_BETS,
                orderBy: "time",
                payload: result
            })
        }
    }catch(err){          
        logger('exception', "fetchUserActiveBets", {err})
    }   
}

export const removeUserActiveBet = (betKey)=> async (dispatch, getState) => {

    let selectedBet = getState().brdoparaData.userActiveBets[betKey]

    if(selectedBet.timestamp < Date.now()/1000 + 900){

        dispatch({
            type:  SET_ACTIVE_SNACKBAR,
            name: "removeActiveBetErrorSnackbar",
            status: "error"
        })

        dispatch({
            type: CLOSE_ACTIVE_MODAL,
        })
    

        return
    }
   
    await firebase.betsRef.child('activeBets').child(betKey).remove()
    await firebase.betsRef.child('activeBetsInfo').child(betKey).remove()
   
    dispatch({
        type: REMOVE_USER_ACTIVE_BET,
        betKey
    })

    dispatch({
        type: REMOVE_ACTIVE_BET,
        betKey
    })

    dispatch({
        type: CLOSE_ACTIVE_MODAL,
    })

    dispatch({
        type:  SET_ACTIVE_SNACKBAR,
        name: "removeActiveBetSuccessSnackbar",
        status: "success"
    })

    return
}

//// BETS HISTORY ACTIONS
export const fetchUserBetsHistory = ({auth, userId, orderBy, startAt, limitToLast=30}) => async dispatch => {
    
    try{
        logger('api_call', "fetchUserBetsHistory")

        let res = null
        console.log({userId, orderBy, startAt})
        
        if(startAt){
            
            res = await firebase.betsRef.child('usersHistory').child(userId).orderByChild(orderBy).endAt(startAt).limitToFirst(limitToLast).once('value')
        }else{
            res = await firebase.betsRef.child('usersHistory').child(userId).orderByChild(orderBy).limitToLast(limitToLast).once('value')
        }
       
        dispatch({
            type: FETCH_USER_BETS_HISTORY,
            userId,
            orderBy: "user",
            payload: res.val()
        })

        return true
    }catch(err){
        
        logger('exception', "fetchUserBetsHistory", {err})
    }
}

export const fetchBetsHistory = ({auth, userId, orderBy, startAt, limitToLast=30}) => async dispatch => {
    
   
    try{
        logger('api_call', "fetchBetsHistory")

        let results = null
        if(startAt) results = await firebase.betsRef.child('history').orderByChild(orderBy).startAt(startAt).limitToFirst(limitToLast).once('value')
        else results = await firebase.betsRef.child('history').orderByChild(orderBy).limitToFirst(limitToLast).once('value')

        let data = results.val()

        dispatch({
            type: FETCH_BETS_HISTORY,
            userId,
            orderBy,
            payload: data
        })
        
        return true
    }catch(err){
        
        logger('exception', "fetchBetsHistory", {err})
    }
}

export const fetchHistoryBetById = ({betId}) => async dispatch => {
    
    try{
        logger('api_call', "fetchHistoryBetById")

        let res = await firebase.betsRef.child('history').child(betId).once('value')
        let result = res.val()

        dispatch({
            type: FETCH_BET_HISTORY,
            betId,
            payload: result
        })
    }catch(err){
        logger('exception', "fetchHistoryBetById", {err})
    }
}


////// BETS FAVORITES ACTION
export const addUserFavoriteBet = ({betId, userId}) => async dispatch => {
    
    try{
        logger('api_call', "addUserFavoriteBet")

        await firebase.betsRef.child('usersFavorites').child(userId).update({
            [betId]: Date.now()
        })
        
        dispatch({
            type: ADD_USER_FAVORITE_BET,
            userId,
            betId,
            payload: Date.now()
        })

        dispatch({
            type:  SET_ACTIVE_SNACKBAR,
            name: "addFavoriteBetSnackbar",
            status: "success"
        })

    }catch(err){
        dispatch({
            type:  SET_ACTIVE_SNACKBAR,
            name: "favoriteBetErrorSnackbar",
            status: "error"
        })
        logger('exception', "addUserFavoriteBet", {err})
    }
}

export const removeUserFavoriteBet = ({betId, userId}) => async dispatch => {
    
    try{
        logger('api_call', "removeUserFavoriteBet")
        
        await firebase.betsRef.child('usersFavorites').child(userId).child(betId).remove()

        dispatch({
            type: REMOVE_USER_FAVORITE_BET,
            userId,
            betId
        })

        dispatch({
            type:  SET_ACTIVE_SNACKBAR,
            name: "removeFavoriteBetSnackbar",
            status: "success"
        })

    }catch(err){
        dispatch({
            type:  SET_ACTIVE_SNACKBAR,
            name: "favoriteBetErrorSnackbar",
            status: "error"
        })
        logger('exception', "removeUserFavoriteBet", {err})
    }
}

export const fetchUserFavoriteBets = ({userId}) => async dispatch => {
    
    try{
        logger('api_call', "fetchUserFavoriteBets")

        let res = await firebase.betsRef.child('usersFavorites').child(userId).once('value')
        let results = res.val()

        dispatch({
            type: FETCH_USER_FAVORITE_BETS,
            payload: results
        })

       
    }catch(err){
        logger('exception', "fetchUserFavoriteBets", {err})
    }
}
