import {
    FETCH_USER_INACTIVE_BETS,
} from "../../../actions/types";
  
  
export default (state = "loading", action) => {
   
    //initialize todays matches object on first load
    let subscribedBets = state !== "loading" ? {...state} : {}
    switch (action.type) {
        case FETCH_USER_INACTIVE_BETS:   
            subscribedBets = action.payload
            return subscribedBets;
        default:
            return state
    }
};