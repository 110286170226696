import React, {Suspense} from 'react';

import * as actions from './../redux/actions'
import { connect } from "react-redux";

import withRoot from './../withRoot';
//import GridItemElement from './default/elements/GridItemElement'

import CustomElement from './custom/CustomElement'
import Preloader from './/components/preloaders/Preloader'

const GridItemElement = React.lazy(()=> import('./default/elements/GridItemElement'))
const GridContainerElement = React.lazy(()=> import('./default/elements/GridContainerElement'))
const ButtonElement = React.lazy(()=> import('./default/elements/ButtonElement'))
const TextElement = React.lazy(()=> import('./default/elements/TextElement'))
const ImageElement = React.lazy(()=> import('./default/elements/ImageElement'))
const FormElement = React.lazy(()=> import('./default/elements/FormElement'))
const FormFieldElement = React.lazy(()=> import('./default/elements/FormFieldElement'))
const CarouselElement = React.lazy(()=> import('./default/elements/CarouselElement'))
const BoxElement = React.lazy(()=> import('./default/elements/BoxElement'))
const BoxWithDrawerElement = React.lazy(()=> import('./default/elements/BoxWithDrawerElement'))
const GoogleMapsElement = React.lazy(()=> import('./default/elements/GoogleMapsElement'))
const IconElement = React.lazy(()=> import('./default/elements/IconElement'))

function  Element(props) {

    const { elementId, edit, elements, style } = props
    
    let element = elements[elementId]

    if (element) {
      switch (element.type) {
        case 'grid_container_element':
          return (
            <Suspense fallback={<Preloader rootStyle={{position: "absolute", top: 0, left: 0}}/>}>
              <GridContainerElement style={style} elementId={elementId}  edit={edit} {...element} />
            </Suspense>
          )
        case 'grid_item_element':

          return (
            <Suspense fallback={<Preloader rootStyle={{position: "absolute", top: 0, left: 0}} />}>
              <GridItemElement style={style} elementId={elementId}  edit={edit} {...element} />
            </Suspense>
          )
        case 'box_element':
          return (
            <Suspense fallback={<Preloader rootStyle={{position: "absolute", top: 0, left: 0}} />}>
              <BoxElement style={style} elementId={elementId} edit={edit}  {...element} />
            </Suspense>
          )
        case 'box_with_drawer_element':
          return (
            <Suspense fallback={<Preloader rootStyle={{position: "absolute", top: 0, left: 0}} />}>
              <BoxWithDrawerElement style={style} elementId={elementId} edit={edit}  {...element} />
            </Suspense>
          )
        case 'carousel_element':
          return (
            <Suspense fallback={<Preloader rootStyle={{position: "absolute", top: 0, left: 0}} />}>
              <CarouselElement style={style} elementId={elementId} edit={edit}  {...element} />
            </Suspense>
            
          )
        case 'button_element':
          return (
            <Suspense fallback={<Preloader rootStyle={{position: "absolute", top: 0, left: 0}} />}>
              <ButtonElement style={style} elementId={elementId} edit={edit} {...element} />
            </Suspense>
            
          )
        case 'text_element':

          return(
            <Suspense fallback={<Preloader rootStyle={{position: "absolute", top: 0, left: 0}} />}>
              <TextElement style={style} elementId={elementId} edit={edit} {...element} />
            </Suspense>
            
          )
        case 'image_element':
          return(
            <Suspense fallback={<Preloader rootStyle={{position: "absolute", top: 0, left: 0}} />}>
              <ImageElement style={style} elementId={elementId} edit={edit} {...element} />
            </Suspense>
            
          )
        case 'google_maps_element':
          return (
            <Suspense fallback={<Preloader rootStyle={{position: "absolute", top: 0, left: 0}} />}>
              <GoogleMapsElement style={style}  elementId={elementId} edit={edit} {...element} />
            </Suspense>
          
          )
        case 'form_element':
            return(
              <Suspense fallback={<Preloader rootStyle={{position: "absolute", top: 0, left: 0}} />}>
                <FormElement style={style} elementId={elementId} edit={edit} {...element} />
              </Suspense>
              
            )
        case 'form_field_element':
            return(
              <Suspense fallback={<Preloader rootStyle={{position: "absolute", top: 0, left: 0}} />}>
                 <FormFieldElement style={style} elementId={elementId} edit={edit} {...element} />
              </Suspense>
             
            )
        case 'icon_element':
          
          return(
            <Suspense fallback={<Preloader rootStyle={{position: "absolute", top: 0, left: 0}} />}>
              <IconElement style={style} elementId={elementId} edit={edit} {...element} />
            </Suspense>
            
          )
        default:
          return <CustomElement style={style} elementId={elementId} edit={edit} {...props} />
      }
    } else return null

}


const mapStateToProps = ({ elements }) => {
  return {
    elements,
  }
}

export default connect(mapStateToProps, actions)(withRoot(Element))
