
import {
    FETCH_USER_BETS_HISTORY,
} from "../../../actions/types";
  
  
export default (state = "loading", action) => {
    if(!action.payload)return state

    //initialize todays matches object on first load
    let historyBets = state !== "loading" ? {...state} : {}
    
    switch (action.type) {
     
        case FETCH_USER_BETS_HISTORY:
            if(action.userId in historyBets){
                historyBets[action.userId] = {...historyBets[action.userId], ...action.payload}
            }else{
                historyBets = {[action.userId]: action.payload}
            }
            return historyBets
        default:
            return state
    }
};