import React from 'react';
import {withRouter} from 'react-router'
import { connect } from "react-redux";
import * as actions from '../../../../redux/actions'
import classNames from 'classnames'
import Typography from '@material-ui/core/Typography';
import Snackbar  from '@material-ui/core/Snackbar';
import Box from '@material-ui/core/Box';
import CustomIcon from '../../../components/icons/CustomIcon'
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import useDictionary from '../../../../hooks/useDictionary';

const actionsPathNames = {
   
    loginError: {
        actions:["/login?mode=forgotPassword"]
    },
  
    registrationErrorMailAlreadyExist:{
        actions: ["/login"]
    },
    passwordResetActionCodeError:{
        actions: ["/login?mode=forgotPassword"]
    }


}

const useStyle = makeStyles( theme => createStyles({
    alertContainer:{
        background: theme.palette.background.primary,
        borderRadius: 8,
    },
    error:{
        border: "2px solid "+theme.palette.error.main
    },
    success:{
        border: "2px solid "+theme.palette.success.main
    },
    info:{
        border: "2px solid "+theme.palette.info.main
    },
    link:{
        textDecoration: "underline",
        cursor: "pointer",
        color: theme.palette.primary.main,
        paddingLeft: 4,
        "&:hover":{
            color: theme.palette.primary.dark
        }
    }
}))

function CustomSnackbarLayout({snackbarStatus, autoHideDuration = 10000, children, ...props}){
   
    const theme = useTheme();
    const classes = useStyle()
    const dictionary = useDictionary({language: 'slv', elementId: "brdopara_snackbar_element"})
    const content = dictionary[snackbarStatus.name]


    const handleClose = () => {
        props.closeActiveSnackbar()
    };
    const handleRedirect = (pathname)=> ()=>{
        props.history.replace(pathname)
    }
    
    return (
        <Snackbar 
            open={snackbarStatus.active}
            onClose={handleClose}
            autoHideDuration={ snackbarStatus === "error" ? 20000:autoHideDuration}
            classes={{}}
        >
            <Box 
                display="flex" 
                alignItems="center" 
                p={2} 
                minWidth={350} 
                maxWidth={700} 
                onClose={handleClose} 
                className={classNames(
                    classes.alertContainer, 
                    {[classes.error]: snackbarStatus.status === "error", 
                    [classes.success]: snackbarStatus.status === "success", 
                    [classes.info]: snackbarStatus.status === "info"}
                )}  
                color="white"
            >
                <Box pr={2} display="flex" alignItems="center" justifyContent="center" fontSize={40}>
                    <CustomIcon name={snackbarStatus.status} />
                </Box>
                
                    {
                        content &&
                            <Box display="flex">
                                <Typography variant="body2">
                                    {content.label}
                                    {
                                        actionsPathNames[snackbarStatus.name] && content.actions && 
                                            actionsPathNames[snackbarStatus.name].actions.map((pathname, i) => (
                                                <span key={i} onClick={handleRedirect(pathname)} className={classes.link}  variant="body2">{(content.actions && content.actions[i]) || "Link"}</span>  
                                            ))
                                    }
                                </Typography>
                            </Box>        
                    }

                    {
                        snackbarStatus.label &&
                            <Box display="flex">
                                <Typography variant="body2">
                                    {snackbarStatus.label}
                                </Typography>
                            </Box>        
                    }
                   
                    {children}
                
            </Box>
          
        </Snackbar>
    )
  }

  const mapStateToProps = ({snackbarStatus}) => {
    return {
      snackbarStatus
    }
  }

  export default withRouter(connect(mapStateToProps, actions)(CustomSnackbarLayout))