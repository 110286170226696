import React from 'react';
import {withRouter} from 'react-router'
import * as actions from '../../../../redux/actions'
import { connect } from "react-redux";
import { makeStyles, createStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import Helmet from 'react-helmet'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import editWrapper from '../../../default/wrappers/editWrapper'
import CustomIcon from './../../../components/icons/CustomIcon'
import CustomButton from './CustomButton'
import CustomDialogLayout from './CustomDialogLayout'
import useDictionary from '../../../../hooks/useDictionary';
import timestampFormatter from '../../../../helpers/timestampFormatter';
import { FacebookShareButton, FacebookIcon } from 'react-share'
import CustomInfoLayout from './CustomInfoLayout'
import useMediaBreakPoint from '../../../../hooks/useMediaBreakPoint';
import SubscribeTipsterModal from './SubscribeTipsterModal'
import classNames from 'classnames';

const TITLE_HEIGHT = 60

const BUTTON_HEIGHT = 45

const StyledTabs = withStyles(theme=>({
    indicator: {
      display: 'flex',
  
      background: "none",

      '& > span': {
        width: '100%',
        backgroundColor: theme.palette.background.dark,
      },

      flexContainer: {
        width: "100%",

      }
    },
  }))((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);
  
  const StyledTab = withStyles((theme) => ({
    root: {
        flexGrow: 1,
        maxWidth: 'none',
        textTransform: 'none',
        
        color: '#fff',
    
        background: theme.palette.background.transparent,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: theme.typography.pxToRem(15),

        '&:first-child':{
            borderTopLeftRadius: theme.shape.borderRadius,
        },
        
        '&:last-child':{
            borderTopRightRadius: theme.shape.borderRadius,
        },
        
        '&:focus': {
            opacity: 1,
            
        },
    },

    selected:{
        background: theme.palette.primary.main,
        color: theme.palette.text.primary,
    }
  }))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles( theme => createStyles({
   
    title:{

    },

    selectedPage:{
        height: TITLE_HEIGHT,
      
        width: TITLE_HEIGHT,
        background: theme.palette.primary.main
    },

    buttonTypography:{
        fontSize: "clamp(0.7em, 3vw, 1em)"
    },

    profileTitle: {
        fontSize: "clamp(1em, 3vw, 1.5em)"
    },

    button:{

        cursor:"pointer",
        '&:hover':{
            background: "#1c76bf",
            boxShadow: theme.shadows[3]
        }

    },
    hyperlink:{

        color: "white",
        "&:hover": {
            color: theme.palette.primary.main
        }

    },

    withoutTopBorder:{
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
    }

}))

function BetInfoElement({ 
    title, 
    auth, 
    brdoparaData, 
    location, 
    history, 
    userGeoLocation,
    ...props}){
    
    const profile = auth.userInfo
    const theme = useTheme()
    const breakpoint = useMediaBreakPoint()
    const classes = useStyles(props)
    const dictionary = useDictionary({elementId: "bet_info_element", userGeoLocation})
    const dateDictionary = useDictionary({elementId: "date", userGeoLocation})
    const headerDictionary = useDictionary({elementId: "date", userGeoLocation})
    const betTypeDictionary = useDictionary({elementId: "bet_options", userGeoLocation})
    const [subscribed, setSubscribed] = React.useState(null)
    const [favoriteBets, setFavoriteBets]  = React.useState(null)
    const [bet, setBet] = React.useState({})
    const [selectedBetCombination, setSelectedBetCombination] = React.useState(0)
    const [loading, setLoading] = React.useState(false)
        
    React.useEffect(() => {
        //reset scroll position
        window.scrollTo(0,0)

        let urlParams = new URLSearchParams(location.search)
        let betId = urlParams.get('betId')

        
        if(brdoparaData.activeBets !== "loading" && betId in brdoparaData.activeBets){
          
            let searchedBet =  brdoparaData.activeBets[betId]
            if(!searchedBet){ 
                setBet(null)
                return
            }
            //check if user is subscribed to bet's creator id
            searchedBet.id = betId
            searchedBet.matchStatus = searchedBet.matchTime < Date.now()/1000 ? true : false
            searchedBet.matchTimeFormatted = timestampFormatter(searchedBet.matchTime, dateDictionary)
            
            if(auth.uid === searchedBet.user || (auth.userInfo.metadata && auth.userInfo.metadata.subscriptions && auth.userInfo.metadata.subscriptions[searchedBet.user])){
                setSubscribed(true)
            }else{
                setBet(searchedBet.combination ? {...searchedBet, ...searchedBet.bets[selectedBetCombination]} : searchedBet)
               
            }
            
        }else{
          
            props.fetchActiveBetInfoById(betId)
        }
        
    },[location, brdoparaData.activeBets])

    React.useEffect(() => {
        
        if(subscribed){
            let urlParams = new URLSearchParams(location.search)
            let betId = urlParams.get('betId')

            let searchedBet = brdoparaData.subscribedBets !== "loading" && brdoparaData.subscribedBets[betId]
             //check if subscribed
            if(!searchedBet){
                props.fetchSubscribedActiveBetById(betId)
            }else{

                searchedBet.id = betId
                searchedBet.matchStatus = searchedBet.matchTime < Date.now()/1000 ? true : false
               
                setBet(searchedBet.combination ? {
                    ...searchedBet, 
                    totalOdds: searchedBet.odds,
                    ...searchedBet.bets[selectedBetCombination]
                } : searchedBet)
            }
            
        }   
        
    }, [location, subscribed, brdoparaData.subscribedBets])

    React.useEffect(() => {
        
        if(auth.userInfo.metadata && auth.userInfo.metadata.subscriptions && auth.userInfo.metadata.subscriptions[bet.user]){
            setSubscribed(true)
        }
        
    }, [auth])

   
    React.useEffect(() => {
        
        if(subscribed){
       
            if(brdoparaData.userFavoriteBets === "loading"){
                props.fetchUserFavoriteBets({userId: auth.uid})
            }else{
                setFavoriteBets(brdoparaData.userFavoriteBets)
            }
            
        }   
        
    }, [subscribed, brdoparaData.userFavoriteBets])
    
    const handleBetAddToFavoriteModal = ()=>{
        props.setActiveModal("betAddToFavoriteModal")
    }
    const handleRemoveFavoriteBetModal = ()=>{
        props.setActiveModal("betRemoveFromFavoriteModal")
    }

    const handleAddFavoriteBet = ()=>{
        props.addUserFavoriteBet({betId: bet.id, userId: auth.uid})

        props.closeActiveModal()
    }

    const handleRemoveFavoriteBet = ()=>{
        props.removeUserFavoriteBet({betId: bet.id, userId: auth.uid})
      
        props.closeActiveModal()
    }

    const handleTipsterButton = ()=>{
        history.push(`/user/profile?userId=${bet.user}`)
    }

    const handleSubscriptionButton = ()=>{
        props.setActiveModal("tipsterSubscribeModal")
    }

    const handleTabChange = (event, newValue)=>{
        setSelectedBetCombination(newValue)
        
        setBet({
            ...bet, 
            betOption: null,
            ...bet.bets[newValue]
        })
    }

    if(!bet){
        return null       
    }else return (
        <Box width="100%" height="100%" position="relative"  borderRadius={2}>  
           
            <Fade in={bet.combination && subscribed && Object.keys(bet).length > 0} timeout={1000} mountOnEnter unmountOnExit>
                <Box flexGrow={4} mb={1}>
                    <Box display="flex" justifyContent="space-between" alignItems="flex-end" mb={1} height={TITLE_HEIGHT}>
                        <CustomButton 
                            label={dictionary.titleCombination} 
                            height={TITLE_HEIGHT}
                            type="title"
                        />
                    </Box>
                        
                    <Box display="flex">
                        <Box flexGrow={1} display="flex" p={2}  flexDirection="column" justifyContent="center" color="text.secondary" bgcolor="background.transparent" borderBottom="1px solid black" borderRadius={4}>
                            <Box pb={1} color="white">
                                <Typography variant={"h2"} noWrap={false}> 
                                    {dictionary.betStakeLabel}
                                </Typography>
                            </Box>
                            <Box width="100%"  borderRadius={8} p={1} >
                                <Typography variant={"body1"} noWrap={false}> {bet.stake} </Typography>
                            </Box>     
                        </Box>

                        <Box flexGrow={1} display="flex" ml={1} p={2} flexDirection="column" justifyContent={"center"}  bgcolor="background.transparent" borderRadius={4} color="text.secondary">
                            <Box pb={1}>
                                <Typography variant={"h2"} noWrap> {dictionary.combinationTotalOddsLabel}</Typography>    
                            </Box>
                            <Box p={1} >
                                <Typography style={{fontWeight: 600, fontSize: "1.4em"}} variant={"body1"} noWrap>{bet.totalOdds}</Typography>
                            </Box>
                            
                        </Box>
                    </Box>
                </Box>  
            </Fade>

            <Fade in={Object.keys(bet).length > 0} mountOnEnter unmountOnExit>
                <Box width="100%"  display="flex" flexDirection="column" borderRadius={2}>
                   
                    <Box display="flex"  justifyContent="space-between" alignItems="flex-end" mb={1}  height={TITLE_HEIGHT}>
                        <CustomButton 
                            label={dictionary.title} 
                            height={TITLE_HEIGHT}
                            type="title"
                        />
                        <Box display="flex" pl={1}>
                            <Box 
                                color="white" 
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                height={BUTTON_HEIGHT}
                            >   
                            </Box>
                           
                            <FacebookShareButton
                                url={`https://brdopara.com/facebookShare?type=bet&id=${bet.id}`}
                                appId="118291070242161"
                            >
                                <Box display="flex" alignItems="center" justifyContent="center" bgcolor="#3b5998" height={BUTTON_HEIGHT} width={BUTTON_HEIGHT} borderRadius={4} overflow="hidden">
                                    <FacebookIcon size={45} />
                                </Box>
                            </FacebookShareButton>
                            {
                                bet.matchStatus ?
                                    <Box 
                                        borderRadius={4} 
                                        bgcolor={bet.matchStatus ? "error.main":"success.main"}
                                        color="white" 
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        height={BUTTON_HEIGHT}
                                        p={1}
                                        px={2}
                                        ml={1}
                                >
                                    <Typography className={classes.buttonTypography} variant={"h2"} noWrap> {dictionary.matchFinished}</Typography>
                                </Box>:
                                <Box ml={1}>
                                    <CustomButton type="titleButton" label={timestampFormatter(bet.matchTime, dateDictionary)} />
                                </Box>    
                                
                            }  
                        </Box>  
                    </Box>
                  
                    {
                        bet.combination &&
                            <StyledTabs 
                                
                                value={selectedBetCombination} 
                                onChange={handleTabChange} 
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="tabs"
                                classes={{
                                    scrollButtons: classes.scrollButtons,
                                   
                                }}
                            >
                                {
                                    bet.bets.map((bet, i)=>
                                        <StyledTab 
                                            value={i} 
                                            key={i} 
                                            label={(i+1)+". "+ dictionary.betCombinationNumLabel} 
                                        />   
                                    )
                                }
                            </StyledTabs>
                    }
                    <Box width="100%" display="flex"  p={1} bgcolor="primary.main" color="text.primary" className={classNames({[classes.withoutTopBorder]: bet.combination})} borderRadius={4} borderBottom={"1px solid black"}> 
                    
                        <Box  p={1} fontSize={breakpoint === "xs" ? 60 : 80} display="flex" justifyContent="center" alignItems="center">
                            <CustomIcon name={bet.sportId}/>
                        </Box>
                        <Box width="calc(100% - 100px)" display="flex" flexDirection="column" justifyContent="flex-start"  alignItems="flex-start" p={1} >
                            <Box width="100%" borderBottom={1} display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap" style={{borderColor: theme.palette.background.dark}} >
                                <Typography variant="h2" align="left" >{bet.match}</Typography>
                            </Box>
                            <Box pt={1}> 
                                <Typography variant="body1" >{bet.competition}</Typography>
                            </Box>
                        </Box>
                    </Box>
                    
                </Box> 
            </Fade>
            <Fade in={Object.keys(bet).length > 0} timeout={500} mountOnEnter unmountOnExit>
                <Box flexGrow={4}>
                    {
                        (subscribed || (breakpoint !=="xs" && breakpoint !=="sm")) &&
                            <Box display="flex" justifyContent="space-between" alignItems="flex-end" my={1}  height={TITLE_HEIGHT} >
                        
                                <CustomButton 
                                    label={dictionary.title2} 
                                    height={TITLE_HEIGHT}
                                    type="title"
                                />
                                <Box display="flex"> 
                                    {subscribed && auth.uid !== bet.user && auth.userInfo.type === 1 ? 
                                        favoriteBets && favoriteBets[bet.id] ? 
                                            <CustomButton type="titleButton" active={true} label={dictionary.unFollowBetButtonLabel} onClick={handleRemoveFavoriteBetModal}/>:
                                            <CustomButton type="titleButton" label={dictionary.followBetButtonLabel} onClick={handleBetAddToFavoriteModal}/>:
                                        null
                                    }
                                    {breakpoint !=="xs" && breakpoint !=="sm" && 
                                        <Box ml={1}>
                                            <CustomButton type="titleButton" label={timestampFormatter(bet.timestamp, dateDictionary, {withoutTime: true})} />
                                        </Box>
                                        
                                    }
                                </Box>
                            </Box>
                    }
                    
                    <Box mt={1} width="100%" display="flex" >
                        {
                            subscribed ? (
                                <Box flexGrow={4} display="flex" flexWrap="wrap" bgcolor="background.transparent" borderRadius={4} borderBottom={"1px solid black"}>
                                    <Box flexGrow={1}  px={2}  display="flex"  flexDirection="column" justifyContent="center"  color="white">
                                        <Box p={1} color="white">
                                            <Typography variant={"h2"} noWrap={false}> 
                                                {dictionary.betTypeLabel}
                                            </Typography>
                                        </Box>
                                    
                                        <Box p={1}>
                                            <Typography variant={"body1"} >{`${betTypeDictionary[bet.type]} (${betTypeDictionary[bet.typeOption]})`}</Typography>
                                        </Box>
                                        
                                    </Box>
                                    <Box flexGrow={1} display="flex" p={2}  flexDirection="column" justifyContent="center" color="text.secondary" >
                                        <Box pb={1} color="white">
                                            <Typography variant={"h2"} noWrap={false}> 
                                                {dictionary.betOddsLabel}
                                            </Typography>
                                        </Box>
                                        <Box width="100%"  borderRadius={8} p={1} >
                                            <Typography variant={"body1"} noWrap={false}> {bet.odds ? bet.odds: "Nan"} </Typography>
                                        </Box>
                                        
                                    </Box>
                                    {
                                        !bet.combination &&
                                            <Box flexGrow={1} display="flex" p={2} flexDirection="column" justifyContent="center" color="text.secondary" borderRadius={4}>
                                                <Box pb={1}>
                                                    <Typography variant={"h2"} noWrap={false}> 
                                                        {dictionary.betStakeLabel}
                                                    </Typography>
                                                </Box>
                                                
                                                <Box width="100%" borderRadius={2} p={1} >
                                                    <Typography variant={"body1"} noWrap={false}>{bet.stake}</Typography>
                                                </Box>
                                            </Box>
                                    } 
                                   
                                </Box>
                            ):(
                                <Box flexGrow={3} display="flex" p={2} flexDirection="column" bgcolor="background.transparent" borderRadius={4} justifyContent={"center"}  color="white" >
                                    <Box pb={2}>
                                        <Typography variant={"h2"}> 
                                            {dictionary.betMoreInfoLabel}
                                        </Typography>    
                                    </Box>
                                    <Box display="flex" justifyContent="center">
                                        <CustomButton width="fit-content" bgcolor="success.dark" type="button" onClick={handleSubscriptionButton} label={dictionary.betMoreInfoButton +" "+ bet.userName} />
                                    </Box>     
                                </Box>
                            ) 
                        }
                        {
                           (subscribed || (breakpoint !=="xs" && breakpoint !=="sm")) && 
                                <Box flexGrow={1} display="flex" ml={1} p={2} flexDirection="column" justifyContent={"center"}  bgcolor="primary.main" borderRadius={4} color="text.primary">
                                    <Box pb={1}>
                                        <Typography variant={"h2"} noWrap> {subscribed ? dictionary.betLabel: dictionary.betStakeLabel}</Typography>    
                                    </Box>
                                    <Box p={1} >
                                        <Typography style={{fontWeight: 600, fontSize: "1.2em"}} variant={"body1"} noWrap> {subscribed ? `${bet.bet} ${bet.betOption ? `(${bet.betOption})`: ""}`: bet.stake}</Typography>
                                    </Box>
                                    
                                </Box>
                        }
                       
                    </Box>
                    
                </Box> 
            </Fade>
            <Fade in={Object.keys(bet).length > 0 && subscribed} timeout={1000} mountOnEnter unmountOnExit>
                <Box flexGrow={4}>    
                    <Box display="flex" justifyContent="space-between" alignItems="flex-end"  my={1}  height={TITLE_HEIGHT}>
                        <CustomButton 
                            label={dictionary.title3} 
                            height={TITLE_HEIGHT}
                            type="title"
                        />
                        <CustomButton type="titleButton" label={bet.userName} onClick={handleTipsterButton}  color="white"/>
                    </Box>
                        
                    <Box bgcolor="background.transparent" mt={1} borderRadius={4} p={1} width="100%"  borderBottom="1px solid black">
                        {
                            bet.comment ? (
                                <Box p={2} display="flex"  width="100%" minHeight="150px" color="white" >
                                    <Typography variant="body2" align="left">{bet.comment}</Typography>
                                </Box>
                            ):(
                                <Box p={1} display="flex" justifyContent="center" alignItems="center"  width="100%"  height="150px" color="white" >
                                    <Typography variant="h2">{dictionary.noCommentLabel}</Typography>
                                </Box>
                            )
                        }
                    
                    </Box>
                </Box>  
            </Fade>

            <SubscribeTipsterModal tipsterId={bet.user} tipsterName={bet.userName}  />

            <CustomDialogLayout name="betAddToFavoriteModal" title={dictionary.betAddToFavoriteModalTitle} actions={[{label: dictionary.confirmButton, onClick: handleAddFavoriteBet}]}>
                <>
                    <Box mb={2}>
                        <Typography variant="body2" align="left">
                            {dictionary.betAddToFavoriteModalDescription}      
                        </Typography>
                    </Box>
                    
                    <CustomInfoLayout
                        type="info"
                        content={dictionary.betAddToFavoriteModalInfo}
                    />

                </>
            </CustomDialogLayout> 
            <CustomDialogLayout name="betRemoveFromFavoriteModal" title={dictionary.betRemoveFromFavoriteModalTitle} actions={[{label: dictionary.confirmButton, onClick: handleRemoveFavoriteBet}]}>
                <>
                    <Box mb={2}>
                        <Typography variant="body2" align="left">
                            {dictionary.betRemoveFromFavoriteModalDescription} 
                        </Typography>
                    </Box>
                    <CustomInfoLayout
                        type="info"
                        content={dictionary.betRemoveFromFavoriteModalInfo} 
                    />
                </>
            </CustomDialogLayout> 
        </Box>
    )
    
}


BetInfoElement.defaultProps = {
    
    variant: "text",
    color: "main",
    elementStyle: {}, // style object of element from database
    style: {}, // style object is pushed to element if there is active animation present
    title: "Zadnje stave",
    dataType: "leaderBoardWeekly",

    elementClasses: [],
    bet:{
        "bet" : 2,
        "betType" : "home-away",
        "betTypeOption" : "2ndset",
        "competition" : "Ismaning Challenger Men Doubles (hard)",
        "date" : 1603227020416,
        "match" : "Kolar Z./Olivetti A. - Romboli F./Yang T.",
        "odd1" : 7.57,
        "odd2" : 1.87,
        "sport" : "tennis",
        "stake" : 44,
        "user" : "mn0ebaKmpVgHw4Yb5zZLuv07PAs1",
        "userName" : "stavnicar1"
      }
  };
  

const mapStateToProps = ({auth, brdoparaData, userGeoLocation}) => {
  return {
    auth,
    brdoparaData,
    userGeoLocation
  }
}

export default withRouter(connect(mapStateToProps, actions)(editWrapper(BetInfoElement)))
