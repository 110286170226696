import React from 'react';
import * as actions from '../../../../redux/actions'
import { connect } from "react-redux";
import Box from '@material-ui/core/Box';
import editWrapper from '../../../default/wrappers/editWrapper'



function CircleBoxElement(props) {

    const { elementStyle, data, title, style } = props


    return (
      <Box 
        style={{...elementStyle, ...style}}
        height={60} 
        width={175}
        borderRadius={8}
        boxShadow={3}
        bgcolor="secondary.main"
        color="white"
        overflow="hidden"
        display="flex"
        flexDirection="row"
        ml={2}
      >
         <Box
          flexGrow={1}
          bgcolor="primary.main"
          display="flex" 
          justifyContent="center" 
          alignItems="center"
          fontSize={16}
          fontWeight={500}
        >
          {title}
        </Box>
        <Box 
          flexGrow={2} 
          display="flex" 
          justifyContent="center"
          alignItems="center"
          fontSize={24}
          fontWeight={300}
         
        >
          {data}
        </Box>
       
      </Box>
    )
            
   
}


CircleBoxElement.defaultProps = {

    variant: "text",
    color: "primary",
    height: 80,
    width: "",
    fontSize: 16,
    elementStyle: {}, // style object of element from database
    style: {}, // style object is pushed to element if there is active animation present
    data: "233",
    title: "Profit"
   
  };
  

const mapStateToProps = ({ auth }) => {
  return {
    auth
  }
}

export default connect(mapStateToProps, actions)(editWrapper(CircleBoxElement))
