import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router'

import * as actions from './../redux/actions'
import {connect} from "react-redux";

import sloDictionary from './../static/languages/sloDictionary'
import engDictionary from './../static/languages/engDictionary'
import srbDictionary from './../static/languages/srbDictionary'
import croDictionary from './../static/languages/croDictionary'


function useDictionary({elementId, userGeoLocation}){
  let [currDictionary, setCurrDictionary] = useState({})
  
  useEffect(()=>{
    let language = userGeoLocation && userGeoLocation.countryCode
  
    if(language === "EN")setCurrDictionary(engDictionary[elementId] || {})
    else if(language === "RS")setCurrDictionary(croDictionary[elementId] || {})
    else if(language === "HR")setCurrDictionary(croDictionary[elementId] || {})
    else setCurrDictionary(sloDictionary[elementId] || {})
   
  }, [userGeoLocation])
 

  return currDictionary
}

export default useDictionary
