import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import blue from '@material-ui/core/colors/blue'
import CssBaseline from '@material-ui/core/CssBaseline';

// A theme with custom primary and secondary color.
// It's optional.
const theme = createMuiTheme({

  palette: {

    background:{
      main: "#1c1c1c",
      secondary: "#333333",
      primary: "#1c1c1c",
      dark: "#000000",
      light: "#242424",
      transparent: "rgba(28, 28, 28, .99)",
      white:"rgba(255,255,255,0.97)"

    },
    primary: {
      text: "#D9B01C",
      light: "rgba(217, 176, 28, 0.5)",
      main: "#D9B01C",
      dark: "#967603",
    },
    secondary: {
      text: "#1c1c1c",
      
      main: "#1c1c1c",
      dark: "#1c1c1c",
    },
    text:{
      primary: "#1c1c1c",
      secondary: "#EEEEEE"
      
    }
  },
  typography: {
    fontSize: 18,
    useNextVariants: true,
    fontFamily: "Roboto",
    fontWeight: 100
  },

  overrides: {
    MuiButton: {
      contained: {
        fontSize: "1.1rem",
        fontWeight: 200,
        textTransform: "initial",
        borderRadius: 2,
        background: "black",
        minWidth: 80,
        paddingLeft: 24,
        paddingRight: 24,
        fontFamily: 'Racing Sans One',
        
      },
      outlined: {

        fontSize: "1.1rem",
        fontWeight: 200,
        textTransform: "initial",
        borderRadius: 0,
        minWidth: 80,
        fontFamily: 'Racing Sans One',
      },
      text: {

        fontSize: "1.1rem",
        fontWeight: 200,
        textTransform: "initial",
        borderRadius: 0,
        minWidth: 80,
        paddingLeft: 24,
        paddingRight: 24,
        fontFamily: 'Racing Sans One',
      },
      /*
      error:{
        fontSize: "1.1rem",
        fontWeight: 200,
        textTransform: "initial",
        borderRadius: 8,
        background: "red",
        
        paddingLeft: 24,
        paddingRight: 24,
        fontFamily: 'Racing Sans One',
      }
      */
      "root": {
        "&$error": {
          "fontSize": "1.1rem",
          "fontWeight": 200,
          "textTransform": "initial",
          "borderRadius": 8,
          "background": "red",
          "paddingLeft": 24,
          "paddingRight": 24,
          "fontFamily": "Racing Sans One"
        }
      }
    },
    MuiTypography: {
      h1: {
        padding: 16,
        fontWeight: 200,
        fontSize: "clamp(1.2em, 2vw, 2em)",
        fontFamily: 'Racing Sans One',
      },
      h2: {
        padding: 0,
        fontWeight: 300,
        fontSize: "clamp(1.2em, 2vw, 1.5em)",
        fontFamily: 'Racing Sans One',
      },
      h3:{
        fontSize: "clamp(0.9em, 2vw, 1.2em)",
        fontWeight: 500,
      },
      body1:{
        fontSize: "clamp(0.9em, 3vw, 1.1em)",
        fontWeight: 300,

      
      },
      body2:{
        fontSize: "clamp(0.8em, 3vw, 0.9em)",
        fontWeight: 300,
      },
      caption:{
        fontSize: "clamp(0.7em, 3vw, 0.7em)",
        fontWeight: 500,
       
      }
    },
    MuiFab:{
      sizeSmall:{
        fontSize: 20,
        color: "white",
        
      }
    },
    MuiInputBase: {
      input: {
        '&:-webkit-autofill': {
          transitionDelay: '9999s',
          transitionProperty: 'background-color, color',
        },
      },
    },
    MuiCssBaseline:{
      '@global':{
        '*': {
          'scrollbar-width': 'thin',
        },
        '*::-webkit-scrollbar':{
          width: '4px',
          height: '4px',
        },
        "*::-webkit-scrollbar-track":{
          background: "#c4c4c4",
          
        },
        "*::-webkit-scrollbar-thumb":{
          background: "rgba(217, 176, 28, 1)",
          borderRadius: 8,
          //boxShadow: "0px 0px 3px 0px #000000",
         
        }
      }
    }
  }
});

function withRoot(Component) {
  function WithRoot(props) {
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    return (
      <MuiThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Component {...props} />
      </MuiThemeProvider>
    );
  }

  return WithRoot;
}

export default withRoot;
