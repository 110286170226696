import {
 SET_DEVICE_NOTIFICATION_STATUS
} from '../../types'

import * as firebase from './../../../../config/firebase'

export const setDeviceNotificationStatus = () => dispatch =>{
  let status = null

  if(firebase.getPushNotificationToken){ 
    try{
      status = Notification.permission
    }catch(err){
      status = "unsupported"
    }
  }else{
    status = "unsupported"
  }

  dispatch({
    type: SET_DEVICE_NOTIFICATION_STATUS,
    payload: status
  })
}
