import {
    FETCH_TODAYS_MATCHES_SPORT_LIST,
    FETCH_TODAYS_MATCHES_COMPETITIONS_LIST,
    FETCH_TODAYS_MATCHES_COMPETITION,
    UPDATE_TODAYS_MATCHES_MATCH_ODDS
} from "./../../../actions/types";
  
  
export default (state = "loading", action) => {
    if(!action.payload)return state
    //initialize todays matches object on first load
    let todaysMatches = state !== "loading" ? {...state} : {sports:{}, sportsList:{}}

    switch (action.type) {
        case FETCH_TODAYS_MATCHES_SPORT_LIST:
            todaysMatches.sportsList = action.payload
            return todaysMatches;
        case FETCH_TODAYS_MATCHES_COMPETITIONS_LIST:
            if(!todaysMatches.sports[action.sportId]){
                todaysMatches.sports = {
                    //add previous sport objects 
                    ...todaysMatches.sports,

                    //initialize sportId obj for the first time
                    [action.sportId]:{
                        competitions:{},
                        competitionsList: action.payload
                    }
                }
            }else{
                todaysMatches.sports[action.sportId].competitionsList = action.payload
            }
            
            return todaysMatches;
        case FETCH_TODAYS_MATCHES_COMPETITION:
            todaysMatches.sports[action.sportId].competitions[action.competitionId] = action.payload
            return todaysMatches;
        case UPDATE_TODAYS_MATCHES_MATCH_ODDS:
            let matchOdds = todaysMatches.sports[action.sportId].competitions[action.competitionId].matches[action.matchId].odds
            
            if(!matchOdds){
                todaysMatches.sports[action.sportId].competitions[action.competitionId].matches[action.matchId].odds = action.payload
                return todaysMatches
            }
            
            if(!matchOdds[action.betTypeId] || (matchOdds[action.betTypeId] && matchOdds[action.betTypeId].updated === 0)){
                matchOdds[action.betTypeId] = action.payload[action.betTypeId]

            }else{
                matchOdds[action.betTypeId][action.betTypeOptionId] = action.payload[action.betTypeId][action.betTypeOptionId]
            }

            todaysMatches.sports[action.sportId].competitions[action.competitionId].matches[action.matchId].odds = matchOdds
            
            return todaysMatches;
        default:
            return state
    }
};