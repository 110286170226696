import React from 'react';

import { connect } from "react-redux";
import * as actions from '../../../../redux/actions'

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';

import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import editWrapper from '../../../default/wrappers/editWrapper'
import { Typography } from '@material-ui/core';

import CustomButton from './CustomButton'
import { setUserGeoLocation } from '../../../../redux/actions';

const useStyles = makeStyles( theme => createStyles({
  root: {
    display:"flex",
    flexDirection: "column",
    width: "100%"
  },
  trophyContainer:{
    minHeight: 350,
    display: "flex",
    flexDirection:"column",
    justifyContent: "space-between",
    background: theme.palette.background.primary,
    color:"white",

    marginTop: theme.spacing(1),
    padding: theme.spacing(2),

    borderRadius: theme.shape.borderRadius,
    borderBottom: "1px solid black"
  },

  trophyTitle:{
    padding: theme.spacing(1),
   
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),

    background: theme.palette.background.primary,

    width: "fit-content",
  
    display: "flex", 
    alignItems: "center", 
    justifyContent: "center", 
    borderRadius: theme.shape.borderRadius,

    color: "white",

    borderBottom: "1px solid black"
  },

  trophyLabel:{
    

    width: "fit-content", 
    display: "flex", 
    padding: "4px",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),

    background: theme.palette.primary.main,
    color:"black", 
    borderRadius: theme.shape.borderRadius,

    fontSize: ".6em"
  }
}))

function TrophiesInfo({trophiesInfo, trophies, userGeoLocation, ...props}){

  const classes = useStyles()
  const theme = useTheme()
  
  const [open, setOpen] = React.useState(false)
  const [activeElement,setActiveElement] = React.useState(0)
  const [transition,setTransition] = React.useState(null)
  const [slideDirection,setSlideDirection] = React.useState(null)
  const [intervalId, setIntervalId] = React.useState(null)

  const [width, setWidth] = React.useState(0)
  const [height, setHeight] = React.useState(0)

  const [showButton, setShowButton] = React.useState(false)

  const containerRef = React.useRef()

  const setWindowSize = () =>{

    setWidth(containerRef.current.offsetWidth)
    setHeight(window.innerHeight/3 )
  }

  React.useEffect(()=>{
   
    if(trophiesInfo === "loading"){
        props.fetchTrophiesInfo({language: userGeoLocation.countryCode})
      
    }
    
  }, [])

  if(trophiesInfo !== "loading" && trophiesInfo){
    return (
        <Box className={classes.root}>
           
            <Grid container justify="center" spacing={1} >
                {
                    trophiesInfo !== "loading" && trophies !== "loading" && Object.keys(trophies).sort((a, b) => trophies[a].pos - trophies[b].pos ).map( (trophyKey, i) =>
                        <Fade in={true} timeout={i*300}>
                             <Grid item xs={12} sm={12} md={10} lg={5} >
                                    <Box className={classes.trophyTitle}>
                                        <Box mr={1} display="flex" alignItems="center" height="40px">
                                            <img height="40px" width="auto" alt="icon" src={trophies[trophyKey].icon} />
                                        </Box>
                                        <Typography variant="h2" align="left">{trophies[trophyKey].label}</Typography>
                                    </Box>
                                
                                    <Box className={classes.trophyContainer}>
                                    
                                        <Box display="flex" flexDirection="column">
                                            <Box className={classes.trophyLabel}>
                                                <Typography variant="h2">OPIS LOVORIKE</Typography>
                                            </Box>
                                            <Box py={1} display="flex">
                                                <Typography variant="body2" align="left">{trophiesInfo[trophyKey].description}</Typography>
                                            </Box>
                                        </Box>
    
                                        <Box display="flex" flexDirection="column">
                                            <Box className={classes.trophyLabel}>
                                                <Typography variant="h2">KAKO DO LOVORIKE</Typography>
                                            </Box>
                                            <Box  py={1} display="flex">
                                                <Typography variant="body2" align="left">{trophiesInfo[trophyKey].how}</Typography>
                                            </Box>
                                        </Box>
    
                                        <Box display="flex" flexDirection="column">
                                            <Box className={classes.trophyLabel}>
                                                <Typography variant="h2" >POGOJI LOVORIKE</Typography>
                                            </Box>
                                            <Box  py={1} display="flex">
                                                <Typography variant="body2" align="left">{trophiesInfo[trophyKey].conditions}</Typography>
                                            </Box>
                                        </Box>
    
                                        <Box display="flex" flexDirection="column">
                                            <Box className={classes.trophyLabel}>
                                                <Typography variant="h2" align="left">TRAJNOST LOVORIKE</Typography>
                                            </Box>
                                            <Box  py={1} display="flex">
                                                <Typography variant="body2" align="left">{trophiesInfo[trophyKey].timespan}</Typography>
                                            </Box>
                                        </Box>
    
                                    </Box>
                                
                                </Grid>
                        </Fade>
                       
                    )
                }
            </Grid>
        </Box>
      )
  }else return null
 
  
}

const mapStateToProps = ({trophiesInfo, userGeoLocation, trophies}) => {
    return {
        trophiesInfo,
        trophies,
        userGeoLocation
    }
  }

export default editWrapper(connect(mapStateToProps, actions)(TrophiesInfo))
