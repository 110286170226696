import React from 'react';
import * as actions from '../../../../redux/actions'
import classNames from 'classnames'
import { connect } from "react-redux";
import {withRouter} from 'react-router'
import { withStyles, useTheme, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import TextField from '@material-ui/core/TextField';
import Zoom from '@material-ui/core/Zoom';
import Snackbar  from '@material-ui/core/Snackbar';
import editWrapper from '../../../default/wrappers/editWrapper'
import CustomButton from './CustomButton'
import { Typography } from '@material-ui/core';
import CustomIcon from './../../../components/icons/CustomIcon'
import useDictionary from '../../../../hooks/useDictionary';
import CustomInfoLayout from './CustomInfoLayout';



const styles = theme => ({
    root:{
        width: "100%",
        maxWidth: 500,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
    },
    container: {
        height: "100%",
        borderRadius: 4,
    },
    buttonSocialGoogle:{
        
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        padding: theme.spacing(1),

        width: "100%",
   
        height: 75,
        background: "rgb(222,82,70)",
        fontSize: "1.7em",
        fontWeight: 400,
        borderRadius: 4,

        '&:hover':{
            background: "#AB3B32",
            boxShadow: theme.shadows[5]
        },

        [theme.breakpoints.down("sm")]:{
            height: 65,
        }
    },
    buttonSocialFacebook:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        padding: theme.spacing(1),

        width: "100%",
        height: 75,
        fontSize: "1.7em",
       
      
        background: "#4267B2",
        borderRadius: 4,

        '&:hover':{
            background: "#375695",
            boxShadow: theme.shadows[5]
        },

        [theme.breakpoints.down("sm")]:{
            height: 65,
        }
  
    },
    buttonTypography:{
        fontSize: "clamp(0.7em, 2vw, 0.8em)",
        fontWeight: 400,
        color: "white",

        [theme.breakpoints.down("sm")]:{
            paddingLeft: theme.spacing(2)
        }
    }

});

const useTextFieldStyles = makeStyles( theme => ({
    root:{
       width:"100%",
    },
    inputDisabled:{
       opacity: 0.3, 
    },
    inputError:{
        color : `${theme.palette.error.main} !important`,
        borderColor: `${theme.palette.error.main} !important`,
    },
  
    cssLabel: {
        color : `${theme.palette.text.secondary} !important`,
        fontWeight: 200,
    },
    
    cssOutlinedInput: {
        color: `${theme.palette.text.secondary} !important`,
        borderRadius: 4,
        
        fontWeight: 200,

        '&$cssFocused $notchedOutline': {
            color: `${theme.palette.text.secondary} !important`,
            borderColor: `${theme.palette.text.secondary} !important`,
        },

    },

    cssOutlinedInputError: {
        color: `${theme.palette.error.main} !important`,
        borderRadius: 4,
        
        fontWeight: 200,

        '&$cssFocused $notchedOutline': {
            color: `${theme.palette.error.main} !important`,
            borderColor: `${theme.palette.error.main} !important`,
        },

    },
    
    cssFocused: {
        color: `${theme.palette.text.secondary} !important`,
    },

    notchedOutline: {
        borderWidth: '1px',
        borderColor: `${theme.palette.text.secondary} !important`,
        fontWeight: 200,
        color: `${theme.palette.text.secondary} !important`,
    },

    clearIndicator:{
        color: theme.palette.error.main
    },

    popupIndicator:{
        color: theme.palette.text.secondary,
        marginRight: 4,
    },
    

}))


const CustomInput = ({label, type = "text", error, onChange, disabled, value, placeholder, ref})=>{
    const classes = useTextFieldStyles()

  

    const handleChange = (e) => {
       
        let value = e.target.value
        onChange(value)
    }

    return (
        <TextField
           
            className={classNames(classes.root, {[classes.inputDisabled]: disabled})}
            disabled={disabled}
            required
            label={label}
            value={value}
            variant="outlined"
            error={error}
            onChange={handleChange}
            type={type}
            ref={ref}
            InputLabelProps={{
            classes:{
                root: classes.cssLabel,
                focused: classes.cssFocused,
            },
            }}
            InputProps={{
                className: classes.input,
                classes:{
                    root: classNames(classes.cssOutlinedInput, {[classes.inputError]: error}),
                    focused: classes.cssFocused,
                    notchedOutline: classNames(classes.notchedOutline , {[classes.inputError]: error}),
                },
           
            }}
            
        />
    )
}


function LoginBoxElement({
    classes,
    style,
    elementStyle,
    boxStyle,
    elementClasses,
    edit,
    auth,
    history,
    location,
    userGeoLocation,
    userVerified,
   
    ...props
}){

    const theme = useTheme()
    const dictionary = useDictionary({elementId: "login_box_element", userGeoLocation})
    const emailVerificationRef = React.useRef()

    const [loading, setLoading] = React.useState(false)

    const [email, setEmail] = React.useState("")

    const [password, setPassword] = React.useState("")
    const [password2, setPassword2] = React.useState("")

    const [inputError, setInputError] = React.useState(false)

    const [mode ,setMode] = React.useState("login")
    const [actionCode ,setActionCode] = React.useState(null)

    const [emailVerificationStatus, setEmailVerificationStatus] = React.useState("loading")

    const [verifiedEmailSnackbarStatus, setVerifiedEmailSnackbarStatus] = React.useState(true)

    const [formType, setFormType] = React.useState(props.formType || "register")

    React.useEffect(()=>{

        let urlParams = new URLSearchParams(location.search)
        if(urlParams.get("mode") === "verifyEmail"){
            setLoading(true)
            props.emailVerification({actionCode: urlParams.get("oobCode"), setEmailVerificationStatus, setLoading})
        }

    }, [])

    React.useEffect(()=>{
        setLoading(false)
        
        let urlParams = new URLSearchParams(location.search)
        setMode(urlParams.get("mode"))
        setActionCode(urlParams.get("oobCode"))
        //FTB: removing email verification
        if(!edit && auth && auth !== "loading"){ // formType === "login" && urlParams.get("mode") !== "verifyEmail"
            
            
            ///if(auth.emailVerified)}

            history.replace("/dashboard")
        }
        
    
    },[auth, history, location])


    const handleEmailChange = (value)=>{
        setEmail(value)
    }

    const handlePasswordChange = (value)=>{
        setPassword(value)
    }

    const handlePassword2Change = (value)=>{
        setPassword2(value)
    }

    const handleSocialLogin = (type) => () => {
        if(type === "google"){
            props.authenticateUserWithGoogle(type, history)
        }else if(type === "facebook"){
            props.authenticateUserWithFacebook(type, history)
        }
    }

    const handleUserLogin = ()=>{
      
        if(!email || !password){
            setInputError(true)
        }else{
            setLoading(true)
            
            props.authenticateUserWithEmail({email, password, setLoading, history})
        }
    }

    const handleUserRegistration = ()=>{
        
        if(!email || !password){
            setInputError(true)
        }else if(password !== password2){
            setPassword("")
            setPassword2("")

            props.setActiveSnackbar("registrationErrorPasswordsNotMatching", "error")
        }else{
            setLoading(true)

            props.createNewUser({email, password, setLoading, history})
        }
    }
    
 
    const handlePasswordReset = ()=>{
        props.passwordResetRequest({email, setLoading, history})
        
    }

    const handleRedirect = (pathname)=> ()=>{
        window.location.replace('https://brdopara.com/dashboard')
    }   

    const handlePasswordChangeCommit = ()=>{
        setLoading(true)

        props.passwordResetViaEmailLink({actionCode, password, setLoading, setEmail, history})
        
        setPassword2("")
        setPassword("")
    }

    const handleEmailVerificationRequest = ()=>{
        props.emailVerificationRequest({email: emailVerificationRef.current.value, auth})
    }

    const handleFormTypeChange = ()=>{
        setFormType( state => state === "register" ? "login" : "register")
    }

    
    if(mode === "forgotPassword"){
        return( 
            <Box
                className={classes.root}
                style={{...style, ...elementStyle}}
                {...boxStyle}
                p={1}
            >
                <Zoom in={true} timeout={400}>
                    <Box flexGrow="1" width="100%" height="100%" display="flex"  flexDirection="column" justifyContent="center" alignItems="center" >
                        <CustomButton 
                            label={dictionary.forgotPasswordTitle}
                            height={75}
                            fontSize="clamp(2em, 2vw, 3em)"
                            type="title"
                            width="100%"
                            
                        />
                        <Box p={3} mt={1} width="100%" display="flex" flexDirection="column" justifyContent="space-between" borderRadius={4} bgcolor="background.primary">
                            <Box mb={2} width="100%">
                                <CustomInput variant="outlined" onChange={handleEmailChange} value={email} label={dictionary.forgotPasswordEmailInputLabel} placeholder="" type="email" error={inputError && !Boolean(email)}/>
                            </Box>
                            <CustomInfoLayout 
                                type="info"
                                content={dictionary.forgotPasswordInfo}
                            />
                            <Box width="100%" pt={2} display="flex" justifyContent="space-between">
                                <CustomButton width="calc(50% - 4px)" onClick={handleRedirect("/login")} type="button" label={dictionary.backButton} height={75} />
                                <CustomButton width="calc(50% - 4px)" onClick={handlePasswordReset} loading={loading} disabled={!Boolean(email)} type="button" label={dictionary.confirmButton} height={75} />
                            </Box>
                        </Box>
                    </Box>  
                </Zoom>
            </Box>
        )
    }else if(mode === "resetPassword"){
        
        return( 
            <Box
                className={classes.root}
                style={{...style, ...elementStyle}}
                {...boxStyle}
                p={1}
            >
                <Zoom in={true} timeout={400}>
                    <Box flexGrow="1" width="100%" height="100%" display="flex"  flexDirection="column" justifyContent="center" alignItems="center">
                        <CustomButton 
                            label={dictionary.resetPasswordTitle}
                            height={75}
                            fontSize="clamp(2em, 2vw, 3em)"
                            type="title"
                            width="100%"
                        />
                        <Box p={3} mt={1} width="100%" display="flex" flexDirection="column" justifyContent="space-between" borderRadius={4} bgcolor="background.primary">
                            <Box width="100%" pb={2}>
                                <CustomInput variant="outlined"  onChange={handlePasswordChange} value={password} label={dictionary.resetPasswordPasswordInputLabel} type="password" error={inputError && Boolean(password)}/>
                            </Box>
                            <Box width="100%" pb={2}>
                                <CustomInput disabled={!password} onChange={handlePassword2Change} value={password2} variant="outlined"  label={dictionary.resetPasswordRetryPasswordInputLabel} type="password" error={inputError && Boolean(password)}/>
                            </Box>
                            <CustomInfoLayout 
                                type="info"
                                content={dictionary.resetPasswordInfo}
                            />
                            <Box width="100%" pt={2} display="flex" justifyContent="space-between">
                                <CustomButton width="calc(50% - 4px)" onClick={handleRedirect("/login")} type="button" label={dictionary.backButton} height={75} />
                                <CustomButton width="calc(50% - 4px)" onClick={handlePasswordChangeCommit} loading={loading} disabled={!password || password !== password2} type="button" label={dictionary.confirmButton} height={75} />
                            </Box>
                        </Box>
                    </Box>  
                </Zoom>
            </Box>
        )
    }else if(mode === "verifyEmail"){
   
        return( 
            <Box
                className={classes.root}
                style={{...style, ...elementStyle}}
                {...boxStyle}
                p={1}
            >
                <Zoom in={true} timeout={400}>
                    <Box flexGrow="1" width="100%" height="100%" display="flex"  flexDirection="column" justifyContent="center" alignItems="center" >
                        <CustomButton 
                            label={dictionary.verifyEmailTitle}
                            height={75}
                            fontSize="clamp(2em, 2vw, 3em)"
                            type="title"
                            width="100%"
                            
                        />
                        {
                            emailVerificationStatus !== "loading" &&
                                <Box p={3} mt={1} width="100%" display="flex" flexDirection="column" justifyContent="space-between" borderRadius={4} bgcolor="background.primary">
                                    <CustomInfoLayout 
                                        type={emailVerificationStatus}
                                        content={emailVerificationStatus === "success" ? dictionary.emailVerificationStatusSuccess : dictionary.emailVerificationStatusError}
                                    />
                                   
                                    <Box width="100%" pt={2} display="flex" justifyContent="space-between">
                                        <CustomButton width="100%" onClick={handleRedirect("/dashboard")} type="button" label={dictionary.backButton} height={75} />
                                    </Box>
                                </Box>
                        }
                        
                    </Box>  
                </Zoom>
            </Box>
        )
    }else{
        return (
            <Box
                className={classes.root}
                style={{...style, ...elementStyle}}
                {...boxStyle}
                p={1}
            >
                <Zoom in={true} timeout={400}>
                    <Box flexGrow="1" width="100%" height="100%" display="flex"  flexDirection="column" justifyContent="center" alignItems="center" >
                        <Box display="flex" width="100%" justifyContent="space-between" alignItems="flex-end"> 
                            <CustomButton 
                                label={formType === "register" ? dictionary.registerTitle : dictionary.loginTitle}
                                height={75}
                                fontSize="clamp(1.5em, 2vw, 3em)"
                                type="title"   
                            />
                            
                            <CustomButton 
                                label={formType === "register" ? dictionary.loginTitle : dictionary.registerTitle}
                                height={50}
                                onClick={handleFormTypeChange}
                                fontSize="clamp(2em, 2vw, 3em)"
                                type="titleButton"   
                            />          
                        </Box>
                       
                        <Box p={3} mt={1} width="100%" display="flex" flexDirection="column" justifyContent="space-between" borderRadius={4} bgcolor="background.primary">
                        
                            
                                {/*
                                    formType !== "register" && auth && auth !== "loading" && !auth.emailVerified ? 
                            <Box>
                                
                                <Box width="100%" pb={2}>
                                    <CustomInput variant="outlined" ref={emailVerificationRef} value={auth.email || ""} label={dictionary.loginInputEmailLabel} placeholder="" type="email" />
                                </Box>
                                
                                <CustomInfoLayout
                                    type="warning"
                                    content={dictionary.unverifiedAccountInfo}
                                /> 
                                <Box width="100%" pt={2}>
                                    <CustomButton 
                                        onClick={handleEmailVerificationRequest} 
                                        type="button" 
                                        label={dictionary.resendVerificationEmailButton} 
                                        bgcolor="background.dark"
                                        height={75} />
                                </Box>
                            </Box>:
                                */}

                            <>
                                <Box width="100%" pb={2}>
                                    <CustomInput variant="outlined" onChange={handleEmailChange} value={email} label={dictionary.loginInputEmailLabel} placeholder="" type="email" error={inputError && !Boolean(email)}/>
                                </Box>
                                <Box width="100%" pb={2}>
                                    <CustomInput variant="outlined"  onChange={handlePasswordChange} value={password} label={dictionary.loginInputPasswordLabel} placeholder="" type="password" error={inputError && Boolean(password)}/>
                                </Box>
                                {
                                    formType === "register" &&
                                        <Box width="100%" pb={2}>
                                            <CustomInput variant="outlined"  onChange={handlePassword2Change} value={password2} label={dictionary.loginInputRetryPasswordLabel} placeholder="" type="password" error={inputError && Boolean(password)}/>
                                        </Box>
                                }
                                <Box width="100%" pb={2}>
                                    <CustomButton 
                                        onClick={formType === "register" ? handleUserRegistration:handleUserLogin} 
                                        loading={loading} 
                                        disabled={formType === "register" ? !Boolean(email) || !Boolean(password)  || !Boolean(password2) : !Boolean(email) || !Boolean(password) } 
                                        type="button" 
                                        label={formType === "register" ? dictionary.registerButtonLabel : dictionary.loginButtonLabel} 
                                        bgcolor="background.dark"
                                        height={75} />
                                </Box>
                                <Box display="flex" justifyContent="center" position="relative" color="white" >
                                    <Box position="absolute" top="0" bottom="0" margin="auto" width="100%"  height="1px" bgcolor="primary.main" />
                                    <Box position="relative" bgcolor="background.primary" px={1}>
                                        <Typography>
                                            {dictionary.loginSeparator}
                                        </Typography>
                                    </Box>
                                    
                                </Box>

                                <Box pt={2} display="flex" flexDirection="row" justifyContent="space-between">
                                    <Box  width="calc(50% - 8px)">
                                        <ButtonBase onClick={handleSocialLogin("google")} style={{width: "100%"}}>
                                            <Box className={classes.buttonSocialGoogle}>
                                                <Box position="absolute" left={16} top="0" bottom="0" display="flex" alignItems="center">
                                                    <CustomIcon name="google"/>
                                                </Box>
                                                <Typography className={classes.buttonTypography} variant="body1" noWrap>Google</Typography>
                                            </Box>
                                            
                                        </ButtonBase>
                                    </Box>
                                    <Box width="calc(50% - 8px)">
                                        <ButtonBase onClick={handleSocialLogin("facebook")} style={{width: "100%"}}>
                                            <Box className={classes.buttonSocialFacebook}>
                                                <Box position="absolute" left={16} top="0" bottom="0"  display="flex" alignItems="center">
                                                    <CustomIcon name="fb"/>
                                                </Box>
                                                <Typography className={classes.buttonTypography} variant="body1" noWrap>Facebook</Typography>
                                            </Box>
                                            
                                        </ButtonBase>
                                    </Box>
                                </Box>
                            </>
                           
                        
                        </Box>
                    </Box>  
                    
                </Zoom>

            </Box>
        )
    }
}

LoginBoxElement.defaultProps = {

  elementClasses: [],
  elementStyle: {}, // style object of element from database
  style: {}, // style object is pushed to element if there is active animation present
  
};

const mapStateToProps = ({auth,userGeoLocation, userVerified}) => {
    return {
        auth,
        userGeoLocation,
        userVerified, 
    }
  }

export default withRouter(connect(mapStateToProps, actions)(editWrapper(withStyles(styles)(LoginBoxElement))))


/*
<Box>
    
    */