import {
  SET_ACTIVE_DOCUMENT_PAGE,
  SET_SELECTED_PAGE,
} from './../../types'

export const setActiveDocumentPage = (activeDocumentPage) => dispatch =>{
  dispatch({
    type: SET_ACTIVE_DOCUMENT_PAGE,
    payload: activeDocumentPage
  })
}

export const setSelectedPage  = (pageId) => async (dispatch, getState) =>{
  dispatch({
    type: SET_SELECTED_PAGE,
    payload: pageId
  })
  return true
}
