export default {
	"header": {
		"title": "Kladi se uz znanje najboljih.",
		"description": "Predlaži oklade ili prati tipstere",
		"image": "https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/fbShareImage%2FhomeFb-min.jpg?alt=media&token=b576f9b1-f6c1-4968-9a15-70b94f7985bd",
		"profileTitle": "Kladi se uz znanje najboljih.",
		"profileDescription": "Predlaži oklade ili prati druge",
		"profileImage": "https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/fbShareImage%2FstatistikaFB-min.jpg?alt=media&token=51bb7c82-1dca-48b8-8fc7-764ff7ffe6a8",
		"betInfoTitle": "Kladi se uz znanje najboljih.",
		"betInfoDescription": "Predlaži oklade ili prati tipstere",
		"betInfoImage": "https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/fbShareImage%2FstavaFB-min.jpg?alt=media&token=1c89fe46-a835-481b-8b0c-0ef645aa6fe2"
	},
	"date": {
		"daysShort": [
			"Ned",
			"Pon",
			"Uto",
			"Sri",
			"Čet",
			"Pet",
			"Sub"
		],
		"monthsShort": [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"Maj",
			"Jun",
			"Jul",
			"Avg",
			"Sep",
			"Okt",
			"Nov",
			"Dec"
		]
	},
	"sports": {
		"soccer": "Fudbal",
		"tennis": "Tenis",
		"basketball": "Košarka",
		"handball": "Rukomet",
		"volleyball": "Odbojka",
		"esports": "e-Sportovi",
		"combination": "Tiketi",
		"hockey": "Hokej",
		"americanFootball": "Američki fudbal"
		
	},
	"bet_options": {
		"1": "Pobjeda 1.",
		"2": "Pobjeda 2.",
		"X": "Neriješeno",
		"1X2": "Neriješeno",
		"over": "Više",
		"under": "Manje",
		"1x2": "1X2",
		"home-away": "Domači/gosti",
		"ah": "Asian handicap",
		"bothteamstoscore": "Oba tima daju gol",
		"cs": "Tačan rezultat",
		"dnb": "Ishod bez neriješenog",
		"dc": "Dupla šansa",
		"eh": "European  handicap",
		"o-e": "Odd or Even",
		"o-u": "Više/Manje",
		"ftincludingot": "Konačni pobjednik s produžecima",
		"fulltime": "Konačan ishod",
		"1sthalf": "1. poluvrijeme",
		"2ndhalf": "2. poluvrijeme",
		"1stset": "1. set",
		"2ndset": "2. set",
		"3rdset": " 3. set",
		"1q": "1. četvrtina",
		"2q": "2. četvrtina",
		"3q": "3. četvrtina",
		"4q": "4. četvrtina",
		"1stperiod": "1. trećina",
		"2ndperiod": "2. trećina",
		"3rdperiod": "3. trećina",
		"over-under": "Više/manje"
	},
	"displayed_data_constructor": {
		"1": "Pobjeda 1.",
		"2": "Pobjeda 2.",
		"user": "Profil",
		"tipster": "Tipster",
		"graphData": "Graf",
		"betsNum": "Broj oklada",
		"avrgWins": "Pobjede",
		"avrgWin": "Pobjede",
		"avrgOdds": "Prosjek koef.",
		"avrgStake": "Prosjek koef.",
		"roi": "ROI",
		"profit": "Profit",
		"sport": "Sport",
		"match": "Utakmica",
		"odds": "Koeficient",
		"stake": "Ulog",
		"time": "Početak",
		"betTime": "Čas",
		"betType": "Opcije",
		"bet": "Tip",
		"competition": "Takmičanje",
		"date": "Datum",
		"betStatus": "Status",
		"returns": "Povrat",
		"stakeSum": "Ulog",
		"winNum": "Pobjede",
		"lostNum": "Porazi",
		"betWinner": "Pobjednik",
		"comment": "Analiza",
		"status": "Status",
		"allBets": "Sve oklade",
		"withComment": "Sa prognozom",
		"combination": "Tiket",
		"userName": "Tipster",
		"leaderBoardWeeklyTitle": "Top 10 nedelje",
		"leaderBoardMonthlyTitle": "Top 10 mjeseca",
		"leaderBoardOverallTitle": "Top 10",
		"betInfo_allActiveBetsInfoBySportTitle": "Slične oklade",
		"betInfo_allHistoryBetsTitle": "Istorija oklada",
		"bets_allActiveBetsInfoTitle": "Aktivne oklade",
		"home_userSubscribedBetsButtonLabel": "Samo naručene oklade",
		"home_userFavoriteBetsButtonLabel": "Samo omiljene oklade",
		"home_userSubscribedBetsTitle": "Naručene oklade",
		"home_userBetsTitle": "Moje oklade",
		"home_allActiveBetsInfoButtonLabel": "Sve oklade",
		"home_userFavoriteBetsTitle": "Omiljene oklade",
		"home_userBetsButtonLabel": "Moje oklade",
		"myProfile_myActiveBetsTitle": "Moje oklade",
		"myProfile_subscribedActiveBetsButtonLabel": "Naručene",
		"myProfile_userHistoryBetsButtonLabel": "Istorija",
		"myProfile_subscribedActiveBetsTitle": "Naručene oklade",
		"myProfile_favoriteActiveBetsButtonLabel": "Omiljene",
		"myProfile_myActiveBetsButtonLabel": "Moje oklade",
		"myProfile_favoriteActiveBetsTitle": "Omiljene oklade",
		"myProfile_userHistoryBetsTitle": "Istorija oklada",
		"myProfile_myInactiveBetsTitle": "Nepotvrđene oklade",
		"myProfile_myInactiveBetsButtonLabel": "Nepotvrđene",
		"userProfile_userActiveBetsInfoTitle": "Aktivne oklade",
		"userProfile_userHistoryBetsTitle": "Istorija oklada",
		"userProfile_userActiveBetsInfoButtonLabel": "Aktivne",
		"userProfile_userHistoryBetsButtonLabel": "Istorija",
		"tipsters_leaderBoardMonthlyTitle": "TIPSTERI",
		"tipsters_leaderBoarWeeklyButtonLabel": "Sedmična statistika",
		"tipsters_leaderBoardOverallButtonLabel": "Svi tipsteri",
		"tipsters_leaderBoarWeeklyTitle": "TIPSTERI",
		"tipsters_leaderBoardMonthlyButtonLabel": "Mjesečna statistika",
		"tipsters_leaderBoardOverallTitle": "TIPSTERI",
		"daysShort": [
			"Ned",
			"Pon",
			"Uto",
			"Sri",
			"Čet",
			"Pet",
			"Sub"
		],
		"monthsShort": [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"Maj",
			"Jun",
			"Jul",
			"Avg",
			"Sep",
			"Okt",
			"Nov",
			"Dec"
		],
		"X": "Izjednačenje",
		"1X2": "Izjednačenje",
		"over": "Više",
		"under": "Manje",
		"1x2": "1X2",
		"home-away": "Domači/Gosti",
		"ah": "Asian handicap",
		"cs": "Tačan rezultat",
		"dnb": "Draw No Bet",
		"dc": "Dupla šansa",
		"eh": "European  handicap",
		"o-e": "Odd or Even",
		"o-u": "Više/Manje",
		"bothteamstoscore": "Both Teams To Score",
		"ftincludingot": "Cjela utakmica + produžeci",
		"fulltime": "Utakmica",
		"1sthalf": "1. poluvrijeme",
		"2ndhalf": "2. poluvrijeme",
		"1stset": "1. set",
		"2ndset": "2. set",
		"3rdset": " 3. set",
		"1q": "1. četvrtina",
		"2q": "2. četvrtina",
		"3q": "3. četvrtina",
		"4q": "4. četvrtina",
		"1stperiod": "1. trećina",
		"2ndperiod": "2. trećina",
		"3rdperiod": "3. trećina",
	},
	"home_element": {
		"homeTitle2": "KLADI SE SA ZNANJEM.",
		"homeTitle3": "NAJJAČIH.",
		"homeButton": "Registriruj se",
		"myProfileButton": "Moj profil",
		"howPhoneSubTitle1": "Napravi",
		"howPhoneTitle1": "svoj account",
		"howPhoneSubTitle2": "Objavi ili prati",
		"howPhoneTitle2": "oklade",
		"howPhoneSubTitle3": "Prati",
		"howPhoneTitle3": "statistiku",
		"tipstersTitle": "TOP5 TIPSTERJEV MJESECA"
	},
	"login_box_element": {
		"backButton": "Nazad",
		"confirmButton": "Potvrdi",
		"forgotPasswordTitle": "Zaboravljena lozinka",
		"forgotPasswordEmailInputLabel": "E-mail",
		"forgotPasswordInfo": "Nakon potvrde, zahtjeva za poništavanje lozinke, bit će poslana na adresu e-pošte koju ste unijeli. Slijedite upute poslane na vašu e-adresu, da biste promijenili lozinku.",
		"resetPasswordTitle": "PROMIJENA LOZINKE",
		"resetPasswordPasswordInputLabel": "Lozinka",
		"resetPasswordRetryPasswordInputLabel": "Ponovite lozinku",
		"resetPasswordInfo": "Nakon potvrde, zahtjeva za poništavanje lozinke, bit će poslana na adresu e-pošte koju ste unijeli. Slijedite upute poslane na vašu e-adresu, da biste promijenili lozinku.",
		"verifyEmailTitle": "POTVRDITE E-MAIL",
		"emailVerificationStatusSuccess": "E-mail je uspešno potvrđen!",
		"emailVerificationStatusError": "Dogodila se greška, pokušajte ponovo!",
		"unverifiedAccountInfo": "Vaš e-mail nije potvrđen. Molimo vas pratite upute, koji su poslani na vaš e-mail. U slučaju, da niste dobili e-mail s potvrdom, kliknite donji gumb.",
		"resendVerificationEmailButton": "Ponovo pošaljite potvrditveni e-mail",
		"registerTitle": "Registracija",
		"loginTitle": "Ulogiraj se",
		"loginInputEmailLabel": "E-mail",
		"loginInputPasswordLabel": "Lozinka",
		"loginInputRetryPasswordLabel": "Potvrđa lozinke",
		"loginSeparator": "ili",
		"loginButtonLabel": "Ulogiraj se",
		"registerButtonLabel": "Registriraj se"
	},
	"navbar_element": {
		"loginButton": "Ulogiraj se",
		"myProfileButton": "Moj profil",
		"homeButton": "Prva stran"
	},
	"add_bet_element": {
		"modalWindowLabel": "Oklada nije potvrđena. Želite li ipak otići?",
		"title": "Izaberi okladu",
		"sportComboBoxLabel": "Izaberi sport",
		"competitionComboBoxLabel": "Izaberi turnir/takmičanje",
		"matchComboBoxLabel": "Izaberi utakmicu",
		"matchComboBoxOptionStatus": "U toku ili završeno",
		"betTypeComboBoxLabel": "Izaberi vrstu oklade",
		"betTypeOptionComboBoxLabel": "Opcija",
		"title2": "Oklada",
		"commentTextInputLabel": "Prognoza",
		"commentTextInputPlaceholder": "Prognoza...",
		"betOdds": "Koeficient",
		"betStake": "Ulog",
		"stakeInputPlaceholder": "Unesite ulog",
		"betReturns": "Povrat",
		"backButton": "Nazad",
		"confirmBetButton": "Objavi okladu"
	},
	"box_with_drawer_element": {
		"userSettingsButton": "Postavke",
		"userLogout": "Odjava",
		"newBetButton": "Nova oklada",
		"activeBetsButton": "Sve oklade",
		"userProfileButton": "Moj profil",
		"subscribedBetsButton": "Naručene oklade",
		"favoriteBetsButton": "Omiljene oklade",
		"userBetsButton": "Moje oklade",
		"userBetsHistoryButton": "Istorija mojih oklada",
		"tipstersButton": "Tipsteri",
		"trophiesButton": "Lovorike",
		"bettingSitesButton": "Kladionice",
		"userSupportButton": "Pomoč",
		"userInitializationModalTitle": "NAPRAVI SVOJ PROFIL",
		"userInitializationModalButton": "Potvrdite",
		"userInitializationModalDisplayNameInputLabel": "Prikazano ime",
		"userInitializationModalUserIconInputLabel": "Izaberi avatar",
		"userInitializationModalUserTypeButton": "Pratilac",
		"userInitializationModalTipsterTypeButton": "Tipster",
		"userInitializationModalEmailInputLabel": "E-naslov",
		"userInitializationModalLanguageInputLabel": "Jezik stranice",
		"userInitializationModalUserInfo1": "Pratilac - korisnički račun namijenjen je svima onima koji bi željeli pratiti željene oklade tipstera i odabrati svoje omiljene oklade od naručenih tipstera.Izabrane omiljene oklade bilježit če se u vaše osobne statistike.",
		"userInitializationModalUserInfo2": " Tipster račun namijenjen je svima onima koji žele svoje oklade objaviti pratilcima i podijeliti s njima svoje znanje. Sve objavljene oklade imaju statistiku koju mogu vidjeti svi. Tipsteri se rangiraju prema uspjehu statistike.",
		"userInitializationModalTipsterInfo": "Zbog sigurnosti i uvjeta korištenja, tipsterski račun mora odobriti administrator.",
		"userSettingsModalTitle": "MOJE POSTAVKE",
		"userSettingsModalButton": "Potvrdi",
		"userSettingsModalDescription": "Praćenje ove oklade, vaša oklada će biti sačuvana u odjeljku omiljene oklade.",
		"userSettingsModalInfo": "Oklade koje pratite, zabilježiti če se u statistiku klađenja. Omiljene oklade možete ukloniti, ali samo ako oklada još nije počela",
		"verifiedEmailSnackbarDescription": "Vaša e-mail adresa nije potvrđena. Funkcionalnosti na stranici je ograničena.",
		"verifiedEmailSnackbarLinkLabel": "Šaljite e-mail za aktivaciju.",
		"userTermsAckLabel": "Imam više od 18 godina i pristajem na uslove korištenja ove web stranice.",
		"userTermsAckLinkLabel": "Više o uslovima upotrebe."
	},
	"bet_info_element": {
		"confirmButton": "Potvrdi",
		"title": "Utakmica",
		"title2": "TIP",
		"title3": "Prognoza",
		"titleCombination": "Tiket",
		"matchFinished": "Završena",
		"matchStarts": "Početak",
		"betCombinationNumLabel": "oklada",
		"unFollowBetButtonLabel": "Pratim",
		"followBetButtonLabel": "Prati okladu",
		"combinationTotalOddsLabel": "Ukupni koeficient",
		"betTypeLabel": "Opcija",
		"betOddsLabel": "Koeficient",
		"betLabel": "TIP",
		"betMoreInfoLabel": "Za više informacija o tipu",
		"betMoreInfoButton": "Naručite se na",
		"betStakeLabel": "Ulog",
		"noCommentLabel": "Bez prognoze...",
		"betAddToFavoriteModalTitle": "PRATI OKLADU",
		"betAddToFavoriteModalDescription": "S pračenjem ovog tipa, vaša oklada spremljena če biti u odjeljku omiljene oklade.",
		"betAddToFavoriteModalInfo": "Okladam, koje pratite se bilježe vaša statistika. Okladu možete slobodno ukloniti, ali samo ako utakmica još nije počela.",
		"betRemoveFromFavoriteModalTitle": "UKLONITE OKALDU",
		"betRemoveFromFavoriteModalDescription": "Oklada če se ukloniti iz odjelka omiljene oklade.",
		"betRemoveFromFavoriteModalInfo": "Oklade koje pratite, prate se u vašoj statistiki klađenja. Okladu možete ukloniti ili dodati, ali samo ako meč još nije počeo"
	},
	"bets_list_box_element": {
		"allSportsTabLabel": "Sve"
	},
	"history_bet_info_element": {
		"title": "Utakmica",
		"title2": "TIP",
		"title3": "Prognoza",
		"betLost": "Promašaj",
		"betWon": "Pogodak",
		"betDeleted": "Obrisano",
		"betTypeLabel": "Opcija",
		"betOddsLabel": "Koeficient",
		"betLabel": "TIP",
		"betReturnsLabel": "Ulog",
		"betProfitLabel": "Profit",
		"noCommentLabel": "Nema prognoze",
		"titleCombination": "Tiket",
		"combinationTotalOddsLabel": "Ukupni koeficient",
		"betStakeLabel": "Ulog",
		"betCombinationNumLabel": "oklada"
	},
	"profile_box_element": {
		"confirmButton": "Potvrdi",
		"title": "Profil",
		"title2": "Statistika",
		"title3": "Graf profita",
		"profitLabel": "Profit",
		"noStatisticLabel": "Statistika nije zabjelježena",
		"subscribedButtonLabel": "Naručen",
		"subscribeButtonLabel": "Naruči se",
		"subscribedUsers": "PRATILCI",
		"weeklyStatisticButtonLabel": "Nedelnjo",
		"monthlyStatisticButtonLabel": "Mjesečno",
		"overallStatisticButtonLabel": "Godišnje",
		"tipsterSubscribeModalInfo": "Na svom profilu možete vidjeti sve aktivne oklade tipstera na koje ste naručeni. Izaberi oklade i tako češ imati zabijelježenu svoju osobnu statistiku izabranih oklada."
	},
	"subscribe_tipster_modal": {
		"tipsterSubscribeModalInfo": "Na svom profilu možete vidjeti sve aktivne oklade tipstera na koje ste naručeni. Izaberi oklade i tako češ imati zabijelježenu svoju osobnu statistiku izabranih oklada.",
		"editSubscriptionModalNotifyViaEmailLabel": "Primajte obavijesti o novim okladama putem e-pošte",
		"editSubscriptionModalPushNotificationsLabel": "Primajte obavijesti o novim okladama putem push obavijesti",
		"allowNotificationButtonLabel": "Dozvoli obavještenje",
		"confirmSubscriptionButtonLabel": "Naruči se"
	},
	"register_user_box_element": {
		"title": "Pridruži nam se",
		"emailInputLabel": "E-mail",
		"passwordInputLabel": "Lozinka",
		"registerButtonLabel": "Registracija",
		"info": "Registracijom se također slažem sa uvjetima korištenja!",
		"infoLink": "Više o uvjetima ovdje!"
	},
	"user_settings_element": {
		"confirmButton": "Potvrdi",
		"user": "Pratilac",
		"tipster": "Tipster",
		"title": "Postavke profila",
		"saveButtonLabel": "Sačuvaj",
		"displayNameInputLabel": "Korisničko ime",
		"emailInputLabel": "E-naslov",
		"descriptionInputLabel": "Kratak opis",
		"profileImageTitle": "Slika profila",
		"profilePictureButtonLabel": "Otpremite novu sliku profila",
		"accountTypeTitle": "Tip računa",
		"accountTypeButtonLabel": "Promijeni tip accounta",
		"userSubscriptionsTitle": "Moje pretplate",
		"subscribedFrom": "Naručen od",
		"notifications": "Obavijesti",
		"noSubscriptions": "Nema pretplata",
		"editSubscriptionModalNotifyViaEmailLabel": "Obavještenje putem e-maila",
		"editSubscriptionModalNotifyViaEmailInfo": " Za svaku novu okladu odabranog tipstera automatski ćemo vam poslati obavijest putem e-maila.",
		"editSubscriptionModalPushNotificationsLabel": "Obavještenje putem push obavijesti (notification)",
		"editSubscriptionModalPushNotificationsInfo": "Za svaku novu okladu odabranog tipstera automatski ćemo vam poslati notification.",
		"editSubscriptionModalPushNotificationsWarning": "Push obavijesti (notificationi) rade samo na preglednicima Chrome i Mozilla ili na novijim verzijama Edgea. Apple ne podržava ovu vrstu obavještenja i ne radi na I PHONE-ima!",
		"removeSubscriptionModalTitle": "Odjava pretplate",
		"removeSubscriptionModalRemoveSubscriptionInfo": "Želite li ukloniti pretplatu na odabranog tipstera?",
		"changeUserTypeModalTitle": "TIP RAČUNA",
		"changeUserTypeModalUserInfo": " Pratilički račun namijenjen je svima koji bi željeli pratiti tipsterske oklade i odabrati svoje omiljene oklade od tipstera koje pratite. Odabrane oklade bilježe se u vašoj osobni statistiki.",
		"changeUserTypeModalTipsterInfo": "Tipster račun namijenjen je svima onima koji bi htjeli svoje oklade objaviti svim ostalim korisnicima portalai sa njima podijeliti svoje sportsko znanje. Sve oklade bilježit če se u statistiku koju mogu vidjeti svi korisnici portala.Tipsteri se rangiraju prema uspjehu statistike.",
		"changeUserTypeModalTipsterWarning": "Ako promijenite tip korisničkog računa, izbrisat će se sva vaša povijest klađenja i statistika.",
		"promptMessage": "Odbaci sve promijene?"
	},
	"list_box_element": {
		"noAvailableData": "Nema podataka."
	},
	"info_element": {
		"aboutTitle": "O firmi",
		"companyNameSubTitle": "Ime firme",
		"headquartersSubTitle": "Sjedište firme",
		"registrationNumberSubTitle": "Matični broj",
		"cookiesTitle": "Cookies",
		"privacyPolicyTitle": "Privacy policy",
		"userTermsTitle": "OPĆI UVJETI KORIŠĆENJA"
	},
	"remove_active_bet_modal": {
		"title": "Izbrišite izabranu okladu",
		"buttonLabel": "Potvrdi",
		"infoContent": "Izabrana oklada bit če izbrisana iz aktivnih oklada! "
	},
	"user_support_element": {
		"title1": "Kontakt",
		"title2": "Prijava greške",
		"backButtonLabel": "Nazad",
		"selectFieldLabel": "Vrsta problema",
		"selectFieldOption1": "Oklada je krivo zaključena.",
		"selectFieldOption2": "Ne primam obavijesti o novim okladama.",
		"selectFieldOption3": "Obavijesti o okladama nisu podržane.",
		"selectFieldOption4": "Ne mogu se naručiti na izabranog tipstera.",
		"selectFieldOption5": "Ne mogu pratiti izabranu okladu.",
		"selectFieldOption6": "Nešto drugo.",
		"textFieldLabel": "Opis problema",
		"infoContent": "Detaljno opišite svoj problem, kako bismo lakše otkrili grešku i ujedno je što brže popravili. Svaki problem / grešku riješit ćemo što prije je moguče.",
		"sendButtonLabel": "Šalji"
	},
	"betting_sites_info_element": {},
	"brdopara_snackbar_element": {
		"loginSuccess": {
			"label": "Uspješna prijava!"
		},
		"loginError": {
			"label": "Netačna prijava! Pogrešan email ili lozinka!",
			"actions": [
				"Zaboravljena lozinka?"
			]
		},
		"registrationSuccess": {
			"label": "Registracija je uspješna!"
		},
		"registrationError": {
			"label": "Prilikom registracije došlo je do greške. Pokušaj ponovo!"
		},
		"registrationErrorMailAlreadyExist": {
			"label": "Korisnik s e-mail adresom kojeg ste unijeli već postoji!",
			"actions": [
				"Prijavite se ovdje!"
			]
		},
		"registrationFacebookErrorMailAlreadyExist": {
			"label": "Ovaj e-naslov je spojen sa Googlovim računom! Molimo vas, izaberite Google gumb za prijavu ili se prijavite putem e-adrese i lozinke."
		},
		"registrationErrorWrongEmailOrPassword": {
			"label": "Pogrešan e-mail ili lozinka."
		},
		"registrationErrorPasswordsNotMatching": {
			"label": "Lozinke koje ste unijeli se ne slažu! Pokušaj ponovo."
		},
		"passwordResetRequestSuccess": {
			"label": "Zahtjev za promjenu lozinke je uspješno poslan. Slijedite upute poslane na adresu e-pošte koju ste unijeli!"
		},
		"passwordResetSuccess": {
			"label": "Lozinka je uspješno promijenjena! Molimo prijavite se ponovo sa lozinkom koju ste upravo promijenili."
		},
		"passwordResetError": {
			"label": "Došlo je do greške, pokušajte ponovo!"
		},
		"passwordResetErrorEmailNotFound": {
			"label": "E-mail kojeg ste unijeli ne postoji, pokušajte ponovo!"
		},
		"passwordResetActionCodeError": {
			"label": "Veza za ponovno postavljanje lozinke nije tačna! Molimo vas, da ponovo pošaljite zahtjev za promjenu lozinke i slijedite uputom!",
			"actions": [
				"Kliknite ovdje!"
			]
		},
		"emailVerificationRequestSuccess": {
			"label": "Veza je poslana na vaš e-mail kako bi potvrdili vašu e-adresu!"
		},
		"emailNotVerified": {
			"label": "Vaš e-mail nije potvrđen. Potvrdite autentičnost unesene adrese e-maila putem veze poslane na vašu e-mail adresu. Ako još jednom niste dobili e-poštu, pokušajte ponovo.",
			"actions": [
				"Šaljite potvrdu e-mailom."
			]
		},
		"emailVerificationRequestError": {
			"label": "Došlo je do greške, pokušajte ponovo!"
		},
		"addBetSuccess": {
			"label": "Oklada je uspješno objavljena.",
			"actions": [
				"Klikni ovdje!"
			]
		},
		"addBetError": {
			"label": "Došlo je do greške, pokušajte ponovo!"
		},
		"addStakeError": {
			"label": "Unesite ulog.!"
		},
		"duplicatedBetError": {
			"label": "Duplirana oklada! Ova oklada več je objavljena."
		},
		"betCombinationDuplicateError": {
			"label": "Duplirana oklada u tiketu! Izbrani tip oklade več postoji."
		},
		"duplicatedCombinationBetError": {
			"label": "Duplirana oklada u tiketu! Ovu okladu več ste stavili u tiket."
		},
		"removeActiveBetErrorSnackbar": {
			"label": "Okladu možede izbrisati samo 15 minuta nakon objave!"
		},
		"removeActiveBetSuccessSnackbar": {
			"label": "Uspešno ste izabrisali okladu."
		},
		"addFavoriteBetSnackbar": {
			"label": "Oklada je dodana kod omiljene oklade. Sve omiljene oklade možete vidjeti na svom profilu.",
			"actions": [
				"Kliknite ovdje!"
			]
		},
		"removeFavoriteBetSnackbar": {
			"label": "Oklada je uklonjena. Sve omiljene oklade možete vidjeti na svom profilu.",
			"actions": [
				"Klikni ovdje!"
			]
		},
		"favoriteBetErrorSnackbar": {
			"label": "Došlo je do greške, pokušajte ponovo!"
		},
		"fetchingMatchOddsInfo": {
			"label": "Osvježavam koeficiente za klađenje! Upit može potrajati nekoliko sekundi!"
		},
		"fetchingMatchOddsError": {
			"label": "Došlo je do greške, pokušajte ponovo!"
		},
		"addSubscriptionSnackbar": {
			"label": "Uspješno ste se naručili na tipstera. Svoje omiljene tipstere možete pratiti u svojim postavkama!",
			"actions": [
				"Moje postavke."
			]
		},
		"removeSubscriptionSnackbar": {
			"label": "Pretplata na odabranog tipstera je uspješno otkazana!"
		},
		"updateSubscriptionSnackbar": {
			"label": "Ažuriranje pretplate je uspješno."
		},
		"subscriptionErrorSnackbar": {
			"label": "Došlo je do greške, pokušajte ponovo!"
		},
		"pushNotificationTokenError": {
			"label": "Došlo je do greške, pokušajte ponovo!"
		},
		"updateUser": {
			"label": "Postavke su uspješno ažurirane."
		},
		"updateUserError": {
			"label": "Došlo je do greške, pokušajte ponovo!"
		},
		"iconUploadError": {
			"label": "Došlo je do greške, pokušajte ponovo!"
		},
		"iconSizeError": {
			"label": "Slika je prevelika! Visina i širina slike moraju biti manje od 50 piksela."
		},
		"fcmDifferentDevice": {
			"label": "Trenutni uređaj nije registrovan za primanje obavještenja. Provjerite postavke."
		},
		"fcmDifferentFcmToken": {
			"label": "Trenutni uređaj nije registrovan za primanje obavještenja. Provjerite postavke."
		},
		"fcmNotSupported": {
			"label": "Push obavijesti ne rade na ovom uređaju!"
		},
		"blockedDeviceNotificationsSnackbar": {
			"label": "Push obavijesti su blokirana za ovu stranicu. Da biste primali obavještenja, morate promijeniti dozvole za obavještavanje u svom pretraživaču!"
		},
		"supportTicketSendSuccess": {
			"label": "Izvještaj o grešci je uspješno poslan. Odgovorit ćemo vam u najkraćem mogućem roku."
		},
		"emailRequired": {
			"label": "Molimo ukucajte svoju e-mail adresu!"
		}
	}
}