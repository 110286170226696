import * as firebase from '../../../../config/firebase'
import logger from '../../../../helpers/logger';

import {
    SET_ACTIVE_SNACKBAR,
    SET_DEVICE_INFO 
} from "../../types";

export const fcmInit = ({cookies, setCookie, uuid})=> async (dispatch, getState) =>{

    if(Notification.permission === "granted"){

        let fcmToken = await firebase.getPushNotificationToken()
        
        let subscriptions = cookies['notification_device_subscription']
       
        if(!subscriptions){
            /*
            dispatch({
                type: SET_ACTIVE_SNACKBAR,
                name: "fcmDifferentDevice",
                status: "info",
            })
            */
            return 
        }
        
        if( fcmToken !== subscriptions.token){
             /*
            dispatch({
                type: SET_ACTIVE_SNACKBAR,
                name: "fcmDifferentFcmToken",
                status: "info",
            })
            */
        }

        dispatch({
            type: SET_DEVICE_INFO,
            payload: subscriptions
        }) 

    }else if(Notification.permission === "default"){
        let permission = await Notification.requestPermission()
        if(permission === "granted"){
            let fcmToken = await firebase.getPushNotificationToken()
        
            let subscriptions = cookies['notification_device_subscription']
           
            if(!subscriptions){
                /*
                dispatch({
                    type: SET_ACTIVE_SNACKBAR,
                    name: "fcmDifferentDevice",
                    status: "info",
                })
                */
                return 
            }
            
            if( fcmToken !== subscriptions.token){
                /*
                dispatch({
                    type: SET_ACTIVE_SNACKBAR,
                    name: "fcmDifferentFcmToken",
                    status: "info",
                })
                 */
            }
    
            dispatch({
                type: SET_DEVICE_INFO,
                payload: subscriptions
            }) 
        }
    }else{
        /*
        dispatch({
            type: SET_ACTIVE_SNACKBAR,
            name: "fcmNoPermission",
            status: "error",
        })
         */
    }
  
}
  
export const invokeFcmMessageListener = ()=> async dispatch =>{
    if(!firebase.messagingRef)return
    firebase.messagingRef.onMessage(payload => {
      
        dispatch({
            type: SET_ACTIVE_SNACKBAR,
            name: "fcmNotification",
            status: "info",
            label: `Tipster ${payload.data.tipster} je objavil novo stavo! ${payload.data.match} ${payload.data.matchTime ? new Date(payload.data.matchTime*1000).toLocaleString() : ""}`,
            actions: [`/bets/active?betId=${payload.data.betId}&orderBy=sportId&groupBy=${payload.data.sportId}`]
        })
    })
}


export const subscribeToTopic = ({fcmToken, tipsterId})=> async dispatch => {
    logger('function_call', "subscribeToTopic")

    try{
        const subscribeToTopic = firebase.cloudFunction.httpsCallable('fcmSubscribeToTopic')
        const res = await subscribeToTopic({fcmToken, topic: tipsterId})

    }catch(err){
        logger('exception', err.message, {err})
    }
}

export const unsubscribeFromTopic = ({fcmToken, tipsterId})=> async dispatch => {
    logger('function_call', "unsubscribeToTopic")

    try{
        const unsubscribeToTopic = firebase.cloudFunction.httpsCallable('fcmUnsubscribeFromTopic')
        const res = await unsubscribeToTopic({fcmToken, topic: tipsterId})
    }catch(err){
        logger('exception', err.message, {err})
    }

}