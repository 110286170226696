import React from 'react';
import classNames from 'classnames';
import {withRouter} from 'react-router'
import * as actions from '../../../../redux/actions'
import { connect } from "react-redux";
import { makeStyles, createStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import editWrapper from '../../../default/wrappers/editWrapper'
import CustomIcon from '../../../components/icons/CustomIcon'
import CustomButton from './CustomButton'
import useDictionary from '../../../../hooks/useDictionary';
import timestampFormatter from '../../../../helpers/timestampFormatter';
import roundNumber from './../../../../helpers/roundNumber'
import useMediaBreakPoint from '../../../../hooks/useMediaBreakPoint';

const TITLE_HEIGHT = 60

const BUTTON_HEIGHT = 45

const useStyles = makeStyles( theme => createStyles({
   
    title:{

    },
    selectedPage:{
        height: TITLE_HEIGHT,
      
        width: TITLE_HEIGHT,
        background: theme.palette.primary.main
    },
    buttonTypography:{
        fontSize: "clamp(1em, 3vw, 1.1em)"
    },
    profileTitle: {
        fontSize: "clamp(1em, 3vw, 1.5em)"
    },

    button:{
        cursor:"pointer",

        '&:hover':{
            background: "#1c76bf",
            boxShadow: theme.shadows[3]
        }
    },

    withoutTopBorder:{
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    }

}))

const StyledTabs = withStyles(theme=>({
  indicator: {
    display: 'flex',

    background: "none",

    '& > span': {
      width: '100%',
      backgroundColor: theme.palette.primary.main,
    },

    flexContainer: {
      width: "100%",

    }
  },
}))((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
      flexGrow: 1,
      maxWidth: 'none',
      textTransform: 'none',
      
      color: '#fff',
  
      background: theme.palette.background.transparent,
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.pxToRem(15),

      '&:first-child':{
          borderTopLeftRadius: theme.shape.borderRadius,
      },
      
      '&:last-child':{
          borderTopRightRadius: theme.shape.borderRadius,
      },
      
      '&:focus': {
          opacity: 1,
      },
  },
}))((props) => <Tab disableRipple {...props} />);

function HistoryBetInfoElement({ 
    title, 
    auth, 
    brdoparaData, 
    location, 
    history, 
    userGeoLocation,
    ...props
}){
    
    const profile = auth.userInfo
    const theme = useTheme()
    const breakpoint = useMediaBreakPoint()
    const classes = useStyles(props)
    const dictionary = useDictionary({elementId: "history_bet_info_element", userGeoLocation})
    const dateDictionary = useDictionary({elementId: "date", userGeoLocation})
    const betOptionsDictionary = useDictionary({elementId: "bet_options", userGeoLocation})

    const [selectedBetCombination, setSelectedBetCombination] = React.useState(0)
    const [bet, setBet] = React.useState(null)
  
    React.useEffect(()=>{
        let urlParams = new URLSearchParams(location.search)
        let betId = urlParams.get('betId')
        
        if(!betId)return
        
        let searchedBet = brdoparaData.historyBets !== "loading" && brdoparaData.historyBets[betId]
        
        if(searchedBet){
       
            searchedBet.matchStatus = true
            searchedBet.matchDateFormatted = timestampFormatter(searchedBet.matchTime, userGeoLocation)
            
             setBet(searchedBet.combination ? 
                  {...searchedBet, 
                    totalOdds: searchedBet.odds,
                    ...searchedBet.bets[selectedBetCombination]}: 
                  searchedBet)

        }else props.fetchHistoryBetById({betId})
        
    },[location, brdoparaData.historyBets])

    const handleTabChange = (event, newValue)=>{
      setSelectedBetCombination(newValue)
      
      setBet({
          ...bet, 
          betOption: null,
          ...bet.bets[newValue]
      })
    }

    if(bet){
        return (
            <Box height="100%" position="relative" >  
              <Fade in={bet.combination} timeout={0} mountOnEnter unmountOnExit>
                  <Box flexGrow={4} mb={1}>
                      <Box display="flex" justifyContent="space-between" alignItems="flex-end" mb={1} height={TITLE_HEIGHT}>
                          <CustomButton 
                              label={dictionary.titleCombination} 
                              height={TITLE_HEIGHT}
                              type="title"
                          />
                      </Box>
                          
                      <Box display="flex">
                          <Box flexGrow={1} display="flex" p={2}  flexDirection="column" justifyContent="center" color="text.secondary" bgcolor="background.transparent" borderBottom="1px solid black" borderRadius={4}>
                              <Box pb={1} color="white">
                                  <Typography variant={"h2"} noWrap={false}> 
                                      {dictionary.betStakeLabel}
                                  </Typography>
                              </Box>
                              <Box width="100%"  borderRadius={8} p={1} >
                                  <Typography variant={"body1"} noWrap={false}> {bet.stake ? bet.stake: "Nan"} </Typography>
                              </Box>     
                          </Box>

                          <Box flexGrow={1} display="flex" ml={1} p={2} flexDirection="column" justifyContent={"center"}  bgcolor="primary.main" borderRadius={4} color="text.primary">
                              <Box pb={1}>
                                  <Typography variant={"h2"} noWrap> {dictionary.combinationTotalOddsLabel}</Typography>    
                              </Box>
                              <Box p={1} >
                                  <Typography style={{fontWeight: 600, fontSize: "1.4em"}} variant={"body1"} noWrap>{bet.totalOdds}</Typography>
                              </Box>
                              
                          </Box>
                      </Box>
                  </Box>  
              </Fade>
              <Fade in={true} timeout={500} mountOnEnter unmountOnExit>
                <Box width="100%"  display="flex" flexDirection="column" borderRadius={2}>
                  <Box display="flex"  justifyContent="space-between" alignItems="flex-end" mb={1} height={TITLE_HEIGHT}>
                    <CustomButton 
                        label={dictionary.title} 
                        height={TITLE_HEIGHT}
                        type="title"
                    />
                    <CustomButton type="titleButton" label={timestampFormatter(bet.timestamp, dateDictionary, {withYear: true})} />
                  </Box>
                  {
                    bet.combination &&
                      <StyledTabs 
                          
                        value={selectedBetCombination} 
                        onChange={handleTabChange} 
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="tabs"
                        classes={{
                            scrollButtons: classes.scrollButtons,
                            
                        }}
                      >
                          
                          {
                            bet.bets.map((bet, i)=>
                                <StyledTab 
                                    value={i} 
                                    key={i} 
                                    label={(i+1)+". "+ dictionary.betCombinationNumLabel} 
                                />   
                            )
                          }

                      </StyledTabs>
                  }
                  <Box flexGrow={1} display="flex" p={1} bgcolor="background.transparent" color="text.secondary" className={classNames({[classes.withoutTopBorder]: bet.combination})}  borderRadius={4}> 
                      <Box fontSize={breakpoint === "xs" ? 60 : 80} display="flex" justifyContent="center" alignItems="center">
                        <CustomIcon name={bet.sportId}/>
                      </Box>
                      <Box flexGrow={4} display="flex" flexDirection="column" justifyContent="flex-start"  alignItems="flex-start" p={1} >
                        <Box borderBottom={1} display="flex" justifyContent="space-between" alignItems="flex-end" style={{borderColor: theme.palette.background.dark}} width="100%" >
                          <Typography variant="h2" align="left">{bet.match}</Typography>
                        </Box>
                        <Box pt={1}> 
                          <Typography variant="body1" >{bet.competition}</Typography>
                        </Box>
                      </Box>
                  </Box>
                </Box> 
              </Fade>
             
              <Fade in={true} timeout={1000} mountOnEnter unmountOnExit>
                <Box flexGrow={4}>
                  <Box display="flex" justifyContent="space-between" alignItems="flex-end" my={1}  height={TITLE_HEIGHT} >
                      <CustomButton 
                          label={dictionary.title2} 
                          height={TITLE_HEIGHT}
                          type="title"
                      />
                      <Box display="flex" pl={1} borderRadius={4}>
                        <Box 
                            borderRadius={4} 
                            height={BUTTON_HEIGHT}
                            bgcolor={bet.status === "lost" ? "error.main": bet.status === "won" ? "success.main": "lightgray"}
                            color="white" 
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            p={2}
                        >
                          <Typography 
                            className={classes.buttonTypography}
                            variant={"h2"} 
                            noWrap
                          > 
                            {bet.status === "lost" ? dictionary.betLost: bet.status === "won" ? dictionary.betWon: dictionary.betDeleted}
                          </Typography>
                        </Box>
                        <Box ml={1}>
                          <CustomButton type="titleButton" label={timestampFormatter(bet.matchTime, dateDictionary, {withYear: true})} />
                        </Box>
                        
                      </Box>
                  </Box>
                
                  <Box display="flex" >
                    <Box flexGrow={4} flexBasis={1} bgcolor="background.transparent" p={1} width="100%"  display="flex" flexWrap="wrap" borderRadius={4}>
                      
                      <Box flexGrow={2} flexBasis={1} display="flex" p={2}  flexDirection="column" justifyContent="center" color="text.secondary" >
                          <Box pb={1} color="white">
                              <Typography variant={"h2"} noWrap={false}>
                                {dictionary.betTypeLabel} 
                              </Typography>
                          </Box>
                          <Typography variant={"body2"} noWrap={false}> {betOptionsDictionary[bet.type] || bet.type} - {betOptionsDictionary[bet.typeOption] || bet.typeOption}</Typography> 
                      </Box>
                      <Box flexGrow={1} flexBasis={1} display="flex" p={2}  flexDirection="column" justifyContent="center" color="text.secondary" >
                          <Box pb={1} color="white">
                              <Typography variant={"h2"} noWrap={false}> 
                                {dictionary.betOddsLabel}
                              </Typography>
                          </Box>
                          
                          <Typography variant={"body1"} noWrap={false}> {bet.odds ? bet.odds: "Nan"} </Typography>
                      </Box>
                        
                      <Box flexGrow={1} flexBasis={1} display="flex" p={2}  flexDirection="column" justifyContent="center" color="text.secondary"  >
                        <Box pb={1}>
                            <Typography variant={"h2"} noWrap={false}>
                              {dictionary.betProfitLabel}
                            </Typography>
                        </Box> 
                        <Typography variant={"body1"} noWrap={false}> 
                        

                         { bet.status ==="won" ? roundNumber(bet.stake*bet.odds - bet.stake):bet.status === "lost" ? -bet.stake:0}
                        </Typography>
                          
                      </Box>
                      <Box flexGrow={1} flexBasis={1} display="flex" p={2}  flexDirection="column" justifyContent={"center"} borderRadius={2} color="text.secondary" >
                          <Box pb={1}>
                              <Typography variant={"h2"} noWrap> {dictionary.betReturnsLabel}</Typography>    
                          </Box>
                          
                          <Typography variant={"body1"} noWrap> {bet.stake} </Typography>
                      </Box>
                    
                    </Box>
                    <Box flexGrow={1} flexBasis={1} ml={1} p={2} display="flex"  flexDirection="column" justifyContent={"center"} bgcolor="primary.main" borderRadius={4} color="text.primary" >
                      <Box pb={1}>
                          <Typography variant={"h2"} noWrap>
                            {dictionary.betLabel} 
                          </Typography>    
                      </Box>
                      <Box>
                        <Typography style={{fontWeight: 500, fontSize: "1.4em"}} variant={"body1"} noWrap> 
                          {`${bet.bet} ${bet.betOption ? `(${bet.betOption})`: ""}`} 
                        </Typography>
                      </Box>
                    </Box>
                  </Box>   
                </Box>
              </Fade>
              
              <Fade in={true} timeout={1500} mountOnEnter unmountOnExit>
                <Box flexGrow={4}>
                  <Box display="flex"  justifyContent="space-between" alignItems="flex-end"  my={1}  height={TITLE_HEIGHT}>
                      
                      <CustomButton 
                          label={dictionary.title3} 
                          height={TITLE_HEIGHT}
                          type="title"
                      />

                      <CustomButton 
                          label={bet.userName}
                          type="titleButton"
                          onClick={()=>history.push(`/user/profile?userId=${bet.user}`)}
                      />
                  </Box>
                
                  <Box bgcolor="background.transparent" mt={1} borderRadius={4} p={1} width="100%">
                    {
                        bet.comment ? (
                            <Box p={2} display="flex"  width="100%" minHeight="150px" color="white" >
                                <Typography variant="body2" align="left">{bet.comment}</Typography>
                            </Box>
                        ):(
                            <Box p={1} display="flex" justifyContent="center" alignItems="center"  width="100%"  height="150px" color="white" >
                                <Typography variant="h2">{dictionary.noCommentLabel}</Typography>
                            </Box>
                        )
                    }
                  </Box>
                </Box> 
              </Fade>
            </Box>
        )
    }return null
    
}


HistoryBetInfoElement.defaultProps = {
    
    variant: "text",
    color: "main",
    elementStyle: {}, // style object of element from database
    style: {}, // style object is pushed to element if there is active animation present
    title: "Zadnje stave",
    dataType: "leaderBoardWeekly",

    elementClasses: [],
    bet:{
        "bet" : 2,
        "betType" : "home-away",
        "betTypeOption" : "2ndset",
        "competition" : "Ismaning Challenger Men Doubles (hard)",
        "date" : 1603227020416,
        "match" : "Kolar Z./Olivetti A. - Romboli F./Yang T.",
        "odd1" : 7.57,
        "odd2" : 1.87,
        "sport" : "tennis",
        "stake" : 44,
        "user" : "mn0ebaKmpVgHw4Yb5zZLuv07PAs1",
        "userName" : "stavnicar1"
      }
  };
  

const mapStateToProps = ({auth, brdoparaData, userGeoLocation}) => {
  return {
    auth,
    brdoparaData,
    userGeoLocation
  }
}

export default withRouter(connect(mapStateToProps, actions)(editWrapper(HistoryBetInfoElement)))
