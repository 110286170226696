export default {
	"header": {
		"title": "Bet with knowledge of the best",
		"description": "Create your own bets or follow others",
		"image": "https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/fbShareImage%2FhomeFb-min.jpg?alt=media&token=b576f9b1-f6c1-4968-9a15-70b94f7985bd",
		"profileTitle": "Bet with knowledge of the best",
		"profileDescription": "Create your own bets or follow others",
		"profileImage": "https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/fbShareImage%2FstatistikaFB-min.jpg?alt=media&token=51bb7c82-1dca-48b8-8fc7-764ff7ffe6a8",
		"betInfoTitle": "Bet with knowledge of the best",
		"betInfoDescription": "Create your own bets or follow others",
		"betInfoImage": "https://firebasestorage.googleapis.com/v0/b/brdopara2.appspot.com/o/fbShareImage%2FstavaFB-min.jpg?alt=media&token=1c89fe46-a835-481b-8b0c-0ef645aa6fe2"
	},
	"date": {
		"daysShort": [
			"Sun",
			"Mon",
			"Tue",
			"Wed",
			"Thu",
			"Fri",
			"Sat",
			
		],
		"monthsShort": [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Okt",
			"Nov",
			"Dec"
		]
	},
	"sports": {
		"soccer": "Football",
		"tennis": "Tennis",
		"basketball": "Basketball",
		"esports": "e-sports",
		"combination": "Combination",
		"handball": "Handball",
		"volleyball": "Volleyball",
		"hockey": "Hockey",
		"americanFootball": "American football"
	},
	"bet_options": {
		"1": "Win 1.",
		"2": "Win 2.",
		"X": "Draw",
		"1X2": "Draw",
		"over": "Over",
		"under": "Under",
		"1x2": "1X2",
		"home-away": "Home/Away",
		"ah": "Asian handicap",
		"cs": "Correct score",
		"dnb": "Draw No Bet",
		"dc": "Double chance",
		"eh": "European  handicap",
		"bothteamstoscore": "Both Teams To Score",	
		"o-e": "Odd or Even",
		"o-u": "Over/Under",
		"ftincludingot": "Full game + overtime",
		"fulltime": "Full game",
		"1sthalf": "1. half",
		"2ndhalf": "2. half",
		"1stset": "1. set",
		"2ndset": "2. set",
		"3rdset": " 3. set",
		"1q": "1. quarter",
		"2q": "2. quarter",
		"3q": "3. quarter",
		"4q": "4. quarter",
		"1stperiod": "1. period",
		"2ndperiod": "2. period",
		"3rdperiod": "3. period",
		"over-under": "Over/under"
	},
	"displayed_data_constructor": {
		"1": "Won 1.",
		"2": "Won 2.",
		"user": "Profile",
		"tipster": "Tipster",
		"graphData": "Graph",
		"betsNum": "Num. bets",
		"avrgWin": "Wins",
		"avrgWins": "Wins",
		"avrgOdds": "Avg. odd",
		"avrgStake": "Avg. stake",
		"roi": "ROI",
		"profit": "Profit",
		"sport": "Sport",
		"match": "Match",
		"odds": "Odds",
		"stake": "Stake",
		"time": "Starts at",
		"betTime": "Time",
		"betType": "Bet type",
		"bet": "Bet",
		"competition": "Competition",
		"date": "Date",
		"betStatus": "Status",
		"returns": "Returns",
		"stakeSum": "Stake sum",
		"winNum": "Bets won",
		"lostNum": "bets lost",
		"betWinner": "Winner",
		"comment": "Comment",
		"status": "Status",
		"allBets": "All bets",
		"withComment": "With comment",
		"combination": "Combination",
		"leaderBoardWeeklyTitle": "Top 10 weekly",
		"leaderBoardMonthlyTitle": "Top 10 monthly",
		"leaderBoardOverallTitle": "Top 10",
		"betInfo_allActiveBetsInfoBySportTitle": "Similar bets",
		"betInfo_allHistoryBetsTitle": "Bets history",
		"bets_allActiveBetsInfoTitle": "Active bets",
		"home_userSubscribedBetsButtonLabel": "Only subscribed bets",
		"home_userFavoriteBetsButtonLabel": "Only favorite bets",
		"home_userSubscribedBetsTitle": "Subscribed bets",
		"home_userBetsTitle": "My bets",
		"home_allActiveBetsInfoButtonLabel": "All bets",
		"home_userFavoriteBetsTitle": "Favorite bets",
		"home_userBetsButtonLabel": "My bets",
		"myProfile_myActiveBetsTitle": "My bets",
		"myProfile_subscribedActiveBetsButtonLabel": "Subscribed",
		"myProfile_userHistoryBetsButtonLabel": "History",
		"myProfile_subscribedActiveBetsTitle": "Subscribed bets",
		"myProfile_favoriteActiveBetsButtonLabel": "Favorites",
		"myProfile_myInactiveBetsButtonLabel": "Unconfirmed",
		"myProfile_myActiveBetsButtonLabel": "My bets",
		"myProfile_myInactiveBetsTitle": "Unconfirmed bets",
		"myProfile_favoriteActiveBetsTitle": "Favorite bets",
		"myProfile_userHistoryBetsTitle": "Bets history",
		"userProfile_userActiveBetsInfoTitle": "Active bets",
		"userProfile_userHistoryBetsTitle": "Bets history",
		"userProfile_userActiveBetsInfoButtonLabel": "Active",
		"userProfile_userHistoryBetsButtonLabel": "History",
		"tipsters_leaderBoardMonthlyTitle": "TIPSTERS",
		"tipsters_leaderBoarWeeklyButtonLabel": "Weekly statistic",
		"tipsters_leaderBoardOverallButtonLabel": "All tipsters",
		"tipsters_leaderBoarWeeklyTitle": "TIPSTERS",
		"tipsters_leaderBoardMonthlyButtonLabel": "Monthly statistic",
		"tipsters_leaderBoardOverallTitle": "TIPSTERS",
		
		"daysShort": [
			"Sun",
			"Mon",
			"Tue",
			"Wed",
			"Thu",
			"Fri",
			"Sat",
			
		],
		"monthsShort": [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Okt",
			"Nov",
			"Dec"
		],
		
		"X": "Draw",
		"1X2": "Draw",
		"over": "Over",
		"under": "Under",
		"1x2": "1X2",
		"home-away": "Home/Away",
		"ah": "Asian handicap",
		"cs": "Correct score",
		"dnb": "Draw No Bet",
		"dc": "Double chance",
		"eh": "European  handicap",
		"o-e": "Odd or Even",
		"o-u": "Over/Under",
		"bothteamstoscore": "Both Teams To Score",	
		"ftincludingot": "Full game + overtime",
		"fulltime": "Full game",
		"1sthalf": "1. half",
		"2ndhalf": "2. half",
		"1stset": "1. set",
		"2ndset": "2. set",
		"3rdset": " 3. set",
		"1q": "1. quarter",
		"2q": "2. quarter",
		"3q": "3. quarter",
		"4q": "4. quarter",
		"1stperiod": "1. period",
		"2ndperiod": "2. period",
		"3rdperiod": "3. period",
		"over-under": "Over/under"
	},
	"home_element": {
		"homeTitle2": "BET THE KNOWLEDGE",
		"homeTitle3": "OF THE BEST.",
		"homeButton": "JOIN US",
		"howPhoneSubTitle1": "Create",
		"howPhoneTitle1": "Account",
		"howPhoneSubTitle2": "Create or Follow",
		"howPhoneTitle2": "Your own bets",
		"howPhoneSubTitle3": "Follow",
		"howPhoneTitle3": "Your achievements",
		"tipstersTitle": "TOP 5 TIPSTERS OF THE MONTH"
	},
	"login_box_element": {
		"backButton": "BACK",
		"confirmButton": "CONFIRM",
		"forgotPasswordTitle": "Forgot password",
		"forgotPasswordEmailInputLabel": "E-mail",
		"forgotPasswordInfo": "Upon conformation password change a request will be send to your e-mail. For more information, how to change your password, follow up instructions written in received e-mail.",
		"resetPasswordTitle": "Password change",
		"resetPasswordPasswordInputLabel": "Password",
		"resetPasswordRetryPasswordInputLabel": "Same password",
		"resetPasswordInfo": "Upon conformation password change a request will be send to your e-mail. For more information, how to change your password, follow up instructions written in received e-mail.",
		"verifyEmailTitle": "E-MAIL CONFORMATION",
		"emailVerificationStatusSuccess": "E-mail was confirmed successfully!",
		"emailVerificationStatusError": "Ups! There was an error. Try again!",
		"unverifiedAccountInfo": "Your email address is not confirmed. Pleas follow up instructions send to your email. If you did not receive a conformation email, click on button bellow to resend it.",
		"resendVerificationEmailButton": "Resend email verification",
		"registerTitle": "Registration",
		"loginTitle": "Login",
		"loginInputEmailLabel": "E-mail",
		"loginInputPasswordLabel": "Password",
		"loginInputRetryPasswordLabel": "Email conformation",
		"loginSeparator": "or",
		"loginButtonLabel": "Login",
		"registerButtonLabel": "Register"
	},
	"navbar_element": {
		"loginButton": "Login",
		"homeButton": "Home"
	},
	"add_bet_element": {
		"modalWindowLabel": "Bet is not confirmed, all entered data will be lost! Want to leave anyway?",
		"title": "Select bet",
		"sportComboBoxLabel": "Select sport",
		"competitionComboBoxLabel": "Select tournament/competition",
		"matchComboBoxLabel": "Select match",
		"matchComboBoxOptionStatus": "In progress or Finished",
		"betTypeComboBoxLabel": "Select bet type",
		"betTypeOptionComboBoxLabel": "Select bet type option",
		"title2": "Bet slip",
		"commentTextInputLabel": "Comment",
		"commentTextInputPlaceholder": "Comment...",
		"betOdds": "Odds",
		"betStake": "Stake",
		"stakeInputPlaceholder": "Enter stake",
		"betReturns": "Return",
		"backButton": "Back",
		"confirmBetButton": "Create new bet"
	},
	"box_with_drawer_element": {
		"userSettingsButton": "Settings",
		"userLogout": "Logout",
		"newBetButton": "New bet",
		"activeBetsButton": "All bets",
		"userProfileButton": "My profile",
		"subscribedBetsButton": "Subscribed bets",
		"favoriteBetsButton": "Favorite bets",
		"userBetsButton": "My bets",
		"userBetsHistoryButton": "My bets history",
		"tipstersButton": "Tipsters",
		"trophiesButton": "Trophies",
		"bettingSitesButton": "Betting sites",
		"userSupportButton": "Help",
		"userInitializationModalTitle": "Create your profile",
		"userInitializationModalButton": "Confirm",
		"userInitializationModalDisplayNameInputLabel": "Display name",
		"userInitializationModalUserIconInputLabel": "Upload profile image",
		"userInitializationModalUserTypeButton": "User",
		"userInitializationModalTipsterTypeButton": "Tipster",
		"userInitializationModalEmailInputLabel": "Email",
		"userInitializationModalLanguageInputLabel": "Language",
		"userInitializationModalUserInfo1": "With FOLLOWER account you can follow your favorite tipsters and choose your favorite bets. You can make your own statistic from bets you selected as favorite.",
		"userInitializationModalUserInfo2": "With TIPSTER account you can create your own bets and share them with all users. Make your statistic and compete with other TIPSTERS on different leader boards. Most successful tipsters will be eligible for nice rewards.",
		"userInitializationModalTipsterInfo": "Because of safety reasons, all TIPSTERS account needs to be confirmed by admin.",
		"userSettingsModalTitle": "My settings",
		"userSettingsModalButton": "Confirm",
		"userSettingsModalDescription": "By following this bet, it will automatically save it to your favorite bets list.",
		"userSettingsModalInfo": "All your favorite bets will be added to your own statistic. You can easily remove bet from your favorite bets list at any time, unless match is already active or the match has started.",
		"verifiedEmailSnackbarDescription": "Your email address is not confirmed. Your functions are limited.",
		"verifiedEmailSnackbarLinkLabel": "Send email for activation",
		"userTermsAckLabel": "I am 18 yeas old or older and I agree with following user terms.",
		"userTermsAckLinkLabel": "More about user terms."
	},
	"bet_info_element": {
		"confirmButton": "Confirm",
		"title": "Match",
		"title2": "Bet",
		"title3": "Comment",
		"titleCombination": "Combination",
		"matchFinished": "Finished",
		"matchStarts": "Started",
		"betCombinationNumLabel": "Bet",
		"unFollowBetButtonLabel": "Following",
		"followBetButtonLabel": "Follow this bet",
		"combinationTotalOddsLabel": "Combined odds",
		"betTypeLabel": "Bet type",
		"betOddsLabel": "Odds",
		"betLabel": "Bet",
		"betMoreInfoLabel": "For more information",
		"betMoreInfoButton": "Subscribe",
		"betStakeLabel": "Stake",
		"noCommentLabel": "No comment",
		"betAddToFavoriteModalTitle": "Follow this bet",
		"betAddToFavoriteModalDescription": "If you follow this bet, it will be stored under favorite bets in your profile.",
		"betAddToFavoriteModalInfo": "",
		"betRemoveFromFavoriteModalTitle": "Remove bet",
		"betRemoveFromFavoriteModalDescription": "Bet will be removed from your favorite bets section.",
		"betRemoveFromFavoriteModalInfo": "All your favorite bets will be added to your own statistic. You can easily remove bet from your favorite bets list at any time, unless match is already active or the match has started."
	},
	"bets_list_box_element": {
		"allSportsTabLabel": "All"
	},
	"history_bet_info_element": {
		"title": "Match",
		"title2": "Bet",
		"title3": "Comment",
		"betLost": "Lost",
		"betWon": "Won",
		"betDeleted": "Deleted",
		"betTypeLabel": "Bet type",
		"betOddsLabel": "Odds",
		"betLabel": "Bet",
		"betReturnsLabel": "Stake",
		"betProfitLabel": "Profit",
		"noCommentLabel": "No comment",
		"titleCombination": "Combination",
		"combinationTotalOddsLabel": "Combined odds",
		"betStakeLabel": "Stake",
		"betCombinationNumLabel": "Bet"
	},
	"profile_box_element": {
		"confirmButton": "Confirm",
		"title": "Profile",
		"title2": "Statistics",
		"title3": "Profits",
		"profitLabel": "Profit",
		"noStatisticLabel": "No statistics logged",
		"subscribedButtonLabel": "Subscribed",
		"subscribeButtonLabel": "Subscribe",
		"subscribedUsers": "Subscribers",
		"weeklyStatisticButtonLabel": "Weekly",
		"monthlyStatisticButtonLabel": "Monthly",
		"overallStatisticButtonLabel": "Yearly",
		"tipsterSubscribeModalInfo": "All following active bets can be seen on your profile. Choose the preferred bets and make your own personal bets chart."
	},
	"subscribe_tipster_modal": {
		"tipsterSubscribeModalInfo":  "All following active bets can be seen on your profile. Choose the preferred bets and make your own personal bets chart.",
		

		"editSubscriptionModalNotifyViaEmailLabel": "Get notified via email when new bets are added",
		"editSubscriptionModalPushNotificationsLabel": "Get notified via push notifications when new bets are added",
		"allowNotificationButtonLabel": "Allow notifications",
		"confirmSubscriptionButtonLabel": "Subscribe"
	},
	"register_user_box_element": {
		"title": "Join us",
		"emailInputLabel": "E-mail",
		"passwordInputLabel": "Password",
		"registerButtonLabel": "Registration",
		"info": "By registering, I acknowledge the terms and conditions!",
		"infoLink": "Click here for more details about the terms and conditions!"
	},
	"user_settings_element": {
		"confirmButton": "Confirm",
		"user": "User",
		"tipster": "Tipster",
		"title": "Profile settings",
		"saveButtonLabel": "Save",
		"displayNameInputLabel": "Username",
		"emailInputLabel": "Email",
		"descriptionInputLabel": "Short description",
		"profileImageTitle": "Profile picture",
		"profilePictureButtonLabel": "Upload a new photo",
		"accountTypeTitle": "Account type",
		"accountTypeButtonLabel": "Change the account type",
		"userSubscriptionsTitle": "My subscription",
		"subscribedFrom": "Subscribed since",
		"notifications": "Notifications",
		"noSubscriptions": "No subscriptions",
		"editSubscriptionModalNotifyViaEmailLabel": "E-mail notifications",
		"editSubscriptionModalNotifyViaEmailInfo": "For every new published bet you will automatically receive an e-mail notification.",
		"editSubscriptionModalPushNotificationsLabel": "Push notifications",
		"editSubscriptionModalPushNotificationsInfo": "For every new published bet you will automatically receive a new push notification.",
		"editSubscriptionModalPushNotificationsWarning": "Push notifications only work when using Google Chrome and Mozilla Firefox or the newest version of the Edge browser. This type of notifications are not supported by Apple and will not work on any Apple devices!",
		"removeSubscriptionModalTitle": "Cancel the subscription",
		"removeSubscriptionModalRemoveSubscriptionInfo": "Would you like to remove a subscription on a chosen profile?",
		"changeUserTypeModalTitle": "TYPE OF ACCOUNT",
		"changeUserTypeModalUserInfo": "With USER account you can follow your favorite tipsters and choose your favorite bets. You can make your own statistic from bets you selected as favorite.",
		"changeUserTypeModalTipsterInfo": "With TIPSTER account you can create your own bets and share them with all users. Make your statistic and compete with other TIPSTERS on different leader boards. Most successful tipsters will be eligible for nice rewards.",
		"changeUserTypeModalTipsterWarning": "Changing the type of your account will result in deletion of all betting history and statistics.",
		"promptMessage": "Refute all changes"
	},
	"list_box_element": {
		"noAvailableData": "No data"
	},
	"info_element": {
		"aboutTitle": "About company",
		"companyNameSubTitle": "Company name",
		"headquartersSubTitle": "Company address",
		"registrationNumberSubTitle": "VAT",
		"cookiesTitle": "Cookies",
		"privacyPolicyTitle": "Privacy policy",
		"userTermsTitle": "User terms"
	},
	"betting_sites_info_element": {},

	user_support_element:{
		title1: "Contact",
		title2: "Report issue",
		backButtonLabel: "Back",
		selectFieldLabel: "Select problem",
		selectFieldOption1: "Bet was wrongfully closed.",
		selectFieldOption2: "I do not receive bet notifications.",
		selectFieldOption3: "Bet notifications are not supported.",
		selectFieldOption4: "I can not subscribe to selected tipster.",
		selectFieldOption5: "I can not follow selected bet.",
		selectFieldOption6: "Other.",

		textFieldLabel: "Describe your issue",

		infoContent: "Pleas describe your problem as much as you can, so that we can locate and correct problem as fast as possible.",

		sendButtonLabel: "Send"
	},	

	"brdopara_snackbar_element": {
		"loginSuccess": {
			"label": "Login successful!"
		},
		"loginError": {
			"label": "Wrong e-mail or password!",
			"actions": [
				"Forgot your password?"
			]
		},
		"registrationSuccess": {
			"label": "Registration was successful!"
		},
		"registrationError": {
			"label": "Registration error! "
		},
		"registrationErrorMailAlreadyExist": {
			"label": "User with entered e-mail already exist!",
			"actions": [
				"Login here!"
			]
		},
		"registrationErrorWrongEmailOrPassword": {
			"label": "Wrong e-mail or password!"
		},
		"registrationErrorPasswordsNotMatching": {
			"label": "Entered passwords do not match! Pleas try again."
		},
		"passwordResetRequestSuccess": {
			"label": "Password change request was send successfully. Follow instruction written in received mail."
		},
		"passwordResetSuccess": {
			"label": "Your password was changed successfully. You can now login with your new password!"
		},
		"passwordResetError": {
			"label": "Ups! There was an error, please try again!"
		},
		"passwordResetErrorEmailNotFound": {
			"label": "Entered e-mail do not exist, please type correct e-mail address and try again!"
		},
		"passwordResetActionCodeError": {
			"label": "Password change link is broken, please check if you entered correct link or send password change request again!",
			"actions": [
				"Send password change request!"
			]
		},
		"emailVerificationRequestSuccess": {
			"label": "On your e-mail was send e-mail conformation link!"
		},
		"emailNotVerified": {
			"label": "Your email is not verified. Follow direction send to your email, to verify your email. If you did not receive email, click on following button",
			"actions": [
				"Send email verification link."
			]
		},
		"emailVerificationRequestError": {
			"label": "Ups! There was an error, please try again!"
		},
		"addBetSuccess": {
			"label": "Bet was published successfully!",
			"actions": [
				"Click here!"
			]
		},
		"addBetError": {
			"label": "Ups! There was an error, please try again!"
		},
		"addStakeError": {
			"label": "Enter your stake!"
		},
		"addFavoriteBetSnackbar": {
			"label": "Bet was added to your favorites. All your favorite bets you can check at your profile.",
			"actions": [
				"Click here!"
			]
		},
		"removeFavoriteBetSnackbar": {
			"label": "Bet was removed from favorites. All your favorite bets you can check at your profile.",
			"actions": [
				"Click here!"
			]
		},
		"favoriteBetErrorSnackbar": {
			"label": "Ups! There was an error, please try again!"
		},
		"fetchingMatchOddsInfo": {
			"label": "Refreshing bet odds! Process can take several seconds."
		},
		"fetchingMatchOddsError": {
			"label": "Ups! There was an error, please try again!"
		},
		"addSubscriptionSnackbar": {
			"label": "Subscription was successful. Your can manage your own subscriptions at your settings!",
			"actions": [
				"My settings"
			]
		},
		"removeSubscriptionSnackbar": {
			"label": "You unsubscribed successfully!"
		},
		"updateSubscriptionSnackbar": {
			"label": "You successfully updated subscription settings."
		},
		"subscriptionErrorSnackbar": {
			"label": "Ups! There was an error, please try again!"
		},
		"pushNotificationTokenError": {
			"label": "Ups! There was an error, please try again!"
		},
		"updateUser": {
			"label": "Settings were updated successfully."
		},
		"updateUserError": {
			"label": "Ups! There was an error, please try again!"
		},
		"iconUploadError": {
			"label": "Ups! There was an error, please try again!"
		},
		"iconSizeError": {
			"label": "Image is too large! Image height and width has to be lower than 50px."
		},
		"fcmDifferentDevice": {
			"label": "This device is not registered to receive notifications. Check your subscription settings."
		},
		"fcmDifferentFcmToken": {
			"label": "This device is not registered to receive notifications. Check your subscription settings."
		},
		"fcmNotSupported": {
			"label": "Push notifications are not supported on this device!"
		},
		"blockedDeviceNotificationsSnackbar": {
			"label": "Push notifications are blocked by browser. If you want to receive push notifications, you need to change notification permissions in your browser."
		},
		"supportTicketSendSuccess": {
			"label": "Ticket has been send successfully! We will respond as soon as possible."
		}
	}
}